import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Panel } from 'src/components/shared/Artboard';
import { PlusImg } from 'src/assets/icons';
import { Card } from '@mui/material';
import { useApplyDocumentOperation } from '../../../hooks/document';
import { Page } from '../../../types/models';
import { AddPageOperation, ReorderPageOperation } from '../../../store/document/operations';
import PageDropTarget from './PageDropTarget';
import { PageThumbnailViewModel } from './types';

function makeViewModel(
  page: Page,
  index: number,
  isSelected: boolean = false,
): PageThumbnailViewModel {
  return {
    ...page,
    url: `/document/${page.documentId}/pages/${index + 1}`,
    originalIndex: index,
    isSelected,
  };
}

/**
 * Lists the pages of the current document as thumbnails. Clicking on a page
 * navigates to the page. Dragging pages reorders them.
 *
 * This function is a container: It interacts with the global store to
 * retrieve the list of pages for the current document and to apply changes.
 */
export default function PagesList(props: {
  documentId: string,
  pages: Page[],
  selectedPage: Page | null
}) {
  const { documentId, pages: originalPages, selectedPage } = props;
  const applyDocumentOperation = useApplyDocumentOperation();
  const navigate = useNavigate();

  // Initialize rendered pages upon the first page load.
  // The component maintains a list of the "original" pages; that is,
  // those that are saved in the document store. The "rendered" pages
  // are those that are displayed at any given time in the UI. The reason
  // for the distinction is to permit preview of the new order when dragging
  // a page around. Once the page is dropped, the global store and local
  // component state should be the same.
  const [renderedPages, setRenderedPages] = useState<PageThumbnailViewModel[]>();
  useEffect(() => {
    setRenderedPages(originalPages.map((page, index) => (
      makeViewModel(page, index, page === selectedPage)
    )));
  }, [originalPages, selectedPage]);

  // Move the page (at dragIndex) to its new position (at dropIndex) and adjust
  // the order of all other pages. This is similar to the logic implements in
  // ReorderPageOperation, but the changes are not applied to the store.
  const onPageOver = useCallback((dragIndex: number, dropIndex: number) => {
    const newPages: PageThumbnailViewModel[] = originalPages?.map((page, index) => (
      makeViewModel(page, index, page === selectedPage)
    )) || [];
    const page = newPages.splice(dragIndex, 1)[0];
    newPages.splice(dropIndex, 0, page);
    setRenderedPages(newPages);
  }, [originalPages, selectedPage]);

  // When the page is actually dropped, apply the reorder operation.
  const onPageDrop = useCallback((dragIndex: number, dropIndex: number) => {
    if (dragIndex === dropIndex) {
      return;
    }
    const originalPage = originalPages[dragIndex];
    applyDocumentOperation(
      new ReorderPageOperation(originalPage.documentId!, originalPage.id, dropIndex),
    );
    navigate(`/document/${documentId}/pages/${dropIndex + 1}`);
  }, [originalPages]);

  const onAddPage = useCallback(async () => {
    applyDocumentOperation(new AddPageOperation(documentId));

    // Navigate to the new page.
    navigate(`/document/${documentId}/pages/${originalPages.length + 1}`);
  }, [documentId]);

  if (!renderedPages) {
    return null;
  }

  return (
    <Panel preferredWidth="180px">
      <DndProvider backend={HTML5Backend}>
        {renderedPages.map((page, index) => (
          <PageDropTarget
            onPageOver={onPageOver}
            onPageDrop={onPageDrop}
            key={page.originalIndex}
            page={page}
            index={index}
          />
        ))}
      </DndProvider>

      <Card
        elevation={2}
        onClick={onAddPage}
        sx={{
          marginLeft: '40px',
          width: '70px',
          textAlign: 'center',
          marginTop: '24px',
          marginBottom: '10px',
          paddingTop: '14px',
          paddingBottom: '12px',
          cursor: 'pointer',

          '&:hover': {
            backgroundColor: '#f5f5f5',
          },
        }}
      >
        <img src={PlusImg} alt="+" />
      </Card>
      {/* <IconButton
        onClick={onAddPage}
        src={PlusImg}
        alt="+"
        label="Add Page"
      >
        {isAddingPage ? '...' : 'Add Page'}
      </IconButton> */}
    </Panel>
  );
}
