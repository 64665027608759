import React, { useEffect, useMemo, useState } from 'react';
import { NativeSelect } from '@mui/material';
import { styled } from '@mui/system';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from '../../components/buttons';
import {
  FlexBox, Form, GradientContainer, Input, Logo, VerificationEmailSent,
} from '../../components';
import {
  Description, ErrorMessage, Link, Title,
} from '../../components/typography';
import { theme } from '../../utils';
import * as UserAPI from '../../api/User';
import { useUserStore } from '../../zustand/user';
import { lang } from '../../lang';

dayjs.extend(localeData);

const Select = styled(NativeSelect)(() => ({
  backgroundColor: 'transparent',
  border: `1px solid ${theme.palette.primary.main}`,
  width: 121,
  height: 51,
  padding: 0,
  textAlign: 'center',
  cursor: 'pointer',
  outline: 'none',
  outlineColor: 'transparent',
  justifyContent: 'center',
  alignItems: 'center',
  textAlignLast: 'center',

  '&::before': {
    borderBottom: 'none !important',
  },

  '&::after': {
    borderBottom: 'none !important',
  },

  svg: {
    top: '25%',
  },

  select: {
    width: 'fill-available',
    height: 'fill-available',
    textAlign: 'center',

    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
}));

function BirthdayForm() {
  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [joinCode, setJoinCode] = useState('');
  const [isMinor, setIsMinor] = useState<boolean | null>(null);
  const [emailSent] = useState(false);
  const [userType, setUserType] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const ageValidation = useMemo(
    () => dayjs().diff(dayjs(`${month}/${day}/${year}`), 'year') > 13,
    [day, month, year],
  );

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();

    try {
      if (!ageValidation && !joinCode && user.role !== 'student') setIsMinor(true);
      else if (user.id) {
        const response = await UserAPI.updateUser({
          userId: user.id,
          birthdate: `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`,
        });
        if (response.status !== 200) throw response.data;
        setUser({
          ...user,
          ...response.data.data,
          actions: user.actions.filter((a: any) => a !== 'add_birthdate'),
        });
      } else {
        const form: any = {
          birthdate: `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`,
          type: userType,
        };
        if (email) form.email = email;
        if (username) form.username = username;
        if (joinCode) form.join_code = joinCode;

        const response = await UserAPI.signUp(form, userType === 'teacher');
        if (response.status !== 200 && response.status !== 201) throw response.data;
        setUser({
          ...user,
          ...response.data.data,
          actions: response.data.data.actions.filter((a: any) => a !== 'add_birthdate'),
        });
      }
    } catch (err: any) {
      setError(err.response.data?.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (error === lang('auth.errors.email_is_google_account')) {
      navigate(`/google-email?email=${email}`);
    }
  }, [error]);

  useEffect(() => {
    if (!user?.id && !searchParams.get('email') && !searchParams.get('gtoken') && !searchParams.get('username')) {
      navigate('/register', { replace: true });
    }
    if (searchParams.get('email')) setEmail(searchParams.get('email')!);
    else if (searchParams.get('username')) setUsername(searchParams.get('username')!);

    setUserType(searchParams.get('userType') || '');
    setJoinCode(searchParams.get('join_code') || '');
  }, []);

  const renderAgeError = () => (
    <>
      <FlexBox textAlign="center" width="100%" alignSelf="center" marginBottom={5}>
        <Title>
          {lang('auth.signup.underage.line_1')}
          <br />
          {lang('auth.signup.underage.line_2')}
        </Title>
      </FlexBox>
      <Description>
        {lang('auth.signup.parent_message')}
        {' '}
        <Link href="#support" fontSize="14px" fontWeight="bold">{lang('auth.signup.support_page')}</Link>
      </Description>
      <FlexBox width={310} alignSelf="center" marginTop={5}>
        <Button label={lang('auth.signup.back_to_homepage')} large onClick={() => { navigate('/'); }} />
      </FlexBox>
    </>
  );

  const renderBirthdayInput = () => (
    <>
      <FlexBox textAlign="center" width="100%" alignSelf="center" marginBottom={5}>
        <Title>{lang('auth.signup.birthday_title')}</Title>
      </FlexBox>

      <FlexBox alignSelf="center" marginTop={2}>
        <Form onSubmit={handleSubmit} style={{ alignSelf: 'center' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, auto)',
              gridGap: 12,
              marginBottom: 20,
            }}
          >
            <Select
              placeholder={lang('general.date.month')}
              aria-label="month"
              value={month}
              onChange={(e: any) => setMonth(e.target.value)}
              required
            >
              {[null, ...dayjs.months()].map((a, i) => {
                if (i === 0) return <option disabled value="" key="default">{lang('general.date.month')}</option>;
                return <option value={i} key={a}>{a}</option>;
              })}

            </Select>
            <Input
              style={{
                width: 121,
                height: 49,
                padding: 0,
                textAlign: 'center',
              }}
              required
              placeholder={lang('general.date.day')}
              min="1"
              max="31"
              type="number"
              aria-label="day"
              value={day}
              onChange={(e: any) => setDay(e.target.value)}
            />
            <Input
              style={{
                width: 121,
                height: 49,
                padding: 0,
                textAlign: 'center',
              }}
              required
              placeholder={lang('general.date.year')}
              type="number"
              min="1900"
              max={new Date().getFullYear()}
              aria-label="year"
              value={year}
              onChange={(e: any) => setYear(e.target.value)}
            />
          </div>
          <Button
            style={{ width: 310, margin: 'auto' }}
            type="submit"
            aria-label="submit"
            label={lang('auth.signup.signup')}
            disabled={!(month && day && year.length === 4) || loading}
            large
          />
        </Form>

        <ErrorMessage>
          {error}
        </ErrorMessage>
      </FlexBox>

      <Description size="small" alignSelf="center" marginTop={5} style={{ textAlign: 'center' }}>
        {lang('auth.signup.age_check_message.line_1')}
        <br />
        {lang('auth.signup.age_check_message.line_2')}
      </Description>
    </>
  );

  return (
    <GradientContainer justifyContent="center">
      <FlexBox textAlign="center" marginBottom={5}>
        <Logo />
      </FlexBox>

      {isMinor === true && renderAgeError()}

      {isMinor === null && emailSent && <VerificationEmailSent email={email} />}

      {isMinor === null && !emailSent && renderBirthdayInput()}
    </GradientContainer>
  );
}

export default BirthdayForm;
