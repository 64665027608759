import React, { useLayoutEffect, useState } from 'react';
import { Box, styled } from '@mui/system';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { theme } from '../../utils';
import { Artboard, FeedbackSideBar, TopBar } from '../../components';
import { useEditor } from '../../hooks/useEditor';
import { useDocumentStore } from '../../zustand/documents';
import { useUserStore } from '../../zustand/user';

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  width: '100vw',
  backgroundColor: theme.gradient.secondary.light,
}));

const Content = styled(Box)(() => ({
  display: 'block',
  height: 'max-content',
  width: 'fill-available',
}));

function PublishedPressto() {
  const { id: routeDocumentId } = useParams();
  const getDocumentInfo = useDocumentStore((state) => state.currentDocumentInfo);
  const user = useUserStore((state) => state.user);

  const locationState = useLocation() as { state: any };

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const { toggleViewMode } = useEditor();

  const loadDocument = async () => {
    try {
      if (routeDocumentId) {
        const response = await getDocumentInfo(routeDocumentId, true);

        if (!response) throw new Error('request error');

        setLoading(false);
      }
    } catch {
      navigate('/');
    }
  };

  useLayoutEffect(() => {
    toggleViewMode(true);
    loadDocument();
  }, []);

  return (
    <Container>
      <TopBar
        showBackButton
        viewOnlyMode
        professorView={false}
      />
      <Content>
        {user.role === 'teacher' && locationState.state?.showFeedback && <FeedbackSideBar />}
        <Artboard isLoading={loading} animateLoading />
      </Content>
    </Container>
  );
}

export default PublishedPressto;
