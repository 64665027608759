import { TextBoxType } from '../../types/LayoutEditor';

export default [
  {
    key: 'textbox1',
    type: 'TextBox',
    defaultFont: 'PARAGRAPH100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 48,
    y: 200,
    height: 1656,
    width: 984,
  } as TextBoxType,
];
