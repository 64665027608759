export default {
  and: 'and',
  save: 'Save',
  next: 'Next',
  skip: 'Skip',
  add: 'Add',
  cancel: 'Cancel',
  edit: 'Edit',
  delete: 'Delete',
  close: 'Close',
  duplicate: 'Duplicate',
  view: 'View',
  done: 'Done',

  pages: 'Pages',
  feedback: 'Feedback',

  loading: 'Loading',

  go: 'Go!',
  go_back: 'Go back',

  date: {
    day: 'Day',
    month: 'Month',
    year: 'Year',
  },

  found: 'Found',
  organize_by: 'Organize by',
  filter_by: 'Filter by',

  errors: {
    not_found: {
      code: '404',
      message: 'Page not found',
    },
  },

  gallery: 'Gallery',
  assignment: 'Assignments',
  classroom: 'Classroom',

  provider: {
    google: 'Google Classroom',
    pressto: 'Pressto',
    regular: 'Regular',
  },

  delete_account_default_message: 'Once you delete, it will be gone forever. Are you sure you want to delete?',
};
