import { Theme, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import GalleryTopBar from '../../components/GalleryTopBar';
import PresstoButton from '../../components/PresstoButton';
import { Description } from '../../components/typography';
import { ClassroomDefinition, PresstoDefinition } from '../../types/Gallery';
import { PresstoGrid } from '../gallery/styles';
import ClassroomPageContainer from './styles';
import { ReactComponent as BackArrow } from '../../assets/icons/back-arrow.svg';
import * as ClassroomAPI from '../../api/Classrooms';
import { useClassroomStore } from '../../zustand/classrooms';
import { lang } from '../../lang';

function StudentWorks({ classrooms }: { classrooms?: ClassroomDefinition[] }) {
  const mobileMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const classroomsStore = useClassroomStore((state) => state.classrooms);

  const [presstos, setPresstos] = useState<PresstoDefinition[]>([]);

  const navigate = useNavigate();

  const { cid, id } = useParams();
  const { name, surname } = useMemo(
    () => {
      const classroom = (classrooms || classroomsStore).find((cl) => cl.id === cid);
      return classroom?.students.find((student: any) => student.id === id) ?? { name: '', surname: '' };
    },
    [classrooms, cid, id],
  );

  useEffect(() => {
    if (!name) navigate(`/classroom/${cid}`);
  }, [name]);

  useEffect(() => {
    async function load() {
      if (!id) return;
      const response = await ClassroomAPI.getStudentsWork(id);

      setPresstos(response.data.data.filter((el: any) => el.version.status === 'PUBLISHED'));
    }

    load();
  }, [id]);

  const handleChangeTab = (tab: number) => {
    navigate('/gallery', { state: { tab } });
  };

  return (
    <>
      <GalleryTopBar tab={2} changeTab={handleChangeTab} />
      <ClassroomPageContainer style={{ flexDirection: 'column', gap: 0 }}>
        {!mobileMQ && (
          <div>
            <span
              className="classroom-back-button"
              role="button"
              tabIndex={-1}
              onClick={() => { navigate(`/classroom/${cid}`); }}
              onKeyDown={() => { navigate(`/classroom/${cid}`); }}
            >
              <BackArrow height={16} width={16} stroke="#999999" />
              <Description size="small">{lang('classroom.student_work.go_back')}</Description>
            </span>
          </div>
        )}
        <div className="main-content">
          <div>
            <Description>{lang('classroom.student_work.title')}</Description>
            <Description className="student-name">
              {name}
              {' '}
              {surname}
            </Description>
          </div>
          <PresstoGrid style={{ padding: '2.5rem 0 0 0' }}>
            <div className="content">
              {presstos.map((pressto) => (
                <PresstoButton
                  key={pressto.id}
                  pressto={pressto}
                  viewOnly
                  teacher
                />
              ))}
            </div>
          </PresstoGrid>
        </div>
      </ClassroomPageContainer>
    </>
  );
}

StudentWorks.defaultProps = {
  classrooms: undefined,
};

export default StudentWorks;
