import { Button } from '@mui/material';
import React, { useState } from 'react';
import { Instance } from '@popperjs/core';
import { ImageTooltip } from './styles';
import { lang } from '../../../../lang';

function ImageElement({
  element,
  onClick,
  isProcessing,
}: {
  element: {
    provider_id: string,
    url: string,
    author?: {
      name: string,
      url: string,
    }
  },
  onClick: any,
  isProcessing: boolean,
}) {
  if (!element.author) {
    return (
      <Button
        onClick={onClick}
        disabled={isProcessing}
      >
        <img src={element.url} alt={element.provider_id} />
      </Button>
    );
  }

  const positionRef = React.useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef<Instance>(null);
  const areaRef = React.useRef<HTMLImageElement>(null);

  const handleMouseMove = (event: React.MouseEvent) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  const [loaded, setLoaded] = useState(false);

  return (
    <ImageTooltip
      disableHoverListener={!loaded}
      disableFocusListener
      PopperProps={{
        popperRef,
        anchorEl: {
          getBoundingClientRect: () => {
            try {
              return new DOMRect(
                areaRef.current!.getBoundingClientRect().right + 85,
                positionRef.current.y - 20,
                0,
                0,
              );
            } catch (e) {
              return new DOMRect();
            }
          },
        },
      }}
      title={(
        <>
          {lang('document.upload_dialog.image_tooltip.line_1')}
          {' '}
          <a href={`${element.author.url}?utm_source=pressto&utm_medium=referral`} rel="noreferrer" target="_blank">{element.author.name}</a>
          {' '}
          {lang('document.upload_dialog.image_tooltip.line_2')}
          {' '}
          <a href="https://unsplash.com/?utm_source=pressto&utm_medium=referral" rel="noreferrer" target="_blank">Unsplash</a>
        </>
      )}
    >
      <span>
        <Button
          onClick={onClick}
          disabled={isProcessing}
          style={{
            border: 'none',
          }}
        >
          <img
            src={element.url}
            alt={element.provider_id}
            ref={areaRef}
            onMouseMove={handleMouseMove}
            onLoad={() => setLoaded(true)}
            style={!loaded ? { visibility: 'hidden' } : {}}
          />
          {!loaded && (
            <div style={{
              position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'grid', aspectRatio: '1',
            }}
            />
          )}
        </Button>
      </span>
    </ImageTooltip>
  );
}

export default ImageElement;
