/**
 * These are miscellaneous API-dependent interactions that are not otherwise
 * encapsulated by hooks or operations.
 *
 * An architectural goal is to encapsulate API and store interactions in
 * hooks. This file is an escape-hatch for that pattern. Add new functions
 * here cautiously.
 */
import * as DocumentsAPI from 'src/api/Documents';
import * as NotesAPI from 'src/api/Notes';
import { CreatableNote } from 'src/components/shared/note/types';
import * as models from 'src/types/models';
import { useDocumentStore } from 'src/store/document';

export async function saveDocument(
  documentId: string,
  data: Partial<Pick<models.Document, 'name' | 'meta'>>,
) {
  const update: typeof data = {};
  if (data.name) {
    update.name = data.name;
  }
  if (data.meta) {
    update.meta = JSON.stringify(data.meta);
  }
  await DocumentsAPI.updateDocument(documentId, update);

  useDocumentStore.setState((state) => {
    const newDocument = {
      ...state.documentsById[documentId],
    } as models.Document;

    if (data.name) {
      newDocument.name = data.name;
    }
    if (data.meta) {
      newDocument.meta = data.meta;
    }

    return {
      ...state,
      documentsById: {
        ...state.documentsById,
        [documentId]: newDocument,
      },
    };
  });
}

export async function saveNote(
  documentId: string,
  newNote: CreatableNote,
) {
  const document = useDocumentStore.getState().documentsById[documentId];

  const { data: { data: noteData } } = await NotesAPI.create({
    text: newNote.text,
    document_version_id: document!.version.id,
  });

  const updatedNote = {
    id: noteData.id,
    text: noteData.text,
    assignmentId: noteData.assignment_id,
    versionId: noteData.document_version_id,
    user: {
      id: noteData.user.id,
      name: noteData.user.name,
      surname: noteData.user.surname,
      fullName: noteData.user.full_name,
    },
    createdAt: new Date(noteData.created_at),
    updatedAt: new Date(noteData.updated_at),
  };

  useDocumentStore.setState((state) => {
    const newVersion = {
      ...state.documentsById[documentId]?.version,
    } as models.DocumentVersion;

    if (newNote.id) {
      // Update note.
      newVersion.notes = newVersion.notes.map((note) => {
        if (note.id === newNote.id) {
          return {
            ...note,
            ...updatedNote,
          };
        }
        return note;
      });
    } else {
      // Create note.
      newVersion.notes = [...newVersion.notes, updatedNote];
    }

    return {
      ...state,
      documentsById: {
        ...state.documentsById,
        [documentId]: {
          ...state.documentsById[documentId] as models.Document,
          version: newVersion,
        },
      },
    };
  });
}
