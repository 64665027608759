import React from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';

import { Description } from '../../../typography';
import { Button } from '../../../buttons';
import { lang } from '../../../../lang';
import { ReactComponent as LeftArrow } from '../../../../assets/icons/arrow-left.svg';

import { IDialogSharedLayoutProps } from './types';
import {
  DialogTitle, DialogContent, DialogBack, DialogContainer,
} from './style';

export default function DialogSharedLayout({
  titleLangKey,
  subtitleLangKey,
  handleNext,
  handleBack,
  disableNextButton = false,
  disableSaveButton = false,
  handleSkip,
  handleSave,
  children,
}: IDialogSharedLayoutProps) {
  const mobileMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <DialogContainer>
      {handleBack && (
        <DialogBack onClick={handleBack}>
          <LeftArrow height={16} width={16} stroke="#A8A7CC" />
          <Description size="small">{lang('document.create_pressto_dialog.go_back')}</Description>
        </DialogBack>
      )}

      <DialogTitle>
        <Description>{lang(titleLangKey)}</Description>
        {
          subtitleLangKey && (
            <Description>{lang(subtitleLangKey)}</Description>
          )
        }
      </DialogTitle>

      <DialogContent>
        <div className="content-wrapper">
          {children}
        </div>

        <div className="dialog-actions">
          {
            handleSkip && (
              <Button
                outline
                label={lang('general.skip')}
                onClick={() => handleSkip()}
                large={mobileMQ}
              />
            )
          }
          {
            handleNext && (
              <Button
                label={lang('general.next')}
                onClick={() => handleNext()}
                large={mobileMQ}
                disabled={disableNextButton}
              />
            )
          }
          {
            handleSave && (
              <Button
                label={lang('general.save')}
                onClick={() => handleSave()}
                large={mobileMQ}
                disabled={disableSaveButton}
              />
            )
          }
        </div>
      </DialogContent>
    </DialogContainer>
  );
}
