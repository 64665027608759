import { ButtonUnstyled } from '@mui/base';
import { styled } from '@mui/system';
import { theme } from '../../../utils';
import { ButtonStyleProps } from './types';

export const ButtonComponent = styled(ButtonUnstyled, {
  shouldForwardProp: (prop) => prop !== 'large' && prop !== 'outline' && prop !== 'clicking',
})<ButtonStyleProps>(({ large, outline, clicking }) => ({
  backgroundColor: outline ? 'white' : theme.palette.primary.main,
  color: outline ? theme.palette.primary.main : theme.palette.primary.contrastText,
  border: outline ? `0.125rem solid ${theme.palette.primary.main}` : '0.125rem solid transparent',
  outline: 'none',
  // fontSize: large ? '1rem' : '0.875rem',
  fontWeight: 700,
  width: large ? '100%' : 'fit-content',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  padding: '0.875rem 1.5rem',
  transition: 'all 150ms ease',
  position: 'relative',
  overflow: 'hidden',

  '&:hover': {
    backgroundColor: outline ? theme.palette.primary.dark : '#7D7CB3',
    color: 'white',
  },

  '&:disabled': {
    opacity: 0.5,
    backgroundColor: outline ? 'white' : theme.palette.secondary.main,
    cursor: 'not-allowed',
    color: theme.palette.secondary.contrastText,
  },

  '&::before': clicking && {
    content: '""',
    position: 'absolute',
    borderRadius: '50%',
    backgroundColor: '#A8A7CC',

    width: '100px',
    height: '100px',
    marginTop: '-50px',
    marginLeft: '-50px',

    top: '50%',
    left: '50%',

    animation: 'ripple 1s',
    opacity: 0,
  },

  /* Add animation */
  '@keyframes ripple': {
    from: {
      opacity: 0.5,
      transform: 'scale(0)',
    },

    to: {
      opacity: 0,
      transform: 'scale(10)',
    },
  },
}));
