import { Card } from '@mui/material';
import React from 'react';

import { Title } from '../../typography';

import {
  BlocksItemCard,
  BlockIcon,
} from './styles';
import { IBlocksItemProps } from './types';

function BlocksItem({
  image,
  title,
  children,
  elevation = 2,
}: IBlocksItemProps) {
  return (
    <BlocksItemCard>
      { children ? (
        <BlockIcon>
          {children}
        </BlockIcon>
      ) : (
        <>
          <Card elevation={elevation} sx={{ marginBottom: 0.5 }}>
            <BlockIcon>
              <img
                src={image}
                alt={`Card of block ${title}`}
              />
            </BlockIcon>
          </Card>
          <Title className="block-title" fontWeight="500">
            {title}
          </Title>
        </>
      )}
    </BlocksItemCard>
  );
}

export default BlocksItem;
