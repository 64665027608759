import {
  Dialog, DialogContent as DialogContentUnstyled,
} from '@material-ui/core';
import { styled } from '@mui/system';
import { theme } from '../../utils';

export const DialogContainer = styled(Dialog)(() => ({
  '& > div > div': {
    position: 'relative',
    width: '31.25rem',
    maxWidth: '75vw',
    maxHeight: '70vh',

    border: '1px solid #52509A',
    borderRadius: '4px',
    boxShadow: '4px 4px 0px #52509A',

    [theme.breakpoints.down('md')]: {
      height: '100%',
      width: '100%',
      maxWidth: '95vw',
      maxHeight: '95vh',
      margin: '0rem',
    },

    '.back-button': {
      position: 'absolute',
      top: '1.5rem',
      left: '1.5rem',
      display: 'flex',
      gap: '.5rem',
      cursor: 'pointer',
    },

    '.close-button': {
      position: 'absolute',
      display: 'flex',
      gap: '.5rem',
      top: 0,
      right: 0,

      [theme.breakpoints.down('md')]: {
        top: '.5rem',
        right: '.5rem',

        p: {
          color: '#A8A7CC',
          fontSize: '.75rem',
        },
      },
    },
  },
}));

export const DialogTitle = styled('div')(() => ({
  display: 'grid',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gridGap: '1rem',

  p: {
    fontFamily: 'Inter',
    color: '#000',

    '&:first-of-type': {
      textAlign: 'center',
    },
  },

  paddingTop: '2.5rem',
  paddingBottom: '1.75rem',
}));

export const DialogContent = styled(DialogContentUnstyled)(() => ({
  height: '100%',
  maxHeight: 'calc(100% - 6rem)',
  overflow: 'overlay',

  [theme.breakpoints.down('sm')]: {
    paddingLeft: '.5rem',
    paddingRight: '.5rem',
  },
}));
