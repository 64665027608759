import { Box, styled } from '@mui/system';
import { theme } from '../../utils';

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  width: '100vw',
  backgroundColor: theme.gradient.secondary.light,
}));

export const Content = styled(Box)(() => ({
  display: 'block',
  height: 'max-content',
  width: 'fill-available',
}));
