import React, { useMemo } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import PUBLIC_ROUTES from './public';
import PRIVATE_ROUTES from './private';
import ACTION_ROUTES from './actions';
import { useUserStore } from '../zustand/user';
import { DataCollector } from '../components';

function RouterRoot() {
  const user = useUserStore((state) => state.user);

  const checkRedirect = useMemo(() => {
    if (user?.actions) {
      switch (user.actions[0]) {
        case 'verify_email':
          return '/register-student';
        case 'add_birthdate':
          return '/birthday-form';
        case 'complete_user_data':
          return '/create-account';
        case 'add_school':
          return '/school-information-form';
        default:
          return '/gallery';
      }
    }
    return '/gallery';
  }, [user?.actions]);

  return (
    <BrowserRouter>
      <DataCollector />
      <Routes>
        {(user?.actions ?? []).length > 0 && (
          <>
            <Route
              path="/"
              element={<Navigate to={checkRedirect} replace />}
            />
            {
              ACTION_ROUTES.filter((e: any) => user.actions && e.action.includes(user.actions[0]))
                .map(({ component: Element, path, name }: any) => (
                  <Route key={name} path={path} element={<Element action={user.actions ? user.actions[0] : ''} />} />
                ))
            }
          </>
        )}
        {(!user?.actions || user?.actions?.length === 0) && (user?.id
          ? (
            <>
              <Route path="/" element={<Navigate to="/gallery" replace />} />
              {PRIVATE_ROUTES.map(({ component: Element, path, name }) => (
                <Route key={name} path={path} element={<Element />} />
              ))}
            </>
          )
          : PUBLIC_ROUTES.map(({ component: Element, path, name }) => (
            <Route key={name} path={path} element={<Element />} />
          )))}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default RouterRoot;
