import React from 'react';
import { TabPanelProps } from './types';

function TabPanel({
  children, tab, index, ...rest
}: TabPanelProps) {
  return (
    <div
      style={{ flexGrow: 1 }}
      hidden={tab !== index}
      role="tabpanel"
      {...rest}
    >
      {tab === index ? children : null}
    </div>
  );
}

export default TabPanel;
