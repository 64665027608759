import API from './config';

export function getAll() {
  return API.get('/classrooms');
}

export function getAllGoogle() {
  return API.get('/classrooms/providers/google');
}

export function connectGoogle(data: { code: string; }) {
  return API.post('/classrooms/providers/google/callback', data);
}

export function createClassroom(data: unknown) {
  return API.post('/classrooms', data);
}

export function importStudents(data: { classrooms: string[] }) {
  return API.post('/classrooms/providers/google', data);
}

export function getClassroomData(id: string) {
  return API.get(`/classrooms/${id}`);
}

export function getStudentsWork(id: string) {
  return API.get(`users/${id}/documents`);
}

export function deleteClassroom(id: string) {
  return API.delete(`/classrooms/${id}`);
}

export function syncWithGoogle(id: string) {
  return API.post(`/classrooms/${id}/sync-users`);
}

export function validateJoinCode(joinCode: string) {
  return API.get(`classrooms/joincode/${joinCode}/validate`);
}

export function saveFromGoogle(data: any) {
  return API.post('/classrooms/providers/google', data);
}

export function joinWithCode(data: { join_code: string }) {
  return API.post('classrooms/join-with-code', data);
}

export function removeStudent(classroomId: string, studentId: string) {
  return API.post(`/classrooms/${classroomId}/remove-student/${studentId}`);
}
