import { BlockTypesEnum } from 'src/types/models';
import BlocksIcons from '../../assets/blocks';

import {
  IBlocksModel,
  IBlocksContent,
} from './types';

const buildBlocksObject = (
  title: string,
  image: string,
  position: number,
): IBlocksContent => ({
  title,
  image,
  position,
});

// The following list will go away in favor of what's in src/types/models.
export default {
  [BlockTypesEnum.Text]: buildBlocksObject('Text', BlocksIcons.TextIcon, 1),
  [BlockTypesEnum.Title]: buildBlocksObject('Title', BlocksIcons.TitleIcon, 1),
  [BlockTypesEnum.Image]: buildBlocksObject('Image', BlocksIcons.ImgIcon, 7),
  [BlockTypesEnum.ImageCaption]: buildBlocksObject('Image Caption', BlocksIcons.ImgCaptionIcon, 8),
  [BlockTypesEnum.Introduction]: buildBlocksObject('Introduction', BlocksIcons.IntroductionIcon, 2),
  [BlockTypesEnum.Lead]: buildBlocksObject('Lead', BlocksIcons.IntroductionIcon, 2),
  [BlockTypesEnum.Quote]: buildBlocksObject('Quote', BlocksIcons.QuoteIcon, 5),
  [BlockTypesEnum.Conclusion]: buildBlocksObject('Conclusion', BlocksIcons.ConclusionIcon, 6),
  [BlockTypesEnum.MainIdea]: buildBlocksObject('Main idea', BlocksIcons.MainIdeaIcon, 3),
  [BlockTypesEnum.Details]: buildBlocksObject('Details (Who / What / Where / When / Why / How)', BlocksIcons.EyeDetailsIcon, 4),
  [BlockTypesEnum.SettingAndCharacters]: buildBlocksObject('Exposition: Setting and Characters', BlocksIcons.SettingCharacterIcon, 2),
  [BlockTypesEnum.ProblemOrGoal]: buildBlocksObject('Problem or Goal', BlocksIcons.ProblemGoalIcon, 3),
  [BlockTypesEnum.Attempts]: buildBlocksObject('Attempts to solve problem or reach goal', BlocksIcons.AttemptsIcon, 4),
  [BlockTypesEnum.StorySolution]: buildBlocksObject('Solution', BlocksIcons.SolutionIcon, 5),
  [BlockTypesEnum.ConsequenceOrResolution]: buildBlocksObject('Consequence or Resolution', BlocksIcons.ConsequenceIcon, 6),
  [BlockTypesEnum.PointOfView]: buildBlocksObject('Opinion or Point of View', BlocksIcons.PointOfViewIcon, 3),
  [BlockTypesEnum.Evidence]: buildBlocksObject('Reasons and Evidence', BlocksIcons.EvidencesIcon, 4),
  [BlockTypesEnum.Event]: buildBlocksObject('Step or Event', BlocksIcons.EventIcon, 3),
  [BlockTypesEnum.Cause]: buildBlocksObject('Cause', BlocksIcons.CauseIcon, 3),
  [BlockTypesEnum.Effect]: buildBlocksObject('Effect', BlocksIcons.EffectIcon, 4),
  [BlockTypesEnum.Compare]: buildBlocksObject('Similarity (Compare)', BlocksIcons.CompareIcon, 3),
  [BlockTypesEnum.Contrast]: buildBlocksObject('Difference (Contrast) ', BlocksIcons.ContrastIcon, 4),
  [BlockTypesEnum.Problem]: buildBlocksObject('Problem', BlocksIcons.ProblemIcon, 3),
  [BlockTypesEnum.Solution]: buildBlocksObject('Solution', BlocksIcons.HeartSolutionIcon, 4),
  [BlockTypesEnum.SupportingDetails]: buildBlocksObject('Supporting Details', BlocksIcons.EyeDetailsIcon, 4),
} as IBlocksModel;
