/* eslint-disable @typescript-eslint/naming-convention */
export const classroom_tab = {
  classroom_count: ({ count }: { count: number }) => `${count} Classroom${count !== 1 ? 's' : ''}`,
  students_count: ({ count }: { count: number }) => `${count} Student${count !== 1 ? 's' : ''}`,
  create: 'Invite Students',
};

export const classroom_page = {
  go_back: 'Go back to all classes',

  joincode: 'Classroom join code',
  delete: 'Delete classroom',

  sync: 'Sync with Google Classroom',
  syncing: 'Syncing...',

  delete_classroom_confirm: {
    title: 'Are you sure you want to delete this Classroom?',
    subtitle: ({ name }: { name: string }) => `If you delete ${name}, your students won’t be able to access Pressto again. Are you sure you want to go through this action?`,
  },

  last_session: 'Last session:',
};

export const remove_student_dialog = {
  title: 'Are you sure you want to remove this student?',
  subtitle: 'Removing a student from your class is a permanent action. The only way to get them back in is to have them rejoin your class.',
};

export const student_work = {
  go_back: 'Go back to all students',

  title: 'Published work from',
};

export const create_classroom_dialog = {
  step_0: {
    title: 'How do you want to invite students?',

    import: 'Import from Google classroom',
    sync: 'Sync and import your Classes',
    create: 'Create a Pressto Join Code',
    invite: 'Invite students directly',
  },

  step_1: {
    title: 'Create a Join Code',

    classroom_placeholder: 'Classroom name',
    subject_placeholder: 'Subject (optional)',
    section_placeholder: 'Section (optional)',

    submit: 'Create Join Code',
  },

  step_2: {
    title: 'Invite your students',
    subtitle: 'Share a class code with your students to join this class',

    copy: 'Click to copy the Code',
    copied: 'Copied!',

    submit: 'Finish',
  },

  step_3: {
    no_classroom: 'No classes found in your Google Classroom account',
    choose_classroom: 'Choose Google Classroom classes to import',

    classroom_count: ({ count }: { count: number }) => `${count} Google Classrooms found`,
    imported: ({ date }: { date: string }) => `Imported ${date}`,

    submit: 'Import',

    create: 'Create them in your account and return here to import.',
  },

  error: {
    title: 'One second, Google Classroom needs your permission to get started',
    subtitle: 'After choosing Google Classroom please read and check off all permissions required for us to import your classroom data!',
  },

  go_back: 'Go back to Classrooms',
};

export const joincode_dialog = {
  title: 'Enter Join Code',
  joincode_placeholder: 'Join Code',
};
