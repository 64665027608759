import React from 'react';
import { Box, BoxProps } from '@mui/material';

export default function Artboard(props: BoxProps) {
  const { children } = props;

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        transition: 'backgroundColor .3s',
        backgroundColor: 'grey.A100',
      }}
      {...props}
    >
      {children}
    </Box>
  );
}
