/**
 * TODO: In the interest of time, I am duplicating toolbar code from
 * src/components/Artboard/Toolbar/. I'm following my own guidelines for the
 * project: Don't futz with retrofitting code - clone and modify. But, let's
 * make sure to review this code and remove the old code so we don't have
 * duplicate toolbars _floating around_.
 *
 * Get it?
 */
import { EditorState } from 'draft-js';
import React, {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import {
  BoldImg,
  // BrushImg,
  ItalicImg,
  UnderlineImg,
} from 'src/assets/icons';
import { MenuRefType } from 'src/components/DropdownMenu/types';
import { getSufix } from 'src/utils';
import { getSelection, setStyle, toggleStyle } from 'src/components/elements/TextBox/utils';
import { ToolbarContainer } from './styles';
import ColorPicker from './ToolbarComponents/ColorPicker';
import FontsComboBox from './ToolbarComponents/FontsComboBox';
import FontSizeController from './ToolbarComponents/FontSizeController';
import IconButton from './ToolbarComponents/IconButton';
import { TextToolbarProps } from './types';

function TextBoxToolbar({
  block,
  editorState,
  setEditorState,
  isFocused,
  handleBackgroundColorChange,
  handleContainerFontChange,
  handleContainerFontSizeChange,
  styleMap,
}: TextToolbarProps) {
  const currentFontFamily = block.properties.fontFamily || '"Open Sans"';
  const currentFontSize = block.properties.fontSize || '1em';

  const colorPickerRef = useRef<MenuRefType>(null);

  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [, setTextAlign] = useState('LEFT');

  const [textColor, setTextColor] = useState<string>('#000');
  const [textBackgroundColor, setTextBackgroundColor] = useState<string>('#fff');
  const [, setBackgroundColor] = useState<string>('#fff');

  const [currentPrefix, setCurrentPrefix] = useState<string>('');

  const [lastSelection, setLastSelection] = useState<any>(null);

  useEffect(() => {
    if (!colorPickerRef.current?.isOpen) {
      setCurrentPrefix('');
    }
  }, [colorPickerRef.current?.isOpen]);

  const handleToggleStyle = (style: string) => {
    setEditorState((prev: EditorState) => {
      const selectionState = getSelection(prev, isFocused, style);
      const selectedState = EditorState.forceSelection(prev, selectionState);
      return toggleStyle(selectedState, style);
    });
  };

  const handleSetStyle = (style: string) => {
    setEditorState((prev: EditorState) => {
      let selectionState = getSelection(prev, isFocused, style);

      if (lastSelection) {
        selectionState = lastSelection;
      }

      const selectedState = EditorState.forceSelection(prev, selectionState);
      const updatedState = setStyle(selectedState, style);

      return updatedState;
    });
  };

  useLayoutEffect(() => {
    const styles = (editorState as EditorState)
      .getCurrentInlineStyle()
      .toArray();

    setIsBold(styles.includes('BOLD'));
    setIsItalic(styles.includes('ITALIC'));
    setIsUnderline(styles.includes('UNDERLINE'));

    const align = styles.find((el) => el.startsWith('ALIGN'));
    if (align) {
      setTextAlign(getSufix(align));
    } else {
      setTextAlign('LEFT');
    }

    if (lastSelection) {
      return;
    }

    const textColorStyle = styles.find((el) => el.startsWith('TEXT_COLOR'));
    if (textColorStyle) {
      setTextColor(styleMap[textColorStyle].color);
    } else {
      setTextColor('#000');
    }

    const textBackgroundColorStyle = styles.find((el) => el.startsWith('TEXT_BACKGROUND'));
    if (textBackgroundColorStyle) {
      setTextBackgroundColor(
        styleMap[textBackgroundColorStyle].backgroundColor,
      );
    } else {
      setTextBackgroundColor('#fff');
    }
  }, [editorState]);

  useEffect(() => {
    if (lastSelection && textColor[0] !== '#') {
      handleSetStyle(textColor);
      setTextColor(styleMap[textColor].color);
      setLastSelection(null);
    }
  }, [textColor, lastSelection]);

  useEffect(() => {
    if (
      lastSelection
      && textBackgroundColor[0] !== '#'
      && textBackgroundColor !== 'transparent'
    ) {
      handleSetStyle(textBackgroundColor);
      setTextColor(styleMap[textBackgroundColor].backgroundColor);
      setLastSelection(null);
    }
  }, [textBackgroundColor, lastSelection]);

  const handleBold = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    handleToggleStyle('BOLD');

    setIsBold(!isBold);
  };

  const handleItalic = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    handleToggleStyle('ITALIC');

    setIsItalic(!isItalic);
  };

  const handleUnderline = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    handleToggleStyle('UNDERLINE');

    setIsItalic(!isItalic);
  };

  const handleChangeColor = (color: string) => {
    switch (currentPrefix) {
      case 'TEXT_COLOR_':
        setTextColor(color);
        break;
      case 'TEXT_BACKGROUND_':
        setTextBackgroundColor(color);
        break;
      case 'BACKGROUND_COLOR_':
        handleBackgroundColorChange(
          styleMap[color].backgroundColor,
        );
        setBackgroundColor(styleMap[color].backgroundColor);
        break;
      default:
    }
  };

  const handleChangeFont = (font: string) => {
    // handleSetStyle(font);
    // setCurrentFont(font);
    handleContainerFontChange(font);
  };

  const openColorPicker = (
    event: React.MouseEvent<HTMLButtonElement>,
    prefix: string,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setCurrentPrefix(prefix);
    setLastSelection(getSelection(editorState, isFocused, prefix));
    colorPickerRef.current?.open(event);
  };

  return (
    <ToolbarContainer>
      <FontsComboBox
        currentFont={currentFontFamily}
        handleFontChange={handleChangeFont}
      />
      <FontSizeController
        currentFontSize={currentFontSize}
        handleFontSizeChange={handleContainerFontSizeChange}
      />
      <div className="group">
        <IconButton
          src={BoldImg}
          alt="Bold"
          onClick={handleBold}
          toggle
          isActive={isBold}
        />
        <IconButton
          src={ItalicImg}
          alt="Italicize"
          onClick={handleItalic}
          toggle
          isActive={isItalic}
        />
        <IconButton
          src={UnderlineImg}
          alt="Underline"
          onClick={handleUnderline}
          toggle
          isActive={isUnderline}
        />
      </div>
      <div className="group">
        <IconButton
          src="TextColorImg"
          alt="Text Color"
          noBorder
          onClick={(e: any) => openColorPicker(e, 'TEXT_COLOR_')}
          variant={textColor}
          isActive={currentPrefix === 'TEXT_COLOR_'}
          id="TEXT_COLOR_"
        />
        <IconButton
          src="TextBackgroundImg"
          alt="Background Color"
          noBorder
          onClick={(e: any) => openColorPicker(e, 'TEXT_BACKGROUND_')}
          variant={textBackgroundColor}
          isActive={currentPrefix === 'TEXT_BACKGROUND_'}
          id="TEXT_BACKGROUND_"
        />
        {/* <IconButton
      src={BrushImg}
      alt="underline"
      onClick={(e: any) => openColorPicker(e, 'BACKGROUND_COLOR_')}
      variant={backgroundColor}
    /> */}
      </div>
      {/* TODO: Re-enable text alignment. It's not working right now.
          I think it only applies to the span.
      */}
      {/* <div className="group">
        <IconButton
          src={AlignLeftImg}
          alt="align left"
          onClick={(e: any) => {
            handleTextAlign(e, 'LEFT');
          }}
          isActive={textAlign === 'LEFT'}
          noBorder
        />
        <IconButton
          src={AlignCenterImg}
          alt="align center"
          onClick={(e: any) => {
            handleTextAlign(e, 'CENTER');
          }}
          isActive={textAlign === 'CENTER'}
          noBorder
        />
        <IconButton
          src={AlignRightImg}
          alt="align right"
          onClick={(e: any) => {
            handleTextAlign(e, 'RIGHT');
          }}
          isActive={textAlign === 'RIGHT'}
          noBorder
        />
      </div> */}
      <ColorPicker
        ref={colorPickerRef}
        prefix={currentPrefix}
        onClick={handleChangeColor}
      />
    </ToolbarContainer>
  );
}

export default TextBoxToolbar;
