import { Box, styled } from '@mui/system';
import React, { useMemo } from 'react';
import { FontDecreaseImg, FontIncreaseImg, FontSizeImg } from '../../../../assets/icons';
import { getSufix } from '../../../../utils';

const FontSizeContainer = styled(Box)(() => ({
  display: 'flex',

  boxSizing: 'border-box',

  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  width: '5rem',
  height: '2.5rem',

  background: '#F6F6FA',

  border: '0.5px solid #A8A7CC',

  div: {
    display: 'grid',
    placeItems: 'center',
  },

  'div:first-of-type, div:last-of-type': {
    cursor: 'pointer',
    padding: '0 .5rem',
  },

}));

function FontSizeController(
  { currentFont, handleFontSizeChange }: {
    currentFont: string,
    handleFontSizeChange: (newFont: string) => void
  },
) {
  const { font, size } = useMemo(() => {
    const sufix = getSufix(currentFont);
    if (!sufix) return { font: '', size: 0 };
    const [fontMatch, sizeMatch] = sufix.match(/\D+|\d+/g)!;
    return { font: fontMatch, size: parseInt(sizeMatch, 10) };
  }, [currentFont]);

  const handleIncrease = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (size === 140) return;

    handleFontSizeChange(`FONT_STYLE_${font}${size + 20}`);
  };

  const handleDecrease = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (size === 60) return;

    handleFontSizeChange(`FONT_STYLE_${font}${size - 20}`);
  };

  const preventDefault = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <FontSizeContainer onMouseDown={preventDefault}>
      <Box onClick={handleDecrease}><img src={FontDecreaseImg} alt="decrease size" /></Box>
      <Box><img src={FontSizeImg} alt="font size controller" /></Box>
      <Box onClick={handleIncrease}><img src={FontIncreaseImg} alt="increase size" /></Box>
    </FontSizeContainer>
  );
}

export default FontSizeController;
