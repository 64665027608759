import { styled, Box, darken } from '@mui/system';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { theme } from '../../utils';
import { CardContainerProps } from './types';

export const Wrapper = styled(Box)(({ expanded }: { expanded: boolean }) => ({
  height: 'calc(100vh - 6rem)',
  margin: '1rem 1rem 0 0',
  width: expanded ? '16rem' : '5rem',
  position: 'absolute',
  left: 'auto',
  right: 0,
  zIndex: 1001,
  background: '#fff',
  borderRight: '1px solid #E3E3E3',
  userSelect: 'none',
  transition: 'width .1s ease-in-out',

  '.header': {
    div: {
      display: expanded ? 'block' : 'none',
    },
  },

  '.content': {
    display: expanded ? 'flex' : 'none',
  },

  '.sidebar-draggable': {
    maxHeight: 'calc(100vh - 6rem)',
    minHeight: 'calc(100vh - 6rem)',
    padding: '.5rem',
    border: '1px solid #A8A7CC',

    '.header-button': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '.5rem',
      gap: '2.75',
      cursor: 'pointer',
      background: 'transparent',
      width: '100%',
      border: 0,

      '&:hover': {
        background: darken('#fff', 0.05),
      },

      '& > div': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 0,
        gap: '1rem',
      },

    },

    '.divider': {
      height: '1px',
      width: '100%',
      background: '#E3E3E3',
    },

    '.cards-list': {
      display: 'grid',
      flexDirection: 'column',
      alignItems: 'center',
      gridGap: '1rem',
      padding: '1rem 0',

      '& > div': {
        width: '100%',
      },

      'div > .MuiBox-root': !expanded && {
        height: '3rem',

        img: {
          display: 'none',
        },

        '.page-number': {
          transform: 'translate(.625rem, .625rem)',
        },
      },
    },
  },

  [theme.breakpoints.down('sm')]: {
    transform: !expanded && 'translateX(-100%)',
    animation: expanded && '',
  },
}));

export const CardContainer = styled(Box)<CardContainerProps>(
  ({ backgroundColor, mainTextColor }) => ({
    width: '100%',
    background: backgroundColor ?? '#F6F6FA',
    borderRadius: 0,
    position: 'relative',
    transition: 'all .2s ease-in-out',
    border: '1px solid #52509A',
    boxShadow: '4px 4px 0px #52509A',

    '&:hover .card-description': {
      display: 'block',
    },

    '.visible': {
      display: 'block',
    },

    '.hidden': {
      display: 'none',
    },

    '.card-icon-absolute': {
      position: 'absolute',
      zIndex: 0,
      top: '.5rem',
      left: '.5rem',
      height: '3.5rem',
    },

    '.card-description': {
      fontWeight: '400 !important',
      fontSize: '0.75rem !important',
      margin: '0.25rem 1.5rem 1rem',
      color: mainTextColor,
      display: 'none',
    },

    '.title-open': {
      width: '100%',
      fontSize: '1rem !important',
      color: '#2C2C2C',
      transition: 'all .2s ease',
    },

    '.expand-button': {
      backgroundColor: '#00000015',
      top: '1rem',
      right: '1rem',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      width: '1rem',
      height: '1rem',
      position: 'absolute',
    },

    '.upside-down': {
      transform: 'rotate(180deg)',
    },

    '.header': {
      display: 'flex',
      alignItems: 'center',
      gap: '.75rem',
      padding: '.75rem .5rem',

      div: {
        fontWeight: '700',
        fontSize: '.875rem',

        '.subtitle': {
          color: '#52509A',
          fontSize: '.675rem',
          lineHeight: '150%',
        },

        '.orange': {
          color: '#E88F25 !important',
        },

        '.subtitle.customize:hover': {
          cursor: 'pointer',
        },
      },
    },

    '.word-count-content': {
      padding: '0rem 1rem',
      gap: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      marginTop: '.5rem',
      marginBottom: '1rem',

      '.content-title': {
        color: theme.palette.secondary.main,
        textTransform: 'uppercase',
        fontSize: '.625rem',
        marginBottom: '0.25rem',
      },

      '.content-value': {
        color: '#888888',
        fontSize: '1.125rem',
        marginRight: '0.25rem',
      },

      '.colored-text': {
        color: mainTextColor,
        fontWeight: '700',
      },
    },

    '.progress-wrapper': {
      padding: '0rem .5rem 1rem',

      'progress[value]::-webkit-progress-bar': {
        backgroundColor: 'green',
      },
    },

    '.description': {
      padding: '.75rem',
      paddingTop: '.25rem',
      fontSize: '.75rem',
    },

    '.signal-words-content': {
      padding: '0rem 1rem',
      gap: '1rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      marginTop: '.5rem',
      marginBottom: '1rem',

      '.words-wrapper': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '.25rem',
      },

      '.signal-words-description': {
        fontSize: '.75rem',
      },
    },

    '.important-words-content': {
      padding: '0rem 1rem',
      gap: '1rem',
      display: 'flex',
      marginTop: '.5rem',
      marginBottom: '1rem',

      '.words-wrapper': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '.25rem',
      },

      '.signal-words-description': {
        fontSize: '.75rem',
      },
    },

  }),
);

export const BorderLinearProgress = styled(LinearProgress)(({ value }: { value: number }) => ({
  borderRadius: 5,
  height: '.35rem',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'white',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: value === 100 ? '#7C9D46' : '#D1BC00',
  },
}));

export const SignalWord = styled('div', { shouldForwardProp: (prop) => prop !== 'activated' && prop !== 'lightColor' && prop !== 'mainColor' })(
  ({ activated, mainColor, lightColor }: {
    activated: boolean, mainColor?: string, lightColor?: string,
  }) => ({
    display: 'flex',
    gap: '.25rem',
    padding: '.5rem .75rem',
    fontSize: '0.625rem',
    fontWeight: 700,
    borderRadius: '2rem',
    alignItems: 'center',

    ...(activated ? {
      border: `1px solid ${mainColor || '#52509A'}`,
      color: mainColor || '#52509A',
    } : {
      border: `1px solid ${lightColor || '#A8A7CC'}`,
      color: lightColor || '#A8A7CC',
    }),
  }),
);
