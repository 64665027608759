import React, { useMemo, useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider, useTheme } from '@mui/material';
import { Chevron } from '../../assets/icons/variantComponents';
import { Create } from '../../assets/icons';
import CreatePresstoDialog from '../../components/dialogs/CreatePresstoDialog';
import PresstoButton from '../../components/PresstoButton';
import TabPanel from '../../components/TabPanel';
import { GalleryTabProps } from './types';
import { PresstoGrid, TabWrapper } from './styles';
import { useDocumentStore } from '../../zustand/documents';
import { lang } from '../../lang';
import AssignmentButton from '../../components/AssignmentButton';

const SORT_BY = ['recent', 'oldest', 'az', 'za'];

function GalleryTab({ tab }: GalleryTabProps) {
  const theme = useTheme();
  const [isCreatePresstoDialogOpen, setIsCreatePresstoDialogOpen] = useState(false);

  const documents = useDocumentStore((state) => state.documents);
  const sortedBy = useDocumentStore((state) => state.sortDocuments);
  const setSortedBy = useDocumentStore((state) => state.setSortDocuments);
  const loadDocuments = useDocumentStore((state) => state.loadDocuments);

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const sortedByLabel = useMemo(
    () => {
      loadDocuments({ order: sortedBy });
      return lang(`gallery.sort_select.${SORT_BY.find((el) => el === sortedBy) ?? SORT_BY[0]}`);
    },
    [sortedBy],
  );

  const handleOpenSortByDialog = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSortByDialog = (selectedItem?: string) => {
    if (selectedItem) {
      setSortedBy(selectedItem);
      localStorage.setItem('sortDocuments', selectedItem);
    }
    setAnchorEl(null);
  };

  const handleCreate = (format: string, meta?: any) => {
    const metaString = meta ? JSON.stringify(meta) : '{}';
    navigate(`/document/new?format=${format}`, { state: { meta: metaString } });
  };

  window.document.title = 'Gallery';
  return (
    <TabPanel tab={tab} index={0}>
      <TabWrapper>
        <div>
          <PresstoGrid>
            <div className="pressto-count-row">
              <div className="pressto-count">
                {lang('general.found')}
                {' '}
                <strong>
                  {lang('gallery.pressto_count', { count: documents.length })}
                </strong>
              </div>
              <div className="sorted-by">
                <span>{lang('general.organize_by')}</span>
                <u
                  id="sort-by-button"
                  aria-controls={open ? 'sorted-by-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  role="button"
                  onClick={handleOpenSortByDialog}
                  onKeyDown={handleOpenSortByDialog}
                  tabIndex={-1}
                  style={{ cursor: 'pointer' }}
                >
                  {sortedByLabel}
                  {' '}
                  <Chevron direction={open ? 'up' : 'down'} />
                </u>
                <Menu
                  id="sorted-by-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => handleCloseSortByDialog()}
                  MenuListProps={{ 'aria-labelledby': 'sort-by-button' }}
                  classes={{ paper: 'order-by-container', list: 'order-by-menu' }}
                >
                  {SORT_BY.map((el) => (
                    <MenuItem
                      key={el}
                      onClick={() => handleCloseSortByDialog(el)}
                      style={el === sortedBy ? { fontWeight: 700 } : {}}
                    >
                      {lang(`gallery.sort_select.${el}`)}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </div>
            <div style={{ display: 'flex', flexGrow: 1 }}>
              <div className="content">
                <button
                  className="create pressto-button"
                  type="button"
                  onClick={() => { setIsCreatePresstoDialogOpen(true); }}
                >
                  <img src={Create} alt="+" />
                  <span>{lang('gallery.gallery_tab.create')}</span>
                </button>
                {documents.map((pressto: any) => {
                  if (pressto.submission) {
                    return (
                      <AssignmentButton
                        key={`pressto-${pressto.id}`}
                        assignment={pressto}
                        onDelete={() => loadDocuments({ order: sortedBy })}
                      />
                    );
                  }
                  return (
                    <PresstoButton key={pressto.id} pressto={pressto} />
                  );
                })}
              </div>
            </div>
            <ThemeProvider theme={theme}>
              <CreatePresstoDialog
                isAssignmentFlow={false}
                onSubmit={handleCreate}
                isOpen={isCreatePresstoDialogOpen}
                onClose={() => setIsCreatePresstoDialogOpen(false)}
              />
            </ThemeProvider>
          </PresstoGrid>
        </div>
      </TabWrapper>
    </TabPanel>
  );
}

export default GalleryTab;
