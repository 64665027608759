import React from 'react';
import { Tag } from './styles';
import { StatusTagProps } from './types';

function StatusTag({ status, isTeacher = false }: StatusTagProps) {
  let statusText;
  switch (status) {
    case 'turnedin': {
      statusText = 'Turned In';
      break;
    }
    case 'new': {
      statusText = isTeacher ? 'Not Started' : status;
      break;
    }
    default: {
      statusText = status;
    }
  }

  return (
    <Tag status={status.toLowerCase()}>
      {statusText}
    </Tag>
  );
}

export default StatusTag;
