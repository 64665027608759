import { ButtonUnstyled } from '@mui/base';
import { Box, darken, styled } from '@mui/system';
import { theme } from '../../utils';

export const Container = styled(Box)(() => ({
  height: 'calc(var(--doc-height) - 4.625rem)',
  width: '100%',

  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',

  background: '#F0F0F4',

  '.top-wrapper': {
    height: '2.5rem',
    margin: '.5rem 0 1.5rem 0',
    position: 'absolute',
    zIndex: 999,
  },

  [theme.breakpoints.down('sm')]: {
    '.top-wrapper': {
      maxWidth: '96vw',
    },
  },

  '.editor-wrapper': {
    flexGrow: 1,
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '.content-wrapper': {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
  },

  '.content-wrapper.animate': {
    animation: 'appear .8s',
  },

  '@keyframes appear': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },

}));

export const LoaderContainer = styled(Box)(({ animate }: { animate: boolean }) => ({
  display: 'flex',
  flexDirection: 'column',

  gap: '0.375rem',
  alignItems: 'center',
  justifyContent: 'center',

  img: {
    animation: animate && 'loading 2s ease-in-out infinite',
    transform: 'translateY(-2rem)',
  },

  '@keyframes loading': {
    '0%': { transform: 'translateY(1rem)' },
    '50%': {
      transform: 'translateY(-.3rem)',
    },
    '100%': { transform: 'translateY(1rem)' },
  },
}));

export const EditorContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'height' && prop !== 'width' })(
  ({ height, width, backgroundColor }: {
    height: number,
    width: number,
    backgroundColor: string
  }) => ({
    position: 'relative',
    display: 'flex',
    height: '75%',
    maxHeight: '75%',
    aspectRatio: `${width} / ${height}`,
    background: '#FFFFFF',
    border: '1px solid #52509A',
    boxShadow: '4px 4px 0px #52509A',
    backgroundColor,
  }),
);

export const NavigationBarContainer = styled(Box)(({ anchor }: { anchor: 'bottom' | 'top' | 'left' | 'right' }) => ({
  width: '100vw',
  maxWidth: '100vw',
  display: 'grid',
  gridTemplateColumns: '2fr auto 2fr',
  marginTop: '2rem',
  gap: '0.25rem',

  '.double-button': {
    display: 'flex',
    justifyContent: 'center',
    gap: '.875rem',
    flexDirection: anchor === 'left' || anchor === 'right' ? 'column' : 'row',
  },

  '.left-container': {
    display: 'grid',
    gridTemplateColumns: '0fr 10rem 3.5rem',
    gridGap: '.875rem',
    left: '1.25rem',

    '& > :first-of-type': {
      marginLeft: '10rem',
    },
  },

  '.right-container': {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  [theme.breakpoints.down('md')]: {
    '.left-container': {
      gridTemplateColumns: '0fr 2.75rem 2.75rem',
    },
  },

  [theme.breakpoints.down('sm')]: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    marginTop: 0,

    '.left-container': {
      gridTemplateColumns: '2.75rem 2.75rem 2.75rem',
      gridGap: '.5rem',

      '& > :first-of-type': {
        marginLeft: 0,
      },
    },

    '.double-button': {
      gap: '.5rem',
    },
  },

  position: 'absolute',

  ...((anchor === 'left' || anchor === 'right') ? {
    top: '50%',
    transform: 'translateY(-50%)',
  } : {}),

  [anchor]: 0,
  zIndex: 999,
  padding: '.5rem',
}));

export const IconTextButton = styled(ButtonUnstyled)(() => ({
  background: 'transparent',
  height: '2.5rem',

  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0 .75rem',
  gap: '.5rem',

  border: '0.5px solid #A8A7CC',
  borderRadius: '.125rem',

  '&:hover': {
    background: darken('#fff', 0.05),
  },

  '&:active': {
    transform: 'scale(0.98)',
  },

  transformOrigin: 'center',
}));
