import React from 'react';
import { CheckImg } from '../../assets/icons';
import {
  MarkerComponent, ListOption, Option, OptionLabel, Checked,
} from './styles';

function Marker(props: any) {
  const { selected, type } = props;
  return (
    <MarkerComponent {...props}>
      {selected && type === 'radio' && <Checked />}
      {selected && type !== 'radio' && (
        <img
          src={CheckImg}
          alt=""
          style={{ margin: '-5px 0 3px 1px', height: '15px' }}
        />
      )}
    </MarkerComponent>
  );
}

export {
  Marker,
  ListOption,
  Option,
  OptionLabel,
};
