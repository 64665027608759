import React from 'react';
import { Card, Grid } from '@mui/material';
import { useDrag } from 'react-dnd';
import { Link } from 'react-router-dom';
import Page from 'src/components/Page';
import { DocumentFormat } from 'src/types/DocumentSettings';
import { PageThumbnailViewModel } from './types';

/**
 * An image representing a page. May be dragged to reorder pages.
 */
export default function PageThumbnail(props: {
  page: PageThumbnailViewModel;
}) {
  const { page } = props;

  const [{ isDragging }, drag] = useDrag({
    type: 'page',
    item: page,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs="auto">
        <Card
          elevation={0}
            // TODO: Remove CSS.
          sx={[
            {
              width: '24px',
              height: '24px',
              backgroundColor: 'grey.A100',
              fontWeight: 'bold',
              fontSize: '12px',
              lineHeight: '24px',
              textAlign: 'center',
            },
            page.isSelected && {
              backgroundColor: 'primary.main',
              borderColor: 'primary.main',
              color: 'primary.contrastText',
            },
          ]}
        >
          {page.originalIndex + 1}
        </Card>
      </Grid>
      <Grid item xs="auto">
        <Link
          to={page.url}
          replace
          ref={drag}
          className={isDragging ? 'dragging' : ''}
          style={{
            textDecoration: 'none',
          }}
        >
          <Card elevation={2}>
            <Page
              format={DocumentFormat.BOOKLET}
              page={page}
              scale={0.1}
              isEditable={false}
            />
          </Card>
        </Link>
      </Grid>
      <Grid item />
    </Grid>
  );
}
