import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../../utils';
import { DialogContainer, DialogContent, DialogTitle } from '../styles';

export const ClassroomDialogContainer = styled(DialogContainer)(() => ({
  '& > div > div': {
    height: '80%',
    width: '80%',
    maxHeight: 'unset',
    maxWidth: 'unset',

    padding: '3.25rem 5.625rem',

    [theme.breakpoints.up('md')]: {
      alignSelf: 'center',
      maxHeight: '90vh',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '0 2.5rem',
      padding: '0 4rem',
      display: 'grid',
      placeItems: 'center',
    },

    '.content-wrapper': {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}));

export const ClassroomDialogTitle = styled(DialogTitle)(() => ({
  padding: '2rem',
  paddingTop: '1.5rem',

  '& > p': { fontSize: '2rem', lineHeight: '3rem' },

  [theme.breakpoints.down('md')]: {
    padding: 0,
    marginBottom: '2.5rem',
  },

  [theme.breakpoints.down('sm')]: {
    marginBottom: '1rem',
    '& > p': { fontSize: '1.5rem', lineHeight: '150%' },
  },
}));

export const ClassroomDialogContent = styled(DialogContent)(() => ({
  padding: 0,

  '&.first-step': {
    display: 'flex',
    flexDirection: 'column',
    gap: '2.5rem',
    marginTop: '3rem',

    'button, input': {
      width: '100%',
    },
  },

  '&.second-step': {
    textAlign: 'center',
    alignItems: 'center',

    p: {
      textAlign: 'center',
      fontSize: '.875rem',
    },

    display: 'grid',
    flexDirection: 'column',
    gridGap: '2.5rem',

    '.code-container': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '1.25rem',

      '.code-box': {
        cursor: 'pointer',
        fontSize: '3rem',
        padding: '1rem 1.5rem',
        lineHeight: '150%',

        background: '#F6F6FA',
        border: '1px solid #A8A7CC',
        borderRadius: '.25rem',

        height: '11.25rem',
        display: 'grid',
        placeItems: 'center',

        color: '#52509A',
      },

      p: {
        fontSize: '.75rem',
      },
    },
  },

  '&.third-step': {
    textAlign: 'center',
    alignItems: 'center',

    p: {
      textAlign: 'center',
      fontSize: '.875rem',
    },

    display: 'flex',
    flexDirection: 'column',
    gap: '2.5rem',

    '.button-container': {
      marginTop: '1.5rem',
      display: 'flex',
      gap: '2.5rem',
    },
  },

  '&.fourth-step': {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '70%',
    margin: 'auto',
  },

  gap: '2.5rem',

  '.input-wrapper': {
    display: 'grid',
    flexDirection: 'column',
    gridGap: '1rem',
  },
}));

export const OptionImport = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})(({ disabled }: { disabled: boolean }) => ({
  border: `1px solid ${theme.gradient.secondary.light}`,
  flexDirection: 'column',
  gap: '15px',
  height: '250px',
  width: '250px',
  padding: '1rem',
  justifyContent: 'center',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'all 150ms ease-in-out',

  '&:hover': !disabled && {
    backgroundColor: 'white',
    boxShadow: `0px 0px 50px ${theme.gradient.primary.light}`,
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

export const OptionImportList = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, auto)',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  gridGap: '10px',
}));
