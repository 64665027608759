import { ImageBoxType, TextBoxType } from '../../types/LayoutEditor';

export default [
  {
    key: 'textbox1',
    type: 'TextBox',
    defaultFont: 'TITLE100',
    defaultTextAlign: 'ALIGN_CENTER',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 128,
    y: 160,
    height: 785,
    width: 2294,
  } as TextBoxType,
  {
    key: 'imagebox1',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 573,
    y: 980,
    height: 1324,
    width: 1405,
  } as ImageBoxType,
  {
    key: 'textbox2',
    type: 'TextBox',
    defaultFont: 'QUOTE100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 328,
    y: 2344,
    height: 796,
    width: 1894,
  } as TextBoxType,
];
