import { IconButton, useMediaQuery, Theme } from '@material-ui/core';
import React, { useState } from 'react';
import { DeleteConfirmationDialogContainer, DeleteConfirmationDialogContent, DeleteConfirmationDialogTitle } from './styles';
import { Description } from '../../typography';
import { DialogCloseImg } from '../../../assets/icons';
import Button from '../../buttons/Button';
import { DeleteConfirmationProps } from './types';
import { lang } from '../../../lang';

function DeleteConfirmationDialog({
  title, description, onSubmit, isOpen, onClose,
}: DeleteConfirmationProps) {
  const mobileMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    await onSubmit();
    setIsLoading(false);
    onClose();
  };

  return (
    <DeleteConfirmationDialogContainer
      onClose={onClose}
      open={isLoading || isOpen}
    >
      <DeleteConfirmationDialogTitle>
        <Description>{title}</Description>
      </DeleteConfirmationDialogTitle>
      <DeleteConfirmationDialogContent>
        <div className="content-wrapper">
          <Description className="subtitle">{description ?? lang('general.delete_account_default_message')}</Description>
        </div>
        <div className="dialog-actions">
          <Button outline label={lang('general.close')} onClick={onClose} disabled={isLoading} large={mobileMQ} />
          <Button className="red-button" label={lang('general.delete')} onClick={handleDelete} disabled={isLoading} large={mobileMQ} />
        </div>
      </DeleteConfirmationDialogContent>
      <IconButton className="close-button" onClick={onClose}>
        <img src={DialogCloseImg} alt="close dialog" />
      </IconButton>
    </DeleteConfirmationDialogContainer>
  );
}

export default DeleteConfirmationDialog;
