import React, { useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import { useSearchParams } from 'react-router-dom';
import { DialogCloseImg } from '../../../assets/icons';
import WRITING_PLANS_LIST from '../../../Models/WritingPlans';
import { CreatePresstoDialogContainer } from './styles';
import FormatSelectionDialog from './FormatSelectionDialog';
import ImportantWordsSelectionDialog from './ImportantWordsSelectionDialog';
import WritingPlanSelectionDialog from './WritingPlanSelectionDialog';
import { CreatePresstoDialogContainerProps } from './types';
import { DocumentFormat } from '../../../types/DocumentSettings';

const screenOrder = [
  'format',
  'writingPlan',
  'importantWords',
] as const;

function CreatePresstoDialog({
  onSubmit,
  isOpen,
  onClose,
  isAssignmentFlow,
}: CreatePresstoDialogContainerProps) {
  const [search, setSearch] = useSearchParams();

  const updateSearch = (update: Record<string, string | undefined>) => {
    const newSearch = new URLSearchParams(search);
    Object.entries(update).forEach(([key, value]) => {
      if (value) {
        newSearch.set(key, value);
      } else {
        newSearch.delete(key);
      }
    });
    setSearch(newSearch);
  };

  const documentParameters = {
    format: search.get('format') || undefined,
    writingPlan: search.get('writingPlan') || undefined,
    importantWords: search.get('importantWords') || undefined,
  };
  const currentStep = search.get('step');

  useEffect(() => {
    if (isOpen) {
      // The dialog has opened from a state of having been closed.
      if (!currentStep) {
        updateSearch({ step: 'format' });
      }
    } else {
      // The dialog has closed.
      updateSearch({
        step: undefined,
        format: undefined,
        writingPlan: undefined,
        importantWords: undefined,
      });
    }
  }, [isOpen]);

  const handleAddImportantWords = (importantWords: string) => {
    let words: string[] = [];
    if (importantWords.trim().length > 0) {
      words = importantWords.split(',').map((word) => word.trim());
    }

    onSubmit(
      documentParameters.format || DocumentFormat.BOOKLET,
      {
        signalWords: WRITING_PLANS_LIST.find(
          (el) => el.id === documentParameters.writingPlan,
        )?.signalWords,
        writingPlan: documentParameters.writingPlan,
        importantWords: words,
      },
    );
    onClose();
  };

  const handleBack = (): void => {
    updateSearch({
      step: screenOrder[screenOrder.indexOf(currentStep as any) - 1],
    });
  };

  return (
    <CreatePresstoDialogContainer onClose={onClose} open={isOpen}>
      {currentStep === 'format' && (
        <FormatSelectionDialog
          selectedFormat={documentParameters.format}
          onClickNext={() => {
            if (isAssignmentFlow) {
              updateSearch({ step: 'writingPlan' });
            } else {
              onSubmit(documentParameters.format || DocumentFormat.BOOKLET);
            }
          }}
          setSelectedFormat={(format) => {
            updateSearch({ format });
          }}
        />
      )}
      {currentStep === 'writingPlan' && (
        <WritingPlanSelectionDialog
          initialWritingPlan={documentParameters.writingPlan}
          onClickBack={handleBack}
          onSkip={() => {
            updateSearch({
              writingPlan: undefined,
              step: 'importantWords',
            });
          }}
          onAdd={(writingPlan) => {
            updateSearch({
              step: 'importantWords',
              writingPlan,
            });
          }}
        />
      )}
      {currentStep === 'importantWords' && (
        <ImportantWordsSelectionDialog
          initialImportantWords={documentParameters.importantWords}
          onClickBack={handleBack}
          onSkip={() => handleAddImportantWords('')}
          onAdd={handleAddImportantWords}
        />
      )}
      <IconButton className="close-button" onClick={onClose}>
        <img src={DialogCloseImg} alt="close dialog" />
      </IconButton>
    </CreatePresstoDialogContainer>
  );
}

export default CreatePresstoDialog;
