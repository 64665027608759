import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import { DocumentFormat, DocumentTemplateSystem } from 'src/types/DocumentSettings';
import { Description } from '../typography';
import { PersonImg } from '../../assets/icons';
import { lang } from '../../lang';
import { AssignmentDocumentButtonProps } from './types';
import StatusTag from '../StatusTag';
import { ButtonContainer } from './styles';
import getPlaceholderDocumentByFormat from '../../utils/getPlaceholderDocumentByFormat';
import { getPresstoCardPredominantColor } from '../../utils';
import Page from '../Page';

function AssignmentDocumentButton({ pressto = {}, user, status = 'new' }: AssignmentDocumentButtonProps) {
  const {
    name, thumbnail_url: thumbnailUrl,
    id, version, meta, format,
    template_system: templateSystem,
  } = pressto;

  const predominantColor = useMemo(
    () => getPresstoCardPredominantColor(version),
    [version],
  );

  const wordCount = useMemo(() => {
    try {
      return JSON.parse(meta).wordCount ?? 0;
    } catch {
      return 0;
    }
  }, [meta]);

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ButtonContainer
      className="pressto-button"
      onClick={() => status !== 'new' && navigate(`/document/${id}`, { state: { prev: location.pathname } })}
    >
      <div className="user-info-container">
        <img src={user?.avatar ?? PersonImg} alt={`${user.name} ${user.surname}`} />
        <Description>{`${user.name || ''} ${user.surname || ''}`}</Description>
      </div>
      <div className="image-container" style={{ backgroundColor: predominantColor }}>
        {templateSystem === DocumentTemplateSystem.BLOCKS ? (
          // Be extra-guarded here because so many Presstos are displayed
          // at once. Better to fail silently than to crash the gallery.
          pressto.version?.content?.pages?.length && (
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Page
                format={format as DocumentFormat}
                page={pressto.version.content.pages[0]}
                scale={0.14}
                isEditable={false}
              />
            </Box>
          )
        ) : (
          <img src={thumbnailUrl || getPlaceholderDocumentByFormat(format as string)} alt={name} />
        )}
      </div>
      <div className="info-container">
        <div className="tags-list">
          <StatusTag status={status} isTeacher />
        </div>
        <Description className="pressto-title">{name}</Description>
        <Description className="word-count">
          {wordCount ?? 0}
          {' '}
          {lang('gallery.info_container.words_written')}
        </Description>
      </div>
    </ButtonContainer>
  );
}

export default AssignmentDocumentButton;
