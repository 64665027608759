import create from 'zustand';
import * as ClassroomAPI from '../api/Classrooms';
import { ClassroomDefinition } from '../types/Gallery';

export interface ClassroomState {
  classrooms: ClassroomDefinition[];
  currentClassroom?: ClassroomDefinition;
  setCurrentClassroom: (c: any) => void;
  setClassrooms: (u: ClassroomDefinition[]) => void;
  loadClassrooms: () => void;
  getClassroomData: (id: string) => any;
}

export const useClassroomStore = create<ClassroomState>((set) => ({
  classrooms: [],
  setClassrooms: (data) => set(() => ({ classrooms: data })),
  setCurrentClassroom: (data) => set(() => ({ currentClassroom: data })),
  loadClassrooms: async () => {
    try {
      const response: { data: { data: ClassroomDefinition[] } } = await ClassroomAPI.getAll();
      return set(() => ({ classrooms: response.data.data }));
    } catch (error) {
      return set(() => ({ classrooms: [] }));
    }
  },
  getClassroomData: async (id: string) => {
    try {
      const response: {
        data: { data: ClassroomDefinition },
      } = await ClassroomAPI.getClassroomData(id);
      set(() => ({ currentClassroom: response.data.data }));
      return response.data.data;
    } catch (error) {
      return null;
    }
  },
}));
