import React, { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DropdownMenu from '../DropdownMenu';
import { MenuRefType } from '../DropdownMenu/types';
import JoinWithCodeDialog from '../dialogs/JoinWithCodeDialog';
import { CleanButton } from './styles';
import { useUserStore } from '../../zustand/user';
import { useDocumentStore } from '../../zustand/documents';

function AvatarMenu() {
  const user = useUserStore((state) => state.user);
  const logout = useUserStore((state) => state.logout);
  const resetDocuments = useDocumentStore((state) => state.resetDocuments);

  const MenuRef = useRef<MenuRefType>(null);

  const [isJoinCodeDialogOpen, setIsJoinCodeDialogOpen] = useState(false);

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    MenuRef.current?.open(e);
  };

  const navigate = useNavigate();

  const menuItems = useMemo(() => {
    const profileItem = {
      key: 'profile',
      label: 'Profile',
      onClick: () => {
        navigate('/profile');
        MenuRef.current?.close();
      },
    };

    const helpCenterItem = {
      key: 'help',
      label: 'Help Center',
      onClick: () => window.open('https://www.joinpressto.com/help-center', '_blank'),
    };

    const logoutItem = {
      key: 'logout',
      label: 'Logout',
      onClick: () => {
        resetDocuments();
        logout();
      },
    };

    if (user.role === 'teacher') {
      return [profileItem, helpCenterItem, logoutItem];
    }

    const joinCodeItem = {
      key: 'joinCode',
      label: 'Join with code',
      onClick: () => {
        MenuRef.current?.close();
        setIsJoinCodeDialogOpen(true);
      },
    };

    return [profileItem, joinCodeItem, helpCenterItem, logoutItem];
  }, [user, MenuRef, navigate]);

  return (
    <div>
      <CleanButton
        id="avatar-button"
        aria-controls={MenuRef.current?.isOpen ? 'avatar-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={MenuRef.current?.isOpen ? 'true' : undefined}
        onClick={handleOpen}
      >
        <div id="avatar">
          {user?.name?.slice(0, 1)}
          {user?.surname?.slice(0, 1)}
        </div>
      </CleanButton>
      <DropdownMenu
        ref={MenuRef}
        items={menuItems}
      />

      <JoinWithCodeDialog
        isOpen={isJoinCodeDialogOpen}
        onClose={() => setIsJoinCodeDialogOpen(false)}
      />
    </div>
  );
}

export default AvatarMenu;
