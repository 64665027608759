import { useUserStore } from 'src/zustand/user';

/**
 * Given the name of a feature, return whether it is enabled at this time.
 * This is referred to as a "feature flag". Prefer using this hook to encapsulate
 * the logic of feature flags, which may come from a variety of sources.
 *
 * Flags may have three states:
 *
 *     true: the feature is enabled
 *     false: the feature is disabled
 *     undefined: the feature state is uncertain, but will load
 *
 * Not all flags pass through the `undefined` state. Some flags may be false until
 * loaded when the functionality is purely additive. It is up to the developer
 * of each feature to determine whether a loading state makes sense.
 * The intent of the `undefined` is avoid screen flicker when the flag changes state.
 *
 * Note: Unlike the pattern of other loading states in the application, the hook
 * will not return `null` to signify an error. Instead, it will return `false`
 * and should be treated the same as the feature being disabled.
 */
export function useFeatureFlag(name: string): boolean | undefined {
  const user = useUserStore((state) => state.user);

  if (name === 'writing-prompts') {
    // In the absence of a feature flag system, code a secret phrase into the
    // name of the user to grant them access. This is temporary.
    return user?.surname?.match(/\[LP\]$/) !== null;
  }

  return false;
}
