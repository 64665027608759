import { ButtonUnstyled } from '@mui/base';
import { darken, lighten, styled } from '@mui/system';
import { theme } from '../../../utils';
import { getAnchorProps, getHasLabelProps } from './utils';

export const IconButtonContainer = styled(ButtonUnstyled, { shouldForwardProp: (prop) => prop !== 'hasLabel' })(
  ({ hasLabel, anchor }: { hasLabel: boolean, anchor?: 'top' | 'bottom' | 'left' | 'right' }) => ({
    height: '2.5rem',
    width: '3.75rem',

    '& + button': {
      borderLeft: 0,
    },

    ':disabled': {
      background: 'rgba(255, 255, 255, 0.5)',
      borderColor: `${lighten('#52509A', 0.5)}`,

      '&:not(:last-of-type)': {
        borderRightColor: '#52509A',
      },

      '*': {
        opacity: 0.5,
      },
    },

    background: '#fff',
    border: '1px solid #52509A',
    boxSizing: 'border-box',
    display: 'inline-grid',
    placeItems: 'center',
    fontWeight: 700,

    cursor: 'pointer',

    '&:hover:not(:disabled)': {
      background: darken('#fff', 0.05),
    },

    '&:active:not(:disabled)': {
      transform: 'scale(0.98)',
    },

    transformOrigin: 'center',
    borderRadius: 0,

    [theme.breakpoints.down('md')]: !hasLabel && {
      height: '2.5rem',
      width: '2.75rem',
    },

    ...getAnchorProps(anchor),

    ...getHasLabelProps(hasLabel),
  }),
);
