import React, { useMemo, useRef, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Description } from '../typography';

import { ReactComponent as OptionsMenu } from '../../assets/icons/options-menu.svg';
import { BinImg, GoogleClassroomImg } from '../../assets/icons';
import ContextMenu from '../ContextMenu';
import { ContextMenuItemProps, ContextMenuRef } from '../ContextMenu/types';
import DeleteConfirmationDialog from '../dialogs/DeleteConfirmationDialog';
import { ClassroomButtonProps } from './types';
import { useClassroomStore } from '../../zustand/classrooms';
import { ButtonContainer } from './styles';
import * as ClassroomsAPI from '../../api/Classrooms';
import { lang } from '../../lang';

function ClassroomButton({ classroom, onClick }: ClassroomButtonProps) {
  const contextMenuRef = useRef<ContextMenuRef>(null);
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false);

  const loadClassrooms = useClassroomStore((state) => state.loadClassrooms);

  const {
    id, name, students, provider, isSyncing,
  } = classroom;

  const handleDropdownClick = (e: any) => {
    e.stopPropagation();

    contextMenuRef.current?.openMenu(e);
  };

  const menuItems = useMemo<ContextMenuItemProps[]>(() => ([
    {
      label: 'Delete',
      icon: BinImg,
      onClick: () => setIsDeleteConfirmationDialogOpen(true),
      style: { color: '#FF5252 !important' },
    },
  ]), []);

  const deleteClassroom = async () => {
    const response = await ClassroomsAPI.deleteClassroom(id);
    if (response.status === 200) loadClassrooms();
  };

  return (
    <>
      <ContextMenu menuItems={menuItems} ref={contextMenuRef}>
        <ButtonContainer color="#BFBFBF" className="classroom-button" onClick={() => onClick(id)}>
          <div className="info-container">
            <Description className="classroom-type">
              {provider === 'google' ? (
                <>
                  <img src={GoogleClassroomImg} alt="google classroom" />
                  {lang('general.provider.google')}
                </>
              ) : lang('general.provider.pressto')}
            </Description>
            <Description weight={700}>{name}</Description>
            <div className="classroom-info">
              <Description>
                {
                  isSyncing
                    ? lang('classroom.classroom_page.syncing')
                    : lang('classroom.classroom_tab.students_count', { count: students.length })
                }
              </Description>
              {/* <div>
              <Description>
                {ongoingAssignmentsCount}
                {' '}
                ongoing assignment
                {ongoingAssignmentsCount !== 1 ? 's' : ''}
              </Description>
            </div> */}
            </div>
          </div>
          <IconButton className="pressto-button-actions" onClick={handleDropdownClick}>
            <OptionsMenu fill="#52509A" />
          </IconButton>
        </ButtonContainer>
      </ContextMenu>
      <DeleteConfirmationDialog
        title={lang('classroom.classroom_page.delete_classroom_confirm.title')}
        description={lang('classroom.classroom_page.delete_classroom_confirm.subtitle', { name })}
        onSubmit={deleteClassroom}
        isOpen={isDeleteConfirmationDialogOpen}
        onClose={() => setIsDeleteConfirmationDialogOpen(false)}
      />
    </>

  );
}

export default ClassroomButton;
