import { ImageBoxType } from '../../types/LayoutEditor';

export default [
  {
    key: 'imagebox1',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 0,
    y: 0,
    height: 534,
    width: 632,
  } as ImageBoxType,
  {
    key: 'imagebox2',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 0,
    y: 546,
    height: 534,
    width: 632,
  } as ImageBoxType,
  {
    key: 'imagebox3',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 644,
    y: 0,
    height: 1080,
    width: 632,
  } as ImageBoxType,
  {
    key: 'imagebox4',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 1288,
    y: 0,
    height: 534,
    width: 632,
  } as ImageBoxType,
  {
    key: 'imagebox5',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 1288,
    y: 546,
    height: 534,
    width: 632,
  } as ImageBoxType,
];
