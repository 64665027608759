import React, { useState } from 'react';

import WRITING_PLANS from '../../../Models/WritingPlans';
import DialogSharedLayout from '../../shared/Dialogs/Layout';
import { Description } from '../../typography';

import { WritingPlanSelectionDialogProps } from './types';
import {
  WritingPlanButton,
  WritingPlanDialogContent,
  ContentContainer,
  EmptyWritingPlan,
} from './styles';
import { RocketLaunchingImg } from '../../../assets/images';
import { lang } from '../../../lang';
import WritingPlanInfo from '../../WritingPlan/Info';

const AVAILABLE_PLANS = WRITING_PLANS.filter((plan) => plan.id !== 'basic');

function WritingPlanSelectionDialog({
  onSkip,
  onAdd,
  onClickBack,
  initialWritingPlan = '',
}: WritingPlanSelectionDialogProps) {
  const [selectedPlan, setSelectedPlan] = useState(initialWritingPlan);

  const currentWritingPlanDisplayed = AVAILABLE_PLANS.find((plan) => plan.id === selectedPlan);

  return (
    <DialogSharedLayout
      titleLangKey="document.create_pressto_dialog.writing_plan.title.line_1"
      subtitleLangKey="document.create_pressto_dialog.writing_plan.title.line_2"
      handleSkip={onSkip}
      handleSave={() => onAdd(selectedPlan)}
      handleBack={onClickBack}
    >
      <WritingPlanDialogContent>
        <div className="tab-container">
          <WritingPlanButton
            onClick={() => setSelectedPlan('')}
            selected={!selectedPlan}
          >
            None
          </WritingPlanButton>
          {AVAILABLE_PLANS.map((plan) => (
            <WritingPlanButton
              key={plan.id}
              onClick={() => setSelectedPlan((prev) => (prev === plan.id ? '' : plan.id))}
              selected={selectedPlan === plan.id}
            >
              {plan.title}
            </WritingPlanButton>
          ))}
        </div>
        <ContentContainer>
          {
            currentWritingPlanDisplayed && selectedPlan ? (
              <WritingPlanInfo
                key={selectedPlan}
                writingPlan={currentWritingPlanDisplayed}
              />
            ) : (
              <EmptyWritingPlan>
                <img
                  src={RocketLaunchingImg}
                  alt={lang('document.create_pressto_dialog.writing_plan.no_plan_selected')}
                />

                <Description align="center">
                  {lang('document.create_pressto_dialog.writing_plan.no_plan_selected')}
                </Description>
              </EmptyWritingPlan>
            )
          }
        </ContentContainer>
      </WritingPlanDialogContent>
    </DialogSharedLayout>
  );
}

export default WritingPlanSelectionDialog;
