import { styled, Box } from '@mui/system';
import { ButtonContainerProps } from './types';

export const ButtonContainer = styled(Box)<ButtonContainerProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',

  '.image-container': {
    height: '8.75rem',
    overflow: 'hidden',
    position: 'relative',

    '& > img': {
      position: 'absolute',
      top: '1.875rem',
      height: '100%',
      left: '50%',
      transform: 'translate(-50%, 0%)',
    },

    '& > div': {
      transform: 'translate(-25%, 0%)',
      position: 'absolute',
      top: '1.875rem',
      height: '100%',
      left: '50%',
    },
  },

  '.tags-list': {
    display: 'flex',
    gap: '.25rem',
    height: '1.5rem',
  },

  '.info-container': {
    height: '8.75rem',
    padding: '.75rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    p: {
      color: '#000000',
      fontWeight: '700',
      fontFamily: 'Inter !important',
    },

    '.secondary-info': {
      display: 'flex',
      justifyContent: 'space-between',
      opacity: 0.5,

      'p:first-of-type': {
        fontWeight: '400',
      },
    },
  },

  '.pressto-button-actions': {
    position: 'absolute',
    top: '.5rem',
    right: '.5rem',
    display: 'grid',
    cursor: 'pointer',
  },
}));
