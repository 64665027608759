import { Box, styled } from '@mui/system';
import { DocumentFormat, DocumentFormatSettings } from 'src/types/DocumentSettings';

export const PageBox: any = styled(Box, {
  shouldForwardProp: (prop: string) => ![
    'scale',
    'format',
    'width',
    'height',
  ].includes(prop),
})(({
  scale,
  format,
  width,
  height,
}: {
  scale: number,
  format: DocumentFormat,
  width: number,
  height: number,
}) => {
  const BASE_FONT = 48 * DocumentFormatSettings[format].scaleFactor;

  return {
    position: 'relative',
    width: `${width / BASE_FONT}em`,
    height: `${height / BASE_FONT}em`,
    fontSize: scale * BASE_FONT,
    backgroundColor: '#fff',
  };
});
