import {
  Document, DocumentVersion, BlockPage, Block, isBlockPage, getBlockType,
} from '../../../types/models';
import SynchronousDocumentOperation from './synchronous';

type BlockUpdate = Partial<Block> & {
  id: string
};

function generateHtml(block: Block): string {
  // Assume that a fixed font-size has been set at the page level
  // and that text font sizes are specified relative that.
  // This HTML does not need to care about font size.
  const blockType = getBlockType(block);
  const blockStyle: Record<string, string | undefined> = {
    width: '100%',
    height: '100%',
    padding: '.5em .6em',
    overflow: 'hidden',
    'background-size': 'cover',
    'background-position': 'center',
    'background-repeat': 'no-repeat',
  };
  const textStyle = {
    'font-size': block.properties.fontSize || blockType.defaultProperties.fontSize || '1em',
    'font-family': block.properties.fontFamily || blockType.defaultProperties.fontFamily,
  };

  if (block.properties.imageUrl) {
    blockStyle['background-image'] = `url(${block.properties.imageUrl})`;
  }

  const innerHtml = block.properties.htmlText || '';

  const styleAttribute = Object.entries(blockStyle).map(
    ([key, value]) => `${key}: ${value || 'initial'}`,
  ).join('; ');
  const textStyleAttribute = Object.entries(textStyle).map(
    ([key, value]) => `${key}: ${value || 'initial'}`,
  ).join('; ');
  const html = `<div style="${styleAttribute}"><div style="${textStyleAttribute}">${innerHtml}</div></div>`;

  return html;
}

function getPageContainingBlock(version: DocumentVersion, blockId: string): BlockPage | null {
  for (let i = 0; i < version.pages.length; i += 1) {
    const page = version.pages[i];
    if (!isBlockPage(page)) {
      return null;
    }

    for (let j = 0; j < page.grid.blocks.length; j += 1) {
      if (page.grid.blocks[j].id === blockId) {
        return page;
      }
    }
  }

  return null;
}

export default class SetBlockOperation extends SynchronousDocumentOperation {
  readonly type = 'set-block';

  block: BlockUpdate;

  constructor(documentId: string, block: BlockUpdate) {
    super(documentId);
    this.block = block;
  }

  mergeNext(next: SetBlockOperation): SetBlockOperation | false {
    if (
      // The operation is against the same block.
      next.block.id === this.block.id

      // Only text properties are changing.
      // TODO: All for partial property updates. The follow check isn't
      // quite right but it gets the job done.
      && next.block.properties?.plainText && this.block.properties?.plainText
    ) {
      return new SetBlockOperation(
        this.documentId,
        {
          ...this.block,
          properties: {
            ...this.block.properties,
            ...next.block.properties,
          },
        },
      );
    }

    return false;
  }

  apply(document: Document): Document {
    // Return a new document version with this block updated.
    // TODO: Commented until Page->Block relationship is defined.
    const { version } = document;
    const page = getPageContainingBlock(version, this.block.id);
    if (!page) {
      throw new Error(`Page for block ${this.block.id} does not exist`);
    }

    const oldBlock = page.grid.blocks.find((block) => block.id === this.block.id);
    if (!oldBlock) {
      throw new Error(`Block ${this.block.id} does not exist`);
    }

    const html = generateHtml({
      ...oldBlock,
      ...this.block,
    });

    const pageIndex = version.pages.indexOf(page);
    const newPage = {
      ...page,
      grid: {
        ...page.grid,
        blocks: page.grid.blocks.map((block) => {
          if (block.id === this.block.id) {
            return {
              ...block,
              ...this.block,
              properties: {
                ...block.properties,
                ...this.block.properties,
                html,
              },
            };
          }
          return block;
        }),
      },
    };

    return {
      ...document,
      version: {
        ...version,
        pages: [
          ...version.pages.slice(0, pageIndex),
          newPage,
          ...version.pages.slice(pageIndex + 1),
        ],
      },
    };
  }
}
