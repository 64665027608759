import { ImageBoxType, TextBoxType } from '../../types/LayoutEditor';

export default [
  {
    key: 'imagebox1',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 856,
    y: 96,
    height: 888,
    width: 952,
  } as ImageBoxType,
  {
    key: 'textbox1',
    type: 'TextBox',
    defaultFont: 'QUOTE100',
    defaultTextAlign: 'ALIGN_CENTER',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 112,
    y: 112,
    height: 634,
    width: 971,
  } as TextBoxType,
  {
    key: 'textbox2',
    type: 'TextBox',
    defaultFont: 'CAPTION100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 112,
    y: 794,
    height: 192,
    width: 696,
  } as TextBoxType,
];
