import { AppBar, Toolbar } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../utils';

export const TopBarContainer = styled(Toolbar)(() => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'row',
  alignItems: 'center',
  color: 'black',
  gap: '2rem',
  justifyContent: 'space-between',

  [theme.breakpoints.down('sm')]: {
    gap: '1rem',
  },

  [theme.breakpoints.down('md')]: {
    justifyContent: 'space-between',
  },

  '.tab-container': {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    height: '100%',
    alignItems: 'flex-end',
    position: 'relative',

    '.tabs-indicator': {
      background: '#52509A',
      height: '.25rem',
    },

    '.MuiTabs-root': {
      maxHeight: '3rem',
      minWidth: '60%',
      position: 'relative',

      button: {
        fontFamily: 'Roboto !important',
        fontSize: '.875rem',
        lineHeight: '1.5rem',
        color: '#A8A7CC',
      },

      '.Mui-selected': {
        color: '#52509A !important',
        fontWeight: 700,
      },

    },
  },

  '.right-content': {
    display: 'grid',
    gridGap: '2rem',
    gridTemplateColumns: 'repeat(2, auto)',
  },
}));

export const TopBarButtonContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const TopBarWrapper = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'hideTabs' })(
  ({ hideTabs }: { hideTabs?: boolean }) => ({
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    height: '4.625rem',

    [theme.breakpoints.down('md')]: !hideTabs && {
      height: '7.95rem',
    },
  }),
);
