import { Box, styled } from '@mui/system';
import { lang } from '../../../lang';
import { ImageBoxContainerProps } from './types';

export const ImageBoxContainer = styled(Box)(({
  dims, selected, noImagePosition, hasImage, isViewMode,
}: ImageBoxContainerProps) => ({
  position: 'absolute',

  '&::before': !isViewMode && {
    content: '""',
    position: 'absolute',
    left: -2,
    top: -2,
    border: selected ? '2px solid #52509A' : '2px solid transparent',
    height: 'calc(100%)',
    width: 'calc(100%)',
  },

  '&:hover': !isViewMode && {
    boxShadow: '0px 0px 5.21073px rgba(82, 80, 154, 0.4)',

    '.no-image-container': {
      filter: !hasImage && 'brightness(50%)',
    },
  },

  '&:hover::before': {
    border: '2px solid #52509A',
  },

  '&:hover::after': !isViewMode && !hasImage && {
    content: lang('document.artboard.elements.image_placeholder'),
    position: 'absolute',
    top: '1.25rem',
    left: '1.25rem',
    color: '#fff',
    zIndex: 999,
    fontFamily: 'Inter',
    fontHeight: 700,
    fontSize: '.75rem',
  },

  transition: 'all .2s ease-in-out',

  ...dims,
  '.crop-container': {
    height: dims.height,
    width: dims.width,

    '.reactEasyCrop_Container': {
      zIndex: 998,

      height: dims.height,
      width: dims.width,
    },
  },

  '.crop-control': {
    marginTop: '.5rem',

    '.crop-slider-container': {
      zIndex: 1000,

      boxSizing: 'border-box',

      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '.5rem .75rem',
      gap: '.75rem',

      position: 'fixed',
      left: `calc(50% + ${dims.offsetX}px)`,
      transform: 'translateX(-50%)',
      width: '11.25rem',
      height: '2.5rem',

      background: '#FFFFFF',

      border: '0.5px solid #A8A7CC',

      boxShadow: '0 0 .5rem rgba(82, 80, 154, 0.4)',
      borderRadius: '.125rem',

      '.crop-slider': {
        color: '#A8A7CC',
        '& .MuiSlider-thumb': {
          height: '.5rem',
          width: '.5rem',
          backgroundColor: '#52509A',
          '&:focus, &:hover, &.Mui-active': {
            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
          },
        },
        '& .MuiSlider-track, & .MuiSlider-rail': {
          opacity: 1,
          height: 1,
          backgroundColor: '#A8A7CC',
        },
      },
    },
  },

  cursor: !isViewMode && 'pointer',

  '.no-image-container': {
    overflow: 'hidden',
    userSelect: 'none',
    display: 'grid',
    placeItems: 'center',
    height: '100%',

    background: '#f3f3f4',

    div: {
      position: noImagePosition ? 'absolute' : 'default',

      ...(noImagePosition ?? {}),

      width: '70%',

      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      p: {
        textAlign: 'center',
      },
      img: {
        height: '7.5vh',
      },
    },
  },
}));
