import React from 'react';
import { Box } from '@mui/material';
import { Block } from 'src/types/models';

/**
 * A simplified rendering of a Block, the purpose of which is to embed
 * in a smaller space. Thumbnails are not interactive.
 */
export default function Thumbnail(props: {
  block: Block | null,
}) {
  const { block } = props;

  return (
    <Box
      sx={{
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        whiteSpace: 'break-spaces',
        userSelect: 'none',
        pointerEvents: 'none',
      }}
    >
      {block && (
        <Box
          sx={{
            height: '100%',
            position: 'relative',
            overflowWrap: 'break-word',
          }}
          dangerouslySetInnerHTML={{
            __html: block.properties.html || '',
          }}
        />
      )}
    </Box>
  );
}
