import React, { useEffect, useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Theme, useMediaQuery } from '@material-ui/core';
import ClassroomTableRow from './ClassroomTableRow';
import { TableWrapper } from './styles';
import { Column } from './types';

export default function ClassroomTable(
  { data, provider /* updateClassroom */ }: {
    data: any[],
    provider: string,
  },
) {
  const mobileMQ = useMediaQuery((t: Theme) => t.breakpoints.down('sm'));

  const [expandedRow, setExpandedRow] = useState<string | null>(null);

  useEffect(() => {
    if (!mobileMQ) { setExpandedRow(null); }
  }, [mobileMQ]);

  const columns: Column[] = useMemo(() => {
    const defaultColumns = mobileMQ
      ? [{ id: 'name', label: 'Student' }] as Column[]
      : [
        { id: 'name', label: 'Student' },
        { id: 'email', label: 'Id' },
        { id: 'last_session', label: 'Last Session' },
        { id: 'work', label: 'Published Work', format: (value: unknown) => (value ? 'View' : '-') },
      ] as Column[];

    return [...defaultColumns, { id: provider === 'google' ? '' : 'actions', label: '' }];
  }, [mobileMQ, provider]);

  return (
    <TableWrapper>
      <TableContainer sx={{ overflowX: 'visible' }} classes={{ root: 'table-container' }}>
        <Table
          stickyHeader
          aria-label="students table"
          sx={{ borderCollapse: 'separate', borderSpacing: '0 .5rem' }}
        >
          <TableHead className="table-header">
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  className="table-header-cell"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {data.map((row) => (
              <ClassroomTableRow
                key={row.id}
                row={row}
                columns={columns}
                expanded={expandedRow === row.id}
                onClick={(id) => mobileMQ && setExpandedRow((prev) => (prev === id ? null : id))}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TableWrapper>
  );
}
