import { EditorState } from 'draft-js';
import { useCallback } from 'react';
import { Block } from 'src/types/models';
import { useDocumentStore } from '../../store/document';

/**
 * There is only ever one block selected at a time. Thus, it is part of the
 * global state. Access that state via this hook, which is responsible
 * for determining which block is selected at any given time.
 */
export function useSelectedBlock(): [Block | null, (blockId: string | null) => void] {
  const selectedBlockId = useDocumentStore((state) => state.selectedBlockId);
  const selectBlock = useDocumentStore((state) => state.setSelectedBlockId);
  const blocksById = useDocumentStore((state) => state.blocksById);
  const selectedBlock = blocksById[selectedBlockId || ''];

  return [
    selectedBlock || null,
    selectBlock,
  ];
}

/**
 * Ditto for hovering.
 */
export function useHoveredBlock(): [Block | null, (blockId: string | null) => void] {
  const hoveredBlockId = useDocumentStore((state) => state.hoveredBlockId);
  const setHoveredBlockId = useDocumentStore((state) => state.setHoveredBlockId);
  const hoveredBlock = useDocumentStore((state) => state.blocksById[hoveredBlockId || '']);

  return [
    hoveredBlock || null,
    setHoveredBlockId,
  ];
}

type EditorStateSetter = (
  newEditorState: EditorState | ((editorState: EditorState) => EditorState)
) => void;

// If the incoming ID is a string, then an initial value is required
// and the hook will never return an undefined value.
export function useBlockEditorState(
  blockId: string, initialValue: EditorState
): [EditorState, EditorStateSetter];

// If the incoming ID may be undefined, then an initial value does not
// make sense and the hook may return an undefined value. Setting the editor state
// may be a no-op.
export function useBlockEditorState(
  blockId: string | undefined
): [EditorState | undefined, EditorStateSetter];

// The hook implementation handles both cases.
export function useBlockEditorState(
  blockId: string | undefined,
  initialValue?: EditorState,
): [EditorState | undefined, EditorStateSetter] {
  const editorState = useDocumentStore((state) => state.draftEditorStateById[blockId || '']);
  const setStoreEditorState = useDocumentStore((state) => state.setDraftEditorState);

  const setEditorState = useCallback((
    newEditorState: EditorState | ((editorState: EditorState) => EditorState),
  ) => {
    setStoreEditorState(blockId!, newEditorState);
  }, [blockId]);

  if (blockId === undefined) {
    return [
      undefined,
      (() => {}) as EditorStateSetter,
    ];
  }

  if (!editorState) {
    if (initialValue) {
      setStoreEditorState(blockId, initialValue);
    }
    return [
      initialValue,
      setEditorState,
    ];
  }

  return [
    editorState,
    setEditorState,
  ];
}
