import React from 'react';
import { Description } from '../../../typography';
import { IconTextButtonContainer } from '../styles';

function IconTextButton(
  {
    onClick, src, alt, label,
  }: { onClick: (...args: any[]) => void, src: string, alt: string, label: string },
) {
  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onClick(e);
  };

  return (
    <IconTextButtonContainer
      onClick={handleClick}
      onMouseDown={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <img src={src} alt={alt} />
      <Description size="small" weight={700} style={{ color: '#52509A' }}>{label}</Description>
    </IconTextButtonContainer>
  );
}

export default IconTextButton;
