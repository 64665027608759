import { darken, styled } from '@mui/system';
import {
  Accordion as UnstyledAccordion,
  AccordionDetails as UnstyledAccordionDetails,
  AccordionSummary as UnstyledAccordionSummary,
  Dialog,
  Menu as UnstyledMenu,
} from '@mui/material';
import { ButtonUnstyled } from '@mui/base';
import { theme } from '../../utils';
import { Button } from '../buttons';
import { Description } from '../typography';

export const MenuContainer = styled(UnstyledMenu)(() => ({
  marginTop: '0.375rem',
  borderRadius: 0,
  ul: {
    padding: 0,
    width: '7.688rem',
    borderRadius: 0,
    border: '1px solid #52509A',
    overflow: 'hidden',
  },
}));

export const Accordion = styled(UnstyledAccordion)(() => ({
  padding: 0,
  display: 'grid',
  placeItems: 'center',

  '&::before': {
    borderTop: '1px solid #52509A',
  },

  'button + &': {
    borderTop: '1px solid #52509A',
    borderRadius: '0 !important',
  },

  '.accordion-active > div > p': {
    color: `${theme.palette.primary.main} !important`,
  },

  '& > .MuiCollapse-root': {
    width: '100% !important',
    maxWidth: '100% !important',
  },

  '& > .Mui-expanded': {
    height: '3.125rem',
    minHeight: '3.125rem !important',
    maxHeight: '3.125rem',

    '& > div': {
      margin: 0,
    },
  },

}));

export const AccordionDetails = styled(UnstyledAccordionDetails)(() => ({
  padding: 0,
  width: '100% !important',
  maxWidth: '100% !important',

  display: 'grid',
  placeItems: 'center',

}));

export const AccordionSummary = styled(UnstyledAccordionSummary)(() => ({
  padding: 0,

  width: '100% !important',
  maxWidth: '100% !important',

  height: '3.125rem',

  display: 'grid',
  placeItems: 'center',

  zIndex: 10,

  '.button-arrow': {
    position: 'absolute',
    height: '.5rem',
    width: '.5rem',
    backgroundColor: '#fff',
    borderRight: '1px solid #52509A',
    borderBottom: '1px solid #52509A',
    left: '50%',
    bottom: 'calc(-.5rem - 2px)',
    transform: 'translate(-50%, -50%) rotate(45deg)',
  },

  '&:hover': {
    background: darken('#fff', 0.05),
  },
}));

export const MenuButton = styled(ButtonUnstyled, {
  shouldForwardProp: (props) => props !== 'hasParent' && props !== 'hasDescription',
})(({ hasParent, hasDescription }) => {
  let hasParentStyles = {};

  if (!hasParent) {
    hasParentStyles = {
      height: '3.125rem',

      p: {
        padding: 0,
        fontWeight: 700,
      },
    };
  }

  return {
    position: 'relative',
    padding: 0,
    marginBottom: hasDescription ? '20px' : 0,
    background: 'transparent',
    border: 0,
    width: '100%',
    '&:not(:first-of-type)': {
      borderTop: '1px solid #52509A',
    },
    cursor: 'pointer',

    p: { padding: '.8rem' },

    '&:hover': {
      background: darken('#fff', 0.05),
    },

    '&:active': {
      transform: 'scale(0.98)',
    },

    borderRadius: 0,
    transformOrigin: 'center',

    ...hasParentStyles,
  };
});

export const StyledDialog = styled(Dialog)(() => ({
  '.MuiPaper-root': {
    margin: 0,
    width: '100vw',

    position: 'fixed',
    bottom: 0,

    display: 'flex',

    '.menu-header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '2.5rem',
      padding: '1rem 1rem 0 1rem',

      p: {
        color: theme.palette.primary.main,
      },

      button: {
        position: 'absolute',
        left: '1rem',
        height: '2.5rem',
        width: '2.5rem',
      },
    },

    '.menu-content': {
      display: 'flex',
      flexDirection: 'column',
      gap: '.5rem',

      padding: '1rem',
    },
  },
}));

export const MobileMenuButton = styled(Button)(() => ({
  background: 'transparent',
  color: theme.palette.primary.main,

  backdropFilter: 'blur(10px)',

  border: '2px solid #52509A',
  borderRadius: '.25rem',

  display: 'flex',
  flexDirection: 'column',

  width: '100%',

  '&:hover': {
    background: darken('#fff', 0.05),
  },
}));

export const ButtonDescription = styled(Description)(() => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  position: 'absolute',
  width: 'inherit',
  height: '25px',
  bottom: -20,
  fontWeight: 500,
  fontSize: '11px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
