import { IconButton, Theme, useMediaQuery } from '@material-ui/core';
import React, { useState } from 'react';
import { Description } from 'src/components/typography';
import { DialogCloseImg } from 'src/assets/icons';
import { Button } from 'src/components/buttons';
import { PublishImg } from 'src/assets/images';
import { lang } from 'src/lang';
import LinkBox from 'src/components/LinkBox';
import { DialogProps } from 'src/components/dialogs/types';
import { Document } from 'src/types/models';
import { useApplyDocumentOperation } from 'src/hooks/document';
import { PublishOperation, UnpublishOperation } from 'src/store/document/operations';
import { PublishDialogContainer, PublishDialogContent, PublishDialogTitle } from './styles';

function PublishDialog({
  document,
  isOpen,
  onClose,
}: DialogProps & {
  document: Document;
}) {
  const mobileMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(false);
  const isPublished = document.version.status === 'PUBLISHED';
  const applyOperation = useApplyDocumentOperation();

  const handlePublish = async () => {
    setLoading(true);
    await applyOperation(new PublishOperation(document.id));
    setLoading(false);
  };

  const handleUnpublish = async () => {
    setLoading(true);
    await applyOperation(new UnpublishOperation(document.id));
    setLoading(false);
  };

  return (
    <PublishDialogContainer onClose={onClose} open={isOpen}>
      <PublishDialogTitle published={isPublished}>
        <Description>{lang(`document.publish_dialog.${isPublished ? 'published' : 'publish'}.title`)}</Description>
      </PublishDialogTitle>
      {isPublished
        ? (
          <PublishDialogContent>
            <div className="content-wrapper">
              <Description className="subtitle" weight={700}>{lang('document.publish_dialog.published.subtitle')}</Description>
              <LinkBox text={`${window.location.origin}/publish/${document.id}`} />
              <Description className="long-description">
                {lang('document.publish_dialog.published.description')}
              </Description>
            </div>
            <div className="dialog-actions">
              <Button className="red-button" label={lang('document.publish_dialog.published.unpublish')} onClick={handleUnpublish} disabled={loading} large={mobileMQ} />
              <Button label={lang('general.close')} onClick={onClose} disabled={loading} large={mobileMQ} />
            </div>
          </PublishDialogContent>
        ) : (
          <PublishDialogContent>
            <div className="content-wrapper">
              <Description className="subtitle">{lang('document.publish_dialog.publish.subtitle')}</Description>
              <div className="content">
                <Description>
                  {lang('document.publish_dialog.published.friends_and_family')}
                </Description>
                <Description weight={700} className="long-description">
                  {lang('document.publish_dialog.published.description')}
                </Description>
              </div>
            </div>
            <div className="dialog-actions">
              <Button label={lang('general.close')} onClick={onClose} outline disabled={loading} large={mobileMQ} />
              <Button label={lang('document.publish_dialog.publish.title')} onClick={handlePublish} disabled={loading} large={mobileMQ} />
            </div>
          </PublishDialogContent>
        )}

      <IconButton className="close-button" onClick={onClose}>
        <img src={DialogCloseImg} alt="close dialog" />
      </IconButton>
      <img
        src={PublishImg}
        alt="publish"
        style={{
          position: 'absolute',
          top: '2.5rem',
          right: '.675rem',
          maxWidth: '35%',
        }}
      />
    </PublishDialogContainer>
  );
}

export default PublishDialog;
