import { IconButton, Theme, useMediaQuery } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { Description } from '../../typography';
import { DialogCloseImg } from '../../../assets/icons';
import { Button } from '../../buttons';
import { PublishImg } from '../../../assets/images';
import { PublishDialogContainer, PublishDialogContent, PublishDialogTitle } from './styles';
import { PublishDialogProps } from './types';
import { useDocumentStore } from '../../../zustand/documents';
import * as DocumentsAPI from '../../../api/Documents';
import { lang } from '../../../lang';
import LinkBox from '../../LinkBox';

function PublishDialog({
  currentDocumentId,
  isOpen,
  onClose,
}: PublishDialogProps) {
  const mobileMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const documents = useDocumentStore((state) => state.documents);
  const currentDocument = useDocumentStore((state) => state.currentDocument);
  const changeDocumentStatus = useDocumentStore((state) => state.changeDocumentStatus);
  const loadDocuments = useDocumentStore((state) => state.loadDocuments);
  const sortedBy = useDocumentStore((state) => state.sortDocuments);

  const { documentId, isPublished } = useMemo(() => {
    if (currentDocument) {
      return {
        documentId: currentDocument.id,
        isPublished: currentDocument.version?.status === 'PUBLISHED',
      };
    }

    const document = documents.find((el) => el.id === currentDocumentId);
    return { documentId: document?.id ?? '', isPublished: document?.version?.status === 'PUBLISHED' ?? false };
  }, [currentDocument, documents, currentDocumentId]);

  const [loading, setLoading] = useState(false);

  const handlePublish = async () => {
    setLoading(true);
    await DocumentsAPI.updateStatus(documentId, {
      status: 'published',
      is_public: true,
    });
    if (currentDocument) {
      changeDocumentStatus('PUBLISHED');
    } else {
      loadDocuments({ order: sortedBy });
    }
    setLoading(false);
  };

  const handleUnpublish = async () => {
    setLoading(true);
    await DocumentsAPI.updateStatus(documentId, {
      status: 'unpublished',
      is_public: false,
    });
    if (currentDocument) {
      changeDocumentStatus('DRAFT');
    } else {
      loadDocuments({ order: sortedBy });
    }
    setLoading(false);
  };

  return (
    <PublishDialogContainer onClose={onClose} open={isOpen}>
      <PublishDialogTitle published={isPublished}>
        <Description>{lang(`document.publish_dialog.${isPublished ? 'published' : 'publish'}.title`)}</Description>
      </PublishDialogTitle>
      {isPublished
        ? (
          <PublishDialogContent>
            <div className="content-wrapper">
              <Description className="subtitle" weight={700}>{lang('document.publish_dialog.published.subtitle')}</Description>
              <LinkBox text={`${window.location.origin}/publish/${documentId}`} />
              <Description className="long-description">
                {lang('document.publish_dialog.published.description')}
              </Description>
            </div>
            <div className="dialog-actions">
              <Button className="red-button" label={lang('document.publish_dialog.published.unpublish')} onClick={handleUnpublish} disabled={loading} large={mobileMQ} />
              <Button label={lang('general.close')} onClick={onClose} disabled={loading} large={mobileMQ} />
            </div>
          </PublishDialogContent>
        ) : (
          <PublishDialogContent>
            <div className="content-wrapper">
              <Description className="subtitle">{lang('document.publish_dialog.publish.subtitle')}</Description>
              <div className="content">
                <Description>
                  {lang('document.publish_dialog.published.friends_and_family')}
                </Description>
                <Description weight={700} className="long-description">
                  {lang('document.publish_dialog.published.description')}
                </Description>
                {/* <FormControlLabel
                  classes={{ label: 'checkbox-label' }}
                  control={<Checkbox />}
                  label="Anyone in Pressto’s community gallery"
                />
                <Description>Your PRESSTO can also be found in our community gallery.</Description>
                */}
              </div>
            </div>
            <div className="dialog-actions">
              <Button label={lang('general.close')} onClick={onClose} outline disabled={loading} large={mobileMQ} />
              <Button label={lang('document.publish_dialog.publish.title')} onClick={handlePublish} disabled={loading} large={mobileMQ} />
            </div>
          </PublishDialogContent>
        )}

      <IconButton className="close-button" onClick={onClose}>
        <img src={DialogCloseImg} alt="close dialog" />
      </IconButton>
      <img
        src={PublishImg}
        alt="publish"
        style={{
          position: 'absolute',
          top: '2.5rem',
          right: '.675rem',
          maxWidth: '35%',
        }}
      />
    </PublishDialogContainer>
  );
}

export default PublishDialog;
