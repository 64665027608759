import { Theme, useMediaQuery } from '@material-ui/core';
import React, {
  useEffect, useState,
} from 'react';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/system';
import { DocumentTemplateSystem, DocumentFormat } from 'src/types/DocumentSettings';
import Page from 'src/components/Page';
import GalleryTopBar from '../../components/GalleryTopBar';
import AssignmentPageContainer from './styles';
import { ReactComponent as BackArrow } from '../../assets/icons/back-arrow.svg';
import { Description } from '../../components/typography';
import DeleteConfirmationDialog from '../../components/dialogs/DeleteConfirmationDialog';
import { GoogleClassroomImg } from '../../assets/images';
import { OriginalDocumentImg } from '../../assets/icons';
import * as AssignmentsAPI from '../../api/Assignments';
import { useUserStore } from '../../zustand/user';
import { lang } from '../../lang';
import AssignmentDocumentButton from '../../components/AssignmentDocumentButton';
import getPlaceholderDocumentByFormat from '../../utils/getPlaceholderDocumentByFormat';
import { PresstoDefinition } from '../../types/Gallery';

function AssignmentTeacher() {
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false);

  const user = useUserStore((state) => state.user);

  const { aid } = useParams();

  const tabletMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const mobileMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const [data, setData] = useState<any>(null);
  const [submissions, setSubmissions] = useState([]);

  const loadData = async () => {
    const responseAssignment = await AssignmentsAPI.getById(aid!);
    if (responseAssignment.status === 200) {
      setData(responseAssignment.data.data);
    }
    const responseSubmissions = await AssignmentsAPI.getDocumentsSubmissions(aid!);
    if (responseSubmissions.status === 200) {
      setSubmissions(responseSubmissions.data.data);
    }
  };

  const baseDocument = data && data['base-document'];

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (!user || user.role !== 'teacher') {
      navigate('/gallery', { state: { tab: 1 } });
      return () => {};
    }
    if (aid) {
      loadData();
      interval = setInterval(loadData, Math.random() * 8000 + 2000);
    }
    return () => interval && clearTimeout(interval);
  }, [aid]);

  const handleChangeTab = (tab: number) => {
    navigate('/gallery', { state: { tab } });
  };

  const handleDeleteAssignment = async () => {
    const response = await AssignmentsAPI.deleteAssignment(data?.id);
    if (response.status === 200) {
      navigate('/gallery', { state: { tab: 1 } });
    }
  };

  const renderBackButton = () => (
    <div>
      <span
        className="assignment-back-button"
        role="button"
        tabIndex={-1}
        onClick={() => { navigate('/gallery'); }}
        onKeyDown={() => { navigate('/gallery'); }}
      >
        <BackArrow height={16} width={16} stroke="#999999" />
        <Description size="small">
          {lang('assignment.assignment_tab.go_back_to_assignemnts')}
        </Description>
      </span>
    </div>
  );

  return (
    <>
      <GalleryTopBar tab={1} changeTab={handleChangeTab} />
      <AssignmentPageContainer>
        {tabletMQ && !mobileMQ && renderBackButton()}
        {!tabletMQ && (
          <div className="left-column">
            {renderBackButton()}
            <div>
              <div className="classroom-name-info">
                <Description className="classroom-type">
                  {data?.classroom.provider === 'google' ? (
                    <>
                      <img src={GoogleClassroomImg} alt="google classroom" height={18} />
                      Google Classroom
                    </>
                  ) : 'Pressto'}
                </Description>
                <Description className="classroom-name">
                  {data?.classroom.name}
                </Description>
              </div>
              <div className="turned-in-assignment-info">
                <div>
                  <p>{data?.turnedin_count || 0}</p>
                  <p>{lang('assignment.assignment_tab.turned_in')}</p>
                </div>
                <div>
                  <p>{data?.assigned_count || 0}</p>
                  <p>{lang('assignment.assignment_tab.assigned')}</p>
                </div>
              </div>
            </div>

            { data && (
              <RouterLink to={`/document/${data['base-document'].id}`} className="original-document-container">
                <div className="original-document-header">
                  <img src={OriginalDocumentImg} alt="an eye in a white circle" />
                  {lang('assignment.assignment_tab.original_document')}
                </div>
                <div className="thumbnail-container">
                  {baseDocument?.template_system === DocumentTemplateSystem.BLOCKS ? (
                    // Be extra-guarded here because so many Presstos are displayed
                    // at once. Better to fail silently than to crash the gallery.
                    baseDocument.version?.content?.pages?.length && (
                    <Box>
                      <Page
                        format={baseDocument.format as DocumentFormat}
                        page={baseDocument.version.content.pages[0]}
                        scale={0.10}
                        isEditable={false}
                      />
                    </Box>
                    )
                  ) : (
                    <img
                      src={data['base-document']?.thumbnail_url || getPlaceholderDocumentByFormat(data['base-document']?.format)}
                      alt={lang('assignment.assignment_tab.original_document')}
                    />
                  )}
                </div>
              </RouterLink>
            )}
          </div>
        )}
        <div className="right-column">
          <h1>{data?.name}</h1>
          <div className="content">
            {submissions.map((document: PresstoDefinition) => (
              <AssignmentDocumentButton
                key={document.id}
                pressto={document}
                user={document.user}
                status={document.submission?.status}
              />
            ))}
          </div>
        </div>
        <Box
          className="delete-assignment"
          onClick={() => setIsDeleteConfirmationDialogOpen(true)}
        >
          {lang('assignment.assignment_tab.delete_assignment')}
        </Box>
      </AssignmentPageContainer>
      <DeleteConfirmationDialog
        title={lang('assignment.assignment_tab.delete_assignment_confirm.title')}
        description={lang('assignment.assignment_tab.delete_assignment_confirm.subtitle')}
        onSubmit={handleDeleteAssignment}
        isOpen={isDeleteConfirmationDialogOpen}
        onClose={() => setIsDeleteConfirmationDialogOpen(false)}
      />
    </>
  );
}

export default AssignmentTeacher;
