import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateJoinCode } from '../../api/Classrooms';
import {
  FlexBox, Form, GradientContainer, Input, Logo,
} from '../../components';
import { Button } from '../../components/buttons';
import { Title, Link } from '../../components/typography';
import { lang } from '../../lang';

function JoinCode() {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    try {
      await validateJoinCode(e.target[0].value);
      navigate(`/register-student?join_code=${e.target[0].value}`);
    } catch (err) {
      setError(lang('auth.errors.invalid_joincode'));
    }
    setLoading(false);
  };

  return (
    <GradientContainer>
      <Logo />

      <FlexBox textAlign="center">
        <Title>{lang('auth.signup.joincode_title')}</Title>

        <Form onSubmit={handleSubmit} style={{ marginBottom: '8rem' }}>
          <Input
            required
            placeholder={lang('auth.signup.joincode_placeholder')}
            onChange={() => setError('')}
            error={!!error}
            invalidMessage={error}
          />
          <Button
            style={{ margin: '2rem 0rem 0rem' }}
            label={lang('general.next')}
            type="submit"
            disabled={loading}
            large
          />
        </Form>

        <Link underline href="/register-student">
          {lang('auth.signup.no_joincode')}
        </Link>
      </FlexBox>

    </GradientContainer>
  );
}

export default JoinCode;
