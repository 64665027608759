import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as SchoolAPI from '../../api/School';
import { Form, Input, Logo } from '../../components';
import { Button } from '../../components/buttons';
import { Title, ErrorMessage } from '../../components/typography';
import { lang } from '../../lang';
import { useUserStore } from '../../zustand/user';

function SchoolForm() {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);

  const navigate = useNavigate();

  const handleChangeName = (e: any) => setName(e.target.value);

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await SchoolAPI.create({
        name,
      });
      if (response.status !== 201) throw response.data;
      setUser({ ...user, actions: [] });
      navigate('/');
    } catch (error: any) {
      setErrorMessage(error.response.data.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (errorMessage) setErrorMessage('');
  }, [name]);

  return (
    <Grid container direction="column" alignItems="center" marginTop="100px">
      <Logo />

      <div style={{ marginTop: 50 }}>
        <Title>{lang('auth.signup.school.register_school_title')}</Title>
      </div>

      <Form onSubmit={handleSubmit} style={{ width: 310, marginTop: 50 }}>
        <Input
          placeholder={lang('auth.signup.school.name_placeholder')}
          required
          error={!!errorMessage}
          value={name}
          onChange={handleChangeName}
        />
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <Button
          style={{ marginTop: 40 }}
          type="submit"
          label={lang('auth.signup.school.signup')}
          disabled={loading || !name}
          large
        />
      </Form>
    </Grid>
  );
}

export default SchoolForm;
