import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { WebApplicationEvent } from '../../types/WebApplicationEvent';
import { useUserStore } from '../../zustand/user';

/*
This component is a central authority for tracking user activity and sending
it to different destinations for analysis.

By design, analytic data (that is, data that is not part of the application
database but is, instead, collected for the purpose of business
decision-making) should be moved through this component. It ensures that
there is one clear place where all of our data collection is documented.
*/

const {
  REACT_APP_INBOUND_DATA_COLLECTOR_URL,
} = process.env;

// Use the following values to coordinate event POSTs.
let eventTimeout: ReturnType<typeof setTimeout> | null = null;
let eventSequence = 0;

// Record events to local storage when an event is posted.
// This does two things. One, it throttles events by placing them in a queue
// instead of sending them as rapidly as they occur. Two, it allows for events
// to be stored when the user is offline or when an event occurs as the user is
// navigating away from the page.
function trackEvent(event: Omit<WebApplicationEvent, 'sentAt' | 'clientEventSequence'>) {
  const pendingEvents = JSON.parse(localStorage.getItem('pendingEvents') || '[]');
  eventSequence += 1;
  pendingEvents.push({
    ...event,
    clientEventSequence: eventSequence,
  });
  localStorage.setItem('pendingEvents', JSON.stringify(pendingEvents));
}

// Check for queued events every second. If there are events, send them to the
// data collector and clear local storage. Only allow one request to be in
// flight at a time. If the request succeeds, clear the queue.
let dataCollectorLock = false;
setInterval(() => {
  if (!REACT_APP_INBOUND_DATA_COLLECTOR_URL) {
    return;
  }

  const pendingEventsJSON = localStorage.getItem('pendingEvents');
  if (pendingEventsJSON && !dataCollectorLock) {
    dataCollectorLock = true;
    const pendingEvents = JSON.parse(pendingEventsJSON);
    const sentAt = new Date().toISOString();
    fetch(`${REACT_APP_INBOUND_DATA_COLLECTOR_URL}/events/web-application-event-v1`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(pendingEvents.map((event: Omit<WebApplicationEvent, 'sentAt'>) => ({
        ...event,
        sentAt,
      }))),
    }).then(() => {
      localStorage.removeItem('pendingEvents');
    }).finally(() => {
      dataCollectorLock = false;
    });
  }
}, 1000);

export default function DataCollector() {
  const location = useLocation();
  const user = useUserStore((state) => state.user);
  const anonymousId = useUserStore((state) => state.anonymousId);

  useEffect(() => {
    // Set variables for use by Google Tag Manager.
    // Do share any identifiable information for students.
    window.dataLayer?.push({
      user_role: user.role || 'anonymous',
    });
  }, [user.role]);

  useEffect(() => {
    // Debounce this effect. If the location fires multiple times in a
    // short period of time, only send the last one. This prevents rapid-fire
    // network requests when routes are redirecting to one another.
    if (eventTimeout) {
      clearTimeout(eventTimeout);
    }
    eventTimeout = setTimeout(() => {
      eventTimeout = null;

      let timezone: string = '';
      try {
        // This next line is supported in most modern browsers.
        // https://caniuse.com/?search=DateTimeFormat
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch {}

      // Send data to our in-house data collector.
      // See https://github.com/pressto/data-analytics/ for details.
      // The 'web-application-event' schema is defined in that repository.
      // It specifies the data that may be sent from this application.
      eventSequence += 1;
      trackEvent({
        type: 'track',
        eventName: 'page-viewed',
        createdAt: new Date().toISOString(),
        anonymousId,
        user: user.id ? {
          id: user.id,
        } : null,
        context: {
          page: {
            path: location.pathname,
            domain: window.location.origin,
            protocol: window.location.protocol,
            search: location.search,
            title: window.document.title,
          },
          app: {
            name: 'write-app',
            environment: process.env.NODE_ENV,
          },
          campaign: {},
          locale: window.navigator.language,
          referrer: {
            url: window.document.referrer,
          },
          screen: {
            width: window.screen.width,
            height: window.screen.height,
            density: window.devicePixelRatio,
          },
          timezone,
          userAgent: window.navigator.userAgent,
        },
      });
    }, 200);
  }, [location]);

  return null;
}

// The following declaration prevents the compiler from complaining that
// `dataLayer` is not a property of `window`.
declare global {
  interface Window {
    dataLayer?: any[];
  }
}
