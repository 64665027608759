import React, { useCallback, useState } from 'react';
import * as UUID from 'uuid';
import SelectBlockTypeDialog from 'src/components/dialogs/SelectBlockTypeDialog';
import { useApplyDocumentOperation, useDocument, useSelectedBlock } from 'src/hooks/document';
import WritingPlans from 'src/Models/WritingPlans';
import { AddBlockOperation } from 'src/store/document/operations';
import { BlockTypesEnum, getBlockType } from 'src/types/models';
import { usePage } from 'src/views/document-v2/PageContext';
import { NewBlockBox } from './styles';
import { INewBlockProps } from './types';

export default function NewBlock({
  x,
  y,
  width,
  height,
}: INewBlockProps) {
  const page = usePage();
  const applyOperation = useApplyDocumentOperation();
  const document = useDocument(page!.documentId);
  const [, setSelectedBlockId] = useSelectedBlock();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onAddBlock = useCallback(async (blockTypeId: BlockTypesEnum) => {
    const newBlockId = UUID.v1();
    applyOperation(new AddBlockOperation(
      page!.documentId,
      page!.id,
      newBlockId,
      getBlockType(blockTypeId),
      {
        x, y, width, height,
      },
    ));

    setSelectedBlockId(newBlockId);
  }, [page, x, y, width, height]);

  const isSpaceToTheRight = x - 1 + width < page!.grid.columns;
  const isSpaceBelow = y - 1 + height < page!.grid.rows;

  // Specify the allowable blocks to add.
  let allWritingPlans = WritingPlans;
  if (document?.meta.writingPlan) {
    allWritingPlans = allWritingPlans.filter((plan) => plan.id === document.meta.writingPlan);
  }

  return (
    <>
      <NewBlockBox
        onClick={() => setIsDialogOpen(true)}
        role="button"
        aria-hidden="true"
        sx={[
          isSpaceToTheRight && {
            borderRightWidth: '1px',
          },
          isSpaceBelow && {
            borderBottomWidth: '1px',
          },
        ]}
      >
        <div style={{ fontSize: '1.5em' }}>+</div>
        Add Block
      </NewBlockBox>
      <SelectBlockTypeDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSelect={onAddBlock}
        availablePlans={allWritingPlans}
      />
    </>
  );
}
