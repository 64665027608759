import React, { useState } from 'react';
import {
  PictureFrame as PictureFrameIcon,
} from 'src/assets/icons';
// import { useEditor } from 'src/hooks/useEditor';
import { lang } from 'src/lang';
import { Block } from 'src/types/models';
import UploadDialog from '../../dialogs/UploadDialog';
import { ToolbarContainer } from './styles';
import IconTextButton from './ToolbarComponents/IconTextButton';

function ImageBoxToolbar(props: {
  selectedBlock: Block;
  handleUploadImage: (element: string) => void;
}) {
  const {
    selectedBlock,
    handleUploadImage,
  } = props;

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const handleReplace = () => {
    setIsUploadDialogOpen(true);
    // handleUploadImage(selectedEl.key);
    // setIsBusy(true);
  };

  return (
    <>
      <ToolbarContainer>
        <IconTextButton
          onClick={handleReplace}
          src={PictureFrameIcon}
          alt="replace"
          label={
            selectedBlock.properties.imageUrl
              ? lang('document.artboard.toolbar.replace')
              : lang('document.artboard.toolbar.image')
          }
        />
      </ToolbarContainer>
      <UploadDialog
        id={`toolbar-${selectedBlock.id}`}
        onUpload={handleUploadImage}
        documentId={selectedBlock.documentId}
        isOpen={isUploadDialogOpen}
        onClose={() => setIsUploadDialogOpen(false)}
      />
    </>
  );
}

export default ImageBoxToolbar;
