import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNewDocument } from '../../hooks/document';
import { DocumentFormat, isValidDocumentFormat } from '../../types/DocumentSettings';

export default function New() {
  const navigate = useNavigate();
  const search = new URLSearchParams(useLocation().search);

  const formatParam = search.get('format');
  const format = isValidDocumentFormat(formatParam) ? formatParam : DocumentFormat.BOOKLET;

  const importantWords = search.get('importantWords')?.trim().split(/,\s*/) || [];
  const writingPlan = search.get('writingPlan');
  const name = search.get('name') || 'Untitled';

  const document = useNewDocument(name, format, writingPlan, importantWords);

  // Document will be undefined until it is created, at which point,
  // redirect the user to the first page.
  useEffect(() => {
    if (document?.id) {
      navigate(`/document/${document.id}/pages/1`, { replace: true });
    }
  }, [document?.id]);

  return <div />;
}
