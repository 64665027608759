import React from 'react';
import { DocumentFormatSettings } from '../../types/DocumentSettings';
import { isBlockPage } from '../../types/models';
import PageGrid from './Grid';
import { PageBox } from './styles';
import { IPageProps } from './types';

function Page({
  page,
  format,
  isEditable = false,
  scale,
}: IPageProps) {
  // TODO: Improve scale based on available space (both vertical and horizontal)
  // TODO: Improve scale on window resize
  // IDEA: To prevent rendrs have a dynamic font size based on min(vh,vw)

  // View Mode (includes non-block documents)
  if (!isBlockPage(page)) {
    return (
      <PageBox
        scale={scale}
        format={format}
        width={DocumentFormatSettings[format].width}
        height={DocumentFormatSettings[format].height}
        // TODO: There may be a script injection vulnerability here.
        // Look at how the API sanitizes incoming HTML.
        dangerouslySetInnerHTML={{ __html: page.html || '' }}
      />
    );
  }

  return (
    <PageBox
      scale={scale}
      format={format}
      width={DocumentFormatSettings[format].width}
      height={DocumentFormatSettings[format].height}
    >
      <PageGrid
        grid={page.grid}
        isThumbnail={scale <= 0.2}
        isEditable={isEditable}
      />
    </PageBox>
  );
}

export default Page;
