import {
  createContext, useContext, useEffect, useState,
} from 'react';
import { APIResourceType } from 'src/api';
import {
  BlockPage, isBlockPage, LayoutPage, Document, getBlockType, BlockTypesEnum,
} from 'src/types/models';
import * as UUID from 'uuid';
import { AddBlockOperation } from 'src/store/document/operations';
import { getWritingPlan } from 'src/Models/WritingPlans';
import { useDocumentStore } from '../../store/document';
import * as DocumentAPI from '../../api/Documents';
import { DocumentFormat, DocumentTemplateSystem } from '../../types/DocumentSettings';
import { useApplyDocumentOperation } from './apply';
import { useSelectedBlock } from './block';

// Hooks are sensitive to context, but do not require it to operate.
// Sentinel values are used to indicate document state:
// - null: A document has been loaded but an error occurred, such as a 404.
// - undefined: The document is loading.
// - false: There is no document in the context.
export const DocumentContext = createContext<Document | null | undefined | false>(false);

function mapAPIDocumentToModel(document: APIResourceType<typeof DocumentAPI.getById>) {
  // Parse the meta field, which is stored as a string. It could be anything.
  let meta = {
    writingPlan: null,
    importantWords: [],
  };
  try {
    meta = JSON.parse(document.meta);
    if (!Array.isArray(meta.importantWords)) {
      meta.importantWords = [];
    }
    if (typeof meta.writingPlan !== 'string') {
      meta.writingPlan = null;
    }
  } catch (e) {}

  return {
    id: document.id,
    name: document.name,
    meta,
    format: document.format,
    isDemo: document.is_demo,
    isPublic: document.is_public,
    user: document.user,
    templateSystem: (
      document.template_system || DocumentTemplateSystem.LAYOUT
    ) as DocumentTemplateSystem,
    submission: document.submission ? {
      id: document.submission.id,
      status: document.submission.status,
      assignmentId: document.submission.assignment_id,
      documentId: document.submission.document_id,
      assignment: {
        id: document.submission.assignment.id,
        name: document.submission.assignment.name,
        notes: document.submission.assignment.notes?.map((note) => ({
          id: note.id,
          text: note.text,
          user: {
            id: note.user.id,
            name: note.user.name,
            surname: note.user.surname,
            fullName: note.user.full_name,
          },
          createdAt: new Date(note.created_at),
          updatedAt: new Date(note.updated_at),
          assignmentId: note.assignment_id,
          versionId: note.document_version_id,
        })) || [],
      },
    } : null,
    version: {
      id: document.version.id,
      documentId: document.id,
      createdAt: new Date(document.version.created_at),
      publishedAt: new Date(document.version.published_at),
      status: document.version.status,
      // document.version.pages is deprecated in favor of document.version.content.
      pages: document.version.content
        ? document.version.content.pages
        : document.version.pages?.map((page) => {
          const mappedPage = {
            id: page.id,
            documentId: document.id,
            position: page.position,
            thumbnail_url: page.thumbnail_url,
            wordCount: page.word_count,
            template: page.template,
            meta: page.meta || '{}',
          };

          // Determine whether this is a layout or block page.
          const blob = JSON.parse(page.content || '{}');
          if (Array.isArray(blob)) {
          // This is a layout page.
            (mappedPage as LayoutPage).content = blob;
          } else if (blob && blob.blocks) {
            (mappedPage as BlockPage).grid = blob;
            (mappedPage as BlockPage).template = 'blocks_grid';
          } else {
          // Initialize the page with default values.
          // TODO: flush this out. Should be in the store?
            (mappedPage as BlockPage).grid = {
              columns: 1,
              rows: 2,
              blocks: [],
            };
          }

          return mappedPage;
        }) || [],
      notes: document.version.notes?.map((note) => ({
        id: note.id,
        text: note.text,
        user: {
          id: note.user.id,
          name: note.user.name,
          surname: note.user.surname,
          fullName: note.user.full_name,
        },
        createdAt: new Date(note.created_at),
        updatedAt: new Date(note.updated_at),
        assignmentId: note.assignment_id,
        versionId: note.document_version_id,
      })) || [],
    },
  };
}

/**
 * Fetches the document from the API and maps it to the client-side Document model.
 */
async function getDocumentFromAPI(documentId: string, isPublished: boolean) {
  const get = isPublished ? DocumentAPI.getPublished : DocumentAPI.getById;
  const {
    data: { data: document },
  } = await get(documentId);

  // Seed the store with the data retreived by the API.
  return mapAPIDocumentToModel(document);
}

/**
 * This is the primary entry-point for retreiving the document.
 */
export function useDocument(
  documentId?: string,
  isPublished: boolean = false,
): Document | null | undefined {
  const contextDocument = useContext(DocumentContext);
  const storedDocument = useDocumentStore((state) => (
    documentId ? state.documentsById[documentId] : false
  ));
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const setStoreDocument = useDocumentStore((state) => state.setDocument);

  useEffect(() => {
    if (documentId && contextDocument === false && storedDocument === undefined) {
      // The document is not in the context and hasn't been loaded yet.
      // Try fetching it from the server.
      setIsLoading(true);
      getDocumentFromAPI(documentId!, isPublished)
        .then((document) => {
          // When a document is cloned, either duplicated by a user or created from
          // an assignment, then the content of the document is copied from the
          // original. Because `version.content` is a blob, the database doesn't do
          // any work to duplicate the relationships. Instead, it just copies the
          // content wholesale. This means that the `documentId` of the pages in the
          // content blob won't match the `documentId` of the document itself.
          // TODO: There is almost certainly a better place to put this logic.
          // It doesn't feel right to do it upon receipt of the document. But I think
          // putting it here will cover all of the cases in which the issue arises.
          if (document.templateSystem === DocumentTemplateSystem.BLOCKS) {
            document.version.pages.forEach((page) => {
              if (isBlockPage(page) && page.documentId !== documentId) {
                page.documentId = document.id;
                page.id = UUID.v1();

                page.grid.blocks.forEach((block) => {
                  block.id = UUID.v1();
                  block.documentId = document.id;
                });
              }
            });
          }

          setStoreDocument(document);
        })
        .catch(setError)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  if (contextDocument !== false) {
    // We are in the context of a document. Return the one in the context.
    // Assume the state is managed higher up in the component tree and
    // do not interfere with it.
    return contextDocument;
  }

  if (!documentId) {
    // We are not in the context of a document and no documentId was provided.
    // Return null to indicate that no document is available.
    throw new Error('A documentId must be provided when not within a DocumentContext.');
  }

  if (error) {
    return null;
  }

  if (storedDocument === false || isLoading) {
    return undefined;
  }

  return storedDocument;
}

export function useNewDocument(
  name: string,
  format: DocumentFormat,
  writingPlanId: string | null,
  importantWords: string[],
) {
  const [newDocumentId, setNewDocumentId] = useState<string | undefined>(undefined);
  const setDocument = useDocumentStore((state) => state.setDocument);
  const applyOperations = useApplyDocumentOperation();
  const [, setSelectedBlockId] = useSelectedBlock();

  useEffect(() => {
    DocumentAPI.create({
      name,
      format,
      template_system: DocumentTemplateSystem.BLOCKS,
      meta: JSON.stringify({
        writingPlan: writingPlanId,
        importantWords,
      }),
    }).then((response) => {
      const { data: { data: newDocument } } = response;
      setNewDocumentId(newDocument.id);

      const document = mapAPIDocumentToModel(newDocument);
      setDocument(document);

      const writingPlan = getWritingPlan(document.meta.writingPlan || 'news-article');
      const firstBlockType = getBlockType(writingPlan?.availableBlocks[0] || BlockTypesEnum.Title);
      const blockId = UUID.v1();

      // The document should be populated with a page, but some future
      // formats might now. Guard so that the app doesn't just crash.
      if (document.version.pages.length > 0) {
        applyOperations(new AddBlockOperation(
          document.id,
          document.version.pages[0].id,
          blockId,
          firstBlockType,
          {
            x: 1, y: 1, width: 1, height: 1,
          },
        ));
      }
      setSelectedBlockId(blockId);
    });
  }, []);

  // This value will always start out with document being undefined.
  // The value of listening to the store in this way is that the useNewDocument
  // hook will re-render when the document is set and when any subsequent
  // changes are made.
  const document = useDocumentStore((state) => (
    newDocumentId ? state.documentsById[newDocumentId] : undefined
  ));

  return document;
}

export function useDemoDocument() {
  const demoId = 'try-it';
  const setDocument = useDocumentStore((state) => state.setDocument);
  const applyOperations = useApplyDocumentOperation();
  const [, setSelectedBlockId] = useSelectedBlock();

  useEffect(() => {
    // Put a demo document in the store. This will not be synced to the
    // server.
    const demoDocument: Document = {
      id: 'try-it',
      name: 'My Zine',
      format: DocumentFormat.BOOKLET,

      // This is counterintuitive, but demo documents are something else.
      isDemo: false,
      isPublic: false,
      meta: {
        writingPlan: null,
        importantWords: [],
      },
      templateSystem: DocumentTemplateSystem.BLOCKS,
      submission: null,
      user: null,
      version: {
        id: 'try-it',
        documentId: 'try-it',
        createdAt: new Date(),
        notes: [],
        publishedAt: null,
        status: 'DRAFT',
        pages: Array(8).fill(0).map((_, i) => ({
          id: UUID.v1(),
          documentId: demoId,
          grid: {
            blocks: [],
            rows: 2,
            columns: 1,
          },
          meta: '{}',
          position: i,
        })) as BlockPage[],
      },
    };

    setDocument(demoDocument);

    const blockId = UUID.v1();
    applyOperations(new AddBlockOperation(
      demoId,
      demoDocument.version.pages[0].id,
      blockId,
      getBlockType(BlockTypesEnum.Title),
      {
        x: 1, y: 1, width: 1, height: 1,
      },
    ));
    setSelectedBlockId(blockId);
  }, []);

  return useDocument('try-it');
}
