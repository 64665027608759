import React, { useEffect, useState } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import { useUserStore } from 'src/zustand/user';
import { Document } from 'src/types/models';
import { Panel } from 'src/components/shared/Artboard';
import { useSelectedBlock } from 'src/hooks/document';
import WritingPlans from 'src/Models/WritingPlans';
import { Box, Typography } from '@mui/material';
import WritingPlanDialog from '../dialogs/WritingPlanDialog';
import WritingPlanCard from './Cards/WritingPlanCard';
import WordCountCard from './Cards/WordCountCard';
import ImportantWordsCard from './Cards/ImportantWordsCard';
import TitleLengthCard from './Cards/TitleLengthCard';
import * as actions from '../actions';

function FeedbackSideBar(props: {
  document: Document;
}) {
  const { document } = props;
  const mobileMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const user = useUserStore((state) => state.user);

  const writingPlanId: string | null = document.meta.writingPlan || null;
  const writingPlan = WritingPlans.find((el) => el.id === writingPlanId);

  const [expanded, setExpanded] = useState(true);
  const [isWritingPlanDialogOpen, setIsWritingPlanDialogOpen] = useState(false);

  useEffect(() => {
    setExpanded(!mobileMQ);
  }, [mobileMQ]);

  // Mocked values
  let selectedBlock = useSelectedBlock()[0];
  if (selectedBlock?.documentId !== document.id) {
    // This block isn't part of this document. Don't include it
    // in document feedback.
    selectedBlock = null;
  }

  return (
    <Panel preferredWidth={{
      lg: '300px',
      md: '240px',
    }}
    >
      <Box
        sx={{
          margin: 1,
          color: '#999',
        }}
      >
        {/* eslint-disable-next-line */}
        <a
          href="#"
          onClick={() => {
            setIsWritingPlanDialogOpen(true);
          }}
          style={{
            color: '#42408A',
            textDecoration: 'none',
            float: 'right',
            fontWeight: 'bold',
          }}
        >
          <small>{writingPlan ? 'Change' : 'Add'}</small>
        </a>
        <Typography
          variant="subtitle2"
        >
          Writing Plan
        </Typography>
        <Typography
          variant="h6"
          fontWeight={700}
        >
          {writingPlan ? writingPlan.title : 'Not selected'}
        </Typography>
      </Box>

      <div className="cards-list">
        <WordCountCard expanded={expanded} block={selectedBlock} document={document} />
        <TitleLengthCard expanded={expanded} block={selectedBlock} document={document} />
        {/* {pageElements?.includes('ImageBox') && <ImageFeedbackCard expanded={expanded} />} */}
        <WritingPlanCard
          expanded={expanded}
          document={document}
          writingPlan={writingPlan || null}
        />
        {(user.role === 'teacher' || document.meta.importantWords) && (
          <ImportantWordsCard expanded={expanded} block={selectedBlock} document={document} />
        )}
      </div>

      <WritingPlanDialog
        open={isWritingPlanDialogOpen}
        onClose={() => setIsWritingPlanDialogOpen(false)}
        onAdd={(id: string) => {
          actions.saveDocument(document.id, {
            meta: {
              ...document.meta,
              writingPlan: id,
            },
          });
          setIsWritingPlanDialogOpen(false);
        }}
        initialWritingPlan={writingPlanId || undefined}
      />
    </Panel>
  );
}

export default FeedbackSideBar;
