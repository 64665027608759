import TitleIcon from './title.svg';
import ImgIcon from './image.svg';
import ImgCaptionIcon from './image-caption.svg';
import IntroductionIcon from './introduction.svg';
import ConclusionIcon from './conclusion.svg';
import QuoteIcon from './quote.svg';
import MainIdeaIcon from './main-idea.svg';
import MainIdeaLightningIcon from './main-idea-lightning.svg';
import EyeDetailsIcon from './eye-details.svg';
import SettingCharacterIcon from './setting-characters.svg';
import ProblemGoalIcon from './problem-goal.svg';
import AttemptsIcon from './attempts.svg';
import SolutionIcon from './solution.svg';
import ConsequenceIcon from './consequence.svg';
import PointOfViewIcon from './point-view.svg';
import EvidencesIcon from './evidence.svg';
import EventIcon from './event.svg';
import CauseIcon from './cause.svg';
import EffectIcon from './effect.svg';
import CompareIcon from './compare.svg';
import ContrastIcon from './contrast.svg';
import ProblemIcon from './problem.svg';
import HeartSolutionIcon from './heart-solution.svg';
import TextIcon from './text.svg';

export default {
  TitleIcon,
  ImgIcon,
  ImgCaptionIcon,
  IntroductionIcon,
  ConclusionIcon,
  QuoteIcon,
  MainIdeaIcon,
  MainIdeaLightningIcon,
  EyeDetailsIcon,
  SettingCharacterIcon,
  ProblemGoalIcon,
  AttemptsIcon,
  SolutionIcon,
  ConsequenceIcon,
  PointOfViewIcon,
  EvidencesIcon,
  EventIcon,
  CauseIcon,
  EffectIcon,
  CompareIcon,
  ContrastIcon,
  ProblemIcon,
  HeartSolutionIcon,
  TextIcon,
};
