import React, { useMemo } from 'react';
import { BlockTypesEnum } from 'src/types/models';
import { TitleLengthImg } from 'src/assets/images';
import { lang } from 'src/lang';
import { BorderLinearProgress, CardContainer } from '../styles';
import { CardProps } from '../types';

function TitleLengthCard({
  expanded,
  block,
}: CardProps) {
  // This feedback item only applies to titles.
  if (block?.typeId !== BlockTypesEnum.Title) {
    return null;
  }

  // TODO: Stricter typing. Define each kind of block's
  // properties.
  const title = (block.properties.plainText || '') as string;

  const x = title.length;
  const getProgressValue = useMemo(
    () => Math.min(100, Math.max(0, -0.5 * x * x + 20 * x - 87.5)),
    [x],
  );

  const renderProgress = () => (
    <div className="progress-wrapper">
      <BorderLinearProgress
        variant="determinate"
        value={getProgressValue}
      />
    </div>
  );

  return (
    <CardContainer
      backgroundColor="#FDF6F6"
      mainTextColor="#793133"
    >
      <div className="header">
        <img
          className={`card-icon ${expanded ? 'expanded' : 'visible'}`}
          src={TitleLengthImg}
          alt="Title Length"
        />
        <div className={expanded ? 'title-open' : ''}>
          {lang('document.side_bar.title_length.title')}
        </div>
      </div>
      {
        expanded && (
          <div className="card-description">
            {x < 15 && lang('document.side_bar.title_length.default_message')}
            {x > 25 && lang('document.side_bar.title_length.too_much_message')}
            {x >= 15 && x <= 25 && lang('document.side_bar.title_length.good_message')}
          </div>
        )
      }
      {expanded && renderProgress()}
    </CardContainer>
  );
}

export default TitleLengthCard;
