import { Box } from '@mui/system';
import React, {
  useState, useMemo,
} from 'react';
import { PLACEHOLDERS } from 'src/assets/images/placeholders';
import { Block, BlockProperties, BlockType } from 'src/types/models';
import UploadDialog from '../../../dialogs/UploadDialog';
import { stringCharCodeSum } from './utils';

function Image(props: {
  block: Block;
  blockType: BlockType;
  isEditable: boolean;
  onChange?: (data: BlockProperties) => void;
  showPlaceholder?: boolean;
}) {
  const {
    isEditable,
    onChange,
    block,
    blockType,
    showPlaceholder = false,
  } = props;

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  const placeholderIndex = useMemo(
    () => {
      const seed = stringCharCodeSum(block.documentId);
      return (block.x + block.y + seed) % PLACEHOLDERS.length;
    },
    [block],
  );

  let { imageUrl } = block.properties;
  if (showPlaceholder && !imageUrl) {
    imageUrl = PLACEHOLDERS[placeholderIndex];
  }

  if (!blockType.configuration.hasImage) {
    return null;
  }

  return (
    <>
      <Box
        onClick={() => {
          if (isEditable && !block.properties.imageUrl && showPlaceholder) {
            setIsUploadDialogOpen(true);
          }
        }}
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          position: 'absolute',
          backgroundImage: `url('${imageUrl}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          rotate: block.properties.rotation || 0,
        }}
      />

      {/* {isEditable && onChange && (
        <CropComponent
          {...props}
          onChange={onChange}
          loadStep={loadStep}
          setLoadStep={setLoadStep}
          isCropping={isCropping}
          setIsCropping={setIsCropping}
          isRotating={isRotating}
          setIsRotating={setIsRotating}
          isSettingUp={isSettingUp}
          setIsSettingUp={setIsSettingUp}
          onImageLoadComplete={setCroppedImage}
        />
      )} */}

      {isEditable && onChange && (
        <UploadDialog
          isOpen={isUploadDialogOpen}
          onClose={() => setIsUploadDialogOpen(false)}
          id={`image-${block.id}`}
          onUpload={(url) => {
            onChange({ imageUrl: url });
          }}
          documentId={block.documentId}
        />
      )}
    </>
  );
}

export default Image;
