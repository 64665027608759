import { ButtonUnstyled } from '@mui/base';
import { Dialog } from '@mui/material';
import { styled, Box, darken } from '@mui/system';
import { theme } from '../../utils';

export const Wrapper = styled(Box)(({ expanded }: { expanded: boolean }) => ({
  margin: '1rem 0 0 1rem',

  height: 'calc(100vh - 6rem)',
  width: expanded ? '10rem' : '6rem',
  position: 'absolute',
  overflow: 'hidden',
  left: 0,
  zIndex: 1000,
  background: '#fff',

  borderRight: '1px solid #E3E3E3',

  userSelect: 'none',

  transition: 'width .1s ease-in-out',

  '.sidebar-draggable': {
    border: '1px solid #A8A7CC',
    overflow: 'auto',
    scrollbarWidth: 'thin',

    maxHeight: 'calc(100vh - 6rem)',
    minHeight: 'calc(100vh - 6rem)',

    padding: '.5rem',

    '.pages-button': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '.5rem',
      gap: '2.75',

      cursor: 'pointer',

      background: 'transparent',
      width: '100%',
      border: 0,

      '&:hover': {
        background: darken('#fff', 0.05),
      },

      '& > div': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 0,
        gap: '1rem',
      },

    },

    '.pages-list': {
      display: 'grid',
      flexDirection: 'column',
      alignItems: 'center',
      gridGap: '1rem',
      padding: '1rem 0',

      '& > div': {
        width: '100%',
      },

      'div > .MuiBox-root': !expanded && {
        height: '3rem',

        img: {
          display: 'none',
        },
      },

      button: !expanded && {
        height: '3rem',
      },
    },
  },

  [theme.breakpoints.down('sm')]: {
    transform: !expanded && 'translateX(-100%)',

    animation: expanded && '',
  },
}));

export const ThumbnailContainer = styled(Box)(({ active }: { active: boolean }) => ({
  height: '5rem',
  maxHeight: '5rem',
  width: '100%',

  background: active ? '#FFFFFF' : '#F6F6FA',
  border: active ? '1px solid #52509A' : '1px solid #A8A7CC',
  boxShadow: active ? '4px 4px 0px #52509A' : '4px 4px 0px #A8A7CC',

  '&:hover': {
    background: '#FFFFFF',
    border: '1px solid #7D7CB3',
    boxShadow: '4px 4px 0px #7D7CB3',
  },

  position: 'relative',

  display: 'grid',
  placeItems: 'center',
  transition: 'all .2s ease-in-out',

  cursor: 'pointer',

  img: {
    maxHeight: '4rem',
    maxWidth: '60%',
    outline: '#A8A7CC 1.5px solid',
  },

  '.page-number': {
    position: 'absolute',
    top: '0',
    left: '0',

    height: '1.25rem',
    width: '1.25rem',

    display: 'grid',
    placeItems: 'center',

    background: active ? '#52509A' : '#F6F6FA',
    border: active ? '1px solid #52509A' : '1px solid #A8A7CC',
    borderTopWidth: 0,
    borderLeftWidth: 0,

    p: {
      color: active ? '#fff' : '#A8A7CC',
    },

  },
}));

export const AddPageButton = styled(ButtonUnstyled)(() => ({
  height: '5rem',
  maxHeight: '5rem',
  width: '100%',

  cursor: 'pointer',

  display: 'grid',
  placeItems: 'center',

  border: '1px solid #52509A',

  img: {
    color: '#52509A',
    height: '1.5rem',
  },

  background: '#FFFFFF',

  '&:hover': {
    background: darken('#fff', 0.15),
  },
}));

export const StyledDialog = styled(Dialog)(() => ({
  userSelect: 'none',

  '.dialog-header': {
    boxSizing: 'border-box',
    height: '4rem',
    padding: '.75rem .5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    position: 'relative',

    button: {
      position: 'absolute',
      left: '.5rem',
      top: '.75rem ',
    },

    p: {
      fontSize: '1.125rem',
      color: '#52509A',
      fontWeight: 700,
    },
  },

  '.dialog-pages-grid': {
    padding: '0 .5rem',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '0.25rem',

    '.add-button-container': {
      display: 'grid',
      placeItems: 'center',
      height: '5rem',

      button: {
        marginTop: 0,
      },
    },
  },
}));
