export default {
  PPINK: '#EEA1A2',
  PGREEN: '#9EC264',
  PDARKGREEN: '#288247',
  PLIGHTBLUE: '#8DC9C3',
  PGRAY: '#D1D7D3',
  PLIGHTORANGE: '#FDAD4E',
  PPURPLE: '#52509A',
  PDARKORANGE: '#E84534',
  VRED: '#C10000',
  VORANGE: '#FD5308',
  VLIGHTORANGE: '#FB9902',
  VYELLOW: '#FEDE33',
  VLIME: '#BCD710',
  VGREEN: '#66B032',
  VBLUE: '#2086BB',
  VDARKBLUE: '#095FAE',
  VPURPLE: '#3D01A4',
  VMAGENTA: '#8601AF',
  VDARKPINK: '#EA3BE0',
  VPINK: '#FD75A6',
  SPINK: '#FAE1E1',
  SPURPLE: '#EEEEF6',
  SORANGE: '#FEE8CD',
  SGREEN: '#E8F1DA',
  SLIGHTBLUE: '#DCEFED',
  SBLUE: '#D3EBF8',
  SNEUTRAL: '#F6F6FA',
  NBLACK: '#181A1B',
  NGRAY: '#AEB4B7',
  NLIGHTGRAY: '#F3F3F4',
  TRANSPARENT: 'transparent',
  NWHITE: '#FFFFFF',
};
