import { ImageBoxType } from '../../types/LayoutEditor';

export default [
  {
    key: 'imagebox1',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 0,
    y: 0,
    height: 1430,
    width: 2550,
  } as ImageBoxType,
  {
    key: 'imagebox2',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 0,
    y: 1454,
    height: 1846,
    width: 1263,
  } as ImageBoxType,
  {
    key: 'imagebox3',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 1287,
    y: 1454,
    height: 1846,
    width: 1263,
  } as ImageBoxType,
];
