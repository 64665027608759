import { Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { theme } from '../../utils';

export const ButtonContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'isTeacher' })<any>(({ isTeacher }) => ({
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',

  '.image-container': {
    height: isTeacher ? '10rem' : '14rem',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',

    '& > img': {
      position: 'absolute',
      height: '100%',
      top: '1.875rem',
      left: '50%',
      transform: 'translate(-50%, 0%)',
    },

    '& > div': {
      position: 'absolute',
      height: '100%',
      top: '1.875rem',
      left: '50%',
      transform: 'translate(-25%, 0%)',
    },
  },

  '.info-container': {
    height: '13.75rem',
    padding: '1.25rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '.main-info': {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridGap: '.5rem',
    },

    '.secondary-info': {
      display: 'flex',
      justifyContent: 'space-between',
      opacity: 0.5,

      'p:first-of-type': {
        fontWeight: '400',
      },
    },

    '.tag': {
      fontSize: '.75rem',
      padding: '.25rem .5rem',
      fontWeight: 700,
      height: '1.5rem',
      textTransform: 'capitalize',
      borderRadius: 4,
      width: 'fit-content',
    },

    '.is-assigned': {
      color: 'white',
      background: '#3E827C',
    },

    '.new': {
      color: '#FB8800',
      background: 'rgba(253, 173, 78, 0.1)',
    },

    '.draft': {
      color: '#999999',
      background: '#E3E3E3',
    },
  },

  '.pressto-button-actions': {
    position: 'absolute',
    top: '.75rem',
    right: '.75rem',
    display: 'grid',
    cursor: 'pointer',
  },

  '.row': {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '1rem',
  },
}));

export const CardNumber = styled(Typography)(() => ({
  color: theme.palette.primary.main,
  fontSize: '2rem',
  fontWeight: 700,
}));

export const CardDescription = styled(Typography)(() => ({
  color: theme.palette.secondary.main,
  fontSize: '.75rem',
  fontWeight: 500,
}));
