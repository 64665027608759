import React, { useCallback } from 'react';
import {
  Grid, Paper, Toolbar,
} from '@mui/material';
import { PageContextProvider } from 'src/views/document-v2/PageContext';
import PageNavigation from 'src/components/PageNavigation';
import { PageCounter } from 'src/components/PageNavigation/styles';
import { IconButton } from 'src/components/buttons';
import { LargeBinImg } from 'src/assets/icons';
import Page from '../../components/Page';
import { useApplyDocumentOperation } from '../../hooks/document';
import { DeletePageOperation } from '../../store/document/operations';
import * as models from '../../types/models';
import BlockToolbar from './BlockToolbar';

export default function PageWrapper({
  page,
  document,
  scale,
  isEditable,
}: {
  page: models.Page,
  document: models.Document,
  scale: number,
  isEditable: boolean,
}) {
  const applyOperation = useApplyDocumentOperation();

  const onDelete = useCallback(() => {
    applyOperation(new DeletePageOperation(document.id, page.id));
  }, [document.id, page?.id]); // TODO: Shouldn't need a ? here.

  const numberOfPages = document.version.pages.length;

  return models.isBlockPage(page) ? (
    <PageContextProvider value={page}>
      <Grid container sx={{ padding: 3, height: '100%' }} direction="column" flexWrap="nowrap">
        <Grid item>
          <BlockToolbar />
        </Grid>

        <Grid item flexGrow={1} display="flex" justifyContent="center" alignItems="center">
          <Paper elevation={4} sx={{ display: 'inline-block' }}>
            <Page
              page={page}
              format={document.format}
              isEditable={isEditable}
              scale={scale}
            />
          </Paper>
        </Grid>

        <Grid item>
          <Toolbar sx={{
            gap: 3,
            justifyContent: 'center',
          }}
          >
            {isEditable && (
              <IconButton
                src={LargeBinImg}
                onClick={onDelete}
                alt="Delete page"
              />
            )}
            <PageCounter>
              <span>{`Page ${page.position + 1} `}</span>
              {`of ${numberOfPages} `}
            </PageCounter>
            <PageNavigation
              page={page}
              numberOfPages={numberOfPages}
              document={document}
            />
          </Toolbar>
        </Grid>
      </Grid>
    </PageContextProvider>
  ) : null;
}
