import React, { useLayoutEffect, useState } from 'react';

import { orderBy } from 'lodash';
import { BlockTypesEnum } from 'src/types/models';
import { IWritingPlanInfoProps } from './types';
import {
  WrapperInfo,
  WritingPlanSection,
  SignalWordsItem,
} from './styles';
import { Description } from '../../typography';
import { lang } from '../../../lang';
import CollapsableList from '../../shared/CollapsableList';
import BlocksItem from '../../Blocks/BlocksItem';
import Blocks from '../../../Models/Blocks';

function WritingPlanInfo({
  writingPlan,
}: IWritingPlanInfoProps) {
  const [wordsIsCollapsed, setWordsIsCollapsed] = useState(true);
  const [blocksIsCollapsed, setBlocksIsCollapsed] = useState(false);

  const allBlocks = writingPlan.availableBlocks.map((block) => ({
    ...Blocks[block as BlockTypesEnum],
  }));

  const blockByPosition = orderBy(allBlocks, 'position', 'asc');

  useLayoutEffect(() => {
    setWordsIsCollapsed(true);
    setBlocksIsCollapsed(false);
  }, []);

  return (
    <WrapperInfo>
      <Description
        className="description"
        fontWeight={400}
        dangerouslySetInnerHTML={{
          __html: writingPlan.description,
        }}
      />

      <WritingPlanSection className="words-section">
        <Description size="small" className="title-section">
          {lang('document.create_pressto_dialog.writing_plan.use_words_message')}
        </Description>

        <CollapsableList
          list={writingPlan.signalWords}
          isCollapsed={wordsIsCollapsed}
          handleCollapse={() => {
            setWordsIsCollapsed(!wordsIsCollapsed);
            setBlocksIsCollapsed(!blocksIsCollapsed);
          }}
          fontSize=".75rem"
          showLess
          renderItem={(word) => (
            <SignalWordsItem key={word}>
              {word}
            </SignalWordsItem>
          )}
        />
      </WritingPlanSection>

      <WritingPlanSection className="blocks-section">
        <Description size="small" className="title-section">
          {lang('document.create_pressto_dialog.writing_plan.use_blocks_message')}
        </Description>

        <CollapsableList
          list={blockByPosition}
          gapItems=".5rem"
          defaultAmountItemsToShow={4}
          isCollapsed={blocksIsCollapsed}
          handleCollapse={() => {
            setBlocksIsCollapsed(false);
            setWordsIsCollapsed(true);
          }}
          renderItem={(block) => (
            <BlocksItem
              key={block.title}
              image={block.image}
              title={block.title}
            />
          )}
        />
      </WritingPlanSection>
    </WrapperInfo>
  );
}

export default WritingPlanInfo;
