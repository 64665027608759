import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { verifyPassword } from '../../utils';
import {
  FlexBox, Form, GradientContainer, Input, InputPassword, Logo,
} from '../../components';
import { Title, Description } from '../../components/typography';
import * as UserAPI from '../../api/User';
import { useUserStore } from '../../zustand/user';
import { Button } from '../../components/buttons';
import { lang } from '../../lang';

function CreateAccount() {
  const navigate = useNavigate();

  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [password, setPassword] = useState('');
  const [inputErrors, setInputErrors] = useState({
    all: false,
    password: false,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const cleanInputErrors = () => {
    setInputErrors({
      all: false,
      password: false,
    });
  };

  const handleChangeEmail = (e: string) => {
    setEmail(e);
    cleanInputErrors();
  };

  const handleChangeName = (e: string) => {
    setName(e);
    cleanInputErrors();
  };

  const handleChangeLastName = (e: string) => {
    setSurname(e);
    cleanInputErrors();
  };

  const handleChangePassword = (e: string) => {
    setPassword(e);
    cleanInputErrors();
  };

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();

    if (!verifyPassword(password)) {
      setErrorMessage(lang('auth.errors.password_too_short'));
      setInputErrors({
        all: false,
        password: true,
      });
      setLoading(false);
      return;
    }

    try {
      if (url) {
        const response = await UserAPI.fillVerificationEmail(url, {
          name, surname, password,
        });
        if (response.status !== 200) throw response.data;
        setUser(response.data.data);
      } else {
        const response = await UserAPI.completeRegister({
          name,
          surname,
          password,
        });
        if (response.status !== 200) throw response.data;
        setUser(response.data.data);
      }

      navigate('/');
    } catch (err: any) {
      setErrorMessage(err.response.data?.message);
      setInputErrors({ all: true, password: false });
    }

    setLoading(false);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const params = urlParams.get('url');
    const signature = urlParams.get('signature');
    if (params) {
      setUrl(`${params}&signature=${signature}`);
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const params = urlParams.get('url');
    const signature = urlParams.get('signature');

    if (url) {
      setUrl(`${params}&signature=${signature}`);
    }

    if (user?.id) {
      setEmail(user.email || user.username);
    } else {
      const paramEmail = urlParams.get('email');
      setEmail(paramEmail || '');
    }
  }, [user]);

  return (
    <GradientContainer>
      <FlexBox alignItems="center">
        <Logo />

        <FlexBox textAlign="center" marginY={5}>
          <Title>{lang('auth.signup.account.title')}</Title>
        </FlexBox>

        <FlexBox width={310}>
          <Form onSubmit={handleSubmit}>
            <Input
              disabled
              placeholder={lang('auth.commom.email_placeholder')}
              error={inputErrors.all}
              value={email}
              onChange={(e: any) => handleChangeEmail(e.target.value)}
            />
            <Input
              placeholder={lang('auth.commom.name_placeholder')}
              error={inputErrors.all}
              value={name}
              onChange={(e: any) => handleChangeName(e.target.value)}
            />
            <Input
              placeholder={lang('auth.commom.last_name_placeholder')}
              error={inputErrors.all}
              value={surname}
              onChange={(e: any) => handleChangeLastName(e.target.value)}
            />
            <Description>
              {lang('auth.signup.account.password_min')}
            </Description>
            <InputPassword
              placeholder={lang('auth.commom.password_placeholder')}
              error={inputErrors.all || inputErrors.password}
              value={password}
              invalidMessage={errorMessage}
              onChange={(e: any) => handleChangePassword(e.target.value)}
            />

            <Button
              type="submit"
              label={lang('auth.signup.signup')}
              disabled={!email || !name || !surname || !password || loading}
              large
            />
          </Form>
        </FlexBox>
      </FlexBox>
    </GradientContainer>
  );
}

export default CreateAccount;
