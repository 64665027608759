import React, { useEffect, useState } from 'react';

import GalleryTab from './GalleryTab';
import GalleryTopBar from '../../components/GalleryTopBar';
import ClassroomsTab from './ClassroomsTab';
import CreateClassroomDialog from '../../components/dialogs/CreateClassroomDialog';
import { useUserStore } from '../../zustand/user';
import AssignmentsTab from './AssignmentsTab';
import { useDocumentStore } from '../../zustand/documents';

function Gallery() {
  const { role } = useUserStore((state) => state.user);
  const clearSelectedDocument = useDocumentStore((state) => state.clearSelectedDocument);

  const getInitialTab = () => {
    const routeViewsObject: {
      [key: string]: number
    } = {
      gallery: 0,
      assignment: 1,
      classroom: 2,
    };

    if (role === 'individual') return routeViewsObject.gallery;

    const galleryViewInLocalStorage = localStorage.getItem('galleryView') || 'gallery';

    const currentTab = routeViewsObject[galleryViewInLocalStorage];

    if (currentTab === routeViewsObject.classroom && role !== 'teacher') return routeViewsObject.gallery;

    return currentTab;
  };

  const [isCreateClassroomDialogOpen, setIsCreateClassroomDialogOpen] = useState(false);

  const [tab, setTab] = useState(getInitialTab());

  // const handleCreateAssignmentFromDialog = (classroom: ClassroomDefinition) => {
  //   setTab(1);
  //   setClassrooms([...classrooms, classroom]);
  //   setTimeout(() => {
  //     assignmentsRef.current?.openDialog(classroom.id);
  //   }, 200);
  // };

  // const handleCreateAssignment = (assignment: AssignmentDefinition) => {
  //   setAssignments([...assignments, assignment]);
  // };

  useEffect(() => {
    clearSelectedDocument();
  }, []);

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        background: '#F0F0F4',
        overflow: 'hidden',
      }}
    >
      {/* TOPBAR */}
      <GalleryTopBar
        tab={tab}
        changeTab={setTab}
      />
      {/* INFO CONTAINER */}
      {/* <InfoContainer tab={tab} assignmentsInfo={assignmentsInfo} /> */}
      {/* TABS */}
      {tab === 0 && <GalleryTab tab={tab} />}

      {tab === 1 && (
        <AssignmentsTab
          tab={tab}
          classrooms={[]}
          onCreateAssignmentButtonClick={() => { }}
        // onCreateAssignmentButtonClick={() => assignmentsRef.current?.openDialog()}
        />
      )}

      {tab === 2 && (
        <ClassroomsTab
          tab={tab}
          onCreateClassroomButtonClick={() => setIsCreateClassroomDialogOpen(true)}
        />
      )}

      {/* DIALOGS */}
      {/* <CreateAssignmentDialog
        ref={assignmentsRef}
        classrooms={classrooms}
        onSubmit={() => { }}
      /> */}
      {role === 'teacher' && (
        <CreateClassroomDialog
          hasGoogleRedirect
          setTab={setTab}
          isOpen={isCreateClassroomDialogOpen}
          onOpen={() => setIsCreateClassroomDialogOpen(true)}
          onClose={() => setIsCreateClassroomDialogOpen(false)}
        />
      )}

    </div>
  );
}

export default Gallery;
