import { styled } from '@mui/system';
import { theme } from '../../utils';

export const TabWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',

  ':not(.filter) > p': {
    fontSize: '1.5rem',
    marginBottom: '3rem',
  },

  '& > div': {
    flexGrow: 1,
    display: 'flex',
    gap: '1.75rem',

    '.filter': {
      width: '16.25rem',
    },
  },

  '.filter': {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',

    '.filter-container': {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '25rem',
      overflow: 'overlay',
      gap: '1.25rem',
    },
  },
}));

export const PresstoGrid = styled('div')(() => ({
  [theme.breakpoints.up('sm')]: {
    padding: '0rem 2.5rem 0 3.5rem',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '1.5rem 1rem 0 1rem',
  },
  position: 'relative',
  flexGrow: 1,

  '.content': {
    width: 'fill-available',
    paddingBottom: '.25rem',
    maxHeight: 'calc(100vh - 12rem)',

    display: 'grid',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(auto-fit, 12.5rem)',
      gap: '1.25rem',
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: 'calc(100vh - 15rem)',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'calc(100vh - 16.5rem)',
      gridTemplateColumns: 'repeat(auto-fit, calc(50% - 1rem))',
      gap: '2rem',
    },
    overflowY: 'auto',
  },

  '.pressto-count-row': {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '.75rem',
    alignItems: 'center',
    margin: '2.5rem 0 2rem 0',
  },

  '.absolute': {
    position: 'absolute',
    top: '2rem',
    fontSize: '.75rem',
    color: '#2C2C2C',
  },

  '.pressto-count': {
    color: '#2C2C2C',

    fontSize: '.875rem',

    strong: {
      fontSize: '1.125rem',
    },
  },

  '.sorted-by': {
    display: 'flex',
    alignItems: 'center',

    '#sort-by-button': {
      border: '1px solid #A8A7CC',
      backgroundColor: '#FFFFFF',
      color: '#52509A',
      backgroundOpacity: 1,
      padding: '.5rem 1rem',
      textDecoration: 'none',
      fontWeight: '700',
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
    },

    span: {
      marginRight: '1rem',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },

  '.pressto-button': {
    [theme.breakpoints.up('sm')]: {
      height: '17.5rem',
      width: '12.5rem',
    },

    [theme.breakpoints.down('sm')]: {
      minWidth: '2rem',
      aspectRatio: '12.5 / 17.5',
    },

    border: '1px solid #52509A',
    boxShadow: '4px 4px 0px #52509A',
    overflow: 'hidden',
    position: 'relative',
    background: '#F0F0F4',

    transition: 'all .2s ease-in-out',

    '&:hover': {
      background: '#FFFFFF',
      border: '1px solid #7D7CB3',
      boxShadow: '4px 4px 0px #7D7CB3',
    },
  },

  '.create': {
    // The create button has a plus sign and a label.
    // Otherwise, it has similar shape and coloring to the rest
    // of the objects in the grid.
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: '700',
    color: 'rgb(82, 80, 149)',

    img: {
      marginBottom: '20px',
    },
  },
}));

export const AssignmentGrid = styled(PresstoGrid, { shouldForwardProp: (prop) => prop !== 'isTeacher' })<any>(({ isTeacher }) => ({
  '.assignment-button': {
    [theme.breakpoints.up('sm')]: {
      height: isTeacher ? '22.5rem' : '17.5rem',
      width: '12.5rem',
    },

    [theme.breakpoints.down('sm')]: {
      // height: 'calc((100vw / 2) * 1.2)',
      // width: '100%',
      minWidth: '2rem',
    },

    background: '#F6F6FA',
    overflow: 'hidden',
    position: 'relative',
    border: '1px solid #7D7CB3',

    '&:hover': {
      background: '#FFFFFF',
      boxShadow: '4px 4px 0px #7D7CB3',
    },
  },

  '.show-assignments': {
    cursor: 'pointer',
    left: 'calc(50% + 1.75rem)',
    transform: 'translateX(-50%)',
    textDecoration: 'underline',
  },

  '.create': {
    // The assignments buttons are tall. Move the contents of
    // the create button up a little so it doesn't look so far down.
    img: {
      marginTop: '-55px',
    },
  },
}));

export const ClassroomGrid = styled(PresstoGrid)(() => ({
  '.classroom-button': {
    height: '12.5rem',
    width: '12.5rem',
    borderRadius: 0,
    background: '#F6F6FA',
    overflow: 'hidden',
    position: 'relative',
    border: '1px solid #A8A7CC',
    boxShadow: '4px 4px 0px #52509A',

    '&:hover': {
      background: '#FFFFFF',
      border: '1px solid #7D7CB3',
      boxShadow: '4px 4px 0px #7D7CB3',
    },
  },
}));
