import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../utils';

export default styled(Box)(() => ({
  padding: '2.5rem 2.5rem 0 2.5rem',
  display: 'flex',
  flexDirection: 'row',

  '.classroom-back-button': {
    display: 'flex',
    gap: '.5rem',
    cursor: 'pointer',
    textDecoration: 'none',
    marginBottom: '2.5rem',
  },

  '.classroom-name-info': {
    padding: '2.5rem',
    borderRadius: '.5rem',
    boxShadow: '0px 0px 50px rgba(82, 80, 154, 0.2)',
    marginBottom: '.5rem',

    '.classroom-name': {
      fontSize: '1.5rem',
      color: '#2C2C2C',
      lineHeight: '150%',
    },

    '.classroom-type': {
      fontSize: '.75rem',
      display: 'flex',
      gap: '.5rem',
      alignItems: 'center',
      color: '#999999',
      lineHeight: '150%',
    },

    '.classroom-code': {
      fontSize: '2rem',
      fontWeight: 700,
      color: '#52509A',
      lineHeight: '150%',
    },
  },

  '.main-content': {
    padding: '0 6.875rem',

    '.student-name': {
      fontSize: '3rem',
      color: '#52509A',
      lineHeight: '150%',
    },
  },

  [theme.breakpoints.down('md')]: {
    paddingTop: '1rem',
    flexDirection: 'column',
    gap: 0,

    '.classroom-back-button': {
      marginBottom: '1.5rem',
    },

    '.classroom-name-info': {
      boxShadow: '0px 0px 50px rgba(82, 80, 154, 0.2)',
      marginBottom: 0,
    },

    '.main-content': {
      padding: 0,
    },
  },

  '.classroom-delete-button': {
    color: '#FF5252',
    fontSize: '.75rem',
    position: 'absolute',
    left: '3.75rem',
    bottom: '2.5rem',
    cursor: 'pointer',
    textDecoration: 'underline',
  },

  '.left-column': {
    width: '20rem',
    minWidth: '20rem',
    marginRight: '3rem',
  },

  '.right-column': {
    width: 'fill-available',
  },

  '.extended-container': {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2.5rem',

    '.classroom-delete-button': {
      display: 'contents',
    },
  },
}));
