import ArrowLeftImg from './arrow-left.svg';
import ArrowRightImg from './arrow-right.svg';
import BinImg from './bin.svg';
import WandImg from './wand.svg';
import BrushImg from './brush.svg';
import LargeBackArrowImg from './large-back-arrow.svg';
import BackArrowImg from './back-arrow.svg';
import UndoImg from './undo.svg';
import RedoImg from './redo.svg';
import BookImg from './book-opened.svg';
import PlayImg from './play.svg';
import ShareImg from './share.svg';
import BookletImg from './booklet.svg';
import PageImg from './page.svg';
import PersonImg from './person.svg';
import PagesImg from './pages.svg';
import PlusImg from './plus.svg';
import LargeBinImg from './large-bin.svg';
import CopyImg from './copy.svg';
import DialogCloseImg from './dialog-close.svg';
import BoldImg from './bold.svg';
import ItalicImg from './italic.svg';
import UnderlineImg from './underline.svg';
import TextColorImg from './text-color.svg';
import TextBackgroundImg from './text-background.svg';
import BackgroundColorImg from './background-color.svg';
import AlignLeftImg from './left-align.svg';
import AlignCenterImg from './center-align.svg';
import AlignRightImg from './right-align.svg';
import CropImg from './crop.svg';
import OkImg from './ok.svg';
import FlipImg from './flip.svg';
import FontDecreaseImg from './font-decrease.svg';
import FontSizeImg from './font-size.svg';
import FontIncreaseImg from './font-increase.svg';
import ReplaceImg from './replace.svg';
import OptionsMenuImg from './options-menu.svg';
import SquareImg from './square.svg';
import FeedbackImg from './feedback.svg';
import SearchImg from './search.svg';
import ClearImg from './clear.svg';
import EditButtonImg from './edit-button.svg';
import EditProfilePicImg from './edit-profile-pic.svg';
import GoogleClassroomImg from './google-classroom.svg';
import CheckImg from './union.svg';
import ConfigImg from './config.svg';
import PencilImg from './pencil.svg';
import PrintImg from './print.svg';
import CheckedImg from './checked.svg';
import CheckedOrangeImg from './checked-orange.svg';
import OriginalDocumentImg from './original-document.svg';
import Create from './create.svg';
import Note from './note.svg';
import Pushpin from './pushpin.svg';
import Cycle from './cycle.svg';
import SmallBin from './small-bin.svg';
import PictureFrame from './picture-frame.svg';
import MoveArrow from './move-arrow.svg';

import FormatBookletImg from './format-booklet.svg';
import FormatStoryImg from './format-story.svg';
import FormatSheetImg from './format-sheet.svg';
import FormatPresentationImg from './format-presentation.svg';
import { DocumentFormat } from '../../types/DocumentSettings';

export default [
  {
    value: DocumentFormat.BOOKLET,
    title: 'Booklet',
    subtitle: '8-page fold',
    image: FormatBookletImg,
    disabled: false,
  },
  {
    value: DocumentFormat.STORY,
    title: 'Story',
    subtitle: 'Social media',
    image: FormatStoryImg,
    disabled: false,
  },
  {
    value: DocumentFormat.SHEET,
    title: 'Sheet',
    subtitle: 'coming soon',
    image: FormatSheetImg,
    disabled: true,
  },
  {
    value: DocumentFormat.PRESENTATION,
    title: 'Presentation',
    subtitle: 'coming soon',
    image: FormatPresentationImg,
    disabled: true,
  },
] as const;

export {
  LargeBackArrowImg,
  BackArrowImg,
  UndoImg,
  RedoImg,
  BookImg,
  PlayImg,
  ShareImg,
  BookletImg,
  PageImg,
  PersonImg,
  ArrowLeftImg,
  ArrowRightImg,
  BinImg,
  WandImg,
  BrushImg,
  PagesImg,
  PlusImg,
  LargeBinImg,
  CopyImg,
  DialogCloseImg,
  BoldImg,
  ItalicImg,
  UnderlineImg,
  TextColorImg,
  TextBackgroundImg,
  BackgroundColorImg,
  AlignLeftImg,
  AlignCenterImg,
  AlignRightImg,
  FontDecreaseImg,
  FontSizeImg,
  FontIncreaseImg,
  CropImg,
  OkImg,
  FlipImg,
  ReplaceImg,
  OptionsMenuImg,
  SquareImg,
  FeedbackImg,
  SearchImg,
  ClearImg,
  EditButtonImg,
  EditProfilePicImg,
  GoogleClassroomImg,
  CheckImg,
  ConfigImg,
  PencilImg,
  PrintImg,
  CheckedImg,
  CheckedOrangeImg,
  OriginalDocumentImg,
  Create,
  Note,
  Pushpin,
  Cycle,
  SmallBin,
  PictureFrame,
  MoveArrow,
};
