import React, { useEffect } from 'react';
import { useDrop } from 'react-dnd';
import PageThumbnail from './PageThumbnail';
import { PageThumbnailViewModel } from './types';

/**
 * Each thumbnail is wrapped by a drop target. When a page is dragged over it,
 * a reorder operation is applied.
 */
export default function PageDropTarget(props: {
  page: PageThumbnailViewModel;
  index: number;
  onPageOver: (dragIndex: number, dropIndex: number) => void;
  onPageDrop: (dragIndex: number, dropIndex: number) => void;
}) {
  const {
    page, index, onPageOver, onPageDrop,
  } = props;

  const [{ isOver, draggedPage }, drop] = useDrop({
    accept: 'page',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      draggedPage: monitor.getItem(),
    }),
    drop: (droppedPage: PageThumbnailViewModel) => {
      onPageDrop(droppedPage.originalIndex, index);
    },
  });

  useEffect(() => {
    // Send a signal to our parent component when the drag state changes.
    // This will visually reorder the pages.
    if (draggedPage !== null && isOver) {
      if (draggedPage !== null) {
        onPageOver(draggedPage.originalIndex, index);
      }
    }
  }, [isOver]);

  return (
    <div
      ref={drop}
      style={{
        padding: '8px 0',
      }}
    >
      <div style={{ opacity: isOver ? 0.2 : 1 }}>
        <PageThumbnail page={page} />
      </div>
    </div>
  );
}
