import { Box } from '@mui/system';
import React, { useMemo, useState } from 'react';
import { CheckedOrangeImg } from 'src/assets/icons';
import { ImportantWordsImg } from 'src/assets/images';
import { lang } from 'src/lang';
import { useUserStore } from 'src/zustand/user';
import { Description } from 'src/components/typography';
import { isBlockPage } from 'src/types/models';
import ImportantWordsDialog from '../../dialogs/ImportantWordsSelectionDialog';
import { CardContainer, SignalWord } from '../styles';
import { CardProps } from '../types';
import * as actions from '../../actions';

function ImportantWordsCard({
  expanded,
  document,
}: CardProps) {
  const user = useUserStore((state) => state.user);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const importantWords = (document.meta.importantWords || []) as string[];
  const hasImportantWords = Array.isArray(importantWords) && importantWords.length;

  // Important words can be set by teachers who own the document.
  const canSet = user.id === document.user?.id && user.role === 'teacher';

  const importantWordsPresence = useMemo(() => {
    const presence: Record<string, boolean> = {};

    if (hasImportantWords && importantWords.length) {
      const allBlocks = document.version.pages.filter(
        isBlockPage,
      ).flatMap(
        (page) => page.grid.blocks,
      );
      const text = allBlocks.map((b) => b.properties.plainText || '').join(' ').toLowerCase();

      importantWords.forEach((word) => {
        presence[word] = text.includes(word.toLowerCase());
      });
    }
    return presence;
  }, [document.version.pages, importantWords]);

  if (!canSet && !hasImportantWords) {
    return null;
  }

  const renderWordDetector = () => {
    if (hasImportantWords && importantWords) {
      return (
        <div className="important-words-content">
          <div className="words-wrapper">
            {importantWords.map((word) => (
              <SignalWord
                activated={importantWordsPresence[word]}
                key={word}
                lightColor="#E88F2580"
                mainColor="#E88F25"
              >
                {word}
                {importantWordsPresence[word] && (
                  <img src={CheckedOrangeImg} alt={`${word} used`} />
                )}
              </SignalWord>
            ))}
          </div>
          <div className="signal-words-description" />
        </div>
      );
    }

    return canSet ? (
      <Description className="description">{lang('document.side_bar.important_words.description')}</Description>
    ) : null;
  };

  return (
    <>
      <CardContainer
        backgroundColor="#FFFCF8"
        mainTextColor="#E88F25"
      >
        <div className="header">
          <img
            className={`card-icon ${expanded ? 'expanded' : 'visible'}`}
            src={ImportantWordsImg}
            alt="Important Words"
          />
          <Box
            className={expanded ? 'title-open' : ''}
            onClick={canSet ? () => {
              setIsDialogOpen(true);
            } : undefined}
          >
            {lang('document.side_bar.important_words.title')}
            {
              canSet && (
                <div className="subtitle customize orange">
                  {lang('document.side_bar.important_words.customize')}
                </div>
              )
            }
          </Box>
        </div>
        {expanded && renderWordDetector()}
      </CardContainer>
      <ImportantWordsDialog
        open={isDialogOpen}
        initialImportantWords={importantWords.join(', ')}
        onAdd={(words) => {
          let wordList: string[];
          if (words.trim().length) {
            wordList = words.split(/,\s*/);
          } else {
            wordList = [];
          }
          actions.saveDocument(document.id, {
            meta: {
              ...document.meta,
              importantWords: wordList,
            },
          });
          setIsDialogOpen(false);
        }}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
}

export default ImportantWordsCard;
