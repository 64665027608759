import { useCallback } from 'react';
import DocumentOperation, { OperationState } from 'src/store/document/operations/abstract';
import { useDocumentStore } from '../../store/document';

export function useApplyDocumentOperation(localOnly = false) {
  const apply = useDocumentStore((state) => state.applyDocumentOperation);
  return useCallback(
    (operation: DocumentOperation): Promise<OperationState> => apply(operation, localOnly),
    [apply, localOnly],
  );
}
