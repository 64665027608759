import { TextBoxType } from '../../types/LayoutEditor';

export default [
  {
    key: 'textbox1',
    type: 'TextBox',
    defaultFont: 'QUOTE100',
    defaultTextAlign: 'ALIGN_CENTER',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 112,
    y: 112,
    height: 632,
    width: 1696,
  } as TextBoxType,
  {
    key: 'textbox2',
    type: 'TextBox',
    defaultFont: 'CAPTION100',
    defaultTextAlign: 'ALIGN_CENTER',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 112,
    y: 776,
    height: 192,
    width: 1696,
  } as TextBoxType,
];
