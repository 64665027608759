import { ImageBoxType, TextBoxType } from '../../types/LayoutEditor';

export default [
  {
    key: 'imagebox1',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 0,
    y: 0,
    height: 562,
    width: 675,
  } as ImageBoxType,
  {
    key: 'textbox1',
    type: 'TextBox',
    defaultFont: 'TITLE100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 48,
    y: 578,
    height: 531,
    width: 579,
  } as TextBoxType,
];
