import { IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import { DialogProps } from '../types';
import { Description } from '../../typography';
import { DialogCloseImg } from '../../../assets/icons';
import Button from '../../buttons/Button';
import { DeleteAccountImg } from '../../../assets/images';
import { DeleteAccountDialogContainer, DeleteAccountDialogContent, DeleteAccountDialogTitle } from './styles';
import * as UserAPI from '../../../api/User';
import { useUserStore } from '../../../zustand/user';
import { lang } from '../../../lang';

function DeleteAccountDialog({ isOpen, onClose }: DialogProps) {
  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);

  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    if (!user.id) return;
    setLoading(true);

    const response = await UserAPI.deleteUser(user.id);
    if (response.status === 200) {
      setUser({
        actions: [],
        name: '',
        surname: '',
        username: '',
        email: '',
      });
    }
    setLoading(false);
  };

  return (
    <DeleteAccountDialogContainer onClose={onClose} open={isOpen}>
      <DeleteAccountDialogTitle>
        <Description style={{ lineHeight: '100%' }}>{lang('auth.delete_account_dialog.title')}</Description>
      </DeleteAccountDialogTitle>

      <DeleteAccountDialogContent>
        <div className="content-wrapper">
          <div className="content">
            <Description className="subtitle">
              {lang('auth.delete_account_dialog.subtitle')}
            </Description>
            <Description className="long-description">
              {lang('auth.delete_account_dialog.description')}
            </Description>
          </div>
        </div>
        <div className="dialog-actions">
          <Button label={lang('general.cancel')} onClick={onClose} outline disabled={loading} />
          <Button label={lang('general.delete')} onClick={handleDelete} disabled={loading} />
        </div>
      </DeleteAccountDialogContent>

      <IconButton className="close-button" onClick={onClose}>
        <img src={DialogCloseImg} alt="close dialog" />
      </IconButton>
      <img
        src={DeleteAccountImg}
        alt="delete account"
        className="delete-account-image"
      />
    </DeleteAccountDialogContainer>
  );
}

export default DeleteAccountDialog;
