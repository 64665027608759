import { styled } from '@mui/system';

function getStatusStyle(status: string) {
  if (status === 'new' || status === 'not-started') {
    return {
      color: '#FB8800',
      background: 'rgba(253, 173, 78, 0.1)',
    };
  }

  if (status === 'assigned' || status === 'turnedin') {
    return {
      color: '#FFFFFF',
      background: '#3E827C',
      border: '1px solid #3E827C',
    };
  }

  if (status === 'draft') {
    return {
      color: '#999999',
      background: '#E3E3E3',
      border: '1px solid #E3E3E3',
    };
  }

  if (status === 'published' || status === 'unpublished') {
    return {
      color: '#52509A',
      background: '#A8A7CC4D',
      border: '1px solid #A8A7CC',
    };
  }

  if (status === 'returned') {
    return {
      color: '#3E827C',
      background: '#FFFFFF',
      border: '1px solid #3E827C',
    };
  }

  return {};
}

export const Tag = styled('div')(({ status }: { status: string }) => ({
  fontSize: '.75rem',
  padding: '.25rem .5rem',
  fontWeight: 700,
  height: '1.5rem',
  textTransform: 'capitalize',
  borderRadius: 0,
  width: 'fit-content',

  ...getStatusStyle(status),

}));
