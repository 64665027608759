import { lighten } from '@mui/material';
import { Box, styled } from '@mui/system';

const CellBox: any = styled(Box, {
  shouldForwardProp: (prop: string) => ![
    'x',
    'y',
    'width',
    'height',
  ].includes(prop),
})(({
  x,
  y,
  width,
  height,
  isEmptyBelow,
  isEmptyToTheRight,
}: {
  x: number,
  y: number,
  width: number,
  height: number,
  isEmptyBelow: boolean,
  isEmptyToTheRight: boolean,
}) => ({
  // This is for troubleshooting rendering performance:
  // background: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
  gridColumnStart: x,
  gridColumnEnd: x + width,
  gridRowStart: y,
  gridRowEnd: y + height,
  borderRight: isEmptyToTheRight ? `1px dashed ${lighten('#A8A7CC', 0.7)}` : 'none',
  borderBottom: isEmptyBelow ? `1px dashed ${lighten('#A8A7CC', 0.7)}` : 'none',
}));

export default CellBox;
