import { IconButton, useMediaQuery } from '@material-ui/core';
import { Theme } from '@mui/system';
import React, {
  useEffect, useMemo, useState,
} from 'react';
import Masonry from '@mui/lab/Masonry';
import SearchInput from 'src/components/inputs/SearchInput';
import { getImage, getImageData } from 'src/api/Image';
import { DialogCloseImg } from 'src/assets/icons';
import { debounce } from 'src/utils';
import { Description } from 'src/components/typography';
import * as ImageAPI from 'src/api/Image';
import { lang } from 'src/lang';
import { DialogProps } from 'src/components/dialogs/types';
import { UploadDialogContainer, UploadDialogTitle, UploadDialogContent } from './styles';
import ImageElement from './ImageElement';

function UploadDialog(props: DialogProps & {
  documentId: string;
  onUpload: (url: string) => void;
  id: string;
}) {
  const {
    isOpen, onClose, documentId, onUpload, id,
  } = props;
  const mobileMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const tabletMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const [searchValue, setSearchValue] = useState('');
  const [imagesArray, setImagesArray] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');

  const loadPageData = async () => {
    // if (!currentDocument?.id && !currentDocument?.isTryIt) return;
    const { data } = await getImage('try-it', {
      text: searchValue,
      page: currentPage,
    });
    if (data?.data) {
      setImagesArray((prev) => prev
        .concat(data?.data)
        .filter((v, i, a) => a.findIndex((v2) => v2.provider_id === v.provider_id) === i));
      setCurrentPage((prev) => prev + 1);
      setTimeout(() => setIsLoading(false), 200);
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (isProcessing) return;
      if (imagesArray.length === 0 || isLoading) loadPageData();
    }
  }, [isOpen, isLoading]);

  useEffect(() => {
    debounce(() => {
      setIsLoading(true);
      setCurrentPage(1);
      setImagesArray([]);
    }, 1000);
  }, [searchValue]);

  const handleClose = () => {
    setSearchValue('');
    // setIsBusy(false);
    setIsProcessing(false);

    if (searchValue) {
      setImagesArray([]);
    }

    onClose();
  };

  // const readFile = async (file: File) => new Promise((resolve) => {
  //   const reader = new FileReader();
  //   reader.addEventListener('load', () => resolve(reader.result), false);
  //   reader.readAsDataURL(file);
  // });

  const handleSubmit = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const file = event.target.files[0];
    // if (!currentDocument) return;

    // if (currentDocument?.isTryIt) {
    //   const imageDataUrl = await readFile(file);

    //   handleElChange(currentElement, 'originalImage', imageDataUrl);
    //   handleClose();
    //   return;
    // }

    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await ImageAPI.upload(
        documentId,
        formData,
      );

      // handleElChange(currentElement, 'originalImage', response.data.data.url);
      onUpload(response.data.data.url);
      handleClose();
    } catch (err: any) {
      setError(err.response.data.message);
    }
  };

  const handleImageChosen = async (image: string) => {
    setIsProcessing(true);
    try {
      const { data } = await getImageData(documentId, { image_id: image });
      if (data.data) {
        onUpload(data.data.url);

        // handleElChange(currentElement, 'originalImage', data.data.url);
        handleClose();
      } else {
        setIsProcessing(false);
      }
    } catch {
      setIsProcessing(false);
    }
  };

  const handleScroll = (e: any) => {
    const section = e.target.firstChild;
    const scrollHeight = e.target.clientHeight + e.target.scrollTop;
    if (isLoading || scrollHeight < section.clientHeight * 0.9) return;
    setIsLoading(true);
  };

  const columns = useMemo(() => {
    if (mobileMQ) return 2;
    if (tabletMQ) return 3;
    return 4;
  }, [mobileMQ, tabletMQ]);

  return (
    <UploadDialogContainer open={isOpen} onClose={handleClose}>
      <UploadDialogTitle>
        <Description size="small">
          {lang('document.upload_dialog.title.line_1')}
        </Description>
        <Description>{lang('document.upload_dialog.title.line_2')}</Description>
      </UploadDialogTitle>
      <UploadDialogContent>
        <div className="search-bar">
          <div>
            <SearchInput
              value={searchValue}
              onChange={setSearchValue}
              placeholder={lang('document.upload_dialog.title.search_placeholder')}
              disabled={isProcessing}
            />
          </div>
          <div className="sub-bar">
            {lang('document.upload_dialog.upload_message.line_1')}
            {' '}
            <label
              id="upload-button"
              style={{ cursor: 'pointer' }}
              htmlFor={`upload-input-${id}`}
            >
              {lang('document.upload_dialog.upload_message.line_2')}
              <input
                id={`upload-input-${id}`}
                type="file"
                onChange={handleSubmit}
                hidden
                accept="image/*"
                disabled={isProcessing}
              />
            </label>
            <br />
            {error && <Description size="small" style={{ color: 'red' }}>{error}</Description>}
          </div>
        </div>
        <div className="image-grid" onScroll={handleScroll} style={isProcessing ? { opacity: 0.5 } : {}}>
          <Masonry columns={columns}>
            {imagesArray.map((el) => (
              <div style={{ boxShadow: 'none' }} className="image-container" key={el.provider_id}>
                <ImageElement
                  isProcessing={isProcessing}
                  element={el}
                  onClick={() => { handleImageChosen(el.provider_id); }}
                />
              </div>
            ))}
          </Masonry>
          {/* {isLoading && (
            <div className="loading-indicator" style={{ color: '#999999' }}>
              <CircularProgress color="inherit" />
            </div>
          )} */}
        </div>
      </UploadDialogContent>

      <IconButton className="close-button" onClick={handleClose}>
        <img src={DialogCloseImg} alt="close dialog" />
      </IconButton>
    </UploadDialogContainer>
  );
}

export default UploadDialog;
