import React from 'react';
import { Paper } from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';

/**
 * A panel is designed for use on the Artboard. It contains information
 * or tools pertaining to the current document being edited.
 */
export default function Panel(props: {
  preferredWidth: ResponsiveStyleValue<number | string>
  children: React.ReactNode;
}) {
  const { children, preferredWidth } = props;

  return (
    <Paper
      elevation={0}
      sx={{
        margin: 3,
        width: preferredWidth,
        padding: {
          md: 2,
          sm: 1,
        },
        // TODO: Could this calculation be done somewhere else?
        // Could we get away with fewer constants?
        height: 'calc(100vh - 64px - 24px * 2)',
        overflowY: 'scroll',
        overflowX: 'hidden',
        // overflow: 'hidden auto',
      }}
    >
      {children}
    </Paper>
  );
}
