export enum BlockTypesEnum {
  Text = 'Text',
  Title = 'Title',
  Image = 'Image',

  Introduction = 'Introduction',
  Lead = 'Lead',
  Quote = 'Quote',
  Conclusion = 'Conclusion',
  ImageCaption = 'ImageCaption',
  Solution = 'Solution',
  StorySolution = 'StorySolution',

  MainIdea = 'MainIdea',
  Details = 'Details',

  SettingAndCharacters = 'SettingAndCharacters',
  ProblemOrGoal = 'ProblemOrGoal',
  Attempts = 'Attempts',
  ConsequenceOrResolution = 'ConsequenceOrResolution',

  PointOfView = 'PointOfView',
  Evidence = 'Evidence',

  Event = 'Event',

  Cause = 'Cause',
  Effect = 'Effect',

  Compare = 'Compare',
  Contrast = 'Contrast',

  Problem = 'Problem',
  SupportingDetails = 'SupportingDetails',
}

export interface Block {
  id: string,
  typeId: BlockTypesEnum,
  documentId: string;
  x: number;
  y: number;
  width: number;
  height: number;
  properties: BlockProperties;
}

// Block sizing must be specified in a unit that is relative to the
// font size: em. For example, '1em', or '2.5em'. The type system will complain if
// this is not the case.
type BlockSizing = `${number}em`;

export interface BlockType {
  id: BlockTypesEnum;
  title: string;
  label: string;
  image: string;
  preferredWidth: number;
  preferredHeight: number;
  defaultProperties: BlockProperties;
  configuration: BlockConfiguration;
}

export type BlockProperties = Partial<{
  plainText: string;
  htmlText: string;
  html: string;
  rawEditorContent: string;
  fontSize: BlockSizing;
  fontStyle: string;
  fontFamily: string;
  color: string;
  backgroundColor: string | null;
  fontColor: string;
  imageUrl: string | null;
  rotation: 0 | 90 | 180 | 270;
  cropX: number;
  cropY: number;
  cropWidth: number | null;
  cropHeight: number | null;
}>;

type BlockConfiguration = Partial<{
  placeholder: string;
  hasText: boolean;
  hasImage: boolean;
}>;

const baseConfiguration = {
  hasText: true,
  hasImage: false,
} as const;
const baseDefaultProperties = {
  text: '',
} as const;

const blockTypeBase = {
  preferredWidth: 1,
  preferredHeight: 1,
  configuration: baseConfiguration,
  defaultProperties: baseDefaultProperties,
} as const;

/**
 * The definitive list of all possible blocks, their default properties,
 * and their configuration.
 */
const blockTypeSpecifications: Record<BlockTypesEnum, Partial<BlockType>> = {
  Text: {
    configuration: {
      placeholder: 'Click to start writing',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  Title: {
    configuration: {
      placeholder: 'Click to write a Title',
      hasImage: true,
    },
    defaultProperties: {
      fontSize: '2em',
      fontFamily: 'Alfa',
      fontColor: 'Black',
    },
  },
  Image: {
    label: 'Image',
    configuration: {
      hasText: false,
      hasImage: true,
    },
    defaultProperties: {
      rotation: 0,
      cropX: 0,
      cropY: 0,
    },
  },
  ImageCaption: {
    title: 'Image Caption',
    label: 'Caption',
    configuration: {
      hasImage: true,
      placeholder: 'Click to write an image caption (click the image icon to add an image)',
    },
    defaultProperties: {
      fontSize: '0.75em',
      fontFamily: 'Roboto',
      backgroundColor: 'Black',
      fontColor: 'White',
    },
  },
  Introduction: {
    configuration: {
      placeholder: 'Click to write an Introduction',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Source Serif 4'",
      fontStyle: 'Italic',
      fontColor: 'Black',
    },
  },
  Lead: {
    configuration: {
      placeholder: 'Click to write a Lead',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Source Serif 4'",
      fontStyle: 'Italic',
      fontColor: 'Black',
    },
  },
  Quote: {
    configuration: {
      placeholder: 'Click to write a Quote',
    },
    defaultProperties: {
      fontSize: '1.5em',
      fontFamily: "'Source Serif 4'",
      fontStyle: 'Italic',
      fontColor: 'Black',
    },
  },
  Conclusion: {
    configuration: {
      placeholder: 'Click to write a Conclusion',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontStyle: 'Bold',
      fontColor: 'Black',
    },
  },
  MainIdea: {
    title: 'Main Idea',
    label: 'Main Idea',
    configuration: {
      placeholder: 'Click to write your main idea',
    },
    defaultProperties: {
      fontSize: '1em',
      // fontFamily: 'Oswald',
      fontFamily: 'Bebas',
      fontColor: 'Black',
    },
  },
  Details: {
    title: 'Details (Who / What / Where / When / Why / How)',
    label: 'Details',
    configuration: {
      placeholder: 'Click to write a Detail (Who / What / Where / When / Why / How)',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  SettingAndCharacters: {
    title: 'Exposition: Setting and Characters',
    label: 'Exposition',
    configuration: {
      placeholder: 'Click to write your Exposition (Setting and Characters)',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontColor: 'Black',
    },
  },
  ProblemOrGoal: {
    title: 'Problem or Goal',
    label: 'Problem or Goal',
    configuration: {
      placeholder: 'Click to write a Problem or Goal',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      backgroundColor: null,
      fontColor: 'Black',
    },
  },
  Attempts: {
    title: 'Attempts to solve problem or reach goal',
    label: 'Attempts',
    configuration: {
      placeholder: 'Click to write an Attempt to solve problem or reach goal',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      backgroundColor: null,
      fontColor: 'Black',
    },
  },
  StorySolution: {
    title: 'Solution',
    label: 'Solution',
    configuration: {
      placeholder: 'Click to write a Solution',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontStyle: 'Bold',
      backgroundColor: null,
      fontColor: 'Black',
    },
  },
  ConsequenceOrResolution: {
    title: 'Consequence or Resolution',
    label: 'Consequence or Resolution',
    configuration: {
      placeholder: 'Click to write a Consequence or Resolution',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      fontStyle: 'Bold',
      backgroundColor: null,
      fontColor: 'Black',
    },
  },
  PointOfView: {
    title: 'Opinion or Point of View',
    label: 'Opinion',
    configuration: {
      placeholder: 'Click to write an Opinion or Point of View',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: 'Bebas',
      // fontFamily: 'Oswald',
      backgroundColor: null,
      fontColor: 'Black',
    },
  },
  Evidence: {
    title: 'Reasons and Evidence',
    label: 'Reasons and Evidence',
    configuration: {
      placeholder: 'Click to write a Reason and Evidence',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      backgroundColor: null,
      fontColor: 'Black',
    },
  },
  Event: {
    title: 'Step or Event',
    label: 'Event',
    configuration: {
      placeholder: 'Click to write a Step or Event',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      backgroundColor: null,
      fontColor: 'Black',
    },
  },
  Cause: {
    configuration: {
      placeholder: 'Click to write a Cause',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      backgroundColor: null,
      fontColor: 'Black',
    },
  },
  Effect: {
    configuration: {
      placeholder: 'Click to write an Effect',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      backgroundColor: null,
      fontColor: 'Black',
    },
  },
  Compare: {
    title: 'Similarity (Compare)',
    label: 'Similarity',
    configuration: {
      placeholder: 'Click to write a Similarity (Compare)',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      backgroundColor: null,
      fontColor: 'Black',
    },
  },
  Contrast: {
    title: 'Difference (Contrast) ',
    label: 'Difference',
    configuration: {
      placeholder: 'Click to write a Difference (Contrast)',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      backgroundColor: null,
      fontColor: 'Black',
    },
  },
  Problem: {
    configuration: {
      placeholder: 'Click to write a Problem',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      backgroundColor: null,
      fontColor: 'Black',
    },
  },
  Solution: {
    configuration: {
      placeholder: 'Click to write a Solution',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      backgroundColor: null,
      fontColor: 'Black',
    },
  },
  SupportingDetails: {
    title: 'Supporting Details',
    configuration: {
      placeholder: 'Click to write a Supporting Detail',
    },
    defaultProperties: {
      fontSize: '1em',
      fontFamily: "'Open Sans'",
      backgroundColor: null,
      fontColor: 'Black',
    },
  },
};

// Merge the specification into the the base types to create a
// lookup by BlockTypesEnum.
const blockTypesById = Object.entries(blockTypeSpecifications).reduce(
  (lookup, [typeId, blockType]) => ({
    ...lookup,
    [typeId]: {
      ...blockTypeBase,
      title: blockType.title || typeId,
      label: blockType.label || typeId,
      ...blockType,
      id: typeId as BlockTypesEnum,
      configuration: {
        ...baseConfiguration,
        ...(blockType as Partial<BlockType>).configuration || {},
      },
      defaultProperties: {
        ...baseDefaultProperties,
        ...(blockType as Partial<BlockType>).defaultProperties || {},
      },
    },
  }),
  {},
) as Record<BlockTypesEnum, BlockType>;

export function getBlockType(block: Block): BlockType;
export function getBlockType(id: BlockTypesEnum): BlockType;
export function getBlockType(key: BlockTypesEnum | Block): BlockType {
  if (typeof key === 'string') {
    return blockTypesById[key] || blockTypesById[BlockTypesEnum.Text];
  }
  return blockTypesById[key.typeId] || blockTypesById[BlockTypesEnum.Text];
}
