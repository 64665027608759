import React, { useEffect, useMemo, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { useLocation, useSearchParams } from 'react-router-dom';

import dayjs from 'dayjs';
import { Description, Title } from '../../typography';
import { BackArrowImg, DialogCloseImg, GoogleClassroomImg } from '../../../assets/icons';
import Input from '../../inputs/Input';
import Button from '../../buttons/Button';
import {
  ClassroomDialogContainer,
  ClassroomDialogContent,
  ClassroomDialogTitle,
  OptionImport,
  OptionImportList,
} from './styles';
import { LoaderImg } from '../../../assets/images';
import { SelectList, Form } from '../..';
import { CreateClassroomDialogProps } from './types';
import * as ClassroomsAPI from '../../../api/Classrooms';
import { useUserStore } from '../../../zustand/user';
import { useClassroomStore } from '../../../zustand/classrooms';
import { lang } from '../../../lang';

const {
  Marker, OptionLabel, ListOption, Option,
} = SelectList;

const GOOGLE_SCOPES = 'https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.profile.emails https://www.googleapis.com/auth/classroom.rosters.readonly';

function CreateClassroomDialog({
  hasGoogleRedirect, setTab, isOpen, onOpen, onClose,
  fromAssignment,
  onCloseCallback,
}: CreateClassroomDialogProps) {
  const user = useUserStore((state) => state.user);
  const loadClassrooms = useClassroomStore((state) => state.loadClassrooms);

  const [searchParams, setSearchParams] = useSearchParams();

  const [currentStep, setCurrentStep] = useState(0);

  const [selectedClassrooms, setSelectedClassrooms] = useState<any>([]);
  const [classroomList, setClassroomList] = useState<any>([]);
  const [classroom, setClassroom] = useState('');
  const [subject, setSubject] = useState('');
  const [section, setSection] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [code, setCode] = useState('');

  const [copied, setCopied] = useState(false);

  const { state } = useLocation() as { state: any };

  useEffect(() => {
    if (isOpen || !!state) return;
    setTimeout(() => {
      setCurrentStep(0);
      setSelectedClassrooms([]);
      setClassroomList([]);
      setClassroom('');
      setSubject('');
      setSection('');
    }, 100);
  }, [isOpen]);

  const handleCopy = () => {
    navigator.clipboard.writeText(code);
    setCopied(true);
  };

  const importData = async () => {
    setLoading(true);
    try {
      const response = await ClassroomsAPI.saveFromGoogle({
        classrooms: selectedClassrooms.map((e: any) => e.id),
      });
      if (response.status === 200) {
        const listOfNewClassrooms = response.data.data.map((c: any) => c.id);
        const responseClassrooms = await ClassroomsAPI.getAll();
        if (response.status === 200) {
          setClassroomList(responseClassrooms.data.data.map((c: any) => ({
            ...c,
            isSyncing: listOfNewClassrooms.includes(c.id),
          })));
        }
        response.data.data.forEach((e: any) => ClassroomsAPI.syncWithGoogle(e.id));
        if (setTab) setTab(2);
        loadClassrooms();
        if (fromAssignment && onCloseCallback) {
          onCloseCallback();
        }
        onClose();
      }
    } catch (error) {

    }
    setLoading(false);
  };

  const getGoogleClassrooms = useGoogleLogin({
    flow: 'auth-code',
    scope: GOOGLE_SCOPES,
    ux_mode: 'redirect',
    redirect_uri: `${window.location.origin}/classrooms/providers/oauth/google`,
    select_account: false,
    hint: user.email,
  });

  useEffect(() => {
    setErrorMessage(false);
    if (searchParams.get('error') && hasGoogleRedirect) {
      onOpen();
      if (state?.permissionError) {
        setCurrentStep(3);
        setErrorMessage(true);
      }
      setSearchParams('');
    } else if (searchParams.get('loadClassrooms') && hasGoogleRedirect) {
      onOpen();
      if (state?.classroomList) {
        setCurrentStep(3);
        setClassroomList(state?.classroomList || []);
      }
      if (!fromAssignment) setSearchParams('');
    }
  }, []);

  const handleSelect = (item: any) => {
    if (selectedClassrooms.find((e: any) => item.id === e.id)) {
      setSelectedClassrooms(selectedClassrooms.filter((e: any) => item.id !== e.id));
    } else {
      setSelectedClassrooms([...selectedClassrooms, item]);
    }
  };

  const submitForm = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const response = await ClassroomsAPI.createClassroom({
      name: classroom, section: section || undefined, subject: subject || undefined,
    });
    if (response.status === 201) {
      setCode(response.data.data.join_code);
      setCurrentStep(2);
      loadClassrooms();
    }
    setLoading(false);
  };

  const enableNextStep = useMemo(() => classroom.length > 0, [classroom]);

  return (
    <ClassroomDialogContainer onClose={onClose} open={isOpen}>
      <div className="content-wrapper">
        {currentStep === 0 && (
          <>
            <ClassroomDialogTitle>
              <Description weight={700} style={{ fontSize: '2rem', lineHeight: '2rem' }}>
                {lang('classroom.create_classroom_dialog.step_0.title')}
              </Description>
            </ClassroomDialogTitle>
            <ClassroomDialogContent>
              <div style={{ display: 'flex' }}>
                <OptionImportList>
                  <OptionImport
                    onClick={() => !loading && getGoogleClassrooms()}
                    disabled={loading}
                  >
                    <img src={GoogleClassroomImg} alt="" height={50} />
                    <Title size="small" align="center" lineHeight="20px">
                      {lang('classroom.create_classroom_dialog.step_0.import')}
                    </Title>
                    <Description>
                      {lang('classroom.create_classroom_dialog.step_0.sync')}
                    </Description>
                  </OptionImport>
                  <OptionImport
                    onClick={() => !loading && setCurrentStep(1)}
                    disabled={loading}
                  >
                    <img src={LoaderImg} alt="" height={50} />
                    <Title size="small" align="center" lineHeight="20px">
                      {lang('classroom.create_classroom_dialog.step_0.create')}
                    </Title>
                    <Description>
                      {lang('classroom.create_classroom_dialog.step_0.invite')}
                    </Description>
                  </OptionImport>
                </OptionImportList>
              </div>
            </ClassroomDialogContent>
          </>
        )}
        {currentStep === 1 && (
          <>
            <ClassroomDialogTitle>
              <Description weight={700} style={{ fontSize: '2rem' }}>
                {lang('classroom.create_classroom_dialog.step_1.title')}
              </Description>
            </ClassroomDialogTitle>
            <ClassroomDialogContent className="first-step">
              {/* <Button
                  label="Import a class from Google Classroom"
                  onClick={() => { }}
                /> */}
              <Form onSubmit={submitForm}>
                <div className="input-wrapper">
                  <Input
                    placeholder={lang('classroom.create_classroom_dialog.step_1.classroom_placeholder')}
                    value={classroom}
                    required
                    onChange={(event: any) => setClassroom(event.target.value)}
                    maxLength={25}
                    disabled={loading}
                  />
                  <Input
                    placeholder={lang('classroom.create_classroom_dialog.step_1.subject_placeholder')}
                    value={subject}
                    onChange={(event: any) => setSubject(event.target.value)}
                    maxLength={25}
                    disabled={loading}
                  />
                  <Input
                    placeholder={lang('classroom.create_classroom_dialog.step_1.section_placeholder')}
                    value={section}
                    onChange={(event: any) => setSection(event.target.value)}
                    maxLength={25}
                    disabled={loading}
                  />
                </div>
                <Button
                  disabled={!enableNextStep || loading}
                  label={lang('classroom.create_classroom_dialog.step_1.submit')}
                  type="submit"
                  outline
                />
              </Form>
              {/* <Description align="center" style={{ marginTop: '1.5rem' }}>
                  Decide later,
                  {' '}
                  <Link underline href="/">
                    go to the Writing App
                  </Link>
                </Description> */}
            </ClassroomDialogContent>
          </>
        )}
        {currentStep === 2 && (
          <>
            <ClassroomDialogTitle>
              <Description weight={700} style={{ fontSize: '2rem' }}>
                {lang('classroom.create_classroom_dialog.step_2.title')}
              </Description>
            </ClassroomDialogTitle>
            <ClassroomDialogContent className="second-step">
              <Description>
                {lang('classroom.create_classroom_dialog.step_2.subtitle')}
              </Description>
              <div className="code-container">
                <Box className="code-box" onClick={handleCopy}>
                  {code}
                </Box>
                <Description
                  style={{ cursor: 'pointer' }}
                  onClick={handleCopy}
                >
                  {lang(`classroom.create_classroom_dialog.step_2.${copied ? 'copied' : 'copy'}`)}
                </Description>
              </div>
              <Button
                label={lang('classroom.create_classroom_dialog.step_2.submit')}
                onClick={() => {
                  if (fromAssignment && onCloseCallback) {
                    onCloseCallback();
                  }
                  onClose();
                }}
                style={{ justifySelf: 'center' }}
              />
              {/* <Button label="Finish" onClick={() => setCurrentStep(3)} /> */}
            </ClassroomDialogContent>
          </>
        )}
        {currentStep === 3 && errorMessage && (
          <div style={{ margin: 'auto', alignSelf: 'center' }}>
            <ClassroomDialogTitle>
              <Description weight={700} style={{ fontSize: '2rem', lineHeight: '2.5rem' }}>
                {lang('classroom.create_classroom_dialog.error.title')}
              </Description>
            </ClassroomDialogTitle>
            <ClassroomDialogContent className="fourth-step" style={{ alignItems: 'center' }}>
              <Description align="center" marginTop={5} marginBottom={5}>
                {lang('classroom.create_classroom_dialog.error.subtitle')}
              </Description>
              <Button
                label={lang('classroom.create_classroom_dialog.go_back')}
                onClick={() => setCurrentStep(0)}
              />
            </ClassroomDialogContent>
          </div>
        )}
        {currentStep === 3 && !errorMessage && (
          <div style={{ margin: 'auto', alignSelf: 'center' }}>
            <ClassroomDialogTitle>
              <Description weight={700} style={{ fontSize: '2rem', lineHeight: '2.5rem' }}>
                {
                  classroomList?.length === 0
                    ? 'No classes found in your Google Classroom account'
                    : 'Choose Google Classroom classes to import'
                }
              </Description>
            </ClassroomDialogTitle>
            <ClassroomDialogContent className="fourth-step">
              {classroomList?.length > 0 ? (
                <>
                  <Description alignSelf="flex-start">
                    {lang('classroom.create_classroom_dialog.step_3.classroom_count', { count: classroomList?.length ?? 0 })}
                  </Description>
                  <ListOption>
                    {
                      classroomList?.map((item: any) => (
                        <Option
                          key={item.id}
                          onClick={() => !item.imported_at && handleSelect(item)}
                          disabled={item.imported_at}
                        >
                          <Marker
                            selected={
                              selectedClassrooms.find((e: any) => e.id === item.id)
                              || item.imported_at
                            }
                          />
                          <div style={{ display: 'flex', alignItems: 'center', margin: '0 .5rem' }}>
                            <img src={GoogleClassroomImg} alt="" width={40} />
                          </div>
                          <OptionLabel>
                            <Title size="small" lineHeight="20px">
                              {item.name}
                              <span
                                style={{
                                  marginLeft: 15,
                                  fontWeight: 'normal',
                                  fontSize: '0.75rem',
                                }}
                              >
                                {item.imported_at && lang('classroom.create_classroom_dialog.step_3.imported', { date: dayjs(item.imported_at).fromNow() })}
                              </span>
                            </Title>
                            <Description>{item.section}</Description>
                          </OptionLabel>
                        </Option>
                      ))
                    }
                  </ListOption>
                  <Button
                    // label={lang('classroom.create_classroom_dialog.step_3.submit')}
                    label={fromAssignment ? 'Finish Assignment' : 'Import'}
                    large
                    outline
                    onClick={importData}
                    disabled={selectedClassrooms.length === 0 || loading}
                  />
                </>
              ) : (
                <>
                  <Description align="center" marginBottom={5} marginTop={5}>
                    {lang('classroom.create_classroom_dialog.step_3.create')}
                  </Description>
                  <Button
                    style={{ alignSelf: 'center' }}
                    label={lang('classroom.create_classroom_dialog.go_back')}
                    onClick={() => setCurrentStep(0)}
                  />
                </>
              )}
            </ClassroomDialogContent>
          </div>
        )}
      </div>

      {currentStep > 0 && (
        <span
          className="back-button"
          role="button"
          tabIndex={-1}
          onClick={() => {
            setCurrentStep(0);
            setClassroom('');
            setSubject('');
            setSection('');
          }}
          onKeyDown={() => {
            setCurrentStep(0);
            setClassroom('');
            setSubject('');
            setSection('');
          }}
        >
          <img src={BackArrowImg} alt="back button" />
          <Description size="small">{lang('general.go_back')}</Description>
        </span>
      )}

      <IconButton className="close-button" onClick={onClose}>
        <img src={DialogCloseImg} alt="close dialog" />
      </IconButton>
    </ClassroomDialogContainer>
  );
}

export default CreateClassroomDialog;
