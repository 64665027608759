import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Create } from '../../assets/icons';
import ClassroomButton from '../../components/ClassroomButton';
import TabPanel from '../../components/TabPanel';
import { ClassroomsTabProps } from './types';
import { useClassroomStore } from '../../zustand/classrooms';
import { ClassroomGrid, TabWrapper } from './styles';
import { lang } from '../../lang';
import { useUserStore } from '../../zustand/user';

function ClassroomsTab({ tab, onCreateClassroomButtonClick }: ClassroomsTabProps) {
  // const [searchBarValue, setSearchBarValue] = useState('');
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);

  // const [sortedBy, setSortedBy] = useState<SortByType>(() => {
  //   const savedData = localStorage.getItem('sortedClassroomBy');
  //   return savedData ? JSON.parse(savedData) : SORT_BY[0];
  // });

  // const handleOpenSortByDialog = (event: any) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleCloseSortByDialog = (selectedItem?: SortByType) => {
  //   if (selectedItem) {
  //     setSortedBy(selectedItem);
  //     localStorage.setItem('sortedClassroomBy', JSON.stringify(selectedItem));
  //   }
  //   setAnchorEl(null);
  // };

  // const filteredClassrooms = useMemo(() => {
  //   const filtered = [...classrooms];

  //   return filtered.sort((a, b) => sortByKey(a, b, sortedBy.id));
  // }, [classrooms, sortedBy]);

  const classrooms = useClassroomStore((state) => state.classrooms);
  const loadClassrooms = useClassroomStore((state) => state.loadClassrooms);
  const user = useUserStore((state) => state.user);

  useEffect(() => {
    if (user.role === 'teacher') loadClassrooms();
  }, []);

  const navigate = useNavigate();

  window.document.title = 'Classrooms';
  return (
    <TabPanel tab={tab} index={2}>
      <TabWrapper>
        <div>
          <ClassroomGrid>
            <div className="pressto-count-row">
              <div style={{ marginTop: '.5rem' }} className="pressto-count">
                {lang('general.found')}
                {' '}
                <strong>
                  {lang('classroom.classroom_tab.classroom_count', { count: classrooms.length })}
                </strong>
              </div>
            </div>
            <div className="content">
              <button
                className="create pressto-button classroom-button"
                type="button"
                onClick={() => onCreateClassroomButtonClick()}
              >
                <img src={Create} alt="+" />
                {lang('classroom.classroom_tab.create')}
              </button>
              {classrooms.map((classroom) => (
                <ClassroomButton
                  key={classroom.id}
                  classroom={classroom}
                  onClick={(id) => navigate(`/classroom/${id}`)}
                />
              ))}
            </div>
          </ClassroomGrid>
        </div>
      </TabWrapper>
    </TabPanel>
  );
}

export default ClassroomsTab;
