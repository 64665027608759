import {
  convertToRaw, EditorState, Modifier, RichUtils,
} from 'draft-js';
import { OrderedSet } from 'immutable';
import { getPrefix, getSufix, kebabize } from '../../../utils';
import { ContentBlock } from './types';
import { LayoutPage as PageType } from '../../../types/models';
import COLORS from '../../../utils/colors';

export const LAYOUT_FONTS = {
  TITLE: {
    name: 'Title',
    styles: { fontFamily: 'Alfa', fontSize: 125 },
  },
  SUBTITLE: {
    name: 'Sub-Title',
    styles: { fontFamily: 'Bebas', fontSize: 92.8 },
  },
  QUOTE: {
    name: 'Quote',
    styles: { fontFamily: '"Source Serif 4"', fontSize: 84 },
  },
  PARAGRAPH: {
    name: 'Paragraph',
    styles: { fontFamily: '"Open Sans"', fontSize: 46.4 },
  },
  CAPTION: {
    name: 'Caption',
    styles: { fontFamily: 'Roboto', fontSize: 36 },
  },
  LABEL: {
    name: 'Label',
    styles: { fontFamily: 'Bebas', fontSize: 40 },
  },
};

export const BLOCK_FONTS = {
  TITLE: {
    name: 'Alfa Slab',
    styles: { fontFamily: 'Alfa', fontSize: 4 },
  },
  PARAGRAPH: {
    name: 'Open Sans',
    styles: { fontFamily: "'Open Sans'", fontSize: 1 },
  },
  SUBTITLE: {
    name: 'Bebas Neue',
    styles: { fontFamily: 'Bebas', fontSize: 3 },
  },
  QUOTE: {
    name: 'Source Serif',
    styles: { fontFamily: "'Source Serif 4'", fontSize: 2.5 },
  },
  CAPTION: {
    name: 'Roboto',
    styles: { fontFamily: 'Roboto', fontSize: 1 },
  },
  // LABEL: {
  //   name: 'Label',
  //   styles: { fontFamily: 'Bebas', fontSize: 40 },
  // },
};

function generateColorsStyleMap() {
  const colorsStyleMap = {} as any;
  Object.keys(COLORS).forEach((key: string) => {
    colorsStyleMap[`TEXT_COLOR_${key}`] = {
      color: COLORS[key as keyof typeof COLORS],
    };
    colorsStyleMap[`TEXT_BACKGROUND_${key}`] = {
      backgroundColor: COLORS[key as keyof typeof COLORS],
    };
    colorsStyleMap[`BACKGROUND_COLOR_${key}`] = {
      backgroundColor: COLORS[key as keyof typeof COLORS],
    };
  });
  return colorsStyleMap;
}

export function getFontSizeMultiplier(
  originalHeight: number,
  containerHeight: number,
) {
  return containerHeight / originalHeight;
}

function calculateFontSize(
  fontSize: number,
  sizeVariant: number,
  fontMultiplier: number,
) {
  return fontSize * fontMultiplier * sizeVariant;
}

function generateFonts(fontSizeMultiplier: number) {
  const fonts = {} as { [key: string]: { fontFamily: string; fontSize: string } };
  Object.keys(LAYOUT_FONTS).forEach((key) => {
    const font = LAYOUT_FONTS[key as keyof typeof LAYOUT_FONTS];
    fonts[`FONT_STYLE_${key}60`] = {
      fontFamily: font.styles.fontFamily,
      fontSize: `${calculateFontSize(
        font.styles.fontSize,
        0.6,
        fontSizeMultiplier,
      )}px`,
    };
    fonts[`FONT_STYLE_${key}80`] = {
      fontFamily: font.styles.fontFamily,
      fontSize: `${calculateFontSize(
        font.styles.fontSize,
        0.8,
        fontSizeMultiplier,
      )}px`,
    };
    fonts[`FONT_STYLE_${key}100`] = {
      fontFamily: font.styles.fontFamily,
      fontSize: `${calculateFontSize(
        font.styles.fontSize,
        1,
        fontSizeMultiplier,
      )}px`,
    };
    fonts[`FONT_STYLE_${key}120`] = {
      fontFamily: font.styles.fontFamily,
      fontSize: `${calculateFontSize(
        font.styles.fontSize,
        1.2,
        fontSizeMultiplier,
      )}px`,
    };
    fonts[`FONT_STYLE_${key}140`] = {
      fontFamily: font.styles.fontFamily,
      fontSize: `${calculateFontSize(
        font.styles.fontSize,
        1.4,
        fontSizeMultiplier,
      )}px`,
    };
  });
  return fonts;
}

/**
 * Describe the fonts for the block-based editor, whcih relies on relative
 * units of measures to scale the fonts up and down.
 */
function generateRelativeFonts() {
  const fonts = {} as { [key: string]: { fontFamily: string; fontSize: string } };
  Object.entries(BLOCK_FONTS).forEach(([key, font]) => {
    for (let i = 60; i <= 140; i += 20) {
      fonts[`FONT_STYLE_${key}_${i}`] = {
        fontFamily: font.styles.fontFamily,
        fontSize: `${font.styles.fontSize * (i / 100.0)}em`,
      };
    }
  });
  return fonts;
}

export function generateStyleMap(fontSizeMultiplier: number = 1) {
  return {
    ...generateColorsStyleMap(),

    ...generateFonts(fontSizeMultiplier),

    BOLD: { fontWeight: 'bold' },
    ITALIC: { fontStyle: 'italic' },
    UNDERLINE: { textDecoration: 'underline' },

    ALIGN_LEFT: { textAlign: 'left' },
    ALIGN_CENTER: { textAlign: 'center' },
    ALIGN_RIGHT: { textAlign: 'right' },
  };
}

export function generateBlocksStyleMap() {
  return {
    ...generateColorsStyleMap(),
    ...generateRelativeFonts(),

    BOLD: { fontWeight: 'bold' },
    ITALIC: { fontStyle: 'italic' },
    UNDERLINE: { textDecoration: 'underline' },

    ALIGN_LEFT: { textAlign: 'left' },
    ALIGN_CENTER: { textAlign: 'center' },
    ALIGN_RIGHT: { textAlign: 'right' },
  };
}

function getSelectedBlocks(
  contentState: Draft.DraftModel.ImmutableData.ContentState,
  startKey: string,
  endKey: string,
  isBackward = false,
): Draft.DraftModel.ImmutableData.ContentBlock[] {
  const isSameBlock = startKey === endKey;
  const startingBlock = contentState.getBlockForKey(startKey);
  const selectedBlocks = [startingBlock];
  let foundLastKey = false;

  if (!isSameBlock) {
    let blockKey = startKey;

    while (blockKey !== endKey) {
      let nextBlock = null;

      if (isBackward) {
        nextBlock = contentState.getBlockBefore(blockKey);
      } else {
        nextBlock = contentState.getBlockAfter(blockKey);
      }

      if (!nextBlock) {
        if (foundLastKey) {
          break;
        } else {
          return getSelectedBlocks(contentState, endKey, startKey, false);
        }
      }
      selectedBlocks.push(nextBlock);
      blockKey = nextBlock.getKey();
      if (blockKey === endKey) {
        foundLastKey = true;
      }
    }
  }

  return isBackward ? selectedBlocks.reverse() : selectedBlocks;
}

export function selectFullBlocks(
  state: EditorState,
  content: Draft.DraftModel.ImmutableData.ContentState,
) {
  const currentSelection = state.getSelection();
  const [startKey, endKey] = [currentSelection.getStartKey(), currentSelection.getEndKey()];

  const selectedBlocks = getSelectedBlocks(
    content,
    startKey,
    endKey,
    currentSelection.getIsBackward(),
  );

  return state.getSelection().merge({
    anchorKey: selectedBlocks[0].getKey(),
    anchorOffset: 0,
    focusKey: selectedBlocks[selectedBlocks.length - 1].getKey(),
    focusOffset: selectedBlocks[selectedBlocks.length - 1].getText().length,
    isBackward: false,
  });
}

export function getSelection(
  state: EditorState,
  selectRange: boolean,
  style: string,
) {
  const currentContent = state.getCurrentContent();
  let selectionState;
  const prefix = getPrefix(style);
  if (selectRange) {
    selectionState = state.getSelection();

    if (prefix === 'ALIGN') {
      selectionState = selectFullBlocks(state, currentContent);
    }
  } else {
    selectionState = state.getSelection().merge({
      anchorKey: currentContent.getFirstBlock().getKey(),
      anchorOffset: 0,
      focusOffset: currentContent.getLastBlock().getText().length,
      focusKey: currentContent.getLastBlock().getKey(),
    });
  }
  return selectionState;
}

export function removeStyle(state: EditorState, removeStyles: string[]) {
  let newState = state;
  for (let i = 0; i < removeStyles.length; i += 1) {
    newState = EditorState.push(
      newState,
      Modifier.removeInlineStyle(
        newState.getCurrentContent(),
        newState.getSelection(),
        removeStyles[i],
      ),
      'change-inline-style',
    );
  }
  return newState;
}

export function applyStylesToBlock(
  state: EditorState,
  blockKey: string,
  startOffset: number,
  endOffset: number,
  styles: (Draft.DraftModel.Encoding.RawDraftInlineStyleRange | string)[],
  position: 'before' | 'after' | 'all',
) {
  return styles.reduce((prev, el) => {
    let anchorOffset = startOffset;
    let focusOffset = endOffset;

    if (typeof el !== 'string') {
      if (position === 'before') {
        anchorOffset = el.offset;
        focusOffset = Math.min(el.offset + el.length, endOffset);
      } else if (position === 'after') {
        anchorOffset = Math.max(el.offset, startOffset);
        focusOffset = el.length - (endOffset - el.offset);
      }
    }

    const selection = state.getSelection().merge({
      anchorKey: blockKey,
      focusKey: blockKey,
      anchorOffset,
      focusOffset,
      isBackward: false,
    });

    if (startOffset === endOffset) {
      return RichUtils.toggleInlineStyle(
        prev,
        typeof el === 'string' ? el : el.style,
      );
    }
    return EditorState.push(
      prev,
      Modifier.applyInlineStyle(
        prev.getCurrentContent(),
        selection,
        typeof el === 'string' ? el : el.style,
      ),
      'change-inline-style',
    );
  }, state);
}

export function removeStylesFromBlock(
  state: EditorState,
  blockKey: string,
  styles: Draft.DraftModel.Encoding.RawDraftInlineStyleRange[],
) {
  return styles.reduce((prev, el) => {
    const selection = state.getSelection().merge({
      anchorKey: blockKey,
      focusKey: blockKey,
      anchorOffset: el.offset,
      focusOffset: el.offset + el.length,
      isBackward: false,
    });
    return EditorState.push(
      prev,
      Modifier.removeInlineStyle(prev.getCurrentContent(), selection, el.style),
      'change-inline-style',
    );
  }, state);
}

function filterStyles(styles: Draft.DraftModel.Encoding.RawDraftInlineStyleRange[]) {
  let newStyles = [...styles];

  newStyles = newStyles.filter(
    (el, i) => {
      if (!el.style.includes) return true;

      const duplicate = styles.findIndex(
        (x, j) => j > i && x.offset === el.offset
          && x.length === el.length
          && x.style.split('-')[0] === el.style.split('-')[0],
      ) as number;

      return duplicate < 0;
    },
  );

  if (styles.find((el) => el.style.startsWith('color-'))) {
    newStyles = newStyles.filter((el) => !el.style.startsWith('TEXT_COLOR_'));
  }

  if (styles.find((el) => el.style.startsWith('bgcolor-'))) {
    newStyles = newStyles.filter((el) => !el.style.startsWith('TEXT_BACKGROUND_'));
  }

  if (styles.find((el) => el.style.startsWith('font'))) {
    newStyles = newStyles.filter((el) => !el.style.startsWith('FONT_STYLE_'));
  }

  return newStyles;
}

function isDependentStyle(prefix: string, style: string) {
  if (prefix === 'TEXT_COLOR' && style.startsWith('color-')) {
    return true;
  }

  if (prefix === 'TEXT_BACKGROUND' && style.startsWith('bgcolor-')) {
    return true;
  }

  if (prefix === 'FONT_STYLE' && style.startsWith('font')) {
    return true;
  }

  return false;
}

export function setStyle(
  state: EditorState,
  style?: string,
  overrideStyle = true,
) {
  if (!style) return state;
  const { blocks } = { ...convertToRaw(state.getCurrentContent()) };
  const prefix = getPrefix(style);
  const currentSelection = state.getSelection();
  const startKey = currentSelection.getStartKey();
  const startOffset = currentSelection.getStartOffset();
  const endKey = currentSelection.getEndKey();
  const endOffset = currentSelection.getEndOffset();
  const noSelection = startKey === endKey && startOffset === endOffset;

  if (noSelection) {
    const currentStyle = state.getCurrentInlineStyle().toArray().find(
      (el) => el.startsWith(prefix),
    );

    let newState = state;

    if (currentStyle) {
      newState = RichUtils.toggleInlineStyle(newState, currentStyle);
    }

    newState = RichUtils.toggleInlineStyle(newState, style);

    if (overrideStyle) {
      const object = convertToRaw(newState.getCurrentContent());
      const selectionObj = newState.getSelection().toObject();
      const replaceCharBlock = object.blocks.find(
        (el) => el.key === selectionObj.anchorKey,
      );
      const replaceCharStyle = replaceCharBlock?.inlineStyleRanges.find(
        (el) => el.offset === selectionObj.anchorOffset - 1
          && el.length === 1
          && el.style.startsWith(prefix)
          && replaceCharBlock.text[selectionObj.anchorOffset - 1] === '\uFEFF',
      );

      let newContent;

      if (replaceCharStyle) {
        const newSelection = currentSelection.merge({
          anchorOffset: replaceCharStyle.offset,
        });
        newContent = Modifier.replaceText(
          newState.getCurrentContent(),
          newSelection,
          '\uFEFF',
          OrderedSet.of(style),
        );
      } else {
        newContent = Modifier.insertText(
          newState.getCurrentContent(),
          currentSelection,
          '\uFEFF',
          OrderedSet.of(style),
        );
      }

      newState = EditorState.push(
        newState,
        newContent,
        'insert-characters',
      );

      return EditorState.forceSelection(
        newState,
        newContent.getSelectionAfter(),
      );
    }
    return newState;
  }

  let newState = state;

  const selectedBlocks = getSelectedBlocks(
    state.getCurrentContent(),
    startKey,
    endKey,
    currentSelection.getIsBackward(),
  );

  newState = selectedBlocks.reduce((prev, block, index) => {
    try {
      let next = prev;
      const currentBlock = blocks.find((el) => el.key === block.getKey())!;

      const beforeStyles = index === 0
        ? currentBlock.inlineStyleRanges.filter(
          (el) => el.style.includes(prefix) && el.offset < startOffset,
        )
        : [];

      const overwrittenStyles = currentBlock.inlineStyleRanges.filter(
        (el) => {
          if (!(el.offset <= startOffset || el.offset + el.length >= endOffset)) {
            return false;
          }

          if (isDependentStyle(prefix, el.style)) {
            return true;
          }

          return el.style.includes(prefix);
        },
      );

      const afterStyles = index === selectedBlocks.length - 1
        ? currentBlock.inlineStyleRanges.filter(
          (el) => el.style.includes(prefix) && el.offset + el.length > endOffset,
        )
        : [];

      if (overrideStyle) {
        if (
          beforeStyles.length > 0
          || overwrittenStyles.length > 0
          || afterStyles.length > 0
        ) {
          next = removeStylesFromBlock(
            next,
            currentBlock.key,
            currentBlock.inlineStyleRanges.filter(
              (el) => el.style.startsWith(prefix) || isDependentStyle(prefix, el.style),
            ),
          );
        }

        if (beforeStyles.length > 0) {
          next = applyStylesToBlock(
            next,
            currentBlock.key,
            0,
            startOffset,
            beforeStyles,
            'before',
          );
        }

        if (afterStyles.length > 0) {
          next = applyStylesToBlock(
            next,
            currentBlock.key,
            endOffset,
            0,
            afterStyles,
            'after',
          );
        }
      } else if (
        currentBlock.inlineStyleRanges.filter(
          (el) => el.style.startsWith(prefix) || isDependentStyle(prefix, el.style),
        ).length === 0
      ) {
        next = applyStylesToBlock(
          next,
          currentBlock.key,
          0,
          currentBlock.text.length,
          [style],
          'all',
        );
      }

      return next;
    } catch {
      return prev;
    }
  }, newState);

  if (overrideStyle) {
    newState = EditorState.push(
      newState,
      Modifier.applyInlineStyle(
        newState.getCurrentContent(),
        currentSelection,
        style,
      ),
      'change-inline-style',
    );
  }

  return newState;
}

export function toggleStyle(state: EditorState, style: string) {
  const sufix = getSufix(style);
  if (
    style === 'BOLD'
    && ['TITLE', 'SUBTITLE', 'LABEL'].some((el) => sufix.startsWith(el))
  ) {
    return state;
  }

  if (
    style === 'ITALIC'
    && ['TITLE', 'SUBTITLE', 'LABEL'].some((el) => sufix.startsWith(el))
  ) {
    return state;
  }

  return RichUtils.toggleInlineStyle(state, style);
}

function styleMap2styleString(style: any) {
  return Object.keys(style)
    .map((key) => `${kebabize(key)}:${style[key]}`)
    .join(';')
    .replace(/"/g, "'");
}

function styleName2styleString(style: string, styleMap: any) {
  try {
    return styleMap2styleString(styleMap[style]);
  } catch {
    const [prefix, ...sufixSep] = style.split('-');
    const sufix = sufixSep.join('-');
    switch (prefix) {
      case 'color':
        return `${prefix}:${sufix}`;
      case 'bgcolor':
        return `background-color:${sufix}`;
      case 'fontsize':
        return `font-size:${sufix}px`;
      case 'fontfamily':
        return `font-family:${sufix === 'sans-serif' ? 'sans-serif' : `'${sufix}'`}`;
      default: return '';
    }
  }
}

const FONTS_ORDER = Object.entries(generateFonts(1))
  .map(([key, value]) => ({ key, ...value }))
  .sort(
    (a, b) => Number(
      a.fontSize.substring(0, a.fontSize.length - 2),
    ) - Number(
      b.fontSize.substring(0, b.fontSize.length - 2),
    ),
  )
  .map((el) => el.key);

export function getSmallestFont(fonts: string[]) {
  const fontsIndex = fonts.map((font) => FONTS_ORDER.findIndex((el) => el === font));
  return FONTS_ORDER[Math.min(...fontsIndex)];
}

export function draft2html(
  object: Draft.DraftModel.Encoding.RawDraftContentState,
  styleMap: any,
  containerStyles: any = null,
) {
  let output = '';
  let lastSetFont = '';

  object.blocks.forEach((block) => {
    let openTags = [] as string[];

    function handleAddStyle(styleString: string) {
      if (openTags[openTags.length - 1] !== styleString) {
        openTags.push(styleString);
        output += `<span style="${styleString}">`;
      }
    }

    function closeAllTags(styleString: string = '') {
      if (
        styleString.length === 0
        || openTags[openTags.length - 1] !== styleString
      ) {
        for (let j = 0; j < openTags.length; j += 1) {
          output += '</span>';
        }
        openTags = [];
      }
    }

    if (block.inlineStyleRanges.length === 0) {
      output += `<p ${lastSetFont ? `style="${styleName2styleString(lastSetFont, styleMap)}"` : ''}>${block.text.length > 0 ? block.text.replace(/</gi, '&lt;').replace(/>/gi, '&gt;') : ' '}</p>`;
    } else {
      output += '<p style="';
      const smallestFont = getSmallestFont(block.inlineStyleRanges.filter((el) => el.style.startsWith('FONT')).map((fontStyle) => fontStyle.style));
      if (smallestFont) {
        output += `${styleName2styleString(smallestFont, styleMap)};`;
      }

      const align = block.inlineStyleRanges.find((el) => el.style.startsWith('ALIGN'));
      if (align) {
        output += `${styleName2styleString(align.style, styleMap)};`;
        block.inlineStyleRanges = block.inlineStyleRanges.filter(
          (el) => el.style !== align.style,
        );
      }

      output += '">';

      for (let i = 0; i < block.text.length; i += 1) {
        const styles = filterStyles(block.inlineStyleRanges.filter(
          (range) => range.offset <= i && range.offset + range.length > i,
        ));

        lastSetFont = styles.find((el) => el.style.startsWith('FONT_STYLE'))?.style ?? '';
        const styleString = styles
          .map((el) => styleName2styleString(el.style, styleMap))
          .join(';');

        closeAllTags(styleString);

        if (styles.length >= 1) {
          handleAddStyle(styleString);
        }

        output += block.text[i].replace('<', '&lt;').replace('>', '&gt;');
      }
      closeAllTags();

      output += '</p>';
    }

    // replacing break lines with break line tags + zero width character
    output = output.replace(/\n/g, '<br>&#8203');
  });

  if (containerStyles) {
    output = `<div style="${styleMap2styleString(containerStyles)}">${output}</div>`;
  }

  return output;
}

export function getTextFromBlocks(blocks?: ContentBlock[]) {
  if (!blocks) return '';
  return blocks.reduce((prev, block) => `${prev} ${block.text}`, '');
}

export function getFilteredTextFromBlocks(blocks: ContentBlock[], filter: string) {
  if (!blocks) return '';
  return blocks.reduce((prev, block) => {
    const applicableStyleRanges = block.inlineStyleRanges.filter((el) => el.style.includes(filter));
    return `${applicableStyleRanges.reduce((prevText, style) => prevText + block.text.substring(style.offset, style.offset + style.length), prev)}`;
  }, '');
}

export function getTextFromPage(page: PageType, filter?: string) {
  if (!Array.isArray(page.content)) return '';
  return page.content.reduce((prev, element) => {
    if (element.type !== 'TextBox') return prev;
    if (filter) {
      return `${prev}${getFilteredTextFromBlocks(element.value.blocks, filter)}`;
    }
    return `${prev}${getTextFromBlocks(element.value.blocks)}`;
  }, '').toLowerCase().replace(/\uFEFF/g, '');
}

export function getBorder(isActive: boolean, isFocused: boolean, hasOverflow: boolean) {
  if (isActive) {
    return `2px solid #52509A${isFocused ? '32' : ''}`;
  }
  if (hasOverflow) {
    return '2px solid #FF5252';
  }
  return '2px solid transparent';
}
