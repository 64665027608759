import React, { useState } from 'react';
import { Box } from '@mui/system';
import { CheckedImg } from '../../../assets/icons';
import { SignalWordsImg } from '../../../assets/images';
import { useEditor } from '../../../hooks/useEditor';
import { lang } from '../../../lang';
import WRITING_PLANS_LIST from '../../../Models/WritingPlans';
import CreatePresstoDialog from '../../dialogs/CreatePresstoDialog';
import { Description } from '../../typography';
import { CardContainer, SignalWord } from '../styles';
import { CardProps } from '../types';

function WritingPlanCard({ expanded }: CardProps) {
  const {
    writingPlanId, signalWords, hasWritingPlan, setDocumentMeta,
  } = useEditor();

  const {
    title,
  } = WRITING_PLANS_LIST.find((el) => el.id === writingPlanId) || {};
  const [isWritingPlanDialogOpen, setIsWritingPlanDialogOpen] = useState(false);

  const renderWordDetector = () => {
    if (hasWritingPlan) {
      return (
        <div className="signal-words-content">
          <div className="words-wrapper">
            {Object.keys(signalWords).map((word: string) => (
              <SignalWord activated={signalWords[word]} key={word}>
                {word}
                {signalWords[word] && <img src={CheckedImg} alt={`${word} used`} />}
              </SignalWord>
            ))}
          </div>
          <div className="signal-words-description" />
        </div>
      );
    }

    return <Description className="description">{lang('document.side_bar.writing_plan.description')}</Description>;
  };

  const someActive = signalWords && Object.values(signalWords).filter((el) => el).length >= 4;

  return (
    <>
      <CardContainer
        backgroundColor="#f6f6fa"
        mainTextColor="#2E2C75"
        onClick={() => !hasWritingPlan && setIsWritingPlanDialogOpen(true)}
      >
        <div className="header">
          <img
            className={`card-icon ${expanded ? 'expanded' : 'visible'}`}
            src={SignalWordsImg}
            alt="Signal Words"
          />
          <Box
            className={expanded ? 'title-open' : ''}
            onClick={() => hasWritingPlan && setIsWritingPlanDialogOpen(true)}
          >
            {lang('document.side_bar.writing_plan.title')}
            <div className="subtitle customize">{lang('document.side_bar.writing_plan.customize')}</div>
          </Box>
        </div>
        {expanded && hasWritingPlan && (
          <div className="card-description">
            {someActive
              ? lang('document.side_bar.writing_plan.active_message')
              : (
                <>
                  {lang('document.side_bar.writing_plan.default_message.line_1')}
                  {' '}
                  <strong>{title}</strong>
                  {' '}
                  {lang('document.side_bar.writing_plan.default_message.line_2')}
                </>
              )}
          </div>
        )}
        {expanded && renderWordDetector()}
      </CardContainer>
      <CreatePresstoDialog
        onSubmit={(format: string, meta: any) => {
          if (!meta) return;
          setDocumentMeta(meta);
        }}
        initialTab={1}
        isOpen={isWritingPlanDialogOpen}
        customize
        onClose={() => setIsWritingPlanDialogOpen(false)}
      />
    </>
  );
}

export default WritingPlanCard;
