import BirthdayForm from '../views/register/BirthdayForm';
import CreateAccount from '../views/register/CreateAccount';
import EmailVerification from '../views/register/EmailVerification';
import SchoolForm from '../views/register/SchoolForm';
import StudentSignUp from '../views/register/StudentSignUp';

const ACTION_ROUTES = [
  {
    path: '/register-student',
    name: 'Register Student',
    action: ['verify_email'],
    component: StudentSignUp,
  },
  {
    path: '/create-account',
    name: 'Create Account',
    action: ['verify_email', 'complete_user_data'],
    component: CreateAccount,
  },
  {
    path: '/school-information-form',
    name: 'School Information Form',
    action: ['add_school'],
    component: SchoolForm,
  },
  {
    path: '/birthday-form',
    name: 'Birthday Form',
    action: ['add_birthdate'],
    component: BirthdayForm,
  },
  {
    path: '/verify',
    name: 'Email verification',
    action: ['verify_email', 'complete_user_data', 'add_school', 'add_birthdate'],
    component: EmailVerification,
  },
];

export default ACTION_ROUTES;
