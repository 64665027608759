/**
 * This component presents a series of screens that, together, walk teachers
 * through the steps required to create a lesson. The wizard helps refine
 * the purpose of the lesson, suggesting instructions and Pressto features to
 * support instruction.
 */
import { lighten } from '@material-ui/core';
import {
  Box,
  Button,
  Card,
  Chip,
  Collapse,
  Container,
  Fade, FormControl, Grid, Input,
  Paper,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import * as AssistantAPI from 'src/api/Assistant';
import { TransitionGroup } from 'react-transition-group';
import { LogoImg } from 'src/assets/images';
import WritingPlans from 'src/Models/WritingPlans';
import { useNavigate } from 'react-router-dom';

type Prompt = {
  text: string;
  tags: string[];
};

const pages = [
  'prompts',
  'important-words',
];

function PaperButton(props: {
  isSelected?: boolean;
  elevation?: number;
  children: React.ReactNode;
  onPress?: (...args: any[]) => Promise<void> | void;
  fullWidth?: boolean;
  sx?: object,
}) {
  const {
    isSelected = false,
    elevation = 14,
    children,
    onPress,
    fullWidth = false,
    sx = null,
  } = props;
  return (
    <Paper
      tabIndex={onPress ? 0 : undefined}
      role="button"
      component="button"
      onClick={onPress}
      onKeyDown={onPress ? (event: React.KeyboardEvent<HTMLButtonElement>) => {
        if (event.key === 'Enter') {
          onPress();
        }
      } : undefined}
      elevation={elevation}
      sx={[
        {
          mr: 1,
          mb: 1,
          p: 1,
          display: 'inline-block',
          fontSize: '0.8rem',
          transition: 'all 0.2s',
        },

        fullWidth && {
          width: '100%',
        },

        !!onPress && {
          cursor: 'pointer',
        },

        isSelected && {
          borderColor: 'primary.main',
          backgroundColor: lighten('#A8A7CC', 0.9),
          color: 'primary.main',
        },

        // By default, the usual border and drop shadow of Paper is hidden.
        !isSelected && {
          borderColor: '#fff',
          boxShadow: 'none',
          backgroundColor: 'grey.A100',
          color: 'secondary.main',
        },

        sx,
      ]}
    >
      {children}
    </Paper>
  );
}

function ChooseOne(props: {
  options: (
    string | {
      value: string;
      label: React.ReactNode;
    }
  )[];
} & ({
  isRequired?: true;
  onChange: (value: string) => void;
  value?: string;
} | {
  isRequired?: false;
  onChange: (value: string | null) => void;
  value?: string | null;
})) {
  const {
    options, value, onChange, isRequired = false,
  } = props;
  return (
    <div>
      {options.map((option) => {
        const optionValue = typeof option === 'string' ? option : option.value;
        const label = typeof option === 'string' ? option : option.label;
        return (
          <PaperButton
            key={optionValue}
            isSelected={value === optionValue}
            onPress={() => {
              if (value !== optionValue) {
                onChange(optionValue);
              } else if (!isRequired) {
                onChange(null as any);
              }
            }}
          >
            {label}
          </PaperButton>
        );
      })}
    </div>
  );
}

function PromptBox(props: {
  prompt: Prompt;
  isSelected?: boolean;
  onPress?: () => void;
  autoEdit?: boolean;
}) {
  const {
    prompt, isSelected = false, onPress,
    autoEdit = false,
  } = props;

  const [isEditing, setIsEditing] = useState(autoEdit);
  const [editedText, setEditedText] = useState(prompt.text);

  return (
    <div>
      <Card
        elevation={isSelected ? 16 : 0}
        onClick={onPress}
        sx={[
          {
            fontSize: '0.85rem',
            textAlign: 'left',
            lineHeight: '150%',
            p: 1.5,
            my: 1,
            cursor: 'pointer',
          },
          isSelected && {
            backgroundColor: lighten('#A8A7CC', 0.9),
            fontWeight: 'bold',
            color: 'primary.main',
          },
        ]}
      >
        {isEditing ? (
          <Input
            value={editedText.trim()}
            onChange={(event) => {
              setEditedText(event.target.value);
            }}
            onBlur={() => {
              setIsEditing(false);
            }}
            autoFocus
            fullWidth
            multiline
            disableUnderline
            sx={{
              border: 'none',
              fontSize: 'inherit',
              textAlign: 'left',
              lineHeight: 'inherit',
              fontWeight: 'inherit',
              color: 'inherit',
            }}
          />
        ) : (
          prompt.text
        )}
        <div style={{
          marginTop: '0.5em',
          textAlign: 'right',
          lineHeight: '80%',
        }}
        >
          <span
            style={{
              fontSize: '0.7em',
              color: '#A8A7CC',
              fontWeight: 'bold',
              marginRight: '0.5em',
            }}
          >
            {prompt.tags.join(', ')}
          </span>
        </div>
      </Card>
    </div>
  );
}

export default function CreateLessonWizard() {
  const topicPlaceholder = useMemo(() => {
    const options = [
      "Charlotte's Web",
      'Abraham Lincoln',
      'The Phantom Tollbooth',
      'Volcanoes',
      "Toussaint L'Ouverture",
      'The Great Gatsby',
      'The Great Wall of China',
      'The Declaration of Independence',
    ];
    return options[Math.floor(Math.random() * options.length)];
  }, []);

  const [page, setPage] = useState('prompts');
  const navigate = useNavigate();

  const [grade, setGrade] = useState<string>('4th');
  const [topic, setTopic] = useState<string>('');
  const [plan, setPlan] = useState<string | null>(null);

  const [selectedPrompts, setSelectedPrompts] = useState<Prompt[]>([]);
  const [suggestedPrompts, setSuggestedPrompts] = useState<Prompt[]>([]);
  const [selectedWords, setSelectedWords] = useState<string[]>([]);
  const [suggestedWords, setSuggestedWords] = useState<string[]>([]);
  const [newWord, setNewWord] = useState<string>('');

  const [isGenerating, setIsGenerating] = useState(false);
  const generateSuggestions = async () => {
    if (topic) {
      setIsGenerating(true);
      try {
        const suggestions = await AssistantAPI.suggestWritingPrompt(
          topic,
          grade,
          plan ? [plan] : [],
        );
        setSuggestedPrompts([
          ...suggestions.map((text) => ({
            text,
            tags: [grade].concat(plan ? [plan] : []),
          })),
          ...suggestedPrompts,
        ]);
      } finally {
        setIsGenerating(false);
      }
    }
  };

  const generateImportantWords = async () => {
    if (topic) {
      setIsGenerating(true);
      try {
        const words = await AssistantAPI.suggestImportantWords(topic, grade, 10);
        setSuggestedWords(words);
      } finally {
        setIsGenerating(false);
      }
    }
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '32px',
        height: 'calc(100vh - 64px)',
        my: '32px',

        header: {
          fontSize: '1.2rem',
          color: '#999',

          h2: {
            color: 'primary.main',
            fontSize: '3rem',
            fontWeight: 'normal',
          },
        },

        small: {
          color: '#666',
        },

        '& p': {
          my: 2,
        },
      }}
    >
      <header>
        {{
          prompts: 'Create',
          'important-words': 'Define',
        }[page]}
        <h2>
          {{
            prompts: 'Writing Prompts',
            'important-words': 'Important Words',
          }[page]}
        </h2>
      </header>

      <Box
        sx={{
          overflowY: 'auto',
          overflowX: 'visible',
        }}
      >
        {page === 'prompts' && (
        <Grid
          container
        >
          <Grid
            item
            sm={5}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
            }}
          >
            <div>
              <p>Grade Level</p>
              <FormControl>
                <ChooseOne
                  isRequired
                  options={[
                    '2nd', '3rd', '4th', '5th', '6th', '7th', '8th',
                    '9th', '10th', '11th', '12th', 'Collegiate',
                  ]}
                  onChange={setGrade}
                  value={grade || undefined}
                />
              </FormControl>
            </div>

            <div>
              <p>Writing Topic</p>
              <FormControl fullWidth>
                <Input
                  type="text"
                  multiline
                  fullWidth
                  placeholder={topicPlaceholder}
                  value={topic}
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  onKeyUp={(event) => {
                    event.stopPropagation();
                  }}
                  onChange={(event) => {
                    setTopic(event.target.value);
                  }}
                />
              </FormControl>
            </div>

            <div>
              <p>What form of writing is your class practicing?</p>
              <FormControl>
                <ChooseOne
                  onChange={setPlan}
                  options={WritingPlans.slice(1).map((p) => ({
                    label: p.title,
                    value: p.id,
                  }))}
                  value={plan}
                  isRequired={false}
                />
              </FormControl>
            </div>

            <div>
              <Button
                variant="outlined"
                onClick={generateSuggestions}
                disabled={topic === '' || isGenerating}
              >
                {isGenerating ? 'Suggesting...' : 'Suggest Prompts'}
              </Button>
            </div>
          </Grid>

          <Grid item sm={1} alignSelf="center">
            {/* <img src={ArrowRightImg} alt="arrow right" /> */}
          </Grid>

          <Grid
            item
            sm={5}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              height: 'auto',
            }}
          >
            <div>
              <p>
                Selected Prompts
                {' '}
                <small>(Up to 3)</small>
              </p>
              <TransitionGroup
                enter={false}
              >
                {selectedPrompts.map((prompt) => (
                  <Collapse key={prompt.text}>
                    <PromptBox
                      prompt={prompt}
                      isSelected
                      autoEdit={prompt.text === ''}
                      onPress={() => {
                        // Deselect the prompt.
                        setSelectedPrompts(selectedPrompts.filter((p) => p !== prompt));
                        setSuggestedPrompts([prompt, ...suggestedPrompts]);
                      }}
                    />
                  </Collapse>
                ))}
              </TransitionGroup>
            </div>

            <div style={{
              overflowY: 'auto',
            }}
            >
              <p>Suggested Prompts</p>
              <div style={{
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
              >
                <TransitionGroup exit={false}>
                  {suggestedPrompts.map((prompt) => (
                    <Collapse key={prompt.text}>
                      <PromptBox
                        prompt={prompt}
                        onPress={() => {
                          // Select the prompt.
                          setSelectedPrompts([...selectedPrompts, prompt]);
                          setSuggestedPrompts(suggestedPrompts.filter((p) => p !== prompt));
                        }}
                      />
                    </Collapse>
                  ))}
                </TransitionGroup>
              </div>
            </div>
          </Grid>
        </Grid>
        )}

        {page === 'important-words' && (
        <Grid
          container
        >
          <Grid
            item
            sm={5}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
            }}
          >
            <div>
              <p>Grade Level</p>
              <FormControl>
                <ChooseOne
                  isRequired
                  options={[
                    '2nd', '3rd', '4th', '5th', '6th', '7th', '8th',
                    '9th', '10th', '11th', '12th', 'Collegiate',
                  ]}
                  onChange={setGrade}
                  value={grade || undefined}
                />
              </FormControl>
            </div>

            <div>
              <p>Writing Topic</p>
              <FormControl fullWidth>
                <Input
                  type="text"
                  multiline
                  fullWidth
                  placeholder={topicPlaceholder}
                  value={topic}
                  onKeyDown={(event) => {
                    event.stopPropagation();
                  }}
                  onKeyUp={(event) => {
                    event.stopPropagation();
                  }}
                  onChange={(event) => {
                    setTopic(event.target.value);
                  }}
                />
              </FormControl>
            </div>

            <div>
              <Button
                variant="outlined"
                onClick={generateImportantWords}
                disabled={topic === '' || isGenerating}
              >
                {isGenerating ? 'Suggesting...' : 'Suggest Words'}
              </Button>
            </div>
          </Grid>

          <Grid item sm={1} alignSelf="center">
            {/* <img src={ArrowRightImg} alt="arrow right" /> */}
          </Grid>

          <Grid
            item
            sm={5}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              height: 'auto',
            }}
          >
            <div>
              <p>
                Important Words
                <br />
                <small>
                  Students will be encouraged to compose with these words.
                </small>
              </p>
              <Box
                sx={{
                  border: '1px solid',
                  borderColor: 'primary.light',
                  p: 1,
                }}
              >
                {selectedWords.map((word) => (
                  <Chip
                    sx={{
                      borderRadius: '6px',
                      color: 'primary.main',
                      fontWeight: 'bold',
                      borderColor: 'primary.main',
                      backgroundColor: lighten('#A8A7CC', 0.9),
                      mr: 1,
                      mb: 1,
                    }}
                    onDelete={() => {
                      setSelectedWords(selectedWords.filter((w) => w !== word));
                    }}
                    variant="outlined"
                    label={word}
                  />
                ))}

                <Input
                  sx={{
                    border: 'none',
                    mt: 1,
                  }}
                  type="text"
                  placeholder="Type a word or phrase, followed by Enter ⏎"
                  fullWidth
                  value={newWord}
                  onChange={(event) => {
                    setNewWord(event.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      event.stopPropagation();
                      if (newWord !== '') {
                        setSelectedWords([...selectedWords, newWord]);
                        setNewWord('');
                      }
                    }
                  }}
                />
              </Box>
            </div>

            <div style={{
              overflowY: 'auto',
            }}
            >
              <p>Suggested Words</p>
              <div style={{
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
              >
                <TransitionGroup
                // Only transition when appearing.
                  exit={false}
                >
                  {suggestedWords
                    .filter((word) => !selectedWords.includes(word))
                    .map((word, index) => (
                      <Fade
                        key={word}
                        timeout={index * 100}
                      >
                        <Chip
                          sx={{
                            borderRadius: '6px',
                            color: '#888',
                            borderColor: '#bbb',
                            mr: 1,
                            mb: 1,
                          }}
                          onClick={() => {
                            setSelectedWords([...selectedWords, word]);
                            setSuggestedWords(suggestedWords.filter((w) => w !== word));
                          }}
                          variant="outlined"
                          label={`+ ${word}`}
                        />
                      </Fade>
                    ))}
                </TransitionGroup>
              </div>
              <Button
                variant="outlined"
                onClick={() => {
                  setSelectedWords([...selectedWords, ...suggestedWords]);
                  setSuggestedWords([]);
                }}
                sx={{
                  my: 1,
                }}
                disabled={suggestedWords.length === 0}
              >
                Select All
              </Button>
            </div>
          </Grid>
        </Grid>
        )}
      </Box>

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          mt: 'auto',
        }}
      >
        <Grid item>
          <img style={{ height: '40px' }} src={LogoImg} alt="logo" />
        </Grid>
        <Grid item>
          {pages.indexOf(page) > 0 && (
          <Button
            sx={{ mx: 2 }}
            onClick={() => {
              const index = pages.indexOf(page);
              if (index > 0) {
                setPage(pages[index - 1]);
              }
            }}
          >
            Back
          </Button>
          )}
          <Button
            variant="contained"
            onClick={() => {
              const index = pages.indexOf(page);
              if (index < pages.length - 1) {
                setPage(pages[index + 1]);

                if (page === 'prompts' && !suggestedWords.length) {
                  generateImportantWords();
                }
              } else {
                // Create a new document.
                // Put assignment instructions in local storage.
                let instruction = '';
                if (selectedPrompts.length === 1) {
                  instruction += 'Write a response to the following prompt:\n\n';
                  instruction += selectedPrompts[0].text;
                } else if (selectedPrompts.length > 1) {
                  instruction += 'Write a response to one of the following prompts:\n\n';
                  instruction += selectedPrompts.map((prompt) => `> ${prompt.text}`).join('\n\n');
                }
                // Put instructions in local storage.
                localStorage.setItem('assignmentInstructions', instruction);
                navigate(`/document/new?${new URLSearchParams({
                  name: topic,
                  importantWords: selectedWords.join(','),
                  writingPlan: plan || '',
                })}`);
              }
            }}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
