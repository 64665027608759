import { BlockTypesEnum } from 'src/types/models';
import splitSignalWords from '../../utils/splitSignalWords';
import Blocks from '../Blocks';
import { IWritingPlanModel } from './types';

const generalBlocks = [
  BlockTypesEnum.Title,
  BlockTypesEnum.Image,
  BlockTypesEnum.ImageCaption,
];

const commonBlocks = [
  BlockTypesEnum.Conclusion,
];

const plans = [
  {
    id: 'basic',
    title: 'Basic',
    description: '',
    signalWords: [],
    availableBlocks: [
      BlockTypesEnum.Text,
      BlockTypesEnum.Image,
    ],
  },
  {
    id: 'news-article',
    title: 'News Article',
    description: 'A <span>News Article</span> recounts events and provides important information.',
    signalWords: splitSignalWords('interviewed, explained, answered, reported, before, during, after, later, for instance, for example, furthermore, to illustrate, specifically, such as, moreover, in addition, consequently'),
    availableBlocks: [
      ...generalBlocks,
      ...commonBlocks,
      BlockTypesEnum.Lead,
      BlockTypesEnum.MainIdea,
      BlockTypesEnum.Details,
      BlockTypesEnum.Quote,
    ],
  },
  {
    id: 'personal-narrative',
    title: 'Story / Personal Narrative',
    description: '<span>Story or Narrative</span> gives a true or fictional account of characters and their experiences.',
    signalWords: splitSignalWords('at first, before, during, while, meanwhile, next, following, later, after, after awhile, all of a sudden, suddenly, as soon as, immediately'),
    availableBlocks: [
      ...generalBlocks,
      BlockTypesEnum.SettingAndCharacters,
      BlockTypesEnum.ProblemOrGoal,
      BlockTypesEnum.Attempts,
      BlockTypesEnum.StorySolution,
      BlockTypesEnum.ConsequenceOrResolution,
    ],
  },
  {
    id: 'op-ed',
    title: 'Op-Ed / Review',
    description: 'An <span>Op-Ed or Review</span> provides strong evidence to support a writer’s opinion about a topic or idea.',
    signalWords: splitSignalWords('opinion, believe, think, agree, disagree, best, worst, most, least, first, second, third, lastly, reason, for example, for instance, in addition to, finally, evidence, significant, important'),
    availableBlocks: [
      ...generalBlocks,
      ...commonBlocks,
      BlockTypesEnum.Introduction,
      BlockTypesEnum.PointOfView,
      BlockTypesEnum.Evidence,
      BlockTypesEnum.Quote,
    ],
  },
  {
    id: 'sequence',
    title: 'Sequence',
    description: '<span>Sequence</span> explains events or steps of a procedure in chronological order.',
    signalWords: splitSignalWords('first, second, third, later, next, before, then, finally, after, when, meanwhile, since, now, previously'),
    availableBlocks: [
      ...generalBlocks,
      ...commonBlocks,
      BlockTypesEnum.Introduction,
      BlockTypesEnum.Event,
    ],
  },
  {
    id: 'cause-effect',
    title: 'Cause / Effect',
    description: '<span>Cause and Effect</span> explains the causes of an event and the resulting effects.',
    signalWords: splitSignalWords('as a result, because, hence, thus, consequently, due to, since, therefore, for this reason, in order to, on account of'),
    availableBlocks: [
      ...generalBlocks,
      ...commonBlocks,
      BlockTypesEnum.Introduction,
      BlockTypesEnum.Cause,
      BlockTypesEnum.Effect,
    ],
  },
  {
    id: 'compare-contrast',
    title: 'Compare and Contrast',
    description: '<span>Compare and Contrast</span> examines how two or more things are similar and different.',
    signalWords: splitSignalWords('in comparison, by contrast, similarly, but, on the other hand, on the contrary, yet, however, despite, as opposed to'),
    availableBlocks: [
      ...generalBlocks,
      ...commonBlocks,
      BlockTypesEnum.Introduction,
      BlockTypesEnum.Compare,
      BlockTypesEnum.Contrast,
    ],
  },
  {
    id: 'problem-solution',
    title: 'Problem and Solution',
    description: '<span>Problem and Solution</span> describes a problem and explains how it was or could be solved.',
    signalWords: splitSignalWords('problem, solution, because, cause, since, as a result, in order to, the problem is, a difficulty is, to solve, to fix, to correct'),
    availableBlocks: [
      ...generalBlocks,
      ...commonBlocks,
      BlockTypesEnum.Introduction,
      BlockTypesEnum.Problem,
      BlockTypesEnum.Solution,
    ],
  },
  {
    id: 'description',
    title: 'Description',
    description: '<span>Description</span> explains a topic by stating a claim or big idea and supporting it with important details.',
    signalWords: splitSignalWords('for instance, for example, furthermore, to illustrate, specifically, such as, moreover, in addition'),
    availableBlocks: [
      ...generalBlocks,
      ...commonBlocks,
      BlockTypesEnum.MainIdea,
      BlockTypesEnum.SupportingDetails,
    ],
  },
] as IWritingPlanModel[];

plans.forEach((plan) => {
  plan.availableBlocks.sort((a, b) => (
    Blocks[a].position - Blocks[b].position
  ));
});

export function getWritingPlan(planId: string) {
  return plans.find((plan) => plan.id === planId);
}

export default plans;
