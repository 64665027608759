import React, { useEffect, useState } from 'react';
import {
  convertFromRaw, convertToRaw, EditorState, Modifier,
} from 'draft-js';
import { Editor as WYSIWYG } from 'react-draft-wysiwyg';
import { draft2html, generateBlocksStyleMap } from 'src/components/elements/TextBox/utils';
import { Block, BlockProperties, BlockType } from 'src/types/models';
import { useBlockEditorState } from 'src/hooks/document';
import { Box } from '@mui/material';

export const defaultStyleMap = generateBlocksStyleMap();
const defaultPlaceholder = 'Click to start writing';

/**
 * This component defines the full text editing experience for a block.
 * Not all blocks offer text editing, but all of those that do should use
 * this component. The Text component is responsible for:
 *
 * - Rendering and managing the state of the rich text editor, including global
 *   editing state that is used by the toolbar (a separate component).
 * - Converting to and from all serialization formats used to store the text
 * - Text sizing and style for rendering in web browser. The component is not
 *   responsible for figuring out how text is printed, but it is responsible
 *   for displaying text that is read-only.
 */
export default function Text(props: {
  block: Block;
  blockType: BlockType;
  isEditable?: boolean;
  autoFocus?: boolean;
  onChange: (content: BlockProperties) => void;
}) {
  const {
    block, blockType, onChange, isEditable = false, autoFocus,
  } = props;

  // The state management here is mind-bendy, be warned.
  // Blocks use DraftJS and react-draft-wysiwyg for rich text editing.
  // There are multiple blocks on the screen at a time, and each has its own
  // editor state. But, there is only one text toolbar on screen at
  // any given time. This means that the particular editor state for the
  // currently selected block must be accessible to the toolbar.
  // There are a few ways to accomplish this. The approach below is to
  // maintain one "selected editor" state in the shared document store,
  // while each block maintains its own editor state locally.
  const [
    editorState,
    setEditorState,
  ] = useBlockEditorState(
    // Define the initial state of the editor. If the block has multiple
    // Text components, a label can be added to the id to differentiate.
    block.id,
    typeof block.properties.rawEditorContent === 'string'
      ? EditorState.createWithContent(
        convertFromRaw(JSON.parse(block.properties.rawEditorContent)),
      )
      : EditorState.createEmpty(),
  );

  // We need to track focus on Editor to prevent moving cursor if we already have a cursor.
  const [hasFocus, setHasFocus] = useState(false);

  // If autoFocus is true we should focus Editor and set cursor in the end.
  useEffect(() => {
    if (autoFocus && !hasFocus) {
      setEditorState((prevEditorState: EditorState) => EditorState.moveFocusToEnd(prevEditorState));
    }
  }, [autoFocus]);

  const onEditorStateChange = (newEditorState: EditorState) => {
    // When this is the selected editor, keep the shared editor state in
    // sync with the local editor state. This will happen when the editor
    // is focused or the text is changed by the user.
    // This causes a second render as local state is updated in response to
    // the shared state being updated.
    // TODO: Is there a way around this?
    setEditorState(newEditorState);

    const contentState = convertToRaw(newEditorState.getCurrentContent());
    const htmlText = draft2html(contentState, defaultStyleMap);
    const plainText = newEditorState.getCurrentContent().getPlainText();

    onChange({
      plainText,
      htmlText,
      rawEditorContent: JSON.stringify(contentState),
    });
  };

  const { properties: p } = block;
  const baseStyle: Record<string, string | undefined> = {
    fontSize: p.fontSize || '1em',
    fontFamily: p.fontFamily || "'Open Sans'",
    // backgroundColor: p.backgroundColor?.toString() || undefined,
    color: p.color || 'black',
  };

  return (
    <Box
      className="text-editor"
      style={{
        padding: '.5em .6em',
        height: '100%',
      }}
    >
      <WYSIWYG
        key={block.id}
        placeholder={
        isEditable
          ? blockType.configuration.placeholder?.toString() || defaultPlaceholder
          : undefined
      }
        toolbarHidden
        editorState={editorState}
      // wrapperClassName="text-editor-wrapper"
      // editorClassName="text-editor"
        wrapperStyle={{
          width: '100%',
          height: '100%',
        }}
        editorStyle={{
          overflowY: 'hidden',
          ...baseStyle,
        }}
        readOnly={!isEditable}
        onEditorStateChange={onEditorStateChange}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        customStyleMap={defaultStyleMap}
        handlePastedText={(text) => {
          const newContent = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            text,
          );

          setEditorState((prev: any) => EditorState.push(
            prev,
            newContent,
            'insert-characters',
          ));

          return true;
        }}
      />
    </Box>
  );
}
