import React from 'react';

import { useNavigate } from 'react-router-dom';
import { LogoImg } from '../../assets/images';
import { LogoContainer } from './styles';
import { LogoProps } from './types';

function Logo({ url, onClick = () => { } }: LogoProps) {
  const navigate = useNavigate();
  return (
    <LogoContainer onClick={async () => {
      await onClick();
      if (url) {
        window.location.href = url;
      } else {
        navigate('/');
      }
    }}
    >
      <img src={LogoImg} alt="Pressto" />
    </LogoContainer>
  );
}

Logo.defaultProps = {
  url: null,
};

export default Logo;
