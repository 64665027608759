import { createContext, useContext } from 'react';
import { BlockPage } from 'src/types/models';

export const PageContext = createContext<BlockPage | null>(null);

export const PageContextProvider = PageContext.Provider;

export function usePage(): BlockPage | null {
  return useContext(PageContext);
}
