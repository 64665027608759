/* eslint-disable @typescript-eslint/naming-convention */
export const assignment_tab = {
  title: 'Check your assignments',
  search_placeholder: 'Search an Assignment',

  state: 'State',

  create: 'New Assignment',

  turned_in: 'Turned in',
  assigned: 'Assigned',
  original_document: 'Original Document',
  delete_assignemnt: 'Delete Assignment',
  go_back_to_assignemnts: 'Go back to all assignments',

  past_assignment: ({ show }: { show: boolean }) => `${show ? 'Hide' : 'Show'} past assignments`,

  assignment_count: ({ count }: { count: number }) => `${count} Assignment${count !== 1 ? 's' : ''}`,

  delete_assignment_confirm: {
    title: 'Are you sure you want to delete this Assignment?',
    subtitle: 'If you delete this assignment, your students will lose access to your instructions and inputs in the Feedback panel. However, students who have already started editing the document will see it turn into a regular Pressto.',
  },
};

export const create_assignment_dialog = {
  add_title: 'Add a title to this assignment',
  add_note: 'Add instructions for your students',

  step_0: {
    description: 'This Pressto will be copied to your students. Customizations like Format, Writing Plan, Important Words and other text or images will be included. You can follow their progress on the Assignment tab.',
    invite_students: 'If you haven\'t invited students to join yet, you will be asked to do so in the next step.',
  },
  step_1: {
    assign_to: 'Assign to',
    add_classes: 'Add classes',
    assign_to_class: 'Assign to Class',
  },
  step_2: {
    title: 'Assigned to your class',
    description: 'You can share the link to this assignment with students directly or through Google Classroom. They\'ll also see it when they sign into Pressto.',
    view_assignment: 'View Assignment',
  },
};

export const common = {
  turned_in: 'Turned in',
  turnin: 'Turn in',
  submit: 'Submit',
  unsubmit: 'Unsubmit',
  return: 'Return',
  returned: 'Returned',
};
