import { convertToRaw, EditorState } from 'draft-js';
import React, {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import ScrollContainer from 'react-indiana-drag-scroll';
import {
  AlignCenterImg,
  AlignLeftImg,
  AlignRightImg,
  BoldImg,
  // BrushImg,
  ItalicImg,
  UnderlineImg,
} from '../../../assets/icons';
import { MenuRefType } from '../../DropdownMenu/types';
import { getPrefix, getSufix } from '../../../utils';
import { getSelection, setStyle, toggleStyle } from '../../elements/TextBox/utils';
import { ToolbarContainer } from './styles';
import ColorPicker from './ToolbarComponents/ColorPicker';
import FontsComboBox from './ToolbarComponents/FontsComboBox';
import FontSizeController from './ToolbarComponents/FontSizeController';
import IconButton from './ToolbarComponents/IconButton';
import { TextToolbarProps } from './types';

function TextBoxToolbar({
  selectedEl,
  editorState,
  setEditorState,
  isFocused,
  handleBackgroundColorChange,
  handleContainerFontChange,
  styleMap,
}: TextToolbarProps) {
  const colorPickerRef = useRef<MenuRefType>(null);
  const mobileMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [currentFont, setCurrentFont] = useState<string>('');

  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [textAlign, setTextAlign] = useState('LEFT');

  const [textColor, setTextColor] = useState<string>('#000');
  const [textBackgroundColor, setTextBackgroundColor] = useState<string>('#fff');
  const [, setBackgroundColor] = useState<string>('#fff');

  const [currentPrefix, setCurrentPrefix] = useState<string>('');

  const [lastSelection, setLastSelection] = useState<any>(null);

  useEffect(() => {
    if (!colorPickerRef.current?.isOpen) {
      setCurrentPrefix('');
    }
  }, [colorPickerRef.current?.isOpen]);

  const handleToggleStyle = (style: string) => {
    if (!selectedEl || selectedEl.type !== 'TextBox') return;
    setEditorState((prev: EditorState) => {
      const selectionState = getSelection(prev, isFocused, style);
      const selectedState = EditorState.forceSelection(prev, selectionState);
      return toggleStyle(selectedState, style);
    });
  };

  const handleSetStyle = (style: string) => {
    if (!selectedEl || selectedEl.type !== 'TextBox') return;
    setEditorState((prev: EditorState) => {
      let selectionState = getSelection(prev, isFocused, style);

      const prefix = getPrefix(style);

      if (lastSelection) {
        selectionState = lastSelection;
      }

      const selectedState = EditorState.forceSelection(prev, selectionState);
      const updatedState = setStyle(selectedState, style);

      const updatedStateContent = convertToRaw(updatedState.getCurrentContent());

      if (
        prefix === 'FONT_STYLE'
        && (
          !updatedStateContent.blocks
          || (updatedStateContent.blocks.length === 1 && updatedStateContent.blocks[0].text === '')
        )
      ) {
        handleContainerFontChange(style);
      }

      return updatedState;
    });
  };

  useLayoutEffect(() => {
    if (selectedEl.type !== 'TextBox') return;

    const styles = (editorState as EditorState)
      .getCurrentInlineStyle()
      .toArray();

    const fontStyle = styles.find((el) => el.startsWith('FONT_STYLE'));
    if (fontStyle) {
      setCurrentFont(fontStyle);
    } else {
      setCurrentFont(`FONT_STYLE_${selectedEl.defaultFont}`);
    }
    setIsBold(styles.includes('BOLD'));
    setIsItalic(styles.includes('ITALIC'));
    setIsUnderline(styles.includes('UNDERLINE'));

    const align = styles.find((el) => el.startsWith('ALIGN'));
    if (align) {
      setTextAlign(getSufix(align));
    } else {
      setTextAlign('LEFT');
    }

    setBackgroundColor(selectedEl?.defaultBackground ?? '#fff');

    if (lastSelection) {
      return;
    }

    const textColorStyle = styles.find((el) => el.startsWith('TEXT_COLOR'));
    if (textColorStyle) {
      setTextColor(styleMap[textColorStyle].color);
    } else {
      setTextColor('#000');
    }

    const textBackgroundColorStyle = styles.find((el) => el.startsWith('TEXT_BACKGROUND'));
    if (textBackgroundColorStyle) {
      setTextBackgroundColor(
        styleMap[textBackgroundColorStyle].backgroundColor,
      );
    } else {
      setTextBackgroundColor('#fff');
    }
  }, [editorState]);

  useEffect(() => {
    if (lastSelection && textColor[0] !== '#') {
      handleSetStyle(textColor);
      setTextColor(styleMap[textColor].color);
      setLastSelection(null);
    }
  }, [textColor, lastSelection]);

  useEffect(() => {
    if (
      lastSelection
      && textBackgroundColor[0] !== '#'
      && textBackgroundColor !== 'transparent'
    ) {
      handleSetStyle(textBackgroundColor);
      setTextColor(styleMap[textBackgroundColor].backgroundColor);
      setLastSelection(null);
    }
  }, [textBackgroundColor, lastSelection]);

  const handleBold = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    handleToggleStyle('BOLD');

    setIsBold(!isBold);
  };

  const handleItalic = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    handleToggleStyle('ITALIC');

    setIsItalic(!isItalic);
  };

  const handleUnderline = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    handleToggleStyle('UNDERLINE');

    setIsItalic(!isItalic);
  };

  const handleTextAlign = (e: any, align: string) => {
    e.preventDefault();
    e.stopPropagation();

    handleSetStyle(`ALIGN_${align}`);

    setTextAlign(align);
  };

  const handleChangeColor = (color: string) => {
    switch (currentPrefix) {
      case 'TEXT_COLOR_':
        setTextColor(color);
        break;
      case 'TEXT_BACKGROUND_':
        setTextBackgroundColor(color);
        break;
      case 'BACKGROUND_COLOR_':
        handleBackgroundColorChange(
          styleMap[color].backgroundColor,
        );
        setBackgroundColor(styleMap[color].backgroundColor);
        break;
      default:
    }
  };

  const handleChangeFont = (font: string) => {
    handleSetStyle(font);
    setCurrentFont(font);
  };

  const openColorPicker = (
    event: React.MouseEvent<HTMLButtonElement>,
    prefix: string,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setCurrentPrefix(prefix);
    setLastSelection(getSelection(editorState, isFocused, prefix));
    colorPickerRef.current?.open(event);
  };

  const [boardPosition, setBoardPosition] = useState<DOMRect | {
    top: number; width: number;
  }>({ top: 0, width: 0 });

  useEffect(() => {
    setBoardPosition(
      document.getElementsByClassName('editor-wrapper')[0]?.getBoundingClientRect() || { top: 0, width: 0 },
    );
  }, [document.getElementsByClassName('editor-wrapper')[0]?.getBoundingClientRect()?.top]);

  const content = () => (
    <>
      <ToolbarContainer
        position="fixed"
        style={mobileMQ ? {
          position: 'relative',
        } : {
          top: boardPosition.top + 10,
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <FontsComboBox
          currentFont={currentFont}
          handleFontChange={handleChangeFont}
        />
        <FontSizeController
          currentFont={currentFont}
          handleFontSizeChange={handleChangeFont}
        />
        <div className="group">
          <IconButton
            src={BoldImg}
            alt="bold"
            onClick={handleBold}
            toggle
            isActive={isBold}
          />
          <IconButton
            src={ItalicImg}
            alt="italic"
            onClick={handleItalic}
            toggle
            isActive={isItalic}
          />
          <IconButton
            src={UnderlineImg}
            alt="underline"
            onClick={handleUnderline}
            toggle
            isActive={isUnderline}
          />
        </div>
        <div className="group">
          <IconButton
            src="TextColorImg"
            alt="text color"
            onClick={(e: any) => openColorPicker(e, 'TEXT_COLOR_')}
            variant={textColor}
            isActive={currentPrefix === 'TEXT_COLOR_'}
            id="TEXT_COLOR_"
          />
          <IconButton
            src="TextBackgroundImg"
            alt="text background"
            onClick={(e: any) => openColorPicker(e, 'TEXT_BACKGROUND_')}
            variant={textBackgroundColor}
            isActive={currentPrefix === 'TEXT_BACKGROUND_'}
            id="TEXT_BACKGROUND_"
          />
          {/* <IconButton
          src={BrushImg}
          alt="underline"
          onClick={(e: any) => openColorPicker(e, 'BACKGROUND_COLOR_')}
          variant={backgroundColor}
        /> */}
        </div>
        <div className="group">
          <IconButton
            src={AlignLeftImg}
            alt="align left"
            onClick={(e: any) => {
              handleTextAlign(e, 'LEFT');
            }}
            isActive={textAlign === 'LEFT'}
            noBorder
          />
          <IconButton
            src={AlignCenterImg}
            alt="align center"
            onClick={(e: any) => {
              handleTextAlign(e, 'CENTER');
            }}
            isActive={textAlign === 'CENTER'}
            noBorder
          />
          <IconButton
            src={AlignRightImg}
            alt="align right"
            onClick={(e: any) => {
              handleTextAlign(e, 'RIGHT');
            }}
            isActive={textAlign === 'RIGHT'}
            noBorder
          />
        </div>
      </ToolbarContainer>
      <ColorPicker
        ref={colorPickerRef}
        prefix={currentPrefix}
        onClick={handleChangeColor}
      />
    </>
  );

  return mobileMQ ? (
    <ScrollContainer
      style={{
        top: boardPosition.top + 10,
        left: 10,
        transform: 'none',
        position: 'fixed',
        width: 'calc(100vw - 10px)',
      }}
    >
      {content()}
    </ScrollContainer>
  ) : content();
}

export default TextBoxToolbar;
