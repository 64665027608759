import { Theme, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import { GoogleClassroomImg } from '../../assets/icons';
import { Button } from '../../components/buttons';
import ExtendableDialog from '../../components/ExtendableDialog';
import GalleryTopBar from '../../components/GalleryTopBar';
import { Description } from '../../components/typography';
import ClassroomTable from '../../components/ClassroomTable';
import ClassroomPageContainer from './styles';
import DeleteConfirmationDialog from '../../components/dialogs/DeleteConfirmationDialog';
import { Chevron } from '../../assets/icons/variantComponents';
import { ReactComponent as BackArrow } from '../../assets/icons/back-arrow.svg';
import * as ClassroomAPI from '../../api/Classrooms';
import { useClassroomStore } from '../../zustand/classrooms';
import { lang } from '../../lang';
import { useUserStore } from '../../zustand/user';

function ClassroomPage() {
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false);

  const { cid } = useParams();

  const tabletMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const mobileMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [syncing, setSyncing] = useState(false);

  const navigate = useNavigate();

  const getClassroomData = useClassroomStore((state) => state.getClassroomData);
  const data = useClassroomStore((state) => state.currentClassroom);
  const setCurrentClassroom = useClassroomStore((state) => state.setCurrentClassroom);
  const user = useUserStore((state) => state.user);

  const loadClassroom = async () => {
    const response = await getClassroomData(cid!);
    if (!response) navigate('/gallery', { state: { tab: 2 } });
  };

  useEffect(() => {
    if (cid && user.role === 'teacher') loadClassroom();

    return () => {
      setCurrentClassroom(undefined);
    };
  }, [cid]);

  const handleChangeTab = (tab: number) => {
    navigate('/gallery', { state: { tab } });
  };

  const handleDeleteClassroom = async () => {
    try {
      await ClassroomAPI.deleteClassroom(data?.id!);
      navigate('/gallery', { state: { tab: 2 } });
    } catch {
      // This action doesn't have any specific error exibition
    }
  };

  const handleGoogleSync = async () => {
    setSyncing(true);
    try {
      await ClassroomAPI.syncWithGoogle(data?.id!);
      setTimeout(() => {
        loadClassroom();
      }, 2000);
    } finally {
      setSyncing(false);
    }
  };

  return (
    <>
      <GalleryTopBar tab={2} changeTab={handleChangeTab} />
      <ClassroomPageContainer>
        {tabletMQ && !mobileMQ && (
          <div>
            <span
              className="classroom-back-button"
              role="button"
              tabIndex={-1}
              onClick={() => { navigate('/gallery'); }}
              onKeyDown={() => { navigate('/gallery'); }}
            >
              <BackArrow height={16} width={16} stroke="#999999" />
              <Description size="small">{lang('classroom.classroom_page.go_back')}</Description>
            </span>
          </div>
        )}
        {!tabletMQ && (
          <div className="left-column">
            <div>
              <span
                className="classroom-back-button"
                role="button"
                tabIndex={-1}
                onClick={() => { navigate('/gallery'); }}
                onKeyDown={() => { navigate('/gallery'); }}
              >
                <BackArrow height={16} width={16} stroke="#999999" />
                <Description size="small">{lang('classroom.classroom_page.go_back')}</Description>
              </span>
            </div>
            <div className="classroom-name-info">
              <Description className="classroom-type">
                {data?.provider === 'google' ? (
                  <>
                    <img src={GoogleClassroomImg} alt="google classroom" />
                    {lang('general.provider.google')}
                  </>
                ) : lang('general.provider.pressto')}
              </Description>
              <Description className="classroom-name">
                {data?.name}
              </Description>
            </div>
            {data?.provider === 'google' ? <Button onClick={handleGoogleSync} large outline label={lang(`classroom.classroom_page.sync${syncing ? 'ing' : ''}`)} /> : (
              <div className="classroom-name-info">
                <Description className="classroom-type">
                  {lang('classroom.classroom_page.joincode')}
                </Description>
                <div style={{ display: 'flex', gap: '1.5rem' }}>
                  <Description className="classroom-code">
                    {data?.join_code}
                  </Description>
                  {/* <Circle size="2.5rem" style={{ background: '#A8A7CC' }} /> */}
                </div>
              </div>
            )}
            <Box
              className="classroom-delete-button"
              onClick={() => setIsDeleteConfirmationDialogOpen(true)}
            >
              {lang('classroom.classroom_page.delete')}
            </Box>
          </div>
        )}
        <div className="right-column">
          {data && (
            <ClassroomTable
              data={data.students}
              provider={data.provider}
            />
          )}
        </div>
        {tabletMQ && (
          <ExtendableDialog>
            {({ extended, toggle }) => (
              <div className="classroom-name-info">
                <Description className="classroom-type">
                  {data?.provider === 'google' ? (
                    <>
                      <img src={GoogleClassroomImg} alt="google classroom" />
                      {lang('general.provider.google')}
                    </>
                  ) : lang('general.provider.pressto')}
                </Description>
                <Description className="classroom-name">
                  {data?.name}
                </Description>
                <IconButton onClick={toggle} style={{ position: 'absolute', top: '2.5rem', right: '2.5rem' }}>
                  <Chevron
                    height="2rem"
                    width="2rem"
                    stroke="#52509A"
                    direction={extended ? 'up' : 'down'}
                  />
                </IconButton>
                {extended && (
                  <div className="extended-container">
                    {data?.provider === 'google' ? (
                      <Button
                        onClick={handleGoogleSync}
                        large
                        outline
                        disabled={syncing}
                        label={lang(`classroom.classroom_page.sync${syncing ? 'ing' : ''}`)}
                      />
                    ) : (
                      <div style={{ width: '100%' }}>
                        <Description className="classroom-type">
                          {lang('classroom.classroom_page.joincode')}
                        </Description>
                        <div style={{ display: 'flex', gap: '1.5rem' }}>
                          <Description className="classroom-code">
                            {data?.join_code}
                          </Description>
                          {/* <Circle size="2.5rem" style={{ background: '#A8A7CC' }} /> */}
                        </div>
                      </div>
                    )}
                    <Box
                      className="classroom-delete-button"
                      onClick={() => setIsDeleteConfirmationDialogOpen(true)}
                    >
                      {lang('classroom.classroom_page.delete')}
                    </Box>
                  </div>
                )}
              </div>
            )}
          </ExtendableDialog>
        )}
      </ClassroomPageContainer>
      <DeleteConfirmationDialog
        title={lang('classroom.classroom_page.title')}
        description={lang('classroom.classroom_page.subtitle', { name: data?.name })}
        onSubmit={handleDeleteClassroom}
        isOpen={isDeleteConfirmationDialogOpen}
        onClose={() => setIsDeleteConfirmationDialogOpen(false)}
      />
    </>
  );
}

export default ClassroomPage;
