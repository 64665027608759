import React, {
  SyntheticEvent,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IconButton } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { DocumentFormat, DocumentTemplateSystem } from 'src/types/DocumentSettings';
import { Box } from '@mui/material';
import { OptionsMenuImg } from '../../assets/icons';
import { Description, Title } from '../typography';
import { ButtonContainer, CardDescription, CardNumber } from './styles';
import DeleteConfirmationDialog from '../dialogs/DeleteConfirmationDialog';
import ContextMenu from '../ContextMenu';
import { AssignmentDefinition } from '../../types/Gallery';
import { ContextMenuItemProps, ContextMenuRef } from '../ContextMenu/types';
import { useUserStore } from '../../zustand/user';
import * as DocumentsAPI from '../../api/Documents';
import * as AssignmentsAPI from '../../api/Assignments';
import { lang } from '../../lang';
import StatusTag from '../StatusTag';
import getPlaceholderDocumentByFormat from '../../utils/getPlaceholderDocumentByFormat';
import { getPresstoCardPredominantColor } from '../../utils';
import Page from '../Page';

function AssignmentButton(
  { assignment, onDelete }: {
    assignment: AssignmentDefinition,
    onDelete?: any,
  },
) {
  const {
    name, id,
  } = assignment;

  let document: any;
  if (assignment['base-document']?.template_system && assignment['base-document']?.version) {
    document = assignment['base-document'];
  } else if ((assignment as any).template_system && (assignment as any).version) {
    document = assignment as unknown as typeof document;
  } else {
    document = null;
  }

  const predominantColor = useMemo(
    () => getPresstoCardPredominantColor(assignment['base-document']?.version),
    [assignment['base-document']?.version],
  );

  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false);

  const user = useUserStore((state) => state.user);

  const contextMenuRef = useRef<ContextMenuRef>(null);

  const navigate = useNavigate();

  const menuItems = useMemo<ContextMenuItemProps[]>(() => ([
    {
      label: 'Delete',
      icon: 'BIN',
      onClick: () => { setIsDeleteConfirmationDialogOpen(true); },
      style: { color: '#FF5252 !important' },
    },
  ]), []);

  const handleDropdownClick = (e: any) => {
    e.stopPropagation();

    contextMenuRef.current?.openMenu(e);
  };

  const handleDelete = async () => {
    if (user.role === 'teacher') {
      const response = await AssignmentsAPI.deleteAssignment(id);
      if (response.status === 200) {
        onDelete();
      }
    } else {
      const response = await DocumentsAPI.deleteDocument(assignment.submission.document_id);
      if (response.status === 200) {
        onDelete();
      }
    }
  };

  const createSubmission = async (assignmentId: string) => {
    const response = await AssignmentsAPI.createSubmission(assignmentId);
    if (response.status === 201) {
      navigate(`/document/${response.data.data.document_id}?notes=true`);
    }
  };

  const handleOnError = (e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.onerror = null;
    e.currentTarget.src = getPlaceholderDocumentByFormat(
      assignment['base-document']?.format as string,
    );
  };

  const getImageUrl = () => {
    if (assignment['base-document']?.thumbnail_url) {
      return assignment['base-document']?.thumbnail_url;
    }
    if (assignment.thumbnail_url) return assignment.thumbnail_url;
    return '';
  };

  return (
    <>
      <ContextMenu menuItems={menuItems} ref={contextMenuRef}>
        <ButtonContainer
          className="pressto-button assignment-button"
          onClick={() => {
            if (user.role === 'teacher') {
              navigate(`/assignment/${assignment.id}`);
              return;
            }

            if (assignment.submission && !assignment.submission.id) {
              createSubmission(assignment.submission.assignment_id);
            } else {
              navigate(`/document/${assignment.submission.document_id}`);
            }
          }}
          isTeacher={user.role === 'teacher'}
        >
          <div className="image-container" style={{ backgroundColor: predominantColor }}>
            {document?.template_system === DocumentTemplateSystem.BLOCKS ? (
              // Be extra-guarded here because so many Presstos are displayed
              // at once. Better to fail silently than to crash the gallery.
              document.version?.content?.pages?.length && (
                <Box
                  sx={{
                    width: '100%',
                  }}
                >
                  <Page
                    format={document.format as DocumentFormat || DocumentFormat.BOOKLET}
                    page={document.version.content.pages[0]}
                    scale={0.13}
                    isEditable={false}
                  />
                </Box>
              )
            ) : (
              <img
                onError={handleOnError}
                src={getImageUrl() || ''}
                alt={name}
              />
            )}
          </div>
          <div className="info-container">
            <div className="main-info">
              {user.role === 'teacher'
                ? <StatusTag status="assigned" />
                : <StatusTag status={assignment.submission.status} />}
              <Title lineHeight="1.5rem" size="small">{name}</Title>
            </div>
            {user.role === 'teacher' ? (
              <>
                <Description size="small">{assignment.classroom?.name}</Description>
                <div className="row">
                  <div>
                    <CardNumber>{assignment.turnedin_count || 0}</CardNumber>
                    <CardDescription>{lang('assignment.assignment_tab.turned_in')}</CardDescription>
                  </div>
                  <div>
                    <CardNumber>{assignment.assigned_count || 0}</CardNumber>
                    <CardDescription>{lang('assignment.assignment_tab.assigned')}</CardDescription>
                  </div>
                </div>
              </>
            ) : (
              <div className="row">
                <Description size="small" className="alert-date">
                  {dayjs(assignment.updated_at).fromNow()}
                </Description>
                <Description size="small">
                  {assignment.submission?.classroom_name}
                </Description>
              </div>
            )}
          </div>
          {
            (!assignment.submission || assignment.submission.status !== 'new') && (
              <IconButton className="pressto-button-actions" onClick={handleDropdownClick}>
                <img src={OptionsMenuImg} alt="options menu" />
              </IconButton>
            )
          }
        </ButtonContainer>
      </ContextMenu>
      <DeleteConfirmationDialog
        title={
          // Since teacher can only access assignments through assignments tab,
          // only a student will see the delete document message
          user.role === 'teacher'
            ? lang('assignment.assignment_tab.delete_assignment_confirm.title')
            : lang('gallery.pressto_button.delete_pressto')
        }
        description={
          user.role === 'teacher'
            ? lang('assignment.assignment_tab.delete_assignment_confirm.subtitle')
            : undefined
        }
        onSubmit={() => { handleDelete(); }}
        isOpen={isDeleteConfirmationDialogOpen}
        onClose={() => setIsDeleteConfirmationDialogOpen(false)}
      />
    </>
  );
}

AssignmentButton.defaultProps = {
  onDelete: () => { },
};

export default AssignmentButton;
