export enum DocumentFormat {
  BOOKLET = 'booklet',
  STORY = 'story',
  SHEET = 'sheet',
  PRESENTATION = 'presentation',
}

export function isValidDocumentFormat(format: string | null): format is DocumentFormat {
  return Object.values(DocumentFormat).includes(format as DocumentFormat);
}

export const DocumentFormatSettings = {
  [DocumentFormat.BOOKLET]: {
    height: 1125,
    width: 675,
    scaleFactor: 1,
    format: DocumentFormat.BOOKLET,
    grid: {
      x: 1,
      y: 2,
    },
  },
  [DocumentFormat.STORY]: {
    height: 1920,
    width: 1080,
    scaleFactor: 1.6, // 1080 / 675,
    format: DocumentFormat.STORY,
    grid: {
      x: 1,
      y: 2,
    },
  },
  [DocumentFormat.PRESENTATION]: {
    height: 1080,
    width: 1920,
    scaleFactor: 1.6, // 1080 / 675,
    format: DocumentFormat.PRESENTATION,
    grid: {
      x: 4,
      y: 2,
    },
  },
  [DocumentFormat.SHEET]: {
    height: 3300,
    width: 2550,
    scaleFactor: 2550 / 675, // ~ 3.77777777777777^
    format: DocumentFormat.SHEET,
    grid: {
      x: 3,
      y: 5,
    },
  },
};

export enum DocumentTemplateSystem {
  LAYOUT = 'layout',
  BLOCKS = 'blocks',
}
