import { ButtonUnstyled } from '@mui/base';
import { Box, ButtonBase } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../../utils';
import { DialogContainer, DialogContent } from '../styles';

export const CreatePresstoDialogContainer = styled(DialogContainer, { shouldForwardProp: (prop) => prop !== 'tab' })(
  () => ({
    '& > div > div': {
      height: '100%',
      width: '100%',
      maxHeight: 'unset',
      margin: 'unset',
      padding: '2rem',
      overflowY: 'auto',

      '.close-button': {
        top: '0',
        right: '0',
      },

      [theme.breakpoints.up('sm')]: {
        width: '53.25rem',
        maxWidth: '55rem',

        maxHeight: '42rem',
        margin: '2.5rem',
        padding: '4rem 3rem',
      },

      [theme.breakpoints.up('md')]: {
        height: '42.5rem',

        padding: '4rem 4rem 2.5rem',
      },
    },
  }),
);

export const FormatSelectionDialogContent = styled(Box)(() => ({
  display: 'grid',
  justifyContent: 'center',

  gridGap: '1.5rem',
  gridTemplateColumns: 'repeat(4, auto)',

  [theme.breakpoints.down('sm')]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    placeItems: 'center',
    padding: '0 1.125rem',
  },
}));

export const FormatButton = styled(ButtonBase)(
  ({ selected, disabled }: { selected: boolean, disabled?: boolean }) => ({
    padding: 0.125,
    width: '6.875rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    borderRadius: 0,
    position: 'relative',

    '.selected-check': selected ? {
      display: 'grid',
      position: 'absolute',
      top: -10,
      left: 0,
      backgroundColor: '#52509A',
      height: '1.5rem',
      width: '1.5rem',
      placeItems: 'center',
      transition: 'all .2s ease-in-out',
    } : { display: 'none' },

    '.image-container': {
      transition: 'all .2s ease-in-out',
      display: 'grid',
      placeItems: 'center',
      height: '6.875rem',
      width: '6.875rem',
      background: '#F6F6FA',

      opacity: disabled ? 0.5 : 1,

      border: selected && '1px solid #52509A',
      boxShadow: '4px 4px 0px #52509A',
      borderRadius: 0,
    },

    '&:hover': !disabled && {
      cursor: 'pointer',

      '.image-container': !selected && {
        border: '1px solid #7D7CB3',
        boxShadow: '4px 4px 0px #7D7CB3',
        background: '#FFFFFF',
        transform: 'scale(1.1)',
      },
    },

    '.text-container': {
      width: '100%',
      p: { textAlign: 'center' },

      'p:first-of-type': {
        fontWeight: 700,
        color: '#2C2C2C',
      },
    },

  }),
);

export const WritingPlanDialogContent = styled(DialogContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: '0 !important',

  '.tab-container': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',

    gap: '0.1rem',

    paddingRight: '0.25rem',
  },

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'stretch',

    overflow: 'visible !important',

    gap: '2rem',

    '.tab-container': {
      flexDirection: 'column',

      justifyContent: 'flex-start',
      alignItems: 'flex-start',

      gap: '0',
    },
  },
}));

export const ContentContainer = styled(Box)(() => ({
  flex: 1,
  width: '700px',

  [theme.breakpoints.up('sm')]: {
    maxHeight: '22rem',
    overflowY: 'auto',
    paddingRight: '2rem',
  },
}));

export const EmptyWritingPlan = styled(Box)(() => ({
  paddingTop: '.5rem',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  gap: '1.5rem',

  [theme.breakpoints.up('sm')]: {
    maxWidth: '18rem',

    margin: '0 auto',
  },
}));

export const WritingPlanButton = styled(ButtonUnstyled)(
  ({ selected }: { selected: boolean }) => ({
    backgroundColor: '#FFF',
    color: '#999',

    border: '1px solid transparent',
    borderRadius: 0,

    padding: '.5rem .75rem',
    marginBottom: '0.25rem',

    fontSize: '.75rem',
    fontFamily: 'Inter',
    fontWeight: 400,

    cursor: 'pointer',

    ...(selected && {
      backgroundColor: '#F6F6FA',
      color: '#52509A',

      borderColor: '#52509A',
      boxShadow: '2px 2px 0 #52509A',

      fontWeight: 700,
    }),
  }),
);

export const OptionList = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1rem',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: '.5rem',
  },
}));

export const Option = styled(Box)(() => ({
  border: `1px solid ${theme.gradient.secondary.light}`,
  flexDirection: 'column',
  padding: '3rem 2.5rem',
  justifyContent: 'center',
  borderRadius: '.25rem',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'all 150ms ease-in-out',

  '&:hover': {
    backgroundColor: 'white',
    boxShadow: `0px 0px 50px ${theme.gradient.primary.light}`,
    border: `1px solid ${theme.palette.primary.main}`,
  },

  '.title': {
    margin: '2.5rem 0 1.5rem 0',
  },

  [theme.breakpoints.down('md')]: {
    padding: '2rem',

    '.title': {
      margin: '1.5rem 0 0.5rem 0',
    },
  },

  [theme.breakpoints.down('sm')]: {
    padding: '2rem 4rem',

    '.title': {
      margin: '1.5rem 0 0.5rem 0',
    },
  },
}));

export const ImportantWordsWrapper = styled('div')(() => ({
  position: 'relative',
}));

export const ImportantWordsCounter = styled('span')(() => ({
  backgroundColor: theme.palette.primary.main,

  position: 'absolute',
  bottom: '1.125rem',
  right: '1.125rem',

  width: '2rem',
  height: '2rem',

  color: 'white',

  borderRadius: '100%',

  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',

  textAlign: 'center',
}));

export const ImportantWordsField = styled('textarea')(() => ({
  width: '100%',
  minWidth: '35rem',
  height: '15rem',
  padding: '.5rem',
  border: `1px solid ${theme.palette.secondary.light}`,
  borderRadius: '0rem',
  fontFamily: 'Inter',
  fontSize: '1rem',
}));
