import CreateLessonScreen from 'src/views/lesson';
import DocumentScreenV1 from '../views/document-v1';
import DocumentScreenV2 from '../views/document-v2';
import NewDocumentScreenV1 from '../views/document-v1/CreateDocument';
import GalleryScreen from '../views/gallery';
import AssignmentScreen from '../views/assignment';
import ProfileScreen from '../views/profile';
import ClassroomPage from '../views/classroom';
import EmailVerification from '../views/register/EmailVerification';
import SchoolForm from '../views/register/SchoolForm';
import StudentWorks from '../views/classroom/StudentWorks';
import GoogleClassroomRedirect from '../views/classroom/GoogleRedirect';

const PRIVATE_ROUTES = [
  {
    path: '/document-l/new',
    name: 'Document',
    component: NewDocumentScreenV1,
  },
  {
    path: '/document-l/:id',
    name: 'Document',
    component: DocumentScreenV1,
  },
  {
    path: '/document/:id/',
    name: 'Document',
    component: DocumentScreenV2,
  },
  {
    path: '/document/:id/pages/:pageIndex',
    name: 'Document',
    component: DocumentScreenV2,
  },
  {
    path: '/gallery/*',
    name: 'Gallery',
    component: GalleryScreen,
  },
  {
    path: '/assignment/:aid',
    name: 'Assignment',
    component: AssignmentScreen,
  },
  {
    path: '/classroom/:cid',
    name: 'Classroom',
    component: ClassroomPage,
  },
  {
    path: '/classroom/:cid/student/:id',
    name: 'Student Profile',
    component: ProfileScreen,
  },
  {
    path: '/classroom/:cid/student-work/:id',
    name: 'Student Works',
    component: StudentWorks,
  },
  {
    path: '/lesson/:id/*',
    name: 'Lesson Plan',
    component: CreateLessonScreen,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileScreen,
  },
  {
    path: '/publish/:id',
    name: 'Published Pressto',
    component: DocumentScreenV2,
  },
  {
    path: '/verify',
    name: 'Email verification',
    component: EmailVerification,
  },
  {
    path: '/school-information-form',
    name: 'School Information Form',
    component: SchoolForm,
  },
  {
    path: '/classrooms/providers/oauth/google',
    name: 'Google classroom redirect',
    component: GoogleClassroomRedirect,
  },
];

export default PRIVATE_ROUTES;
