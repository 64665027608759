import { DocumentTemplateSystem } from './DocumentSettings';
import { LayoutPage as PageType, Note } from './models';
import { UserAuth } from './User';

export enum GalleryTab {
  GALLERY = 0,
  ASSIGNMENTS = 1,
  CLASSROOMS = 2,
}

export enum PresstoState {
  PUBLISHED = 'Published',
  DRAFT = 'Draft',
  DELIVERED = 'Delivered',
}

export enum AssignmentState {
  PENDING = 'Pending',
  DELIVERED = 'Delivered',
  UNKNOWN = '??',
}

export enum DateFilter {
  LASTWEEK = 'Last week',
  LASTTWOWEEKS = 'Last two weeks',
  LASTMONTH = 'Last month',
  LASTTHREEMONTHS = 'Last three months',
  LASTSEMESTER = 'Last semester',
  LASTYEAR = 'Last year',
}

export interface PresstoUser {
  id: string;
  name: string;
  surname: string;
  email: string;
  role: string
}

// Mask the Note model in case the legacy document code requires adjustment.
export interface PresstoNote extends Note {}

export interface PresstoVersion {
  id: string;
  pages: PageType[];
  status?: string;
  notes?: PresstoNote[];

  // For blocks
  content?: any;
}

export interface PresstoSubmission {
  id: string;
  status: string;
  assignment_id: string;
  document_id: string;
  student_id: string;
  assignment?: {
    id: string;
    name: string;
    notes: PresstoNote[];
  }
}

export interface PresstoDefinition {
  id: string;
  format: string;
  thumbnail_url?: string;
  name: string;
  updated_at: Date;
  user: PresstoUser;
  version: PresstoVersion;
  meta: any;
  submission?: PresstoSubmission;
  template_system?: DocumentTemplateSystem;
}

export interface SelectedPresstoDefinition extends PresstoDefinition {
  lastSaved?: Date | string | null;
  author?: string;
  isOwner?: boolean;
  isTryIt?: boolean;
  prev?: string;
  next?: string;
}

export interface AssignmentDefinition {
  created_at: string;
  format: string;
  id: string;
  is_demo: boolean;
  is_public: boolean;
  meta: string;
  name: string;
  submission: any;
  thumbnail_url?: string;
  updated_at: string;
  user: UserAuth;
  word_count: number;
  classroom?: any;
  turnedin_count?: number;
  assigned_count?: number;
  'base-document'?: {
    format: string;
    thumbnail_url: string;
    template_system: DocumentTemplateSystem;
    version: PresstoVersion;
  }
}

export interface StudentDefinition {
  id: string;
  name: string;
  surname: string;
  email: string;
  last_session: string;
  word_writen: number;
}

export interface ClassroomDefinition {
  id: string;
  name: string;
  subject: string;
  section: string;
  join_code: string;
  provider: string;
  createdAt: Date;
  students: StudentDefinition[];
  has_documents: boolean;
  isSyncing?: boolean;
}
