import React from 'react';
import {
  Button, IconButton, Grid, Box, Typography,
} from '@mui/material';
import { BlockTypesEnum } from 'src/types/models';
import { IWritingPlanModel } from 'src/Models/WritingPlans/types';
import BlocksItem from 'src/components/Blocks/BlocksItem';
import Blocks from 'src/Models/Blocks';
import { DialogCloseImg } from '../../../assets/icons';
import { Title, Container } from './styles';
import { lang } from '../../../lang';
import { DialogProps } from '../types';

export default function SelectBlockTypeDialog({
  isOpen,
  onClose,
  onSelect,
  availablePlans,
}: DialogProps & {
  onSelect: (blockTypeId: BlockTypesEnum) => void
  availablePlans: IWritingPlanModel[]
}) {
  // TODO: Add basic plan (text + image).
  return (
    <Container
      onClose={onClose}
      open={isOpen}
    >
      <IconButton className="close-button" onClick={onClose}>
        <img src={DialogCloseImg} alt="close dialog" />
      </IconButton>
      <Grid container sx={{ width: '100%', height: '100%' }} flexWrap="nowrap">
        <Grid item sx={{ py: 4, px: 6 }} sm="auto">
          <Title>
            <div>
              <p>{lang('document.choose_blocktype_dialog.title.line_1')}</p>
              <p>{lang('document.choose_blocktype_dialog.title.line_2')}</p>
            </div>
          </Title>
        </Grid>

        <Grid
          item
          sx={{
            padding: 4, overflowY: 'auto', py: 6, paddingRight: 16,
          }}
        >
          <div>
            {availablePlans.map((plan) => (
              <div key={plan.id}>
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: 2,
                    marginTop: 4,
                  }}
                >
                  {plan.title}
                </Typography>
                <Box sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  columnGap: 3,
                  rowGap: 1,
                  alignItems: 'flex-start',
                }}
                >
                  {plan.availableBlocks.map((blockType) => {
                    const block = Blocks[blockType];
                    return (
                      <Button
                        key={block.title}
                        onClick={() => {
                          onSelect(blockType);
                          onClose();
                        }}
                        sx={{
                          padding: 0,
                          textTransform: 'unset',
                          opacity: 0.8,

                          '&:hover': {
                            transform: 'scale(1.1)',
                            backgroundColor: 'unset',
                            fontWeight: 'bold',
                            opacity: 1.0,
                          },
                        }}
                      >
                        <BlocksItem
                          title={block.title}
                          image={block.image}
                        />
                      </Button>
                    );
                  })}
                </Box>
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
