import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { ClickAwayListener } from '@mui/base';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { useMediaQuery } from '@material-ui/core';
import { Theme } from '@mui/material';
import { Description } from '../typography';
import { TopBarInput } from './styles';
import { useEditor } from '../../hooks/useEditor';
import { useDocumentStore } from '../../zustand/documents';
import { lang } from '../../lang';

dayjs.extend(relativeTime);

let timeout: NodeJS.Timeout;

function ProjectInfo() {
  const mobileMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { isViewMode } = useEditor();

  const saving = useDocumentStore((state) => state.saving);
  const currentDocument = useDocumentStore((state) => state.currentDocument);
  const updateDocument = useDocumentStore((state) => state.updateDocument);

  const [isEditingProjectName, setIsEditingProjectName] = useState(false);
  const [newProjectName, setNewProjectName] = useState('');
  const [tempProjectName, setTempProjectName] = useState<string | null>(null);
  const [lastSavedString, setLastSavedString] = useState('');

  const handleNameChange = async () => {
    setIsEditingProjectName(false);
    if (!newProjectName) return;
    setNewProjectName('');
    setTempProjectName(newProjectName);
    if (!currentDocument?.isTryIt) updateDocument(currentDocument?.id ?? '', newProjectName);
  };

  const updateSavingString = () => {
    if (timeout) { clearTimeout(timeout); }

    if (currentDocument?.lastSaved === 'error') {
      return;
    }

    const newString = dayjs(currentDocument?.lastSaved).fromNow();
    if (newString !== lastSavedString) {
      setLastSavedString(newString);
    }

    timeout = setTimeout(updateSavingString, 10 * 1000);
  };

  useEffect(() => {
    if (currentDocument) updateSavingString();
  }, [lastSavedString, currentDocument?.lastSaved]);

  const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    switch (e.code) {
      case 'Enter':
        handleNameChange();
        break;
      case 'Escape':
        setIsEditingProjectName(false);
        setNewProjectName('');
        break;
      default:
    }
  };

  const getSubtitle = () => {
    if (currentDocument?.isTryIt) return null;

    if (isViewMode) {
      return (
        <Description size="small">
          {currentDocument?.user.name}
          {' '}
          {currentDocument?.user.surname}
        </Description>
      );
    }
    if (currentDocument?.lastSaved) {
      if (currentDocument?.lastSaved === 'error') {
        return (
          <Description size="small" style={currentDocument.lastSaved === 'error' ? { color: '#ffadad' } : {}}>
            Unsaved document
          </Description>
        );
      }
      if (mobileMQ) {
        return (
          <Description size="small">
            {['hour', 'minute', 'seconds'].some((el) => lastSavedString.includes(el)) ? dayjs(currentDocument.lastSaved).format('HH:mm') : lastSavedString}
          </Description>
        );
      }
      return (
        <Description size="small">
          {lang('document.top_bar.project_info.last_saved')}
          {' '}
          {lastSavedString}
        </Description>
      );
    }
    return <Description size="small">{saving && lang('document.top_bar.project_info.saving')}</Description>;
  };

  return (
    <Box onClick={() => {
      if (isViewMode || currentDocument?.submission) return;
      setIsEditingProjectName(true);
    }}
    >
      {
        isEditingProjectName
          ? (
            <ClickAwayListener onClickAway={handleNameChange}>
              <div className="topbar-project-info">
                <TopBarInput
                  autoFocus
                  placeholder={tempProjectName ?? currentDocument?.name ?? ''}
                  onKeyDown={handleInputKeyPress}
                  onChange={
                    (e: React.ChangeEvent<HTMLInputElement>) => setNewProjectName(e.target.value)
                  }
                />
              </div>
            </ClickAwayListener>
          ) : (
            <div className="topbar-project-info">
              <Description weight={700}>{tempProjectName ?? currentDocument?.name}</Description>
              {getSubtitle()}
            </div>
          )
      }

    </Box>
  );
}

export default ProjectInfo;
