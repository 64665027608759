import { styled } from '@mui/system';
import { DialogContainer, DialogContent, DialogTitle } from '../styles';

export const DeleteAccountDialogContainer = styled(DialogContainer)(() => ({
  '& > div > div': {
    height: 'unset',
    width: '37.25rem',

    '.close-button': {
      top: '.25rem',
      right: '.25rem',
    },

    padding: '1.75rem 2.5rem 2.5rem 3.75rem',

    '.delete-account-image': {
      position: 'absolute',
      top: '1.75rem',
      right: '2.5rem',
      maxWidth: '35%',
    },
  },
}));

export const DeleteAccountDialogTitle = styled(DialogTitle)(() => ({
  marginTop: '8.75rem !important',
  padding: '1rem !important',

  '& > *': { width: '100%' },

  p: {
    color: '#52509A !important',
    fontSize: '3rem',
    textAlign: 'left !important',
  },
}));

export const DeleteAccountDialogContent = styled(DialogContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '.5rem 1rem !important',

  '.content-wrapper': {
    display: 'flex',
    flexDirection: 'column',

    gap: '1.5rem',

    '.subtitle': {
      fontSize: '1.125rem',
      color: '#52509A !important',
      margin: '1.5rem 0',
    },

    '.long-description': {
      fontSize: '1.125rem',
      marginBottom: '2.5rem',
    },
  },

  '.dialog-actions': {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-between',

    button: {
      height: '3rem',
    },

    '.red-button': {
      background: '#FF5252',
    },
  },
}));
