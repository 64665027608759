import { styled } from '@mui/system';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { DialogContainer, DialogContent, DialogTitle } from '../styles';

export const UploadDialogContainer = styled(DialogContainer)(() => ({
  '& > div > div': {
    height: '35rem',
    width: '53.125rem',
    maxHeight: 'unset',

    '.close-button': {
      top: '.25rem',
      right: '.25rem',
    },

    '.dialog-footer': {
      height: '5rem',
      display: 'grid',
      placeItems: 'center',
      boxShadow: '0px 0px 50px rgba(82, 80, 154, 0.2)',

      fontSize: '0.75rem',
      color: '#999999',
    },
  },
}));

export const UploadDialogTitle = styled(DialogTitle)(() => ({
  paddingTop: '2.5rem !important',

  p: {
    textAlign: 'center !important',

    '&:first-of-type': {
      color: '#2C2C2C !important',
      fontSize: '.75rem',
    },

    '&:last-of-type': {
      color: '#52509A !important',
      fontSize: '2rem',
      fontWeight: 700,
    },
  },
}));

export const UploadDialogContent = styled(DialogContent)(() => ({
  padding: '0 4.125rem !important',
  height: 'unset !important',

  '.search-bar': {
    marginBottom: '1.125rem',

    '.sub-bar': {
      marginTop: '.5rem',
      fontSize: '.75rem',
      color: '#999999',

      label: {
        color: '#52509A',
        textDecoration: 'underline',
      },
    },
  },

  '.image-grid': {
    minHeight: '17.125rem',
    maxHeight: '17.125rem',
    height: '17.125rem',

    overflow: 'auto',

    '.image-container': {
      'button, img': {
        width: '100%',
      },
    },

    '.loading-indicator': {
      width: '100%',
      display: 'grid',
      placeItems: 'center',
      padding: '.5rem 0',
    },
  },
}));

export const ImageTooltip = styled(Tooltip)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f6f6fa',
    color: 'rgba(0, 0, 0, 0.87)',
    width: 180,
    padding: '.5rem .7rem',
    a: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
}));
