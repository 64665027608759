import React from 'react';

import { lang } from 'src/lang';
import { ICollapsableListProps } from './types';
import { CollapsableListWrapper, CollapseButton } from './styles';

function CollapsableList({
  list,
  renderItem,
  defaultAmountItemsToShow = 5,
  gapItems = '0.25rem',
  isCollapsed,
  handleCollapse,
  fontSize,
  showLess,
}: ICollapsableListProps) {
  const itemsCount = list.length;

  const displayedItems = list.slice(
    0,
    isCollapsed ? defaultAmountItemsToShow : itemsCount,
  );

  return (
    <CollapsableListWrapper gap={gapItems} fontSize={fontSize}>
      {displayedItems.map((item) => renderItem(item))}
      {
        (isCollapsed || showLess) && (
          <CollapseButton
            className="collapse-button"
            onClick={() => {
              handleCollapse();
            }}
          >
            {
              (showLess && !isCollapsed)
                ? lang('document.create_pressto_dialog.writing_plan.show_less')
                : `+${itemsCount - defaultAmountItemsToShow}`
            }
          </CollapseButton>
        )
      }
    </CollapsableListWrapper>
  );
}

export default CollapsableList;
