import { ImageBoxType } from '../../types/LayoutEditor';

export default [
  {
    key: 'imagebox1',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 0,
    y: 0,
    height: 367,
    width: 675,
  } as ImageBoxType,
  {
    key: 'imagebox2',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 0,
    y: 379,
    height: 367,
    width: 675,
  } as ImageBoxType,
  {
    key: 'imagebox3',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 0,
    y: 759,
    height: 367,
    width: 675,
  } as ImageBoxType,
];
