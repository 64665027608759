import API from './config';

export function getAll() {
  return API.get('/assignments');
}

export function getDocumentsSubmissions(id: string) {
  return API.get(`/assignments/${id}/documents`);
}

export function submit(aid: string, sid: string) {
  return API.post(`/assignments/${aid}/submissions/${sid}/submit`);
}

export function unsubmit(aid: string, sid: string) {
  return API.post(`/assignments/${aid}/submissions/${sid}/unsubmit`);
}

export function createSubmission(id: string) {
  return API.post(`/assignments/${id}/submissions`);
}

export function getById(id: string) {
  return API.get(`/assignments/${id}`);
}

export function create(params: {
  name: string;
  document_id: string;
  classroom_id: string;
  note?: string;
}) {
  return API.post('/assignments', params);
}

export function deleteAssignment(id: string) {
  return API.delete(`/assignments/${id}`);
}
