import { IconButton } from '@material-ui/core';
import React, { useState } from 'react';
import { joinWithCode } from '../../../api/Classrooms';
import { DialogCloseImg } from '../../../assets/icons';
import { lang } from '../../../lang';
import { useUserStore } from '../../../zustand/user';
import { Button } from '../../buttons';
import FlexBox from '../../FlexBox';
import Form from '../../Form';
import Input from '../../inputs/Input';
import { Title } from '../../typography';
import { DialogContainer, DialogContent } from '../styles';
import { DialogProps } from '../types';

function JoinWithCodeDialog({ isOpen, onClose }: DialogProps) {
  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);

  const [joinCode, setJoinCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e: any) => {
    setError('');
    setJoinCode(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await joinWithCode({ join_code: joinCode });
      if (response.status === 200) {
        setUser({ ...user, role: 'student' });
        onClose();
      }
    } catch (err: any) {
      setError(err.response.data.message || lang('auth.errors.invalid_joincode'));
    }
    setLoading(false);
  };

  return (
    <DialogContainer onClose={onClose} open={isOpen} style={{ margin: '5rem' }}>
      <DialogContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Form onSubmit={handleSubmit}>
          <FlexBox width={310} alignSelf="center" gap="2rem">
            <Title align="center" marginBottom={5}>{lang('classroom.joincode_dialog.title')}</Title>
            <Input
              placeholder={lang('classroom.joincode_dialog.joincode_placeholder')}
              required
              onChange={handleChange}
              error={!!error}
              invalidMessage={error}
            />
            <Button label={lang('general.next')} disabled={!joinCode || loading} large type="submit" />
          </FlexBox>
        </Form>
      </DialogContent>
      <IconButton className="close-button" onClick={onClose}>
        <img src={DialogCloseImg} alt="close dialog" />
      </IconButton>
    </DialogContainer>
  );
}

export default JoinWithCodeDialog;
