import { lighten } from '@mui/material';
import { Box, styled } from '@mui/system';

export const NewBlockBox: any = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  fontSize: '1.3em',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',

  color: lighten(theme.palette.primary.light, 0.6),

  '&:hover': {
    outlineWidth: '2px',
    outlineStyle: 'solid',
    outlineColor: theme.palette.primary.dark,
    color: theme.palette.primary.dark,
  },
}));
