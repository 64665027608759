import { styled } from '@mui/system';
import { OutlinedInput } from '@mui/material';
import { ButtonUnstyled } from '@mui/base';
import { InputProps } from './types';
import { theme } from '../../utils';

export const BaseInput = styled('input', {
  shouldForwardProp: (prop) => prop !== 'error',
})<InputProps>(({ error }) => ({
  backgroundColor: 'transparent',
  border: error ? `0.125rem solid ${theme.palette.error.main}` : `0.06rem solid ${theme.palette.primary.main}`,
  color: theme.palette.secondary.contrastText,
  borderRadius: 0,
  outline: 'none',
  fontSize: '0.875rem',
  width: 'fill-available',
  display: 'flex',
  alignItems: 'center',
  padding: '0.875rem 1.5rem',
  transition: 'all 150ms ease',
  paddingRight: '2.5rem',

  '&:invalid': {
    '&:not(:placeholder-shown)': {
      border: `0.125rem solid ${theme.palette.error.main}`,
    },
  },

  '&:disabled': {
    opacity: 0.5,
    borderColor: theme.palette.secondary.main,
    cursor: 'not-allowed',
    color: theme.palette.secondary.contrastText,
  },
}));

export const SearchInputBar = styled(OutlinedInput, {
  shouldForwardProp: (prop) => prop !== 'error',
})<InputProps>(() => ({
  width: 'fill-available',
  fontSize: '.75rem !important',

  input: {
    padding: '.75rem 0',
    fontWeight: 700,
  },

  borderRadius: 0,
}));

export const PasswordVisibilityToggler = styled(ButtonUnstyled)(() => ({
  position: 'absolute',
  color: theme.palette.secondary.main,
  right: '0.7rem',
  background: 'transparent',
  top: '50%',
  transform: 'translate(0, -50%)',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  display: 'grid',
  placeItems: 'center',
}));
