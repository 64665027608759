import React, { useState } from 'react';
import { ButtonComponent } from './styles';
import { ButtonProps } from './types';

function Button({
  label, large, outline, image, ...rest
}: ButtonProps) {
  const [clicking, setClicking] = useState(false);

  return (
    <ButtonComponent
      {...rest}
      large={large}
      outline={outline}
      clicking={clicking}
      onMouseDownCapture={() => { setClicking(true); }}
      onMouseUpCapture={() => { setClicking(false); }}
    >
      {image && (
        <>
          {image}
          <br />
        </>
      )}
      {label}
    </ButtonComponent>
  );
}

export default Button;
