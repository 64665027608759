import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkVerificationEmail } from '../../api/User';
import { FlexBox, GradientContainer, Logo } from '../../components';
import { Description, Link, Title } from '../../components/typography';
import { lang } from '../../lang';

function EmailVerification() {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const verifyEmail = async () => {
    try {
      const response = await checkVerificationEmail(url);
      if (response.status === 200) {
        const { email } = response.data;
        navigate(`/create-account?email=${email}&url=${url}`, { replace: true });
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const parseUrl = () => {
    let str = '';
    const urlParams = new URLSearchParams(window.location.search);
    const outro = urlParams.get('verify_url')?.replace(/3D/gi, '').split('fill-verification-email');
    if (outro && outro.length > 0) {
      str += outro[1];
      const paramEmail = urlParams.get('signature')?.replace('3D', '');
      str += '&signature=';
      str += paramEmail;
      setUrl(str);
    }
  };

  useEffect(() => {
    if (url) verifyEmail();
  }, [url]);

  useEffect(() => {
    parseUrl();
  }, []);

  return loading ? <div /> : (
    <GradientContainer>
      <FlexBox textAlign="center">
        <Logo />
      </FlexBox>
      <FlexBox textAlign="center">
        <Title>{lang('auth.errors.invalid_code.line_1')}</Title>
        <Description align="center">
          {lang('auth.errors.invalid_code.line_2')}
        </Description>
      </FlexBox>
      <FlexBox textAlign="center">
        <Description align="center">
          {lang('auth.signup.already_have_account')}
          {' '}
          <Link underline href="/">{lang('auth.signup.login')}</Link>
        </Description>
      </FlexBox>
    </GradientContainer>
  );
}

export default EmailVerification;
