import { Menu, MenuItem } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFeatureFlag } from 'src/hooks/flags';
import AssignmentButton from '../../components/AssignmentButton';
import TabPanel from '../../components/TabPanel';
import { AssignmentGrid, TabWrapper } from './styles';
import { Chevron } from '../../assets/icons/variantComponents';
import { Create } from '../../assets/icons';
import CreatePresstoDialog from '../../components/dialogs/CreatePresstoDialog';
import * as DocumentsAPI from '../../api/Documents';
import * as AssignmentsAPI from '../../api/Assignments';
import { AssignmentDefinition } from '../../types/Gallery';
import { lang } from '../../lang';
import { AssignmentsTabProps } from './types';
import { useUserStore } from '../../zustand/user';

const SORT_BY = ['recent', 'oldest', 'az', 'za'];

let timeout: NodeJS.Timeout;

function AssignmentsTab({
  tab,
}: AssignmentsTabProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [isFormatSelectionDialogOpen, setIsFormatSelectionDialogOpen] = useState(false);

  const user = useUserStore((state) => state.user);
  const hasWritingPrompts = useFeatureFlag('writing-prompts');

  const navigate = useNavigate();

  // TODO: This code is duplicative of the code in GalleryTab.tsx.
  // This logic should probably be inside the dialog box itself, but that has to
  // be separated into multiple dialog boxes.
  const handleCreate = (format: string, meta: {
    writingPlan?: string;
    importantWords?: string[];

    // TODO: Signal words is here for backward compatibility. It may be removable.
    signalWords?: string[];
  }) => {
    const parameters: Record<string, string> = { format };
    if (meta.writingPlan) {
      parameters.writingPlan = meta.writingPlan;
    }
    if (meta.importantWords && meta.importantWords.length > 0) {
      parameters.importantWords = meta.importantWords.join(',');
    }
    navigate(`/document/new?${new URLSearchParams(parameters)}`);
  };

  const [assignments, setAssignments] = useState<AssignmentDefinition[]>([]);

  const [sortedBy, setSortedBy] = useState<string>(() => {
    const savedData = localStorage.getItem('sortedAssignmentBy');
    return savedData ? JSON.parse(savedData) : SORT_BY[0];
  });

  const handleOpenSortByDialog = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSortByDialog = (selectedItem?: string) => {
    if (selectedItem) {
      setSortedBy(selectedItem);
      localStorage.setItem('sortedAssignmentBy', JSON.stringify(selectedItem));
    }
    setAnchorEl(null);
  };

  const loadAssignments = async () => {
    if (user.role === 'teacher') {
      const response = await AssignmentsAPI.getAll();
      if (response.status === 200) {
        setAssignments(response.data.data);
        timeout = setTimeout(loadAssignments, Math.random() * 8000 + 2000);
      }
    } else {
      const response = await DocumentsAPI.getAll({ order: 'recent', type: 'assignment' });
      if (response.status === 200) {
        setAssignments(response.data.data);
        timeout = setTimeout(loadAssignments, Math.random() * 8000 + 2000);
      }
    }
  };

  useEffect(() => {
    loadAssignments();

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const sortedByLabel = useMemo(
    () => lang(`gallery.sort_select.${SORT_BY.find((el) => el === sortedBy) ?? SORT_BY[0]}`),
    [sortedBy],
  );

  window.document.title = 'Assignments';
  return (
    <TabPanel tab={tab} index={1}>
      <TabWrapper>
        {/* <p>{lang('assignment.assignment_tab.title')}</p> */}
        <div>
          <AssignmentGrid isTeacher={user.role === 'teacher'}>
            <div className="pressto-count-row">
              <div className="pressto-count">
                {lang('general.found')}
                {' '}
                <strong>
                  {lang('assignment.assignment_tab.assignment_count', { count: assignments.length })}
                </strong>
              </div>
              <div className="sorted-by">
                <span>{lang('general.organize_by')}</span>
                <u
                  id="sort-by-button"
                  aria-controls={open ? 'sorted-by-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  role="button"
                  onClick={handleOpenSortByDialog}
                  onKeyDown={handleOpenSortByDialog}
                  tabIndex={-1}
                  style={{ cursor: 'pointer' }}
                >
                  {sortedByLabel}
                  {' '}
                  <Chevron direction={open ? 'up' : 'down'} />
                </u>
                <Menu
                  id="sorted-by-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => handleCloseSortByDialog()}
                  MenuListProps={{ 'aria-labelledby': 'sort-by-button' }}
                  classes={{ paper: 'order-by-container', list: 'order-by-menu' }}
                >
                  {SORT_BY.map((el) => (
                    <MenuItem
                      key={el}
                      onClick={() => handleCloseSortByDialog(el)}
                      style={el === sortedBy ? { fontWeight: 700 } : {}}
                    >
                      {lang(`gallery.sort_select.${el}`)}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </div>
            <div className="content">
              {
                user.role === 'teacher' && (
                  <button
                    className="create pressto-button assignment-button"
                    type="button"
                    onClick={() => {
                      if (hasWritingPrompts) {
                        navigate('/lesson/new');
                      } else {
                        setIsFormatSelectionDialogOpen(true);
                      }
                    }}
                  >
                    <img src={Create} alt="+" />
                    {lang('assignment.assignment_tab.create')}
                  </button>
                )
              }
              {assignments.map((assignment) => (
                <AssignmentButton
                  key={assignment.id}
                  assignment={assignment}
                  onDelete={loadAssignments}
                />
              ))}
            </div>
            <CreatePresstoDialog
              isAssignmentFlow
              onSubmit={handleCreate}
              isOpen={isFormatSelectionDialogOpen}
              onClose={() => setIsFormatSelectionDialogOpen(false)}
            />
          </AssignmentGrid>
        </div>
      </TabWrapper>
    </TabPanel>
  );
}

export default AssignmentsTab;
