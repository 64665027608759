import { ImageBoxType, TextBoxType } from '../../types/LayoutEditor';

export default [
  {
    key: 'imagebox1',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 0,
    y: 0,
    height: 1220,
    width: 2550,
  } as ImageBoxType,
  {
    key: 'textbox1',
    type: 'TextBox',
    defaultFont: 'TITLE100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 128,
    y: 1292,
    height: 344,
    width: 2294,
  } as TextBoxType,
  {
    key: 'textbox2',
    type: 'TextBox',
    defaultFont: 'PARAGRAPH100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 128,
    y: 1732,
    height: 1408,
    width: 1099,
  } as TextBoxType,
  {
    key: 'textbox3',
    type: 'TextBox',
    defaultFont: 'PARAGRAPH100',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 1291,
    y: 1732,
    height: 1408,
    width: 1099,
  } as TextBoxType,
];
