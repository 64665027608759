/* eslint-disable react/prop-types */
import React, {
  useEffect,
  useState,
} from 'react';
import { IconButton, Input as MuiInput } from '@mui/material';
import { styled } from '@material-ui/core';
import { ButtonUnstyled } from '@mui/base';
import { useLocation, useNavigate } from 'react-router-dom';

import { Description, Title } from '../../typography';
import { DialogCloseImg, PlusImg } from '../../../assets/icons';
import { Input } from '../../inputs';
import { Button } from '../../buttons';
import { AssignmentDialogContainer } from './styles';
import * as AssignmentsAPI from '../../../api/Assignments';
import { SelectList } from '../..';
import { theme } from '../../../utils';
import { GoogleClassroomImg } from '../../../assets/images';
import CreateClassroomDialog from '../CreateClassroomDialog';
import { useDocumentStore } from '../../../zustand/documents';
import * as ClassroomAPI from '../../../api/Classrooms';
import { lang } from '../../../lang';
import StatusTag from '../../StatusTag';
import LinkBox from '../../LinkBox';
import getPlaceholderDocumentByFormat from '../../../utils/getPlaceholderDocumentByFormat';
import { DialogProps } from '../types';

const {
  Marker, OptionLabel, ListOption, Option,
} = SelectList;

const AddClassesButton = styled(ButtonUnstyled)(() => ({
  left: 1,
  bottom: 1,
  width: '150px',
  display: 'flex',
  fontWeight: 600,
  color: theme.palette.primary.main,
  position: 'absolute',
  padding: '1rem',
  alignItems: 'center',
  cursor: 'pointer',
  background: 'white',
  border: 'none',
}));

function CreateAssignmentDialog({
  isOpen, onOpen, onClose,
}: DialogProps) {
  const [title, setTitle] = useState('');
  const [noteText, setNoteText] = useState('');
  const [classrooms, setClassrooms] = useState<any>([]);
  const [selectedClassroom, setSelectedClassroom] = useState<any>(null);
  const [newAssignmentId, setNewAssignmentId] = useState('');

  const [currentStep, setCurrentStep] = useState(0);
  const [isCreateClassroomDialogOpen, setIsCreateClassroomDialogOpen] = useState(false);

  const { state } = useLocation() as { state: any };

  const navigate = useNavigate();

  const currentDocument = useDocumentStore((selectState) => selectState.currentDocument);

  const handleSave = async (e: any) => {
    e.preventDefault();
    if (!selectedClassroom) return;

    const response = await AssignmentsAPI.create({
      name: title,
      document_id: currentDocument?.id ?? '',
      classroom_id: selectedClassroom,
      note: noteText,
    });

    if (response.status === 201) {
      setNewAssignmentId(response.data.data.id);
      setCurrentStep(2);
      return;
    }

    onClose();
  };

  const loadClassrooms = async () => {
    try {
      const response = await ClassroomAPI.getAll();
      if (response.status === 200) setClassrooms(response.data.data);
    } catch {
      // This action doesn't have any specific error exibition
    }
  };

  useEffect(() => {
    if (state?.classroomList) {
      if (onOpen) onOpen();
      setTimeout(() => {
        setCurrentStep(1);
        if (localStorage.getItem('assignmentName')) {
          setTitle(localStorage.getItem('assignmentName')!);
        }
      }, 500);
    }
    loadClassrooms();
  }, []);

  const openClassroomDialog = () => {
    localStorage.setItem('assignmentName', title);
    localStorage.setItem('doc', currentDocument?.id || '');
    setIsCreateClassroomDialogOpen(true);
  };

  const renderAssignmentImage = () => (
    <div className="assignment-image">
      <div className="thumbnail-container">
        <img src={currentDocument?.version?.pages[0]?.thumbnail_url || getPlaceholderDocumentByFormat(currentDocument?.format ?? '')} alt="" />
      </div>
      <div className="card-content">
        <StatusTag status="new" />
        <div className="card-title">{title}</div>
      </div>
    </div>
  );

  const handleClose = () => {
    setTitle('');
    setCurrentStep(0);
    onClose();
    localStorage.removeItem('assignmentName');
    localStorage.removeItem('doc');
  };

  return (
    <>
      <AssignmentDialogContainer
        onClose={handleClose}
        open={isOpen}
      >
        {currentStep === 0 && (
          <div className="content-wrapper">
            {renderAssignmentImage()}
            <form
              className="assignment-content"
              onSubmit={(e) => {
                e.preventDefault();
                setCurrentStep(1);
              }}
            >
              <Description>
                {lang('assignment.create_assignment_dialog.add_title')}
              </Description>
              <Input
                placeholder="Title"
                onChange={(e) => setTitle(e.target.value)}
                style={{ fontWeight: 700, fontSize: '1.5rem', padding: '0.7rem' }}
                required
              />

              <Description marginTop="1rem">
                {lang('assignment.create_assignment_dialog.add_note')}
              </Description>
              <MuiInput
                placeholder="Instructions"
                multiline
                minRows={3}
                disableUnderline
                value={noteText}
                onChange={(e) => setNoteText(e.target.value)}
                sx={{
                  borderWidth: '0.06rem',
                  borderColor: theme.palette.primary.main,
                  borderStyle: 'solid',
                  padding: '0.7rem',
                }}
              />

              <Description marginTop="1rem" marginBottom="2rem">
                {lang('assignment.create_assignment_dialog.step_0.description')}
              </Description>
              <div style={{ margin: '0rem 2rem 2rem' }}>
                <Button label="Create Assignment" large type="submit" />
              </div>
              <Description align="center" size="small">
                {lang('assignment.create_assignment_dialog.step_0.invite_students')}
              </Description>
            </form>
          </div>
        )}
        {currentStep === 1 && (
          <div className="content-wrapper">
            {renderAssignmentImage()}
            <form
              className="assignment-content"
              onSubmit={handleSave}
            >
              <Title>{lang('assignment.create_assignment_dialog.step_1.assign_to')}</Title>
              <div className="classroom-list">
                <ListOption style={{ height: '200px', paddingBottom: '50px' }}>
                  {
                    classrooms?.map((item: any) => (
                      <Option
                        key={item.id}
                        onClick={() => setSelectedClassroom(item.id)}
                      >
                        <Marker
                          type="radio"
                          selected={selectedClassroom === item.id}
                        />
                        {
                          item.provider === 'google' && (
                            <div style={{ display: 'flex', alignItems: 'center', margin: '0 .5rem' }}>
                              <img src={GoogleClassroomImg} alt="" height={18} />
                            </div>
                          )
                        }
                        <OptionLabel>
                          <Title size="small" lineHeight="20px">
                            {item.name}
                          </Title>
                          <Description>{item.section}</Description>
                        </OptionLabel>
                      </Option>
                    ))
                  }
                </ListOption>
                <AddClassesButton onClick={openClassroomDialog}>
                  <img src={PlusImg} alt="" style={{ marginRight: 5 }} />
                  {' '}
                  {lang('assignment.create_assignment_dialog.step_1.add_classes')}
                </AddClassesButton>
              </div>
              <div style={{ padding: '0rem 3rem' }}>
                <Button
                  label={lang('assignment.create_assignment_dialog.step_1.assign_to_class')}
                  large
                  disabled={!selectedClassroom}
                  type="submit"
                />
              </div>
            </form>
          </div>
        )}

        {currentStep === 2 && (
          <div className="content-wrapper">
            {renderAssignmentImage()}
            <div
              className="assignment-content"
            >
              <Title>
                {lang('assignment.create_assignment_dialog.step_2.title')}
              </Title>
              <Description>
                {lang('assignment.create_assignment_dialog.step_2.description')}
              </Description>
              <LinkBox text={`${window.location.origin}/assignment/${newAssignmentId}${noteText ? '?notes=1' : ''}`} />
              <Button
                outline
                label={lang('assignment.create_assignment_dialog.step_2.view_assignment')}
                onClick={() => navigate(`/assignment/${newAssignmentId}`)}
                style={{ height: '3rem' }}
              />
            </div>
          </div>
        )}

        <IconButton className="close-button" onClick={handleClose}>
          <img src={DialogCloseImg} alt="close dialog" />
        </IconButton>
      </AssignmentDialogContainer>
      <CreateClassroomDialog
        hasGoogleRedirect
        fromAssignment
        isOpen={isCreateClassroomDialogOpen}
        onOpen={() => setIsCreateClassroomDialogOpen(true)}
        onClose={() => setIsCreateClassroomDialogOpen(false)}
        onCloseCallback={loadClassrooms}
      />
    </>
  );
}

export default CreateAssignmentDialog;
