import React, { useMemo, useState } from 'react';
import { Box } from '@mui/system';
import { CheckedImg } from 'src/assets/icons';
import { SignalWordsImg } from 'src/assets/images';
import { lang } from 'src/lang';
import { Description } from 'src/components/typography';
import { isBlockPage, Document } from 'src/types/models';
import { IWritingPlanModel } from 'src/Models/WritingPlans/types';
import { CardContainer, SignalWord } from '../styles';
import WritingPlanDialog from '../../dialogs/WritingPlanDialog';
import * as actions from '../../actions';

function WritingPlanCard(props: {
  expanded: boolean;
  document: Document;
  writingPlan: IWritingPlanModel | null;
}) {
  const {
    expanded, document, writingPlan,
  } = props;
  const hasWritingPlan = !!writingPlan;

  const [isWritingPlanDialogOpen, setIsWritingPlanDialogOpen] = useState(false);

  const signalWordsPresence = useMemo(() => {
    const presence: Record<string, boolean> = {};

    if (writingPlan?.signalWords.length) {
      const allBlocks = document.version.pages.filter(
        isBlockPage,
      ).flatMap(
        (page) => page.grid.blocks,
      );
      const text = allBlocks.map((b) => b.properties.plainText || '').join(' ').toLowerCase();

      writingPlan.signalWords.forEach((word) => {
        presence[word] = text.includes(word.toLowerCase());
      });
    }
    return presence;
  }, [document.version.pages, writingPlan?.signalWords]);

  const renderWordDetector = () => {
    if (hasWritingPlan) {
      return (
        <div className="signal-words-content">
          <div className="words-wrapper">
            {Object.keys(signalWordsPresence).map((word: string) => (
              <SignalWord activated={signalWordsPresence[word]} key={word}>
                {word}
                {signalWordsPresence[word] && <img src={CheckedImg} alt={`${word} used`} />}
              </SignalWord>
            ))}
          </div>
          <div className="signal-words-description" />
        </div>
      );
    }

    return <Description className="description">{lang('document.side_bar.writing_plan.description')}</Description>;
  };

  return (
    <>
      <CardContainer
        backgroundColor="#f6f6fa"
        mainTextColor="#2E2C75"
        onClick={() => !hasWritingPlan && setIsWritingPlanDialogOpen(true)}
      >
        <div className="header">
          <img
            className={`card-icon ${expanded ? 'expanded' : 'visible'}`}
            src={SignalWordsImg}
            alt="Signal Words"
          />

          <Box
            className={expanded ? 'title-open' : ''}
            onClick={() => hasWritingPlan && setIsWritingPlanDialogOpen(true)}
          >
            {lang('document.side_bar.writing_plan.title')}
            {!document.submission && (
              // The writing plan cannot be changed in the context of a submission.
              <div className="subtitle customize">{lang('document.side_bar.writing_plan.customize')}</div>
            )}
          </Box>
        </div>
        {expanded && renderWordDetector()}
      </CardContainer>
      <WritingPlanDialog
        onSubmit={() => { }}
        open={isWritingPlanDialogOpen}
        initialWritingPlan={writingPlan?.id}
        onClose={() => setIsWritingPlanDialogOpen(false)}
        onAdd={(id: string) => {
          actions.saveDocument(document.id, {
            meta: {
              ...document.meta,
              writingPlan: id,
            },
          });
          setIsWritingPlanDialogOpen(false);
        }}
      />
    </>
  );
}

export default WritingPlanCard;
