import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, styled } from '@mui/system';

import { useUserStore } from '../../zustand/user';
import AssignmentTeacher from './teacher';

import * as AssignmentAPI from '../../api/Assignments';
import { useEditor } from '../../hooks/useEditor';
import { useDocumentStore } from '../../zustand/documents';
import { Artboard, TopBar } from '../../components';
import { theme } from '../../utils';

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  width: '100vw',
  backgroundColor: theme.gradient.secondary.light,
}));

const Content = styled(Box)(() => ({
  display: 'block',
  height: 'max-content',
  width: 'fill-available',
}));

function Assignment() {
  const user = useUserStore((state) => state.user);
  const getDocumentInfo = useDocumentStore((state) => state.currentDocumentInfo);
  const submitAssignment = useDocumentStore((state) => state.submitAssignment);

  const navigate = useNavigate();
  const location = useLocation();
  const { aid } = useParams();

  const [loading, setLoading] = useState(true);
  const [submissionData, setSubmissionData] = useState<any>(null);

  const { toggleViewMode } = useEditor();

  const createSubmission = async () => {
    const response = await submitAssignment(aid!);
    if (response.status === 201) {
      navigate(`/document/${response.data.data.document_id}${location.search}`, { replace: true });
    }
  };

  const loadDocument = async (id: string) => {
    try {
      const response = await getDocumentInfo(id);

      if (!response) throw new Error('request error');

      setLoading(false);
    } catch {
      navigate('/');
    }
  };

  const loadAssignment = async () => {
    const response = await AssignmentAPI.getById(aid!);
    if (response.status === 200) {
      if (response.data.data.submission) {
        setSubmissionData(response.data.data.submission);
        if (response.data.data.submission.status !== 'turnedin') {
          navigate(`/document/${response.data.data.submission.document_id}${location.search}`, { replace: true });
        } else {
          toggleViewMode(true);
          loadDocument(response.data.data.submission.document_id);
        }
      } else {
        createSubmission();
      }
    }
  };

  const onUnsubmit = async () => {
    const response = await AssignmentAPI.unsubmit(aid!, submissionData?.id);
    if (response.status === 200) {
      navigate(`/document/${submissionData.document_id}`, { replace: true });
    }
  };

  useEffect(() => {
    if (aid && user.role === 'student') {
      loadAssignment();
    }
  }, []);

  return user.role === 'teacher' ? <AssignmentTeacher /> : (
    <Container>
      <TopBar
        showBackButton
        viewOnlyMode
        professorView={false}
        isTurnedIn
        isAssignment
        onUnsubmit={onUnsubmit}
      />
      <Content>
        <Artboard isLoading={loading} animateLoading />
      </Content>
    </Container>
  );
}

export default Assignment;
