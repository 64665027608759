import { darken, styled } from '@mui/system';
import { ButtonUnstyled } from '@mui/base';
import { theme } from '../../../utils';
import { getBorder } from './utils';

export const ToolbarContainer = styled('header')<{
  position?: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed';
}>(({ position }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '1rem',
  position: position || 'absolute',
  left: 0,
  transform: 'translateX(-50%)',

  '.group': {
    display: 'flex',
  },

  [theme.breakpoints.down('sm')]: {
    justifyContent: 'unset',
    transform: 'none',
    position: position || 'inherit',
  },
}));

export const IconTextButtonContainer = styled(ButtonUnstyled)(() => ({
  background: '#F6F6FA',
  height: '2.5rem',

  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0 .75rem',
  gap: '.5rem',

  border: '0.5px solid #A8A7CC',
  borderRadius: '.125rem',

  '&:hover': {
    background: darken('#fff', 0.05),
  },

  '&:active': {
    transform: 'scale(0.98)',
  },

  cursor: 'pointer',

  transformOrigin: 'center',

  p: {
    whiteSpace: 'nowrap',
  },
}));

export const Button = styled(ButtonUnstyled, { shouldForwardProp: (prop) => prop !== 'noBorder' && prop !== 'isActive' })(({ noBorder, isActive }: any) => ({
  height: '2.5rem',
  width: '2.5rem',

  background: '#F6F6FA',
  borderRadius: '.25rem',

  cursor: 'pointer',

  '&:hover': {
    background: darken('#F6F6FA', 0.1),
  },

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',

  border: getBorder(noBorder, isActive),
  boxShadow: isActive ? '0px 0px 8px rgba(82, 80, 154, 0.4)' : '',

  '.button-icon': {
    display: 'grid',
    placeItems: 'center',
    height: '1.5rem',
  },
}));
