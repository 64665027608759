import React from 'react';
import { Alert, Container, ThemeProvider } from '@mui/material';
import CreateLessonWizard from 'src/components/shared/CreateLessonWizard';
import { useFeatureFlag } from 'src/hooks/flags';
import { theme } from 'src/utils';

export default function CreateLessonScreen() {
  const hasWritingPrompts = useFeatureFlag('writing-prompts');

  return (
    <ThemeProvider theme={theme}>
      {hasWritingPrompts && (
        <CreateLessonWizard />
      )}
      {!hasWritingPrompts && (
        <Container sx={{ my: 12 }}>
          <Alert severity="error">
            This feature is in closed trial.
            Please contact
            {' '}
            <a href="mailto:hello@joinpressto.com">hello@joinpressto.com</a>
            {' '}
            to request access.
          </Alert>
        </Container>
      )}
    </ThemeProvider>
  );
}
