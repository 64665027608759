import { Box, styled } from '@mui/system';
import { theme } from '../../utils';

export const OptionLabel = styled(Box)(() => ({
  flex: 1,
}));

export const ListOption = styled(Box)(() => ({
  height: '250px',
  background: 'white',
  border: `1px solid ${theme.palette.secondary.light}`,
  overflowY: 'scroll',
  padding: '.25rem 0',
}));

export const Option = styled(Box)<{
  disabled?: boolean
}>(({ disabled }) => ({
  backgroundColor: 'white',
  height: '60px',
  width: '100%',
  background: 'white',
  display: 'flex',
  gap: '15px',
  padding: '1rem 2rem',
  alignItems: 'center',
  justifyContent: 'space-around',
  cursor: disabled ? 'default' : 'pointer',
  opacity: disabled ? 0.7 : 1,
}));

export const Checked: any = styled(Box)(() => ({
  height: '18px',
  width: '18px',
  borderRadius: '100%',
  top: -1.5,
  left: -1.5,
  border: `5px solid ${theme.palette.primary.main}`,
  position: 'absolute',
}));

export const MarkerComponent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<any>(({ selected, type }) => ({
  position: 'relative',
  height: '18px',
  width: '18px',
  background: 'white',
  border: `1.5px solid ${selected ? theme.palette.primary.main : theme.palette.secondary.main}`,
  borderRadius: type === 'radio' ? '100%' : '.3rem',
  boxShadow: selected && `0px 0px 10px -2px ${theme.palette.primary.main}`,
}));
