import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  useParams, useNavigate, useSearchParams, Link, useLocation,
} from 'react-router-dom';
import Grid from '@mui/material/Grid';

import { useDocumentStore } from 'src/store/document';
import TopBar from 'src/components/shared/TopBar';
import NoteButton from 'src/components/shared/note/NoteButton';
import { Box, ThemeProvider } from '@mui/material';
import { debounce, theme } from 'src/utils';
import { Artboard } from 'src/components/shared/Artboard';
import { DocumentFormatSettings, DocumentTemplateSystem } from 'src/types/DocumentSettings';
import { Button, IconButton } from 'src/components/buttons';
import { ArrowLeftImg, PencilImg, PlayImg } from 'src/assets/icons';
import { CreatableNote } from 'src/components/shared/note/types';
import { lang } from 'src/lang';
import { useUserStore } from 'src/zustand/user';
import { LogoImg, LogoSmallImg } from 'src/assets/images';
import { ReturnSubmission, TurnInSubmission, UnsubmitSubmission } from 'src/store/document/operations';
import { Document } from 'src/types/models';
import CreateAssignmentDialog from './dialogs/CreateAssignmentDialog';
import {
  DocumentContext,
  useApplyDocumentOperation, useDemoDocument, useDocument, useSelectedBlock,
} from '../../hooks/document';
import * as actions from './actions';
import PageWrapper from './PageWrapper';
import PagesList from './PagesList';
import FeedbackSideBar from './FeedbackSideBar';
import DocumentTitle from './DocumentTitle';
import ShareButton from './ShareButton';
import NotFound from '../notFound';

export default function Edit(props: {
  documentId: string;
  isPublishedView?: boolean;
}) {
  const { documentId, isPublishedView = false } = props;
  const { pageIndex } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  // TODO: Published view needs to be revisited.
  const isTryIt = documentId === 'try-it';
  let document: Document | null | undefined;
  if (isTryIt) {
    document = useDemoDocument();
  } else {
    document = useDocument(documentId || '', isPublishedView);
  }
  const applyOperation = useApplyDocumentOperation(isTryIt);
  const user = useUserStore((state) => state.user);
  const isOwner = document?.user?.id === user?.id;

  // Check that the document, when loaded, uses the Blocks system.
  // Otherwise, redirect it to the old editor.
  useEffect(() => {
    if (document && document.templateSystem !== DocumentTemplateSystem.BLOCKS) {
      navigate({
        pathname: `/${isPublishedView ? 'publish' : 'document'}-l/${documentId}`,
      }, { replace: true });
    }
  }, [document?.templateSystem]);

  // TODO: Don't access the store directly from components. Move this into
  // an operation or a hook.
  const currentOperationState = useDocumentStore(
    (state) => state.currentOperationState,
  );

  const onSaveTitle = useCallback(async (data: { name: string }) => {
    await actions.saveDocument(document!.id, data);
  }, [document?.id]);

  // Manage the Notes associated with this document.
  // They can come from the version or the assignment.
  const notes = useMemo(() => [
    ...(document?.submission?.assignment?.notes || []),
    ...(document?.version.notes || []),
  ], [document?.version.notes, document?.submission?.assignment?.notes]);

  const onSaveNote = useCallback(async (note: CreatableNote) => {
    await actions.saveNote(document!.id, note);
  }, [document?.id]);

  // Case 1: No page is specified. Use the first page, if there is one.
  // Case 2: A valid page is specified. Use that one.
  // Case 3: A page is specified, but it is out of range. Use the first page.
  const selectedPage = document?.version.pages[Number(pageIndex) - 1];
  useEffect(() => {
    if (
      document?.id
      && !selectedPage
      // Layout legacy: Prevent redirect for non Block layouts
      && document.templateSystem === DocumentTemplateSystem.BLOCKS
    ) {
      // This is an invalid page. Redirect to the last page, if there is one.
      if (document.version.pages.length) {
        navigate({
          ...location,
          pathname: `/document/${document.id}/pages/1`,
        }, { replace: true });
      } if (pageIndex !== undefined) {
        navigate({
          ...location,
          pathname: `/document/${document.id}`,
        }, { replace: true });
      }
    }
  }, [document?.id, document?.templateSystem, pageIndex, navigate, selectedPage]);

  // Clear the block selection when certain interactions outside of
  // a block occur, such as clicking on the artboard background.
  const [, selectBlock] = useSelectedBlock();
  const clearBlockSelection = useCallback(() => {
    selectBlock(null);
  }, []);

  // Set the window name.
  useEffect(() => {
    if (document?.name) {
      window.document.title = document.name;
    }
  }, [document?.name]);

  // Move this back down to the Page component, or set a default.
  // Listen for page resizes and adjust the scale of the page wrapper.
  const [pageScale, setPageScale] = useState<number>();
  const whitespace = (
    // 3 Toolbars
    3 * 64
    // 3 horizontal margins
    + 3 * 24
  );

  useEffect(() => {
    const onResize = () => {
      debounce(() => {
        if (document?.format) {
          setPageScale(
            (window.innerHeight - whitespace)
            / DocumentFormatSettings[document.format].height,
          );
        }
      }, 25);
    };
    window.addEventListener('resize', onResize);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [document?.format]);

  const [isAssignmentDialogOpen, setIsAssignmentDialogOpen] = useState(false);

  if (document === null) {
    return <NotFound />;
  }

  if (document === undefined) {
    // Loading.
    return null;
  }

  // The display mode determines whether editing controls are visible.
  // Viewing mode is generally used when the viewer of the document is
  // not the owner. However, the owner can also put the document into
  // preview mode to see it as other people would see it. There is an
  // anonymized mode for trying out the editor as well.
  let displayMode: 'editing' | 'viewing' | 'previewing' | 'reviewing';
  if (isOwner || isTryIt) {
    // If the document is owner by the current user, then it will be in edit
    // mode, with two exceptions.
    if (searchParams.get('display') === 'preview') {
      // Case 1: The user has put the document into preview mode.
      displayMode = 'previewing';
    } else if (document?.submission?.status === 'turnedin') {
      // Case 2: The user is a student has a turned in an assignment.
      // In this case, the student can no longer edit the document until
      // it is returned to them for revision.
      displayMode = 'reviewing';
    } else {
      // Otherwise, the document is in edit mode.
      displayMode = 'editing';
    }
  } else if (user.role === 'teacher' && document?.submission) {
    // The user is a teacher and teaches the class in which
    // for which this document is a submission. In which case,
    // the teacher will also be participating in the review.
    // TODO: The logic here is incomplete and too permissive.
    displayMode = 'reviewing';
  } else {
    // With no special privileges, all other users are viewing.
    // The API limits this to documents that have been published.
    // This can also happen when the document is still loading.
    displayMode = 'viewing';
  }

  // TODO: Our use of MUI is inconsistent. In App.tsx, there is a
  // ThemeProvider from 'material-ui', but it is being passed a theme
  // from 'src/utils' that is based on '@mui/material'. It would seem that
  // components imported from '@mui' ignore the theme from 'material-ui'.
  // Since I want to use '@mui' components and have a theme, I am providing
  // a theme local to this view. Within this view, two themes are currently
  // active, but components will only use the theme from their library.
  return (
    <ThemeProvider theme={theme}>
      <DocumentContext.Provider value={document}>
        <Artboard
          sx={{
            backgroundColor: (
              displayMode === 'previewing' || displayMode === 'viewing'
            ) ? '#41414E' : 'grey.A100',
          }}
        >
          <TopBar>
            <TopBar.Section>
              {/* Navigation tools */}
              {displayMode === 'editing' || displayMode === 'reviewing' ? (
              // Show a back button when editing and reviewing.
                <IconButton
                  src={ArrowLeftImg}
                  alt="←"
                  label="Go Back"
                  onClick={() => {
                    navigate('/gallery');
                  }}
                />
              ) : (
              // When viewing, show the Pressto logo.
                <>
                  <Box display={{ xs: 'none', sm: 'block' }}>
                    <Link to="/">
                      <img src={LogoImg} alt="Pressto" style={{ height: '2.25rem' }} />
                    </Link>
                  </Box>
                  <Box display={{ xs: 'block', sm: 'none' }}>
                    <Link to="/">
                      <img src={LogoSmallImg} alt="Pressto" style={{ height: '2.25rem' }} />
                    </Link>
                  </Box>
                </>
              )}
            </TopBar.Section>

            <TopBar.Section>
              {/* Document status tools */}
              <DocumentTitle
                document={document}
                state={displayMode === 'editing' && !isTryIt ? currentOperationState : undefined}
                onSubmit={onSaveTitle}
              />
            </TopBar.Section>

            <TopBar.Spacer />

            <TopBar.Section>
              {/* Assignment and review tools */}
              {(displayMode === 'reviewing' || displayMode === 'editing') && document.submission && (
              <NoteButton
                notes={notes}
                onSave={onSaveNote}
                showCreateForm={user.role === 'teacher'}
              />
              )}

              {isOwner && (
                document.submission?.status === 'turnedin'
              ) && (
              // The work has been turned in and can be unsubmitted.
              <>
                <IconButton
                  onClick={() => {
                    applyOperation(new UnsubmitSubmission(document!.id));
                  }}
                  label={lang('assignment.common.unsubmit')}
                  style={{ height: '2.5rem', paddingLeft: '0.5rem' }}
                  src=""
                  alt=""
                />
                <Button
                  disabled
                  label={lang('assignment.common.turned_in')}
                  style={{ height: '2.5rem' }}
                />
              </>
              )}

              {isOwner && (
                document.submission?.status === 'draft'
              || document.submission?.status === 'returned'
              ) && (
              // The work is assigned and can be turned in.
              <Button
                onClick={() => {
                  applyOperation(new TurnInSubmission(document!.id));
                }}
                label={lang('assignment.common.turnin')}
                style={{ height: '2.5rem' }}
              />
              )}

              {!isOwner && user.role === 'teacher' && (
                document.submission?.status === 'turnedin'
              ) && (
              // The work has been turned in and can be returned by the teacher.
              <Button
                onClick={() => {
                  applyOperation(new ReturnSubmission(document!.id));
                }}
                label={lang('assignment.common.return')}
                style={{ height: '2.5rem' }}
              />
              )}

              {!isOwner && user.role === 'teacher' && (
                document.submission?.status === 'returned'
              ) && (
              // The work has been turned in and can be returned by the teacher.
              <Button
                disabled
                label={lang('assignment.common.returned')}
                style={{ height: '2.5rem' }}
              />
              )}

              {isOwner && user.role === 'teacher' && (
              <>
                <Button
                  onClick={() => setIsAssignmentDialogOpen(true)}
                  label={lang('document.top_bar.export.assign')}
                  style={{ height: '2.5rem' }}
                />
                <CreateAssignmentDialog
                  document={document}
                  isOpen={isAssignmentDialogOpen}
                  onClose={() => setIsAssignmentDialogOpen(false)}
                  onOpen={() => setIsAssignmentDialogOpen(true)}
                />
              </>
              )}
            </TopBar.Section>

            <TopBar.Section>
              {/* Publishing tools */}
              {displayMode === 'editing' && (
              <IconButton
                src={PlayImg}
                label={lang('document.top_bar.export.view')}
                onClick={() => {
                  const newParams = new URLSearchParams(searchParams);
                  newParams.set('display', 'preview');
                  setSearchParams(newParams);
                }}
                alt=""
              />
              )}
              {displayMode === 'previewing' && (
              <IconButton
                src={PencilImg}
                label={lang('document.top_bar.export.stop')}
                onClick={() => {
                  const newParams = new URLSearchParams(searchParams);
                  newParams.delete('display');
                  setSearchParams(newParams);
                }}
                alt=""
              />
              )}

              <ShareButton
                document={document}
              />
            </TopBar.Section>
          </TopBar>
          <Grid
            container
            height="calc(100vh - 64px)"
            onMouseUp={clearBlockSelection}
            flexWrap="nowrap"
          >
            <Grid
              item
              sm="auto"
              sx={{
                display: {
                  xs: 'none',
                  sm: 'unset',
                },
              }}
            >
              {(displayMode === 'editing' || displayMode === 'reviewing') && (
              <PagesList
                documentId={document.id}
                selectedPage={selectedPage || null}
                pages={document.version.pages}
              />
              )}
            </Grid>

            <Grid item sm xs={12}>
              {selectedPage && pageScale && (
                <PageWrapper
                  page={selectedPage}
                  document={document}
                  scale={pageScale}
                  isEditable={displayMode === 'editing'}
                />
              )}
            </Grid>
            <Grid
              item
              sm="auto"
              sx={{
                display: {
                  xs: 'none',
                  md: 'unset',
                },
              }}
            >
              {(displayMode === 'editing' || displayMode === 'reviewing') && (
              <FeedbackSideBar document={document} />
              )}
            </Grid>
          </Grid>
        </Artboard>
      </DocumentContext.Provider>
    </ThemeProvider>
  );
}
