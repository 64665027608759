import { Configuration, OpenAIApi } from 'openai';

const openai = new OpenAIApi(new Configuration({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
}));

export async function suggestImportantWords(
  topic: string,
  gradeLevel: string = '4th',
  count: number = 8,
) {
  const prompt = (
    `Provide ${count} key words a ${gradeLevel}-grade student could `
    + `use in an essay about '${topic}', as a comma-separated list:`
  );

  const { data } = await openai.createCompletion({
    model: 'text-davinci-003',
    prompt,
    temperature: 0.5,
    max_tokens: 50, // Includes the length of the prompt.
  });

  const words = data.choices[0].text?.split(',').map((word) => word.trim().replace(/\W+$/, '')) || [];
  return words;
}

export async function suggestWritingPrompt(
  topic: string,
  gradeLevel: string = '4th',
  skills: string[] = [],
) {
  let prompt = (
    `Provide an interesting writing prompt for a ${gradeLevel}-grade student `
    + `to write on the subject of '${topic}'. The prompt should be written `
    + `at a ${gradeLevel}-grade reading level so as to be understandable to `
    + 'the student.'
  );
  if (skills.length > 0) {
    prompt += (
      ' The writing prompt should subtly encourage development '
      + `of the following skill${skills.length !== 1 ? 's' : ''} : ${skills.join(', ')}. `
      + 'Do not state the name of the skills explicitly.'
    );
  }

  const { data } = await openai.createCompletion({
    model: 'text-davinci-003',
    prompt: prompt.trim(),
    temperature: 0.8,
    n: 3,
    frequency_penalty: 1.0,
    presence_penalty: 1.0,
    max_tokens: 300, // Includes the length of the prompt.
  });

  return data.choices.map((choice) => choice.text?.trim()).filter(Boolean) as string[];
}
