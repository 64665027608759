import React, { useEffect } from 'react';
import {
  useSearchParams, useParams, useNavigate,
} from 'react-router-dom';
import * as UserAPI from '../../api/User';
import { useUserStore } from '../../zustand/user';

function GoogleRedirect() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const params = useParams();

  const setUser = useUserStore((state) => state.setUser);
  const setUserError = useUserStore((state) => state.setUserError);

  const handleCredentialResponse = async () => {
    const data: any = {
      code: searchParams.get('code') || '',
    };

    try {
      if (localStorage.getItem('registerFlow') === 'signUp') {
        data.join_code = localStorage.getItem('joinCode') || undefined;

        const response: any = await UserAPI.googleSignUp(data, ['teacher'].includes(params.type!));
        if (response.status !== 200 && response.status !== 201) throw response.data;
        setUser(response.data.data);
        return;
      }

      const response: any = await UserAPI.googleSignIn({ code: data.code });
      if (response.status !== 200 && response.status !== 201) throw response.data;
      setUser(response.data.data);
    } catch (error: any) {
      setUserError({ error: true, message: error?.response?.data?.message || '' });
      if (localStorage.getItem('registerFlow') === 'signUp') navigate(`/register-${params.type}`);
      else navigate('/');
    }
  };

  useEffect(() => {
    handleCredentialResponse();

    return () => {
      localStorage.removeItem('registerFlow');
      localStorage.removeItem('joinCode');
    };
  }, []);

  return (
    <div />
  );
}

export default GoogleRedirect;
