import React from 'react';

import { Dialog, DialogContent, DialogProps } from '@mui/material';
import { IconButton } from '@material-ui/core';
import { WritingPlanSelectionDialogProps } from 'src/components/dialogs/CreatePresstoDialog/types';
import WritingPlanSelectionDialog from 'src/components/dialogs/CreatePresstoDialog/WritingPlanSelectionDialog';
import { DialogCloseImg } from 'src/assets/icons';

export default function WritingPlanDialog(
  props: (DialogProps & WritingPlanSelectionDialogProps & {
    onClose: (...args: any[]) => void;
  }),
) {
  const {
    open, onAdd, onClose, initialWritingPlan,
  } = props;

  return (
    <Dialog
      open={open}
      PaperProps={{ elevation: 5 }}
      onClose={onClose}
      sx={{
        '.close-button': {
          position: 'absolute',
          top: '.25rem',
          right: '.25rem',
        },
      }}
    >
      <DialogContent
        sx={{
          minHeight: '80vh',
          maxWidth: {
            md: '60vw',
          },
        }}
      >
        <WritingPlanSelectionDialog
          onAdd={onAdd}
          initialWritingPlan={initialWritingPlan}
        />
      </DialogContent>

      <IconButton className="close-button" onClick={onClose}>
        <img src={DialogCloseImg} alt="close dialog" />
      </IconButton>
    </Dialog>
  );
}
