import { Box, styled } from '@mui/system';

export const ButtonContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'color' })(
  ({ color }: { color: string }) => ({
    cursor: 'pointer',
    border: `2px solid ${color}`,

    '.info-container': {
      height: '100%',
      width: '100%',
      padding: '2rem 1.25rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',

      p: {
        color: '#000000',
        fontFamily: 'Inter !important',

        fontSize: '1.125rem',
      },

      '.classroom-type': {
        display: 'flex',
        gap: '.5rem',
        color: '#999',
        fontSize: '.75rem',
      },

      '.classroom-info': {
        display: 'flex',
        flexDirection: 'column',
        gap: '.5rem',

        p: {
          color: '#999',
          fontSize: '.75rem',
        },
      },
    },

    '.pressto-button-actions': {
      position: 'absolute',
      top: '.125rem',
      right: '.125rem',
      display: 'grid',
      cursor: 'pointer',
    },
  }),
);
