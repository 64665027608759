import { lighten } from '@mui/material';
import { Box, styled } from '@mui/system';

export const Wrapper = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  padding: `${theme.spacing(3)} 0`,

  borderBottom: `1px solid ${theme.palette.divider}`,

  '&:last-child': {
    borderBottom: 'none',
  },

  'p + p': {
    marginTop: theme.spacing(1),
  },

  blockquote: {
    p: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(2),
      backgroundColor: lighten(theme.palette.primary.light, 0.9),
    },

    'p + p': {
      marginTop: theme.spacing(3),
    },
  },
}));

export const ReactionChip = styled('span')(({ theme }) => ({
  display: 'inline-block',
  backgroundColor: theme.palette.grey.A100,
  fontSize: '1.3rem',
  width: '2em',
  height: '2em',
  borderRadius: '100%',
  textAlign: 'center',
  lineHeight: '2em',
  cursor: 'pointer',
  borderStyle: 'solid',
  borderWidth: '2px',
  borderColor: 'transparent',

  // Do some specific things to force the emoji to render as such.
  // This is supported in Firefox.
  fontVariantEmoji: 'emoji',

  // Override the default font family. This works on Macs, at least.
  // Our font, Inter, does not seem to honor the emoji variant selector.
  fontFamily: '--apple-system, emoji, sans-serif',

  '&:hover': {
    borderColor: theme.palette.primary.main,
  },
}));
