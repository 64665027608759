import { Box, styled } from '@mui/system';
import React from 'react';
import { FontDecreaseImg, FontIncreaseImg, FontSizeImg } from 'src/assets/icons';
import { BlockProperties } from 'src/types/models';

const FontSizeContainer = styled(Box)(() => ({
  display: 'flex',

  boxSizing: 'border-box',

  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  width: '5rem',
  height: '2.5rem',

  backgroundColor: '#FFF',

  border: '0.5px solid #A8A7CC',

  div: {
    display: 'grid',
    placeItems: 'center',
  },

  'div:first-of-type, div:last-of-type': {
    cursor: 'pointer',
    padding: '0 .5rem',
  },

}));

function FontSizeController(
  { currentFontSize, handleFontSizeChange }: {
    currentFontSize: string,
    handleFontSizeChange: (newFont: BlockProperties['fontSize']) => void
  },
) {
  // Split the number and unit. For example, 20px -> [20, px]
  const [, value, unit] = currentFontSize?.match(/([.\d]+)(\D+)/) ?? ['', '1', 'em'];
  const size = Number(value);

  const handleIncrease = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (size >= 6) return;

    handleFontSizeChange(`${size + 0.5}${unit}` as BlockProperties['fontSize']);
  };

  const handleDecrease = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (size <= 0.5) return;

    handleFontSizeChange(`${size - 0.5}${unit}` as BlockProperties['fontSize']);
  };

  const preventDefault = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <FontSizeContainer onMouseDown={preventDefault}>
      <Box onClick={handleDecrease}><img src={FontDecreaseImg} alt="decrease size" /></Box>
      <Box><img src={FontSizeImg} alt="font size controller" /></Box>
      <Box onClick={handleIncrease}><img src={FontIncreaseImg} alt="increase size" /></Box>
    </FontSizeContainer>
  );
}

export default FontSizeController;
