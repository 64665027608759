import { darken, styled } from '@mui/system';
import { theme } from '../../../utils';
import { DialogContainer, DialogContent } from '../styles';

export const AssignmentDialogContent = styled(DialogContent)(() => ({
  '&.first-step': {
    'input, textarea': {
      fontSize: '1rem',
    },

    padding: '1rem 4.875rem 2rem 4.875rem',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    div: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
    },
  },

  '&.classroom-step': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '0 4.875rem',
    paddingBottom: '2rem',
  },

  '.classroom-grid': {
    height: '100%',
    width: '100%',
    display: 'grid',
    placeItems: 'center',
    justifyContent: 'center',
    gridTemplateColumns: 'repeat(3, 7.5rem)',
    gridAutoRows: '7.5rem',
    gap: '1.25rem',

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 7.5rem)',
    },
  },

  '.selected-classroom-container': {
    display: 'flex',
    alignItems: 'center',
    marginTop: '2.25rem',
    gap: '2.5rem',

    'label > span': {
      fontSize: '.75rem',
    },
  },

  '.classroom-item': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    background: '#FFFFFF',
    border: '1px solid #000000',
    borderRadius: '.25rem',
    padding: '1rem',
    height: '7.5rem',
    width: '7.5rem',

    '&:hover': {
      cursor: 'pointer',
      background: darken('#F6F6F6', 0.1),
    },

    p: {
      color: '#000',
      fontSize: '0.875rem',
    },

    'p:first-of-type': {
      opacity: 0.5,
    },
  },
}));

export const AssignmentDialogContainer = styled(DialogContainer)(() => ({
  '& > div > div': {
    height: '80%',
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },

    maxHeight: 'unset',
    maxWidth: 'unset',
    justifyContent: 'center',

    '.content-wrapper': {
      display: 'flex',
      height: '700px',
      overflow: 'hidden auto',

      [theme.breakpoints.up('md')]: {
        padding: '10rem 5rem',
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        padding: '0rem 2rem 0rem 2rem',
        justifyContent: 'center',
      },

      '.assignment-image': {
        position: 'relative',
        border: '1px solid #A8A7CC',
        alignSelf: 'center',

        [theme.breakpoints.up('md')]: {
          width: '300px',
          marginLeft: '0px',
          marginRight: '40px',
          height: '350px',
        },
        [theme.breakpoints.down('md')]: {
          height: '300px',
          width: '300px',
          marginLeft: '20px',
          marginRight: '20px',
        },

        '.thumbnail-container': {
          height: '50%',
          paddingLeft: '30px',
          paddingRight: '30px',
          position: 'relative',
          backgroundColor: 'gray',
          paddingTop: '1rem',
          display: 'flex',
          justifyContent: 'center',

          img: {
            height: '100%',
          },
        },
        '.card-content': {
          background: '#F6F6FA',
          position: 'relative',
          width: '100%',
          height: '50%',
          padding: '1.4rem',

          '.card-title': {
            fontWeight: 700,
            marginTop: '1rem',
            maxWidth: '150px',
            maxHeight: '80px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
          },

          '.card-badge': {
            backgroundColor: '#FDAD4E44',
            width: 'fit-content',
            padding: '.5rem',
            color: '#FB8800',
            fontWeight: 700,
          },
        },
      },

      '.assignment-content': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
          width: '65%',
        },
        [theme.breakpoints.down('md')]: {
          marginTop: '2rem',
        },
      },

      '.classroom-list': {
        position: 'relative',
        height: '200px',
        marginBottom: '0.5rem',
      },

    },
  },
}));
