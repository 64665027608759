import { styled } from '@mui/system';
import { theme } from 'src/utils';
import { DialogContainer, DialogContent, DialogTitle } from 'src/components/dialogs/styles';

export const PublishDialogContainer = styled(DialogContainer)(() => ({
  '& > div > div': {
    height: 'unset',
    width: 'unset',
    maxHeight: 'unset',

    '.close-button': {
      top: '.25rem',
      right: '.25rem',
    },

    padding: '1.75rem 2.5rem 2.5rem 3.75rem',

    [theme.breakpoints.down('sm')]: {
      padding: '6.125rem 1.25rem 0 1.25rem',

    },
  },

}));

export const PublishDialogTitle = styled(DialogTitle, {
  shouldForwardProp: (prop) => prop !== 'published',
})(({ published }: { published: boolean }) => ({
  marginTop: `${published ? '7rem' : '8.75rem'} !important`,
  padding: '1rem 0 !important',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',

  '& > *': { width: '100%' },

  p: {
    color: '#52509A !important',
    fontSize: '3rem',
    textAlign: 'left !important',
  },

  [theme.breakpoints.down('sm')]: {
    marginTop: '0 !important',

    p: {
      fontSize: '1.875rem',
    },
  },
}));

export const PublishDialogContent = styled(DialogContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '1.5rem 0 !important',

  '.content-wrapper': {
    display: 'grid',
    flexDirection: 'column',

    gridGap: '1.5rem',

    '.checkbox-label': {
      fontSize: '.875rem',
      fontWeight: 700,
      fontFamily: 'Inter',
    },

    '& > p': {
      fontSize: '.875rem',

      '&:first-of-type': {
        color: '#000',
      },
    },

    '.subtitle': {
      fontSize: '1.125rem',
      color: '#52509A !important',
    },

    '.long-description': {
      fontSize: '.875rem',
    },

    '.copied-message': {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '-1.125rem',
      p: {
        textAlign: 'center',
      },
    },

    '& > .content': {
      display: 'grid',
      flexDirection: 'column',
      gridGap: '.75rem',
    },

    '.link-box': {
      display: 'flex',
      boxShadow: '0px 0px 50px #52509a33',
      borderRadius: '.25rem',
      background: '#F6F6FA',
      border: '1px solid #A8A7CC',
      padding: '.5rem',
      height: '4rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',

      p: {
        textAlign: 'center',
        width: 'fill-available',
        fontFamily: 'Inter',
        color: '#52509A',
        fontWeight: 700,
        fontSize: '.875rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        margin: 'auto',
      },
    },
  },

  '.dialog-actions': {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-between',

    button: {
      height: '3rem',
    },

    '.red-button': {
      background: '#FF5252',
    },
  },

  [theme.breakpoints.down('sm')]: {
    '.dialog-actions': {
      flexDirection: 'column',
      justifyContent: 'center',
      gap: '.25rem',
      marginTop: '1.5rem',
    },
  },
}));
