import { ImageBoxType, TextBoxType } from '../../types/LayoutEditor';

export default [
  {
    key: 'imagebox1',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 0,
    y: 0,
    height: 1638,
    width: 1263,
  } as ImageBoxType,
  {
    key: 'imagebox2',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 1287,
    y: 0,
    height: 1638,
    width: 1263,
  } as ImageBoxType,
  {
    key: 'imagebox3',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 0,
    y: 1662,
    height: 1638,
    width: 1263,
  } as ImageBoxType,
  {
    key: 'imagebox4',
    type: 'ImageBox',
    value: '',
    originalImage: null,
    cropArea: null,
    rotation: 0,
    x: 1287,
    y: 1662,
    height: 1638,
    width: 1263,
  } as ImageBoxType,
  {
    key: 'textbox1',
    type: 'TextBox',
    defaultFont: 'TITLE100',
    defaultTextAlign: 'ALIGN_CENTER',
    value: {
      blocks: [],
      entityMap: {},
    },
    x: 448,
    y: 1267,
    height: 770,
    width: 1654,
  } as TextBoxType,
];
