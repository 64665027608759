import * as UUID from 'uuid';
import { Document, BlockPage } from 'src/types/models';
import { DocumentFormatSettings } from 'src/types/DocumentSettings';
import SynchronousDocumentOperation from './synchronous';

export default class AddPageOperation extends SynchronousDocumentOperation {
  readonly type = 'add-page';

  // eslint-disable-next-line class-methods-use-this
  apply(document: Document): Document {
    const { version } = document;

    const newPage: BlockPage = {
      id: UUID.v1(),
      meta: '{}',
      position: version.pages.length,
      documentId: document.id,
      grid: {
        rows: DocumentFormatSettings[document.format].grid.y,
        columns: DocumentFormatSettings[document.format].grid.x,
        blocks: [],
      },
    };

    return {
      ...document,
      version: {
        ...version,
        pages: [...version.pages, newPage],
      },
    };
  }
}
