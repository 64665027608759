import React from 'react';
import { WordCountImg } from 'src/assets/images';
import { lang } from 'src/lang';
import { isBlockPage } from 'src/types/models';
import { CardContainer } from '../styles';
import { CardProps } from '../types';

function wordCount(text: string) {
  return text.trim().split(/\s+/).filter((s) => s).length;
}

function WordCountCard({
  document,
  block,
  expanded,
}: CardProps) {
  const allBlocks = document.version.pages.filter(
    isBlockPage,
  ).flatMap(
    (page) => page.grid.blocks,
  );

  const documentWordCount = allBlocks.reduce((count, b) => (
    wordCount(b.properties?.plainText || '') + count
  ), 0);

  const blockWordCount = wordCount(block?.properties?.plainText?.toString() || '');

  return (
    <CardContainer
      backgroundColor="#F4FAF9"
      mainTextColor="#35645E"
    >
      <div className="header">
        <img
          className={`card-icon ${expanded ? 'expanded' : 'visible'}`}
          src={WordCountImg}
          alt="Words Written"
        />
        <div className={expanded ? 'title-open' : ''}>
          {lang('document.side_bar.word_count.title')}
        </div>
      </div>
      {expanded && (
        <div className="word-count-content">
          <div>
            <div className="content-title">Block</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className={`content-value ${blockWordCount > 0 && 'colored-text'}`}>
                {block ? blockWordCount : '-'}
              </span>
              {lang('document.side_bar.word_count.words', { count: blockWordCount })}
            </div>
          </div>
          <div>
            <div className="content-title">Document</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className={`content-value ${documentWordCount > 0 && 'colored-text'}`}>
                {documentWordCount}
              </span>
              {lang('document.side_bar.word_count.words', { count: documentWordCount })}
            </div>
          </div>
        </div>
      )}
    </CardContainer>
  );
}

export default WordCountCard;
