import React, { useCallback } from 'react';
import { Toolbar } from '@mui/material';
import { defaultStyleMap } from 'src/views/document-v2/Block/Features/Text';
import { useApplyDocumentOperation, useBlockEditorState, useSelectedBlock } from 'src/hooks/document';
import { BlockProperties, getBlockType } from 'src/types/models';
import { SetBlockOperation } from 'src/store/document/operations';
import TextBoxToolbar from './Block/Toolbar/TextBoxToolbar';
import ImageBoxToolbar from './Block/Toolbar/ImageBoxToolbar';

/**
 * Show this toolbar at the top of the artboard when a block is selected.
 */
export default function BlockToolbar() {
  const handleBackgroundColorChange = () => {};
  const selectedBlock = useSelectedBlock()[0];
  const blockType = selectedBlock && getBlockType(selectedBlock);

  const stopPropogation = useCallback((event: React.SyntheticEvent) => {
    // This prevents the block selection from being cleared when this is clicked on.
    event.stopPropagation();
  }, []);

  const [editorState, setEditorState] = useBlockEditorState(selectedBlock?.id);

  const applyOperation = useApplyDocumentOperation();
  const onPropertiesChange = useCallback((properties: BlockProperties) => {
    applyOperation(new SetBlockOperation(selectedBlock!.documentId, {
      id: selectedBlock!.id,
      properties: {
        ...selectedBlock!.properties,
        ...properties,
      },
    }));
  }, [selectedBlock]);

  return (
    <Toolbar
      onMouseUp={stopPropogation}
      onKeyUp={stopPropogation}
      sx={{
        justifyContent: 'center',
        columnGap: 4,
        rowGap: 1,
        flexWrap: 'wrap',
      }}
    >
      {selectedBlock && blockType?.configuration.hasText && editorState && (
        <TextBoxToolbar
          block={selectedBlock}
          editorState={editorState}
          setEditorState={setEditorState}
          isFocused
          handleBackgroundColorChange={handleBackgroundColorChange}
          handleContainerFontChange={(fontFamily) => {
            onPropertiesChange({
              fontFamily,
            });
          }}
          handleContainerFontSizeChange={(fontSize) => {
            onPropertiesChange({
              fontSize,
            });
          }}
          styleMap={defaultStyleMap}
        />
      )}
      {selectedBlock && blockType?.configuration.hasImage && (
        <ImageBoxToolbar
          selectedBlock={selectedBlock}
          handleUploadImage={(imageUrl) => onPropertiesChange({ imageUrl })}
        />
      )}
    </Toolbar>
  );
}
