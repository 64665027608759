import React, { useCallback, useRef, useState } from 'react';
import {
  Box, Divider, Paper, useTheme,
} from '@mui/material';
import {
  useApplyDocumentOperation, useDocument, useHoveredBlock, useSelectedBlock,
} from 'src/hooks/document';
import { DeleteBlockOperation, SetBlockOperation } from 'src/store/document/operations';
import * as models from 'src/types/models';
import { BlockProperties, BlockTypesEnum, getBlockType } from 'src/types/models';
import {
  SmallBin as SmallBinIcon, Cycle as CycleIcon, MoveArrow,
} from 'src/assets/icons';
import WritingPlans from 'src/Models/WritingPlans';
import SelectBlockTypeDialog from 'src/components/dialogs/SelectBlockTypeDialog';
import Text from './Features/Text';
import Image from './Features/Image';
import IconButton from './Toolbar/ToolbarComponents/IconButton';

export default function Block({
  block,
  blockType,
  isEditable = false,
  neighbors,
}: {
  block: models.Block,
  blockType: models.BlockType,
  isEditable?: boolean,
  neighbors?: {
    above: models.Block | null,
    below: models.Block | null,
  }
}) {
  // TODO: I need the primary color from the theme, but the sx.outlineColor
  // property does not seem to pick up on it.
  const theme = useTheme();

  const document = useDocument(block.documentId);
  const applyOperation = useApplyDocumentOperation();
  const [selectedBlock, setSelectedBlockId] = useSelectedBlock();
  const [hoveredBlock, setHoveredBlockId] = useHoveredBlock();
  const [isChangeTypeDialogOpen, setIsChangeTypeDialogOpen] = useState(false);

  // An active block may trigger additional block funcionality,
  // this would be a click on the already selected block (AKA second click).
  // Main use (only current one) is to focus on text where ever you click on the block.
  const [isActive, setIsActive] = useState(false);

  // TODO: We should be able to do a reference equality check here.
  // But it's not working. This indicates the store is getting out of sync
  // with the block object. Investigate.
  const isSelected = block.id === selectedBlock?.id;

  const updateProperties = useCallback((properties: BlockProperties) => {
    applyOperation(new SetBlockOperation(block.documentId, {
      id: block.id,
      properties: {
        ...block.properties,
        ...properties,
      },
    }));
  }, [block.id, block.properties]);

  // Any time an element within this block is focused, use that as a
  // signal to mark the block as selected in the store. This will
  // automatically clear any other block selection.
  const captureFocus = useCallback((event: React.SyntheticEvent) => {
    if (!isEditable) {
      return;
    }
    if (!event.isDefaultPrevented()) {
      // If already selected block we set isActive to trigger additional block funcionality.
      if (isSelected) {
        setIsActive(true);
      } else {
        setSelectedBlockId(block.id);
        setIsActive(false);
      }
    }
    event.stopPropagation();
  }, [block, isEditable, isSelected]);

  // Manage the block's type.
  let allWritingPlans = WritingPlans;
  if (document?.meta.writingPlan) {
    allWritingPlans = allWritingPlans.filter((plan) => plan.id === document.meta.writingPlan);
  }
  const onChangeBlockType = useCallback((newBlockTypeId: BlockTypesEnum) => {
    // Reset any properties that still have their default values.
    const changedProperties: BlockProperties = {};
    const newBlockType = getBlockType(newBlockTypeId);
    Object.entries(blockType.defaultProperties).forEach(([key, defaultValue]) => {
      const property = key as keyof BlockProperties;
      if (block.properties[property] !== defaultValue) {
        changedProperties[property] = block.properties[property] as any;
      }
    });

    applyOperation(new SetBlockOperation(block.documentId, {
      id: block.id,
      typeId: newBlockTypeId,
      properties: {
        ...newBlockType.defaultProperties,
        ...changedProperties,
      },
    }));
  }, [block, blockType]);

  const onDeleteBlock = useCallback(() => {
    setSelectedBlockId(null);
    setHoveredBlockId(null);
    applyOperation(new DeleteBlockOperation(block.documentId, block.id));
  }, [block]);

  const onImageChange = useCallback((
    data: Pick<models.BlockProperties, 'imageUrl' | 'rotation' | 'cropX' | 'cropY' | 'cropWidth' | 'cropHeight'>,
  ) => {
    updateProperties(data);
  }, [block]);

  const onTextChange = useCallback((
    data: Pick<models.BlockProperties, 'plainText' | 'htmlText' | 'rawEditorContent'>,
  ) => {
    // Operations might trigger API calls and other heavy side effect.
    // The Text component might signal changes more often than the text
    // materially changes. Check if the text has actually changed.
    // Use the HTML representation as the definitive representation here.
    if (data.htmlText !== block.properties.htmlText) {
      updateProperties(data);
    }
  }, [block]);

  // Use this to keep track of the size of the content area and
  // response to changes.
  const contentRef = useRef<HTMLDivElement>(null);

  const isOutlined = isEditable && (
    hoveredBlock ? hoveredBlock === block : isSelected
  );

  return (
    <Box
      ref={contentRef}
      sx={[
        {
          height: '100%',
          position: 'relative',
          whiteSpace: 'break-spaces',

          '.public-DraftStyleDefault-block': {
            margin: 0,
          },
        },
        isOutlined && {
          outlineWidth: '2px',
          outlineStyle: 'solid',
          outlineColor: theme.palette.primary.main,

          // Put a label in the upper-left corner.
          '&::before': {
            content: `"${blockType?.title}"`,
            position: 'absolute',
            fontSize: '10px',
            fontFamily: 'Inter, sans-serif',
            px: '10px',
            height: '16px',
            lineHeight: '16px',
            top: '-16px',
            left: '-2px',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
        },
      ]}
      onMouseEnter={() => setHoveredBlockId(block.id)}
      onMouseLeave={() => setHoveredBlockId(null)}
      onFocus={captureFocus}
      onMouseUp={captureFocus}
      // TODO: Bluntly stopping propogation of clicks and keypresses feels like
      // it could have some unintended consequences. The goal is to
      // deselect the block when the user clicks anywhere else on the
      // artboard.
      onKeyDown={(event) => {
        // Avoid keyboard events within the block from triggering page-level
        // keyboard shortcuts.
        event.stopPropagation();
      }}
    >
      {isSelected && (
        <Paper
          sx={{
            position: 'absolute',
            right: {
              xs: '-60px',
              lg: '-150px',
            },
            display: 'flex',
            flexDirection: {
              xs: 'column',
              lg: 'row',
            },
          }}
          elevation={2}
        >
          <IconButton
            src={MoveArrow}
            noBorder
            isSmall
            alt="Move"
            onClick={() => {
              // Am I at the top?
              if (block.y === 1 && block.height === 1) {
                // Do I have space underneath me?
                if (!neighbors?.below) {
                  // I can grow down.
                  applyOperation(new SetBlockOperation(block.documentId, {
                    id: block.id,
                    height: block.height + 1,
                  }));
                } else {
                  // Swap positions with the block below me.
                  applyOperation(new SetBlockOperation(block.documentId, {
                    id: block.id,
                    y: block.y + 1,
                  }));
                  applyOperation(new SetBlockOperation(block.documentId, {
                    id: neighbors.below.id,
                    y: neighbors.below.y - 1,
                  }));
                }
              } else if (block.y === 2 && block.height === 1) {
                // I'm at the bottom.
                // Is there space above me?
                if (!neighbors?.above) {
                  // I can grow up.
                  applyOperation(new SetBlockOperation(block.documentId, {
                    id: block.id,
                    height: block.height + 1,
                    y: block.y - 1,
                  }));
                } else {
                  // Swap positions with the block above me.
                  applyOperation(new SetBlockOperation(block.documentId, {
                    id: block.id,
                    y: block.y - 1,
                  }));
                  applyOperation(new SetBlockOperation(block.documentId, {
                    id: neighbors.above.id,
                    y: neighbors.above.y + 1,
                  }));
                }
              } else if (block.y === 1 && block.height === 2) {
                // I'm big. I can shrink.
                applyOperation(new SetBlockOperation(block.documentId, {
                  id: block.id,
                  height: block.height - 1,
                }));
              }
            }}
          />
          <Divider orientation="vertical" flexItem sx={{ borderColor: 'primary.light' }} />
          <IconButton
            src={CycleIcon}
            noBorder
            isSmall
            alt="Change Block Type"
            onClick={() => {
              setIsChangeTypeDialogOpen(true);
            }}
          />
          <Divider orientation="vertical" flexItem sx={{ borderColor: 'primary.light' }} />
          <IconButton
            src={SmallBinIcon}
            noBorder
            isSmall
            alt="Delete"
            onClick={onDeleteBlock}
          />
        </Paper>
      )}

      {blockType.configuration.hasImage && (
        <Image
          isEditable={isEditable}
          block={block}
          blockType={blockType}
          onChange={onImageChange}
          showPlaceholder={isEditable && !blockType.configuration.hasText}
        />
      )}
      {blockType.configuration.hasText && (
        <Text
          block={block}
          blockType={blockType}
          onChange={onTextChange}
          isEditable={isEditable}
          autoFocus={isActive}
        />
      )}

      <SelectBlockTypeDialog
        isOpen={isChangeTypeDialogOpen}
        onClose={() => setIsChangeTypeDialogOpen(false)}
        onSelect={onChangeBlockType}
        availablePlans={allWritingPlans}
      />

    </Box>
  );
}
