import { IconButton } from '@material-ui/core';
import React, {
  SyntheticEvent,
  useMemo,
  useRef,
  useState,
} from 'react';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';

import { DocumentFormat, DocumentTemplateSystem } from 'src/types/DocumentSettings';
import { Box } from '@mui/material';
import { OptionsMenuImg } from '../../assets/icons';
import PublishDialog from '../dialogs/PublishDialog';
import { Description } from '../typography';
import ContextMenu from '../ContextMenu';
import { ContextMenuItemProps, ContextMenuRef } from '../ContextMenu/types';
import DeleteConfirmationDialog from '../dialogs/DeleteConfirmationDialog';
import { ButtonContainer } from './styles';
import { PresstoButtonProps } from './types';
import * as DocumentsAPI from '../../api/Documents';
import { useDocumentStore } from '../../zustand/documents';
import { lang } from '../../lang';
import StatusTag from '../StatusTag';
import getPlaceholderDocumentByFormat from '../../utils/getPlaceholderDocumentByFormat';
import { getPresstoCardPredominantColor } from '../../utils';
import Page from '../Page';

function PresstoButton({ pressto, viewOnly, teacher }: PresstoButtonProps) {
  const contextMenuRef = useRef<ContextMenuRef>(null);
  const loadDocuments = useDocumentStore((state) => state.loadDocuments);
  const sortedBy = useDocumentStore((state) => state.sortDocuments);

  const [isPublishDialogOpen, setIsPublishDialogOpen] = useState(false);
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false);

  const [publishDialogDocumentId, setPublishDialogDocumentId] = useState('');

  const {
    id,
    name,
    version,
    updated_at: updatedAt,
    thumbnail_url: thumbnailUrl,
    template_system: templateSystem,
    submission,
    format,
  } = pressto;

  const predominantColor = useMemo(
    () => getPresstoCardPredominantColor(version),
    [version],
  );

  const navigate = useNavigate();
  const location = useLocation();

  const handleDropdownClick = (e: any) => {
    if (viewOnly) return;
    e.stopPropagation();

    contextMenuRef.current?.openMenu(e);
  };

  const handleDialogMenu = () => {
    setPublishDialogDocumentId(id);
    setIsPublishDialogOpen(true);
  };

  const duplicate = async () => {
    const response = await DocumentsAPI.duplicateDocument(id);
    if (response.status === 201) loadDocuments({ order: sortedBy });
  };

  const menuItems = useMemo<ContextMenuItemProps[]>(() => {
    const items = [];

    // Layout legacy: Disable publish and duplicate buttons
    if (templateSystem !== DocumentTemplateSystem.LAYOUT) {
      items.push({
        label: lang('gallery.pressto_button.share_link'),
        icon: 'SHARE',
        onClick: () => handleDialogMenu(),
      });

      items.push({
        label: lang('general.duplicate'),
        icon: 'DUPLICATE',
        onClick: () => duplicate(),
      });
    }

    items.push({
      label: lang('general.delete'),
      icon: 'BIN',
      onClick: () => setIsDeleteConfirmationDialogOpen(true),
      style: { color: '#FF5252 !important' },
    });

    return items;
  }, [handleDialogMenu]);

  const deleteDocument = async () => {
    const response = await DocumentsAPI.deleteDocument(id);
    if (response.status === 200) loadDocuments({ order: sortedBy });
  };

  const handleOnError = (e: SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.onerror = null;
    e.currentTarget.src = getPlaceholderDocumentByFormat(format as string);
  };

  return (
    <>
      <ContextMenu menuItems={menuItems} ref={contextMenuRef} disabled={viewOnly}>
        <ButtonContainer
          className="pressto-button"
          onClick={() => {
            if (teacher) {
              // Layout legacy: Navigate to versioned templateSystem URL
              navigate(`/${templateSystem === DocumentTemplateSystem.LAYOUT ? 'publish-l' : 'publish'}/${id}`, {
                state: { showFeedback: true, prev: location.pathname },
              });
            } else {
              // Layout legacy: Navigate to versioned templateSystem URL
              navigate(`/${templateSystem === DocumentTemplateSystem.LAYOUT ? 'document-l' : 'document'}/${id}`);
            }
          }}
        >
          <div
            className="image-container"
            style={{ backgroundColor: predominantColor }}
          >
            {templateSystem === DocumentTemplateSystem.BLOCKS ? (
              // Be extra-guarded here because so many Presstos are displayed
              // at once. Better to fail silently than to crash the gallery.
              pressto.version?.content?.pages?.length && (
                <Box
                  sx={{
                    width: '100%',
                  }}
                >
                  <Page
                    format={pressto.format as DocumentFormat}
                    page={pressto.version.content.pages[0]}
                    scale={0.15}
                    isEditable={false}
                  />
                </Box>
              )
            ) : (
              <img
                onError={handleOnError}
                src={thumbnailUrl || ''}
                alt={name}
              />
            )}
          </div>

          <div className="info-container">
            <div className="tags-list">
              <StatusTag status={submission?.status || version.status || ''} />
            </div>
            <Description>{name}</Description>
            <div className="secondary-info">
              <Description size="small">{dayjs(updatedAt).fromNow()}</Description>
            </div>
          </div>
          {!viewOnly && (
            <IconButton className="pressto-button-actions" onClick={handleDropdownClick}>
              <img src={OptionsMenuImg} alt="options menu" />
            </IconButton>
          )}
        </ButtonContainer>
      </ContextMenu>
      <DeleteConfirmationDialog
        title={lang('gallery.pressto_button.delete_pressto')}
        onSubmit={() => deleteDocument()}
        isOpen={isDeleteConfirmationDialogOpen}
        onClose={() => setIsDeleteConfirmationDialogOpen(false)}
      />
      <PublishDialog
        currentDocumentId={publishDialogDocumentId}
        isOpen={isPublishDialogOpen}
        onClose={() => {
          setIsPublishDialogOpen(false);
          setPublishDialogDocumentId('');
        }}
      />
    </>
  );
}

PresstoButton.defaultProps = {
  viewOnly: false,
  teacher: false,
};

export default PresstoButton;
