import React, { useMemo, useState } from 'react';
import { IconButton, Tab, Tabs } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Theme, useMediaQuery } from '@material-ui/core';
import { Button } from '../buttons';
import HeaderContainer from '../HeaderContainer';
import { TopBarContainer, TopBarWrapper } from './styles';
import AvatarMenu from '../AvatarMenu';
import { GalleryTobBarProps } from './types';
import { LogoImg, LogoSmallImg } from '../../assets/images';
import { LargeBackArrowImg } from '../../assets/icons';
import CreatePresstoDialog from '../dialogs/CreatePresstoDialog';
import CreateClassroomDialog from '../dialogs/CreateClassroomDialog';
import { useUserStore } from '../../zustand/user';
import { lang } from '../../lang';

function GalleryTopBar({ tab, changeTab, hideTabs }: GalleryTobBarProps) {
  const mobileMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const tabletMQ = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const user = useUserStore((state) => state.user);

  const [isCreatePresstoDialogOpen, setIsCreatePresstoDialogOpen] = useState(false);
  const [isCreateClassroomDialogOpen, setIsCreateClassroomDialogOpen] = useState(false);

  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (!changeTab) {
      return;
    }

    const routeViewsObject: {
      [key: number]: string
    } = {
      0: 'gallery',
      1: 'assignment',
      2: 'classroom',
    };

    localStorage.setItem('galleryView', routeViewsObject[newValue]);

    changeTab(newValue);
  };

  const createPressto = (format: string, meta: any) => {
    const metaString = meta ? JSON.stringify(meta) : '{}';
    navigate(`/document/new?format=${format}`, { state: { meta: metaString } });
  };

  const renderButton = () => {
    if (user.role === 'teacher') {
      return <Button style={{ padding: '0rem 1.5rem' }} label={lang('gallery.top_bar.invite')} onClick={() => setIsCreateClassroomDialogOpen(true)} />;
    }
    return <Button label={lang('gallery.top_bar.new_pressto')} onClick={() => setIsCreatePresstoDialogOpen(true)} />;
  };

  const renderTabs = () => (tab !== null && user.role !== 'individual' ? (
    <div className="tab-container">
      <Tabs
        value={tab}
        variant="fullWidth"
        aria-label="gallery tabs"
        classes={{ indicator: 'tabs-indicator' }}
      >
        <Tab
          label={lang('general.gallery')}
          id="gallery-tab"
          aria-controls="gallery-tabpanel"
          onClick={(e) => handleChange(e, 0)}
          value={0}
        />
        <Tab
          label={lang('general.assignment')}
          id="assignments-tab"
          aria-controls="assignments-tabpanel"
          onClick={(e) => handleChange(e, 1)}
          value={1}
        />
        {!['student'].includes(user.role!) && (
          <Tab
            label={lang('general.classroom')}
            id="classrooms-tab"
            aria-controls="classrooms-tabpanel"
            onClick={(e) => handleChange(e, 2)}
            value={2}
          />
        )}
      </Tabs>
    </div>
  ) : null);

  const { pathname } = useLocation();

  const { showBackButton, prevRoute } = useMemo(() => {
    if (!['classroom', 'profile'].some((el) => pathname.includes(el))) {
      return { showBackButton: false, prevRoute: null };
    }

    if (!pathname.includes('classroom')) {
      return { showBackButton: true, prevRoute: '/gallery' };
    }

    const prev = pathname.split('/student/')[0];
    return { showBackButton: true, prevRoute: prev };
  }, [pathname]);

  const showTabs = !hideTabs && user.role !== 'individual';

  return (
    <>
      <HeaderContainer hideTabs={!showTabs}>
        <TopBarWrapper hideTabs={!showTabs}>
          <TopBarContainer>
            <div style={{ display: 'flex', gap: '1.5rem' }}>
              {showBackButton && mobileMQ && (
                <IconButton onClick={() => navigate(prevRoute ?? '/gallery')}>
                  <img
                    src={LargeBackArrowImg}
                    alt="return"
                  />
                </IconButton>
              )}
              <img src={mobileMQ ? LogoSmallImg : LogoImg} alt="pressto logo" style={{ maxHeight: '2.25rem' }} />
            </div>
            {!hideTabs && !tabletMQ && renderTabs()}
            <div className="right-content">
              {renderButton()}
              <AvatarMenu />
            </div>
          </TopBarContainer>
          {tabletMQ && showTabs && (
            <TopBarContainer>
              {renderTabs()}
            </TopBarContainer>
          )}
        </TopBarWrapper>
      </HeaderContainer>
      <CreatePresstoDialog
        onSubmit={createPressto}
        isOpen={isCreatePresstoDialogOpen}
        isAssignmentFlow={false}
        onClose={() => setIsCreatePresstoDialogOpen(false)}
      />
      <CreateClassroomDialog
        isOpen={isCreateClassroomDialogOpen}
        onOpen={() => setIsCreatePresstoDialogOpen(true)}
        onClose={() => setIsCreateClassroomDialogOpen(false)}
      />
    </>
  );
}

export default GalleryTopBar;
