import {
  Document, DocumentVersion, BlockPage, isBlockPage,
} from '../../../types/models';
import SynchronousDocumentOperation from './synchronous';

function getPageContainingBlock(version: DocumentVersion, blockId: string): BlockPage | null {
  for (let i = 0; i < version.pages.length; i += 1) {
    const page = version.pages[i];
    if (!isBlockPage(page)) {
      return null;
    }

    for (let j = 0; j < page.grid.blocks.length; j += 1) {
      if (page.grid.blocks[j].id === blockId) {
        return page;
      }
    }
  }

  return null;
}

export default class DeleteBlockOperation extends SynchronousDocumentOperation {
  readonly type = 'delete-block';

  blockId: string;

  constructor(documentId: string, blockId: string) {
    super(documentId);
    this.blockId = blockId;
  }

  apply(document: Document): Document {
    // Return a new document version with this block updated.
    // TODO: Commented until Page->Block relationship is defined.
    const { version } = document;
    const page = getPageContainingBlock(version, this.blockId);
    if (!page) {
      return document;
    }

    const pageIndex = version.pages.indexOf(page);
    const newPage = {
      ...page,
      grid: {
        ...page.grid,
        blocks: page.grid.blocks.filter((b) => b.id !== this.blockId),
      },
    };

    return {
      ...document,
      version: {
        ...version,
        pages: [
          ...version.pages.slice(0, pageIndex),
          newPage,
          ...version.pages.slice(pageIndex + 1),
        ],
      },
    };
  }
}
