import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ThemeProvider } from '@material-ui/core';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { theme } from './utils';
import { EditorProvider } from './hooks/useEditor';
import { useUserStore } from './zustand/user';
import RouterRoot from './router';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? 'local',
    tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACESSAMPLERATE ?? '0.0'),
  });
}

function Wrapper() {
  const fetchCsrf = useUserStore((state) => state.fetchCsrfToken);

  const [loading, setLoading] = useState<boolean>(true);

  const fetchUser = async () => {
    const response = await fetchCsrf();
    if (typeof response === 'boolean') setLoading(false);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return loading ? <div /> : <RouterRoot />;
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <EditorProvider>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_ID || ''}>
          <Wrapper />
        </GoogleOAuthProvider>
      </EditorProvider>
    </ThemeProvider>
  );
}

export default App;
