import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { theme } from '../../utils';

export const LogoContainer = styled(Box)(() => ({
  marginBottom: '20px',
  [theme.breakpoints.down('md')]: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: '70px',
    },
  },

  textAlign: 'center',
  cursor: 'pointer',

  img: {
    height: '60px',
  },
}));
